import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import clsx from "clsx";

function LoginPaymentBlocker({condition, lg = true, login = false, subscription = false}) {
    const navigate = useNavigate()

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);
    return (
        <div className={'blocker-container'}>
            {
                ((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated)
                &&
                <div
                    className={clsx('wall-stopper-absolute wall-stopper-container', lg ? 'wall-stopper-container-lg' : "wall-stopper-container-sm")}>
                    <div
                        className={clsx('wall-stopper-content', lg ? 'wall-stopper-content-lg' : 'wall-stopper-content-sm')}>
                        <div style={{margin: 'auto'}}>
                            {(login && !isAuthenticated) &&
                                <>
                                    <p>{condition}</p>
                                    <button onClick={() => {
                                        navigate('/login')
                                    }}
                                            className={'btn form-control payment-subscribe-btn'}>Login
                                    </button>
                                </>
                            }
                            {(subscription && isAuthenticated && user.subscription.package_name === 'Free') &&
                                <>
                                    <p>You must be paid subscriber to use this feature</p>
                                    <button onClick={() => {
                                        navigate('/payment')
                                    }}
                                            className={'btn form-control payment-subscribe-btn'}>Subscribe
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default LoginPaymentBlocker;

