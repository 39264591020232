import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../Context/ThemeContext";

function OwnershipBreakdownChart({ownership}) {

    const {webTheme} = useContext(ThemeContext);
    const [data, setData] = useState([])
    const labels = ["Institutions", "Insiders", "Retail"]

    useEffect(() => {
        setData([
            parseFloat(ownership.PercentInstitutions.toFixed(2)),
            parseFloat(ownership.PercentInsiders.toFixed(2)),
            parseFloat((100 - ownership.PercentInsiders - ownership.PercentInstitutions).toFixed(2)),
        ])
    }, [ownership]);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const state = {
        series: data,
        options: {
            chart: {
                type: 'donut',
                foreColor: graphVar.color,
                width: 300,
            },
            dataLabels: {
                enabled: true,
            },
            colors: ['#ee1981', '#8929cd', '#3e9fed'],
            legend: {
                show: true,
                position: 'bottom'
            },
            labels: labels,
            responsive: [{
                breakpoint: 490,
                options: {
                    chart: {
                        width: 310
                    },
                    legend: {
                        position: 'top',
                        show: false
                    }
                }
            }]
        }
    };

    return (
        data.length > 0 &&
        <>
            <p className={'company-overview-pie-chart-title'}>Ownership
                Breakdown</p>
            <div className={'company-overview-pie-chart-container'}>
                <div className={'company-overview-pie-chart-inner-container'}
                     style={{display: 'flex', flexDirection: "column"}}>
                    <ReactApexChart options={state.options} series={data} type="pie" width={'100%'}
                                    height={'100%'}
                    />
                </div>
            </div>
        </>
    );
}

export default OwnershipBreakdownChart;