import React, {useEffect, useState} from 'react';
import {Tab, Tabs, useTheme} from "@material-ui/core";
import {a11yProps, getModalStyle, TabPanel} from "../Utils";
import {CgClose} from "react-icons/cg";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import ExchangeSearch from "../ISqoreTabs/ExchangeSearch";
import ETFExchangeSearch from "./ETFExchangeSearch";
import EtfSecondSearch from "./ETFSecondSearch";

function EtfFilter({
                       handleCloseModal,
                       etf_type,
                       setETFData,
                       setFilterETFData,
                       setETFLoadingStat,
                       return_instrument,

                       checkedExchange,
                       setCheckedExchange,
                       url,
                       checkedSecond,
                       setCheckedSecond,

                       setLoading
                   }) {
    const theme = useTheme();

    const [modalStyle] = useState(getModalStyle);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [tempCheckedExchange, setTempCheckedExchange] = useState(checkedExchange);

    const [tempCheckedSecond, setTempCheckedSecond] = useState(checkedSecond);

    return (
        <div style={modalStyle}
             className={'best-iSqore-filter-modal-body'}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <div className={'generic-tabs-container navbar-tab-container'}>

                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor={'secondary'}
                    >
                        <Tab label="Exchange" {...a11yProps(0)} />
                        <Tab label={etf_type === 'Thematic' ? "Thematic" : "Region"}
                             style={{}} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>

                <div className="tabsPanelContainer">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        disabled={true}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel className={'navbar-tab-panel'} value={value} index={0}
                                  dir={theme.direction}>
                            <ETFExchangeSearch
                                tempCheckedExchange={tempCheckedExchange}
                                setTempCheckedExchange={setTempCheckedExchange}
                                setCheckedExchange={setCheckedExchange}
                                setCheckedSecond={setCheckedSecond}
                                tempCheckedSecond={tempCheckedSecond}
                                setTempCheckedSecond={setTempCheckedSecond}
                                setLoading={setLoading}
                                url={url}
                                handleCloseModal={handleCloseModal}
                                setETFData={setETFData}
                                setFilterETFData={setFilterETFData}
                                setETFLoadingStat={setETFLoadingStat}
                                return_instrument={return_instrument}
                                etf_type={etf_type}
                            />
                        </TabPanel>
                        <TabPanel className={'navbar-tab-panel'} value={value} index={1}
                                  dir={theme.direction}>
                            <EtfSecondSearch
                                tempCheckedExchange={tempCheckedExchange}
                                setTempCheckedExchange={setTempCheckedExchange}
                                setCheckedExchange={setCheckedExchange}
                                setCheckedSecond={setCheckedSecond}
                                tempCheckedSecond={tempCheckedSecond}
                                setTempCheckedSecond={setTempCheckedSecond}
                                setLoading={setLoading}
                                url={url}
                                handleCloseModal={handleCloseModal}
                                setETFData={setETFData}
                                setFilterETFData={setFilterETFData}
                                setETFLoadingStat={setETFLoadingStat}
                                return_instrument={return_instrument}
                                etf_type={etf_type}
                            />
                        </TabPanel>
                    </SwipeableViews>
                </div>

            </div>

        </div>
    );
}

export default EtfFilter;