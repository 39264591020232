import React, {useEffect, useState} from 'react';
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import {Helmet} from "react-helmet-async";
import {a11yProps, TabPanel} from "../Container/Utils";
import SwipeableViews from "react-swipeable-views";
import DashboardLayout from "../Layout/DashboardLayout";
import TopInvestor from "../Container/GuruInvestor/TopInvestor";
import Holdings from "../Container/GuruInvestor/Holdings";
import {useParams} from "react-router-dom";

function GuruInvestor() {
    const theme = useTheme();

    const {name} = useParams();

    const [topInvestor, setTopInvestor] = useState([])

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (name) {
            setValue(1)
        }
    }, [name]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <Helmet>
                <title>Guru & Investor</title>
                <meta name={"description"}
                      content={"Guru & Investor"}/>
            </Helmet>
            <DashboardLayout>
                <div className={"generic-tabs-container"}>
                    <Box display="flex" justifyContent="left" width="100%">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            indicatorColor={'primary'}
                            scrollButtons="auto"
                        >
                            <Tab label="Top Investor" {...a11yProps(0)} />
                            <Tab label="Holdings"
                                 style={{}} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <div className="tabsPanelContainer company-overview-panel">
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            disabled={true}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel className={'generic-tab-panel'} value={value} index={0} dir={theme.direction}>
                                <TopInvestor setValue={setValue} topInvestor={topInvestor}
                                             setTopInvestor={setTopInvestor}/>
                            </TabPanel>

                            <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                                <Holdings name={name ?? "Michael-Burry"}/>
                            </TabPanel>

                        </SwipeableViews>
                    </div>
                </div>
            </DashboardLayout>
        </>
    );
}

export default GuruInvestor;