import React, {useState} from 'react';
import {AiOutlineClose} from "react-icons/ai";
import {getModalStyle} from "../Utils";

function FilterComponentExchangeModal({filterScreener, setFilterScreener, handleCloseRegionModel, type}) {
    const [modalStyle] = useState(getModalStyle);

    const regions = [
        {
            name: "US & Canada",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "United States",
                    country: "United States",
                    code: "US",
                },
                {
                    flag: "https://flagcdn.com/108x81/ca.png",
                    name: "Toronto Exchange",
                    country: "Canada",
                    code: "TO"
                },
                {
                    flag: "https://flagcdn.com/108x81/ca.png",
                    name: "TSX Venture",
                    country: "Canada",
                    code: "V"
                }
            ]
        },
        {
            name: "Europe",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "Xetra Exchange",
                    country: "Germany",
                    code: "XETRA"
                },
                {
                    flag: "https://flagcdn.com/108x81/gb.png",
                    name: "London Exchange",
                    country: "United Kingdom",
                    code: "LSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/fr.png",
                    name: "Euronext",
                    country: "France",
                    code: "PA"
                },
                {
                    flag: "https://flagcdn.com/108x81/it.png",
                    name: "Borsa Italiana",
                    country: "Italy",
                    code: "MI"
                },

                {
                    flag: "https://flagcdn.com/108x81/es.png",
                    name: "Madrid Exchange",
                    country: "Spain",
                    code: "MC"
                },
                {
                    flag: "https://flagcdn.com/108x81/nl.png",
                    name: "Amstadm Exchange",
                    country: "Netherlands",
                    code: "AS"
                },
                {
                    flag: "https://flagcdn.com/108x81/gr.png",
                    name: "Athens Exchange",
                    country: "Greece",
                    code: "AT"
                },
                {
                    flag: "https://flagcdn.com/108x81/be.png",
                    name: "Euronext Brussels",
                    country: "Belgium",
                    code: "BR"
                },

                {
                    flag: "https://flagcdn.com/108x81/dk.png",
                    name: "Copenhagen Exchange",
                    country: "Denmark",
                    code: "CO"
                },
                {
                    flag: "https://flagcdn.com/108x81/fi.png",
                    name: "Helsinki Exchange",
                    country: "Finland",
                    code: "HE"
                },
                {
                    flag: "https://flagcdn.com/108x81/ie.png",
                    name: "Irish Exchange",
                    country: "Ireland",
                    code: "IR"
                },
                {
                    flag: "https://flagcdn.com/108x81/tr.png",
                    name: "Istanbul Exchange",
                    country: "Turkey",
                    code: "IS"
                },


                {
                    flag: "https://flagcdn.com/108x81/pt.png",
                    name: "Euronext Lisbon",
                    country: "Portugal",
                    code: "LS"
                },
                {
                    flag: "https://flagcdn.com/108x81/no.png",
                    name: "Oslo Exchange",
                    country: "Norway",
                    code: "OL"
                },
                {
                    flag: "https://flagcdn.com/108x81/se.png",
                    name: "Stockholm Exchange",
                    country: "Sweden",
                    code: "ST"
                },
                {
                    flag: "https://flagcdn.com/108x81/ch.png",
                    name: "Swiss Exchange",
                    country: "Switzerland",
                    code: "SW"
                },


                {
                    flag: "https://flagcdn.com/108x81/il.png",
                    name: "Tel Aviv Exchange",
                    country: "Israel",
                    code: "TA"
                },
                {
                    flag: "https://flagcdn.com/108x81/at.png",
                    name: "Vienne Exchange",
                    country: "Austria",
                    code: "VI"
                },
                {
                    flag: "https://flagcdn.com/108x81/pl.png",
                    name: "Warsaw Exchange",
                    country: "Poland",
                    code: "WAR"
                }
            ]
        },
        {
            name: "Asia & Pacific",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/jp.png",
                    name: "Tokyo Exchange",
                    country: "Japan",
                    code: "TSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/hk.png",
                    name: "Hong Kong Exchange",
                    country: "Hong Kong",
                    code: "HK"
                },
                {
                    flag: "https://flagcdn.com/108x81/cn.png",
                    name: "Shanghai Exchange",
                    country: "China",
                    code: "SHG"
                },
                {
                    flag: "https://flagcdn.com/108x81/cn.png",
                    name: "Shenzhen Exchange",
                    country: "China",
                    code: "SHE"
                },


                {
                    flag: "https://flagcdn.com/108x81/tw.png",
                    name: "Taiwan Exchange",
                    country: "Taiwan",
                    code: "TW"
                },
                {
                    flag: "https://flagcdn.com/108x81/au.png",
                    name: "Australia Exchange",
                    country: "Australia",
                    code: "AU"
                },
                {
                    flag: "https://flagcdn.com/108x81/kr.png",
                    name: "Korea Stock Exchange",
                    country: "South Korea",
                    code: "KO"
                },
                {
                    flag: "https://flagcdn.com/108x81/cn.png",
                    name: "KOSDAQ",
                    country: "South Korea",
                    code: "KQ"
                },


                {
                    flag: "https://flagcdn.com/108x81/in.png",
                    name: "NSE Exchange",
                    country: "India",
                    code: "NSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/sg.png",
                    name: "Singapore Exchange",
                    country: "Singapore",
                    code: "SG"
                },
                {
                    flag: "https://flagcdn.com/108x81/id.png",
                    name: "Jakarta Exchange",
                    country: "Indonesia",
                    code: "JK"
                },
                {
                    flag: "https://flagcdn.com/108x81/pk.png",
                    name: "Karachi Exchange",
                    country: "Pakistan",
                    code: "KAR"
                },


                {
                    flag: "https://flagcdn.com/108x81/my.png",
                    name: "Kuala Lumpur Exchange",
                    country: "Malaysia",
                    code: "KLSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/th.png",
                    name: "Thailand Exchange",
                    country: "Thailand",
                    code: "BK"
                },
                {
                    flag: "https://flagcdn.com/108x81/ph.png",
                    name: "Philippine Exchange",
                    country: "Philippines",
                    code: "PSE"
                }
            ]
        },
        {
            name: "Others",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/za.png",
                    name: "Johannesburg Exchange",
                    country: "South Africa",
                    code: "JSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/br.png",
                    name: "Sao Paole Exchange",
                    country: "Brazil",
                    code: "SA"
                },
                {
                    flag: "https://flagcdn.com/108x81/mx.png",
                    name: "Mexico Exchange",
                    country: "Mexico",
                    code: "MX"
                },
                {
                    flag: "https://flagcdn.com/108x81/cl.png",
                    name: "Chilean Exchange",
                    country: "Chile",
                    code: "SN"
                },
                {
                    flag: "https://flagcdn.com/108x81/sa.png",
                    name: "Saudi Arabia Exchange",
                    country: "Saudi Arabia",
                    code: "SR"
                },
            ]
        }
    ]

    const indices = [
        {
            name: "US & Canada",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "S&P 500",
                    country: "United States",
                    code: "GSPC",
                },
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "S&P 100",
                    country: "United States",
                    code: "SP100",
                },
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "DowJones Industrial",
                    country: "United States",
                    code: "DJI",
                },
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "Russell 1000",
                    country: "United States",
                    code: "RUI",
                },
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "Russell 2000",
                    country: "United States",
                    code: "RUT",
                },
                {
                    flag: "https://flagcdn.com/108x81/us.png",
                    name: "Nasdaq 100",
                    country: "United States",
                    code: "NDX",
                },
                {
                    flag: "https://flagcdn.com/108x81/ca.png",
                    name: "TSX Composite",
                    country: "United States",
                    code: "GSPTSE",
                },
            ]
        },
        {
            name: "Europe",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/eu.png",
                    name: "Stoxx Europe 600",
                    country: "Europe",
                    code: "STOXX",
                },
                {
                    flag: "https://flagcdn.com/108x81/eu.png",
                    name: "EuroStoxx 50",
                    country: "Europe",
                    code: "STOXX50E",
                },


                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "DAX 40",
                    country: "Germany",
                    code: "GDAXI",
                },
                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "DAX MidCap",
                    country: "Germany",
                    code: "MDAXI",
                },
                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "DAX SmallCap",
                    country: "Germany",
                    code: "SDAXI",
                },
                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "TecDAX",
                    country: "Germany",
                    code: "TECDAX",
                },
                {
                    flag: "https://flagcdn.com/108x81/de.png",
                    name: "HDAX",
                    country: "Germany",
                    code: "GDAXHI",
                },


                {
                    flag: "https://flagcdn.com/108x81/gb.png",
                    name: "FTSE 100",
                    country: "United Kingdom",
                    code: "FTSE"
                },
                {
                    flag: "https://flagcdn.com/108x81/gb.png",
                    name: "FTSE 250",
                    country: "United Kingdom",
                    code: "FTMC"
                },
                {
                    flag: "https://flagcdn.com/108x81/gb.png",
                    name: "FTSE 350",
                    country: "United Kingdom",
                    code: "FTLC"
                },
                {
                    flag: "https://flagcdn.com/108x81/gb.png",
                    name: "FTSE All Shares",
                    country: "United Kingdom",
                    code: "FTAS"
                },

                {
                    flag: "https://flagcdn.com/108x81/it.png",
                    name: "FTSE MIB",
                    country: "Italy",
                    code: "SPMIB"
                },


                {
                    flag: "https://flagcdn.com/108x81/fr.png",
                    name: "CAC 40",
                    country: "France",
                    code: "FCHI"
                },
                {
                    flag: "https://flagcdn.com/108x81/fr.png",
                    name: "CAC All-Tradable",
                    country: "France",
                    code: "CACT"
                },


                {
                    flag: "https://flagcdn.com/108x81/es.png",
                    name: "IBEX 35",
                    country: "Spain",
                    code: "IBEX"
                },

                {
                    flag: "https://flagcdn.com/108x81/nl.png",
                    name: "AEX Index",
                    country: "Netherlands",
                    code: "AEX"
                },

                {
                    flag: "https://flagcdn.com/108x81/dk.png",
                    name: "OMX Copenhagen 20",
                    country: "Denmark",
                    code: "OMXC20"
                },
                {
                    flag: "https://flagcdn.com/108x81/dk.png",
                    name: "OMX Copenhagen All",
                    country: "Denmark",
                    code: "OMXCGI"
                },


                {
                    flag: "https://flagcdn.com/108x81/fi.png",
                    name: "OMX Helsinki 25",
                    country: "Finland",
                    code: "OMXH25"
                },
                {
                    flag: "https://flagcdn.com/108x81/fi.png",
                    name: "OMX Helsinki All",
                    country: "Finland",
                    code: "OMXHPI"
                },


                {
                    flag: "https://flagcdn.com/108x81/ie.png",
                    name: "ISEQ 20",
                    country: "Ireland",
                    code: "IETP"
                },


                {
                    flag: "https://flagcdn.com/108x81/se.png",
                    name: "OMX Stockholm 30",
                    country: "Sweden",
                    code: "OMXS30GI"
                },
                {
                    flag: "https://flagcdn.com/108x81/se.png",
                    name: "OMX Stockholm All",
                    country: "Sweden",
                    code: "OMXSPI"
                },


                {
                    flag: "https://flagcdn.com/108x81/no.png",
                    name: "OBX All Share",
                    country: "Norway",
                    code: "OSEAX"
                },


                {
                    flag: "https://flagcdn.com/108x81/at.png",
                    name: "Austrian Traded Index",
                    country: "Austria",
                    code: "ATX"
                },


                {
                    flag: "https://flagcdn.com/108x81/ch.png",
                    name: "Swiss Performance Index",
                    country: "Switzerland",
                    code: "SSHI"
                },
                {
                    flag: "https://flagcdn.com/108x81/ch.png",
                    name: "Swiss Market Index",
                    country: "Switzerland",
                    code: "SSMI"
                },


                {
                    flag: "https://flagcdn.com/108x81/il.png",
                    name: "Tel Aviv 35 Index",
                    country: "Israel",
                    code: "TA35"
                },
                {
                    flag: "https://flagcdn.com/108x81/il.png",
                    name: "Tel Aviv 35 Index",
                    country: "Israel",
                    code: "TA125"
                },


                {
                    flag: "https://flagcdn.com/108x81/pt.png",
                    name: "PSI 20",
                    country: "Portugal",
                    code: "PSI20"
                },


                {
                    flag: "https://flagcdn.com/108x81/pl.png",
                    name: "WIG 30",
                    country: "Poland",
                    code: "WIG30"
                },


                {
                    flag: "https://flagcdn.com/108x81/tr.png",
                    name: "BIST 30",
                    country: "Turkey",
                    code: "XU030"
                },
                {
                    flag: "https://flagcdn.com/108x81/tr.png",
                    name: "BIST 100",
                    country: "Turkey",
                    code: "XU100"
                },
            ]
        },
        {
            name: "Asia & Pacific",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/jp.png",
                    name: "Nikkei 225",
                    country: "Japan",
                    code: "N225",
                },


                {
                    flag: "https://flagcdn.com/108x81/hk.png",
                    name: "Hang Seng",
                    country: "Hong Kong",
                    code: "HSI",
                },
                {
                    flag: "https://flagcdn.com/108x81/hk.png",
                    name: "Hang Seng Composite",
                    country: "Hong Kong",
                    code: "HSCI",
                },
                {
                    flag: "https://flagcdn.com/108x81/hk.png",
                    name: "Hang Seng China Enterprise",
                    country: "Hong Kong",
                    code: "HSCE",
                },
                {
                    flag: "https://flagcdn.com/108x81/hk.png",
                    name: "Hang Seng Tech",
                    country: "Hong Kong",
                    code: "HSTECH",
                },


                {
                    flag: "https://flagcdn.com/108x81/cn.png",
                    name: "Shanghei 180",
                    country: "China",
                    code: "SSE180",
                },
                {
                    flag: "https://flagcdn.com/108x81/cn.png",
                    name: "SZSE Composite",
                    country: "China",
                    code: "SSE180",
                },


                {
                    flag: "https://flagcdn.com/108x81/au.png",
                    name: "ASX 50",
                    country: "Australia",
                    code: "AXAF",
                },
                {
                    flag: "https://flagcdn.com/108x81/au.png",
                    name: "ASX 100",
                    country: "Australia",
                    code: "ATOI",
                },
                {
                    flag: "https://flagcdn.com/108x81/au.png",
                    name: "ASX 200",
                    country: "Australia",
                    code: "AXAT",
                },


                {
                    flag: "https://flagcdn.com/108x81/in.png",
                    name: "Nifty 50",
                    country: "India",
                    code: "NSEI",
                },


                {
                    flag: "https://flagcdn.com/108x81/kr.png",
                    name: "Kosdaq Composite",
                    country: "South Korea",
                    code: "KOSDAQ",
                },


                {
                    flag: "https://flagcdn.com/108x81/tw.png",
                    name: "Taiwan Weighted Index",
                    country: "Taiwan",
                    code: "TWII",
                },


                {
                    flag: "https://flagcdn.com/108x81/my.png",
                    name: "FTSE Malaysia KLCI",
                    country: "Malaysia",
                    code: "KLSE",
                },


                {
                    flag: "https://flagcdn.com/108x81/id.png",
                    name: "FTSE Straits Times",
                    country: "Malaysia",
                    code: "Indonesia",
                },


                {
                    flag: "https://flagcdn.com/108x81/ph.png",
                    name: "PSEI Index",
                    country: "Philippines",
                    code: "PSEI",
                },


                {
                    flag: "https://flagcdn.com/108x81/pk.png",
                    name: "Karachi 100",
                    country: "Pakistan",
                    code: "KSE100",
                },


                {
                    flag: "https://flagcdn.com/108x81/ph.png",
                    name: "SET Index",
                    country: "Thailand",
                    code: "SET",
                }
            ]
        },
        {
            name: "Others",
            options: [
                {
                    flag: "https://flagcdn.com/108x81/br.png",
                    name: "Bovespa Index",
                    country: "Brazil",
                    code: "BVSP",
                },
                {
                    flag: "https://flagcdn.com/108x81/br.png",
                    name: "Brazil 50",
                    country: "Brazil",
                    code: "IBX50",
                },


                {
                    flag: "https://flagcdn.com/108x81/mx.png",
                    name: "BMV IPC",
                    country: "Mexico",
                    code: "MXX",
                },


                {
                    flag: "https://flagcdn.com/108x81/cl.png",
                    name: "CLX IPSA",
                    country: "Chile",
                    code: "SPIPSA",
                },


                {
                    flag: "https://flagcdn.com/108x81/sa.png",
                    name: "Tadawul All Share",
                    country: "Saudi Arabia",
                    code: "TWII",
                }
            ]
        }
    ]

    const [filtered, setFiltered] = useState(type === 'exchanges' ? regions : indices)

    const filter = (e) => {
        const search = e.target.value

        if (search === '') {
            setFiltered(regions)
        } else {

            let filteredRegions = []

            const tempRegions = type === 'exchanges' ? regions : indices

            tempRegions.map(region => {
                let temp_region = {
                    ...region,
                    options: region.options.filter(exchange => (exchange.name.toLowerCase().includes(search.toLowerCase()) || exchange.country.toLowerCase().includes(search.toLowerCase()) || exchange.code.toLowerCase().includes(search.toLowerCase())))
                }
                if (temp_region.options.length) {
                    filteredRegions.push(temp_region)
                }
            })

            setFiltered(filteredRegions)

        }
    }

    const handleExchangeClick = (exchange) => {
        if (filterScreener['General']['components'][type].value.filter(item => item.code === exchange.code).length) {
            setFilterScreener(
                {
                    ...filterScreener,
                    "General": {
                        ...filterScreener['General'],
                        components: {
                            ...filterScreener['General']['components'],
                            [type]: {
                                ...filterScreener['General']['components'][type],
                                value: filterScreener['General']['components'][type]['value'].filter(item => item.code !== exchange.code)
                            }
                        }
                    }
                })
        } else {
            setFilterScreener(
                {
                    ...filterScreener,
                    "General": {
                        ...filterScreener['General'],
                        components: {
                            ...filterScreener['General']['components'],
                            [type]: {
                                ...filterScreener['General']['components'][type],
                                value: [exchange, ...filterScreener['General']['components'][type]['value']]
                            }
                        }
                    }
                })
        }
    }

    const handleSelectAllBtn = () => {
        const exchanges = []

        if (type === 'exchanges') {
            regions.map(region =>
                region.options.map(exchange => {
                    exchanges.push(exchange)
                })
            )
        } else {
            indices.map(index =>
                index.options.map(option => {
                    exchanges.push(option)
                })
            )
        }

        setFilterScreener(
            {
                ...filterScreener,
                "General": {
                    ...filterScreener['General'],
                    components: {
                        ...filterScreener['General']['components'],
                        [type]: {
                            ...filterScreener['General']['components'][type],
                            value: exchanges
                        }
                    }
                }
            })
    }

    const handleClearAllBtn = () => {
        setFilterScreener({
            ...filterScreener,
            "General": {
                ...filterScreener['General'],
                components: {
                    ...filterScreener['General']['components'],
                    [type]: {
                        ...filterScreener['General']['components'][type],
                        value: []
                    }
                }
            }
        })
    }

    return (
        <div style={modalStyle} className={'generic-modal-body screener-filter-modal exchange-filter-modal'}>
            <div style={{display: 'flex', margin: "10px 0"}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <AiOutlineClose onClick={handleCloseRegionModel}
                                className={'search-close-modal-button'}/>
            </div>

            <input
                onChange={filter}
                className={'form-control exchange-search-filter'}
                placeholder={`Search ${type === "exchanges" ? "Exchanges i.e Xetra" : "Index i.e DJI"}`}/>

            <div className={'exchange-list'}>
                {filtered.length ?
                    filtered.map((region, index) => {
                        return (
                            <div key={index}>
                                <h2 className={'search-modal-text-heading'}
                                    style={{margin: "10px 0", fontSize: 17}}>{region.name}</h2>

                                <div className="row g-0">
                                    {region.options.map((exchange, index) => {
                                        return (
                                            <div
                                                className={'col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mb-1'}>
                                                <div style={{paddingRight: 5}}>
                                                    <div
                                                        onClick={() => handleExchangeClick(exchange)}
                                                        className={'screener-exchange-div'}>
                                                        <img
                                                            style={{width: 30, height: 20, margin: "auto 10px auto 0"}}
                                                            src={exchange.flag}
                                                            alt={'exchange'}
                                                        />
                                                        <div>
                                                            <p className={'exchange-searched-name'}
                                                               style={{textAlign: 'left'}}>{exchange.name}</p>
                                                            <p className={'exchange-searched-country'}
                                                               style={{textAlign: 'left'}}>{exchange.country}</p>
                                                        </div>

                                                        <input className="form-check-input filter-exchange-checkbox"
                                                               style={{margin: "auto 0 auto auto"}}
                                                               type="checkbox" value="" id="flexCheckChecked"
                                                               checked={filterScreener['General']['components'][type].value.filter(item => item.code === exchange.code).length}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        )
                    })
                    :
                    <p className={'no-companies-found-text'}>No Exchange Found</p>
                }
            </div>
            <div style={{display: 'flex', marginTop: 15}}>
                <button className={'btn btn-danger clear-all-btn'} style={{marginRight: 10}}
                        onClick={handleClearAllBtn}>Clear All
                </button>
                <button className={'btn checked-filter-btn'} style={{marginRight: 10, marginLeft: 0}}
                        onClick={handleSelectAllBtn}>Select All
                </button>
                <button className={'btn checked-filter-btn'} style={{margin: 0}}
                        onClick={handleCloseRegionModel}>Done
                </button>
            </div>
        </div>
    );
}

export default FilterComponentExchangeModal;
