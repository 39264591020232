import {useEffect, useState} from 'react';
import clsx from "clsx";
import {Modal} from "@material-ui/core";
import SelectPaymentTypeModal from "../Payments/SelectPaymentTypeModal";
import {useDispatch, useSelector} from "react-redux";
import {free_subscription, load_user} from "../../actions/actions";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import {info_complete} from "../Utils";
import {AiOutlineCloseCircle} from "react-icons/ai";
import QueryString from "query-string";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

function PaymentColumns({euro}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const user = useSelector(state => state.actions.user);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const packages = {
        'monthly': [
            {
                name: 'Free Plan',
                description: 'The ultimate beginner package - everything you need to get started',
                actual_price: 0,
                price: '0',
                sub_price: '00',
                items: ["Market Overview", "Company Key facts", "ETF & Index Key facts", "1 Watchlist", "10 Year Financials"],
                order: 1
            },
            {
                name: 'Basic Plan',
                type: 'Monthly',
                description: 'Unlock more features and tools - this is the right plan for you',
                actual_price: 7.99,
                price: '7',
                sub_price: '99',
                items: ["14-Day Money Back Guarantee", "50+ Exchanges", "40,000+ Stocks", "10 Watchlist", 'iSqore Ratings', 'TSI Score', 'Rule of 40', 'Fair Value Analysis', 'ETF & Index Components', 'ETF & Stock Screener', 'many other features'],
                order: 2
            }
        ],
        'annual': [
            {
                name: 'Free Plan',
                description: 'The ultimate beginner package - everything you need to get started',
                actual_price: 0,
                price: '0',
                sub_price: '00',
                items: ["Market Overview", "Company Key facts", "ETF & Index Key facts", "1 Watchlist", "10 Year Financials"],
                order: 1
            },
            {
                name: 'Basic Plan',
                type: 'Annual',
                description: 'Unlock more features and tools - this is the right plan for you',
                actual_price: 79.99,
                price: '79',
                sub_price: '99',
                items: ["14-Day Money Back Guarantee", "50+ Exchanges", "40,000+ Stocks", "10 Watchlist", 'iSqore Ratings', 'TSI Score', 'Rule of 40', 'Fair Value Analysis', 'ETF & Index Components', 'ETF & Stock Screener', 'many other features'],
                order: 3
            }
        ]
    }

    const [order, setOrder] = useState(null)

    useEffect(() => {
        if (user['subscription']) {
            if (user['subscription']['payment_method'] === "Free") {
                setOrder(1)
            } else if (parseFloat(user['subscription']['amount']) === parseFloat(7.99)) {
                setOrder(2)
            } else if (parseFloat(user['subscription']['amount']) === parseFloat(79.99)) {
                setOrder(3)
            }
        }
    }, [])

    const handleFreeSubscription = () => {
        dispatch(free_subscription(navigate))
    }

    const [packageTypeActive, setPackageTypeActive] = useState('monthly')
    const [selectedPackage, setSelectedPackage] = useState(null)

    const changePackageType = (type) => {
        setPackageTypeActive(type)
    }

    const [open, setOpen] = useState(false);

    const handleOpenModal = (package_item) => {
        handleCloseConfirmCancelPaymentModel()
        if (package_item.order === 1) {
            handleFreeSubscription()
        } else {
            setOpen(true)
        }
    }
    const handleCloseModal = () => {
        setOpen(false)
    }

    const [openConfirmCancelPayment, setOpenConfirmCancelPayment] = useState(false)

    const [modalStyle] = useState(getModalStyle);

    const handleCloseConfirmCancelPaymentModel = () => {
        setOpenConfirmCancelPayment(false)
    }

    const handleOpenConfirmCancelPaymentModel = () => {
        setOpenConfirmCancelPayment(true)
    }

    useEffect(() => {
        const values = QueryString.parse(location.search);
        if (values.success) {
            toast.success('Subscription Successful', {duration: 5000})
            dispatch(load_user())
            navigate('/market-overview/')
        }

        if (values.canceled) {
            toast.error("Order canceled, you can make subscription whenever you are ready.", {duration: 5000})
        }
    }, []);

    const get_btn_text = (package_item) => {
        // {order === package_item.order ? "Active Plan" : (package_item.order === 1 ? "Downgrade" : "Subscribe")}
        if (order === package_item.order) {
            return "Active Plan"
        } else if (package_item.order > 1) {
            return "Subscribe"
        } else if (package_item.order === 1) {
            return "Downgrade"
        }
    }

    const btn_disabled = (package_item) => {
        if (package_item.name === 'Free Plan' && order > 1) {
            return false
        } else return order === package_item.order
    }

    return (
        <div className={'py-1'}>
            <div className={'my-5'} style={{display: 'flex'}}>
                <div style={{margin: "0 auto"}} className={'package-type-change-handler'}>
                    <div
                        className={clsx('payment-package-type payment-package-left', packageTypeActive === 'monthly' && 'payment-package-active-type')}
                        onClick={() => {
                            changePackageType('monthly')
                        }}>
                        Monthly
                    </div>
                    <div
                        className={clsx('payment-package-type payment-package-right', packageTypeActive === 'annual' && 'payment-package-active-type')}
                        onClick={() => {
                            changePackageType('annual')
                        }}>
                        Annual
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className={'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4'}>
                    <p className={'payment-explanation-text'}>Simple and Transparent Pricing</p>
                </div>
                {packages[packageTypeActive].map((package_item, index) => {
                    return (
                        <div key={index} className={'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4'}>
                            <div className={'package-column'}>
                                <p className={'payment-package-name'}>{package_item.name}</p>
                                <p className={'payment-package-description'}>{package_item.description}</p>

                                <p className={'package-price'}>
                                    <span className={'package-price-subtext'}>{euro ? "€" : "$"}</span>
                                    {package_item.price}
                                    <span className={'package-price-subtext'}>{package_item.sub_price}</span>
                                </p>

                                <hr/>

                                {package_item.items.map((item, index) => {
                                    return (
                                        <div key={index} style={{display: 'flex', marginBottom: 20}}>
                                            <p className={'package-list-item'}><span
                                                className={'tick'}>✓</span> {item}
                                            </p>
                                        </div>
                                    )
                                })}

                                <button
                                    disabled={btn_disabled(package_item)}
                                    // disabled={order === package_item.order}
                                    onClick={() => {
                                        if (isAuthenticated) {
                                            // toast('Payment buttons disabled temporarily for site maintenance. If you are facing a problem. Please contact support.')
                                            if (info_complete(user)) {
                                                setSelectedPackage(package_item)
                                                if (user['subscription'] && user['subscription']['payment_method'] !== "Free") {
                                                    handleOpenConfirmCancelPaymentModel()
                                                } else {
                                                    handleOpenModal(package_item)
                                                }
                                            } else {
                                                toast.error("Necessary Information missing", {duration: 4000})
                                                navigate('/profile-info/')
                                            }
                                        } else {
                                            navigate('/login/')
                                        }
                                    }}
                                    className={clsx('btn form-control subscribe-btn', btn_disabled(package_item) ? "active-subscribe-btn" : "payments-subscribe-btn")}>
                                    {get_btn_text(package_item)}
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>

            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectPaymentTypeModal euro={euro} handleCloseModal={handleCloseModal}
                                        selectedPackage={selectedPackage}/>
            </Modal>

            <Modal
                open={openConfirmCancelPayment}
                onClose={handleCloseConfirmCancelPaymentModel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle}
                     className={clsx('generic-modal-body cancel-subscription-modal')}>
                    <div className={'cancel-subscription-modal-heading-container'}>
                        <p className={'cancel-subscription-modal-heading'}>Payment method removal</p>
                        <AiOutlineCloseCircle onClick={handleCloseConfirmCancelPaymentModel}
                                              className={'cancel-subscription-modal-close'}
                        />
                    </div>
                    <hr className={'cancel-subscription-modal-hr'}/>
                    <p className={'cancel-subscription-modal-text'}>
                        Are you sure you want to cancel your current paid subscription for a new subscription ?
                    </p>

                    <div className={'cancel-subscription-modal-footer-container'}>
                        <button className={'btn btn-outline-secondary btn-close-payment-modal'}
                                onClick={handleCloseConfirmCancelPaymentModel}>
                            No
                        </button>
                        <button onClick={() => {
                            handleOpenModal(selectedPackage)
                        }} className={'btn btn-continue-payment-modal'}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>

        </div>
    );
}

export default PaymentColumns;
