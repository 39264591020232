import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import ReactApexChart from "react-apexcharts";

function DividendLineGraph({dividends}) {
    const {webTheme} = useContext(ThemeContext);

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([])
    const [dates, setDates] = useState([])

    useEffect(() => {
        if (dividends) {
            let _data = []
            let _dates = []

            dividends['dividends'].map(item => {
                _data.push(item.value)
                _dates.push(item.date)
            })

            setData(_data)
            setDates(_dates)
        }
    }, [dividends])

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const series = [{
        name: 'Value',
        data: data,
        color: "#03f3a0"
    }]

    const options = {
        chart: {
            width: '100%',
            type: 'area',
            foreColor: graphVar.color
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: dates
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(2)
                }
            }
        },
        tooltip: {
            style: {
                color: 'red'
            },
            x: {
                format: 'dd/MM/yy'
            },
        }
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'dividend-chart-container'}>
            <div className={'dividend-chart-inner-container'}>
                <div id="chart">
                    <div className="toolbar">
                        <p className={'market-overview-chart-name'}>Dividends</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type="area" width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default DividendLineGraph;