import React from 'react';

function Maintenance({}) {
    return (
        <div style={{display: "flex", width: '100vw', height: '100vh'}}>
            <div style={{margin: "auto"}}>
                <h1>We'll be back soon!</h1>
                <p>Sorry for the inconvenience, but we're performing some maintenance at the moment. We'll be back
                    online shortly!</p>
            </div>
        </div>
    );
}

export default Maintenance;