import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../../Config";
import {divide, multiple} from "../../Utils";
import TopFlopsTableBody from "../TopFlopsTableBody";

const indexCompaniesAPI = async (indexCompanies) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-index-companies/${indexCompanies}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function MarketOverviewTopFlop({indexCompanies, setChartData}) {

    const [highPerformers, setHighPerformers] = useState([])
    const [lowPerformers, setLowPerformers] = useState([])

    const [counts, setCount] = useState({
        positiveCount: 0,
        positiveCountPer: 0,

        negativeCount: 0,
        negativeCountPer: 0
    })

    const {data: indexCompaniesReturn, isLoading: indexCompaniesIsLoading} = useQuery({
        queryKey: [`index-companies/${indexCompanies.code}/`, indexCompanies.code],
        queryFn: () => indexCompaniesAPI(indexCompanies.code),
        staleTime: 30000
    })

    useEffect(() => {
        if (!indexCompaniesIsLoading && indexCompaniesReturn) {
            setHighPerformers(indexCompaniesReturn['high_performers'])
            setLowPerformers(indexCompaniesReturn['low_performers'])

            const sum = indexCompaniesReturn['positive_sum'] + indexCompaniesReturn['negative_sum']

            setCount({
                positiveCount: indexCompaniesReturn['positive_sum'],
                positiveCountPer: multiple(divide(indexCompaniesReturn['positive_sum'], sum), 100),

                negativeCount: indexCompaniesReturn['negative_sum'],
                negativeCountPer: multiple(divide(indexCompaniesReturn['negative_sum'], sum), 100)
            })
        }
    }, [indexCompaniesReturn, indexCompaniesIsLoading])

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'} style={{display: "block", padding: "10px"}}>
                <p>{indexCompanies.name}</p>

                <div className={'d-flex'} style={{marginTop: 5}}>
                    <p style={{fontSize: 12}}>{counts.positiveCount}</p>
                    <p style={{fontSize: 12, marginLeft: "auto"}}>{counts.negativeCount}</p>
                </div>
                <div className={'progress analyst-progress-bar'} style={{width: '100%'}}>
                    <div className="progress-bar" role="progressbar"
                         style={{
                             width: `${counts.positiveCountPer}%`,
                             backgroundColor: "#28c07f",
                             borderRadius: '5px 0 0 5px'
                         }}
                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                    <div className="progress-bar" role="progressbar"
                         style={{
                             width: `${counts.negativeCountPer}%`,
                             backgroundColor: "#ea165d",
                             borderRadius: '0 5px 5px 0'
                         }}
                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                </div>
            </div>

            <TopFlopsTableBody indexCompanies={indexCompanies} companies={highPerformers} setCompanies={setHighPerformers}
                               tableName={'Tops'}
                               setChartData={setChartData}/>

            <TopFlopsTableBody indexCompanies={indexCompanies} companies={lowPerformers}  setCompanies={setLowPerformers}
                               tableName={'Flops'}
                               setChartData={setChartData}/>

            {/*<MarketOverviewIndicesTable  body={body} setBody={setBody} setChartData={setChartData} setIndexCompanies={setIndexCompanies}/>*/}
        </div>
    );
}

export default MarketOverviewTopFlop;