import {Link} from "@material-ui/core";
import {return_grade_color, return_per_div, return_strong_num} from "../../Utils";

const IndexTopBar = ({index, selectedIndexData}) => {

    const calculate_bar = () => {
        const dif1 = selectedIndexData['indices']['real-time']['hi_250d'] - selectedIndexData['indices']['real-time']['lo_250d']
        const dif2 = selectedIndexData['indices']['real-time']['hi_250d'] - selectedIndexData['indices']['real-time']['adjusted_close']
        return (1 - dif2 / dif1) * 100
    }

    return (
        <div className={'index-top-bar'}>
            <div className={'top-bar-container'}>
                <div className={'top-bar-inner-container'}>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>52 Week Low</p>
                        <p className={'bar-sm-text'}>{selectedIndexData['indices']['real-time']['lo_250d']}</p>
                    </div>
                    <div className={'bar-component'}>
                        <div style={{width: "100%", height: "100%", display: 'flex'}}>
                            <div style={{margin: 'auto', position: "relative", height: '100%', width: 200}}>
                                <div style={{
                                    width: 5,
                                    height: 5,
                                    borderRadius: "50%",
                                    backgroundColor: "#213E44",
                                    position: "absolute",
                                    top: '45.835%',
                                    left: 0
                                }}>
                                </div>
                                <div style={{
                                    height: 2,
                                    width: 200,
                                    backgroundColor: "#213E44",
                                    position: "absolute",
                                    top: '50%',
                                    left: 0
                                }}>
                                </div>
                                <div style={{
                                    width: 5,
                                    height: 5,
                                    borderRadius: "50%",
                                    backgroundColor: "#213E44",
                                    position: "absolute",
                                    top: '45.835%',
                                    left: '100%'
                                }}>
                                </div>
                                <div style={{
                                    height: 20,
                                    width: 5,
                                    backgroundColor: "#1BA2DA",
                                    position: "absolute",
                                    top: '25%',
                                    left: `${calculate_bar()}%`
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>52 Week High</p>
                        <p className={'bar-sm-text'}>{selectedIndexData['indices']['real-time']['hi_250d']}</p>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>Last Price</p>
                        <div style={{display: "flex"}}>
                            <p className={'bar-sm-text'} style={{margin: "auto 5px auto 0"}}>{return_strong_num(selectedIndexData['indices']['real-time']['close'])}</p>
                            {return_per_div(selectedIndexData['indices']['real-time']['change_p'])}
                        </div>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>Index Members</p>
                        <p className={'bar-sm-text'}>{selectedIndexData.indices.display_component_length}</p>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>Exchange Website</p>
                        <Link href={`${index.realUrl}`} target="_blank" rel="noopener noreferrer">
                            <p
                                className={'bar-sm-text'}
                                style={{textDecoration: 'underline', cursor: 'pointer'}}
                            >{index.displayUrl}</p>
                        </Link>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>Country</p>
                        <p className={'bar-sm-text'}>{index.country}</p>
                    </div>
                    <div className={'bar-component'}>
                        <p className={'bar-lg-text'}>iSqore</p>
                        <p className={'bar-sm-text'}>{return_strong_num(selectedIndexData.indices.average_iSqore * 100)} |&nbsp;
                            <span style={{color: return_grade_color(selectedIndexData.indices.average_iSqoreGrade)}}>
                                {selectedIndexData.indices.average_iSqoreGrade}
                            </span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndexTopBar;