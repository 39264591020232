import React, {useEffect, useState} from "react";
import {return_per_div, return_strong_num} from "../../../Utils";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";

const Watchlist = ({watchlists, setWatchList, handleSetChartData}) => {

    const [lettersLength, setLettersLength] = useState(35)

    const [changeSort, setChangeSort] = useState(false)

    function handleResize() {
        if (window.innerWidth <= 768) {
            setLettersLength(15)
        } else {
            setLettersLength(35)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])

    const sort = (order) => {
        setChangeSort(!changeSort)
        if (order) {
            setWatchList([...watchlists].sort((a, b) => {
                if (a['change_p'] < b['change_p']) {
                    return -1;
                }
                if (a['change_p'] > b['change_p']) {
                    return 1;
                }
                return 0;
            }))
        } else {
            setWatchList([...watchlists].sort((a, b) => {
                if (a['change_p'] > b['change_p']) {
                    return -1;
                }
                if (a['change_p'] < b['change_p']) {
                    return 1;
                }
                return 0;
            }));
        }
    }

    return (
        <>
            {watchlists.length > 0 &&
                <div className={'market-overview-summary-table-body-container'} style={{
                    borderRadius: '0 0 10px 10px',
                }}>
                    <table className={'table generic-table-body'}>
                        <tbody>
                        <tr>
                            <td style={{fontWeight: 600}}>
                                Name
                            </td>
                            <td style={{width: 60, fontWeight: 600}}>
                                Symbol
                            </td>
                            <td style={{width: 65, textAlign: 'right', fontWeight: 600}}>
                                Price
                            </td>
                            <td style={{width: 78, textAlign: 'right', fontWeight: 600, cursor: "pointer"}} onClick={() => {
                                sort(changeSort)
                            }}>
                                Change %{!changeSort ? <BsArrowUp/> : <BsArrowDown/>}
                            </td>
                        </tr>
                        {watchlists.map((watchlist, index) => {
                            return (
                                <tr key={index}  style={{borderBottom: "none"}}>
                                    <td className={watchlist.name.length > lettersLength && "hovertext"}
                                        data-hover={watchlist.name}
                                        onClick={() => {
                                            handleSetChartData({
                                                name: watchlist.name,
                                                code: watchlist.code,
                                                exchange: watchlist.exchange,
                                                type: "Common Stock"
                                            })
                                        }}
                                    >
                                        {watchlist.name.length > lettersLength ? watchlist.name.substring(0, lettersLength) + "..." : watchlist.name}
                                    </td>
                                    <td>
                                        {watchlist.code}
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        {return_strong_num(watchlist.adjusted_close)}
                                    </td>
                                    <td style={{
                                        display: 'flex',
                                        paddingRight: 2
                                    }}>
                                        {return_per_div(watchlist.change_p)}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}

export default Watchlist;