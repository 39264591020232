import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import {useEffect, useState} from "react";
import SwipeableViews from "react-swipeable-views";
import EtfProfiler from "./ETFProfiler";
import {a11yProps, TabPanel} from "../../../Utils";
import {useDispatch, useSelector} from "react-redux";
import EtfTypeTab from "../../../ETF/ETFTypeTab";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {load_common_etf} from "../../../../actions/actions";
import Config from "../../../../Config";
import config from "../../../../Config";


const EtfTab = () => {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const location = useLocation()
    const tab = location.pathname.split('/')[2]

    const etf = useSelector(state => state.actions.etf)

    const [loader, setLoader] = useState(true)

    const [chartData, setChartData] = useState({name: "", code: "", exchange: "", type: "ETF"})

    const [data, setData] = useState(null)

    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const {code, exchange} = useParams()

    const dispatch = useDispatch()

    const [priceData, setPriceData] = useState({
        price: 0,
        change: 0,
        changesPercentage: 0,
        adjusted_close_date: 0
    })

    const [iSqore, setISqore] = useState(null)

    useEffect(() => {
        if (isAuthenticated && user.subscription.package_name !== "Free") {
            if (code && exchange) {
                axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/etf-top-bar-iSqore/${code}/${exchange}/`, Config())
                    .then(response => {
                        setISqore(response.data)
                    })
                    .catch(err => {
                        console.log(err.message())
                    })
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/etf-top-bar-iSqore/${etf.code}/${etf.exchange}/`, Config())
                    .then(response => {
                        console.log(response.data)
                        setISqore(response.data)
                    })
                    .catch(err => {
                        console.log(err.message())
                    })
            }
        }
    }, [code, exchange, isAuthenticated, user.subscription])

    useEffect(() => {
        if (code && exchange) {
            setValue(4)
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/etf/${code}/${exchange}/`, Config())
                .then(response => {
                    setData(response.data)
                    setChartData({name: response.data.name, exchange: exchange, code: response.data.code, type: "ETF"})
                    setPriceData({
                        price: response.data.price,
                        change: response.data.change,
                        changesPercentage: response.data.changesPercentage,
                        adjusted_close_date: response.data.adjusted_close_date
                    })
                    setLoader(false)
                    dispatch(load_common_etf(code, exchange))
                })
            setLoader(true)
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/etf/${etf.code}/${etf.exchange}/`, Config())
                .then(response => {
                    if (tab) {
                        setValue(4)
                    }
                    setData(response.data)
                    setChartData({
                        name: response.data.name,
                        exchange: etf.exchange,
                        code: response.data.code,
                        type: "ETF"
                    })
                    setPriceData({
                        price: response.data.price,
                        change: response.data.change,
                        changesPercentage: response.data.changesPercentage,
                        adjusted_close_date: response.data.adjusted_close_date
                    })
                    setLoader(false)
                })
            setLoader(true)
        }
    }, [code, exchange])

    const load_fmp_prices = () => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/etf-fmp-prices/`, {code: data['fmp_code']}, config)
            .then(response => {
                const resp = response.data
                if (resp.length >= 1) {
                    setPriceData({
                        price: resp[0].price,
                        change: resp[0].change,
                        changesPercentage: resp[0].changesPercentage,
                        adjusted_close_date: new Date(resp[0].timestamp * 1000).toISOString().slice(0, 19).replace('T', ' ')
                    })
                }
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        let interval;
        if (data && 'fmp_code' in data && data['fmp_code']) {
            load_fmp_prices()

            interval = setInterval(() => {
                load_fmp_prices()
            }, 30000)

        }
        return () => clearInterval(interval);
    }, [data])

    return (
        <div className={'generic-tabs-container'}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Global ETFs"
                         {...a11yProps(0)} />
                    <Tab label="Sector ETFs"
                         {...a11yProps(1)} />
                    <Tab label="Dividend ETFs"
                         {...a11yProps(2)} />
                    <Tab label="Thematic ETFs"
                         {...a11yProps(3)} />
                    <Tab label="ETF Profile"
                         {...a11yProps(4)} />
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel className={'generic-tab-panel'} value={value} index={0} dir={theme.direction}>
                    <EtfTypeTab etf_type={'Global'} setValue={setValue}/>
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                    <EtfTypeTab etf_type={'Sector'} setValue={setValue}/>
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                    <EtfTypeTab etf_type={'Dividend'} setValue={setValue}/>
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={3} dir={theme.direction}>
                    <EtfTypeTab etf_type={'Thematic'} setValue={setValue}/>
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={4} dir={theme.direction}>
                    <EtfProfiler code={etf.code ?? code} exchange={etf.exchange ?? exchange} chartData={chartData} data={data} iSqore={iSqore} priceData={priceData} loader={loader}/>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default EtfTab;