import React, {useEffect, useState} from 'react';
import {info_complete} from "../Utils";
import {BsPencil} from "react-icons/bs";
import ProfilePersonalInformation from "./ProfilePersonalInformation";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import axios from "axios";
import Config from "../../Config";
import {load_user} from "../../actions/actions";

function PersonalInformationTab({user, userData, setUserData}) {
    const dispatch = useDispatch()
    const [edit, setEdit] = useState(false);

    const updateProfile = () => {
        toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/update-user/`, userData, Config()), {
            loading: 'Updating User information...',
            success: (response) => {
                dispatch(load_user())
                return 'User information updated successfully'
            },
            error: 'Error updating user information. Please try again'
        })
    }

    return (
        <div className="profile-container">
            <div style={{display: 'flex', marginBottom: 15}}>
                <p className={'profile-header'}>Personal Information {!info_complete(user) &&
                    <span style={{fontSize: 10}}>(Please fill this for Payment)</span>}</p>
                {!edit ?
                    <BsPencil className={'profile-pencil'} onClick={() => {
                        setEdit(!edit)
                    }}/>
                    :
                    <button className={'btn profile-save-btn'} onClick={() => {
                        updateProfile()
                        setEdit(!edit)
                    }}>
                        Save
                    </button>
                }
            </div>

            <ProfilePersonalInformation userData={userData} setUserData={setUserData} edit={edit}/>
        </div>
    );
}

export default PersonalInformationTab;