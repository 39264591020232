import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import Config from "../../../Config";
import {ThemeContext} from "../../../Context/ThemeContext";
import {return_strong_num} from "../../Utils";

function AnalystForcastChart({code, exchange, upside, currency}) {
    const {webTheme} = useContext(ThemeContext);
    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })
    const [graphVarChange, setGraphVarChange] = useState(false)
    const [loading, setLoading] = useState(true)

    const [dates, setDates] = useState([])

    const [series, setSeries] = useState([{
        name: '',
        data: []
    }])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-analyst-forcast-highlights-chart/${code}/${exchange}/`, Config())
            .then(response => {
                setSeries([{
                    name: '',
                    data: [...response.data.prices.map(item => return_strong_num(item)), return_strong_num(response.data.WallStreetTargetPrice)]
                }])
                setDates([...response.data.dates, response.data.future_date])
                setLoading(false)
            })
            .catch(err => console.log(err.message()))
    }, [code, exchange])

    const options = {
        chart: {
            width: '100%',
            type: 'line',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        forecastDataPoints: {
            count: 1
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        yaxis: {
            opposite: true
        },
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
            tickAmount: 6,
            categories: dates,
            labels: {
                formatter: function (value, timestamp, opts) {
                    return opts.dateFormatter(new Date(timestamp), 'MMM yy')
                }
            }
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
        },
        annotations: {
            yaxis: [
                {
                    y: series[0].data.length !== 0 ? series[0].data[series[0].data.length - 1] : 0,
                    borderColor: webTheme === 'dark' ? "#0C1B29" : "#F5F6FA",
                    label: {
                        borderColor: upside > 0 ? '#4ebe9629' : '#d84f6829',
                        style: {
                            fontSize: 14,
                            fontWeight: 600,
                            color: upside > 0 ? '#4ebe96' : '#d84f68',
                            background: upside > 0 ? '#4ebe9629' : '#d84f6829',
                        },
                        text: `${series[0].data.length !== 0 ? series[0].data[series[0].data.length - 1] : 0} ${currency}`
                    }
                }
            ]
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                gradientToColors: ['#FDD835'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
            },
        },
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'price-target-chart-container'}>
            <div className={'price-target-chart-inner-container'}>
                <div id="chart">
                    <div className="analyst-toolbar">
                        <p className={'market-overview-chart-name'}>{code} - Analyst Price Target</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <div style={{width: "100%", height: "100%", marginLeft: 5}}>
                        <ReactApexChart options={options} series={series} type={'line'} width={'100%'}
                                        height={'90%'}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default AnalystForcastChart;