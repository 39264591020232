import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatNumericValue, return_grade_color, return_obj, return_string, return_strong_num} from "../Utils";
import CompanyOverviewFinancialsOverviewChart1
    from "./FinancialTabs/FinancialOverview/CompanyOverviewFinancialsOverviewChart1";
import iSqoreValueLight from "../../assets/blockers/iSqoreValueLight.png";
import iSqoreValueDark from "../../assets/blockers/iSqoreValueDark.png";
import RestrictedContent from "../RestrictedContent";
import ISqoreTable from "../ISqoreTabs/ISqoreTable";
import Config from "../../Config";

const ValuesTab = ({code, exchange}) => {
    const [chartData, setChartData] = useState({
        name: "",
        data: []
    })
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-value/${code}/${exchange}/`, Config())
            .then(response => {
                format_data(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    const format_data = (values) => {
        const data = {
            tableData: [
                return_obj(
                    "P/Earnings (Fwd)",
                    values['valuation']['ForwardPE'],
                    values['iSqore']['priceEarningsForwardGrade'],
                    values['median_sector']['priceEarningsTTM'],
                    values['calculation-3-year-avg']['historical_data']['priceEarnings'],
                    values['calculation-5-year-avg']['historical_data']['priceEarnings']
                ),
                return_obj(
                    "P/Earnings (Cur)",
                    values['current-cal']['priceEarnings'],
                    values['iSqore']['priceEarningsCurrentGrade'],
                    values['median_sector']['priceEarningsCurrent'],
                    values['calculation-3-year-avg']['historical_data']['priceEarnings'],
                    values['calculation-5-year-avg']['historical_data']['priceEarnings']
                ),
                return_obj(
                    "P/Earnings (Year)",
                    values['additional_cal']['priceEarnings'],
                    values['iSqore']['priceEarningsYearGrade'],
                    values['median_sector']['priceEarningsYear'],
                    values['calculation-3-year-avg']['historical_data']['priceEarnings'],
                    values['calculation-5-year-avg']['historical_data']['priceEarnings']
                ),

                return_obj(
                    "P/Sales (Fwd)",
                    values['current-cal']['priceSalesForward'],
                    values['iSqore']['priceSalesForwardGrade'],
                    values['median_sector']['priceSalesForward'],
                    values['calculation-3-year-avg']['historical_data']['priceSales'],
                    values['calculation-5-year-avg']['historical_data']['priceSales']
                ),
                return_obj(
                    "P/Sales (Cur)",
                    values['current-cal']['priceSales'],
                    values['iSqore']['priceSalesCurrentGrade'],
                    values['median_sector']['priceSalesCurrent'],
                    values['calculation-3-year-avg']['historical_data']['priceSales'],
                    values['calculation-5-year-avg']['historical_data']['priceSales']
                ),
                return_obj(
                    "P/Sales (Year)",
                    values['additional_cal']['priceSales'],
                    values['iSqore']['priceSalesYearGrade'],
                    values['median_sector']['priceSalesYear'],
                    values['calculation-3-year-avg']['historical_data']['priceSales'],
                    values['calculation-5-year-avg']['historical_data']['priceSales']
                ),

                return_obj(
                    "P/Book (Cur)",
                    values['current-cal']['priceBook'],
                    values['iSqore']['priceBookCurrentGrade'],
                    values['median_sector']['priceBookCurrent'],
                    values['calculation-3-year-avg']['historical_data']['priceBook'],
                    values['calculation-5-year-avg']['historical_data']['priceBook']
                ),
                return_obj(
                    "P/Book (Year)",
                    values['additional_cal']['priceBook'],
                    values['iSqore']['priceBookYearGrade'],
                    values['median_sector']['priceBookYear'],
                    values['calculation-3-year-avg']['historical_data']['priceBook'],
                    values['calculation-5-year-avg']['historical_data']['priceBook']
                ),

                return_obj(
                    "P/FCF (Cur)",
                    values['current-cal']['priceFreeCashFlow'],
                    values['iSqore']['priceFreeCashFlowCurrentGrade'],
                    values['median_sector']['priceFreeCashFlowCurrent'],

                    values['calculation-3-year-avg']['historical_data']['priceFreeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['priceFreeCashFlow']
                ),
                return_obj(
                    "P/FCF (Year)",
                    values['additional_cal']['priceFreeCashFlow'],
                    values['iSqore']['priceFreeCashFlowYearGrade'],
                    values['median_sector']['priceFreeCashFlowYear'],

                    values['calculation-3-year-avg']['historical_data']['priceFreeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['priceFreeCashFlow']
                ),

                return_obj(
                    "PEG Ratio (Fwd)",
                    values['current-cal']['pegRatioForward'],
                    values['iSqore']['pegRatioForwardGrade'],
                    values['median_sector']['pegRatioForward'],
                    values['calculation-3-year-avg']['historical_data']['pegRatio'],
                    values['calculation-5-year-avg']['historical_data']['pegRatio']
                ),
                return_obj(
                    "PEG Ratio (Cur)",
                    values['current-cal']['pegRatio'],
                    values['iSqore']['pegRatioCurrentGrade'],
                    values['median_sector']['pegRatioCurrent'],

                    values['calculation-3-year-avg']['historical_data']['pegRatio'],
                    values['calculation-5-year-avg']['historical_data']['pegRatio']
                ),
                return_obj(
                    "PEG Ratio (Year)",
                    values['additional_cal']['pegRatio'],
                    values['iSqore']['pegRatioYearGrade'],
                    values['median_sector']['pegRatioYear'],

                    values['calculation-3-year-avg']['historical_data']['pegRatio'],
                    values['calculation-5-year-avg']['historical_data']['pegRatio']
                ),


                return_obj(
                    "EV/Revenue (Cur)",
                    values['current-cal']['evRevenue'],
                    values['iSqore']['evRevenueCurrentGrade'],
                    values['median_sector']['evRevenueCurrent'],

                    values['calculation-3-year-avg']['historical_data']['evRevenue'],
                    values['calculation-5-year-avg']['historical_data']['evRevenue']
                ),
                return_obj(
                    "EV/Revenue (Year)",
                    values['additional_cal']['evRevenue'],
                    values['iSqore']['evRevenueYearGrade'],
                    values['median_sector']['evRevenueYear'],
                    values['calculation-3-year-avg']['historical_data']['evRevenue'],
                    values['calculation-5-year-avg']['historical_data']['evRevenue']
                ),

                return_obj(
                    "EV/EBIT (Cur)",
                    values['current-cal']['eveBit'],
                    values['iSqore']['eveBitCurrentGrade'],
                    values['median_sector']['eveBitCurrent'],

                    values['calculation-3-year-avg']['historical_data']['eveBit'],
                    values['calculation-5-year-avg']['historical_data']['eveBit']
                ),
                return_obj(
                    "EV/EBIT (Year)",
                    values['additional_cal']['eveBit'],
                    values['iSqore']['eveBitYearGrade'],
                    values['median_sector']['eveBitYear'],

                    values['calculation-3-year-avg']['historical_data']['eveBit'],
                    values['calculation-5-year-avg']['historical_data']['eveBit']
                ),

                return_obj(
                    "EV/EBITDA (Cur)",
                    values['current-cal']['eveBitda'],
                    values['iSqore']['eveBitdaCurrentGrade'],
                    values['median_sector']['eveBitdaCurrent'],

                    values['calculation-3-year-avg']['historical_data']['eveBitda'],
                    values['calculation-5-year-avg']['historical_data']['eveBitda']
                ),
                return_obj(
                    "EV/EBITDA (Year)",
                    values['additional_cal']['eveBitda'],
                    values['iSqore']['eveBitdaYearGrade'],
                    values['median_sector']['eveBitdaYear'],

                    values['calculation-3-year-avg']['historical_data']['eveBitda'],
                    values['calculation-5-year-avg']['historical_data']['eveBitda']
                ),

                return_obj(
                    "Earnings Yield (Cur)",
                    values['current-cal']['earningsYield'],
                    values['iSqore']['earningsYieldCurrentGrade'],
                    values['median_sector']['earningsYieldCurrent'],
                    values['calculation-3-year-avg']['historical_data']['earningsYield'],
                    values['calculation-5-year-avg']['historical_data']['earningsYield'],
                    true
                ),
                return_obj(
                    "Earnings Yield (Year)",
                    values['additional_cal']['earningsYield'],
                    values['iSqore']['earningsYieldYearGrade'],
                    values['median_sector']['earningsYieldYear'],
                    values['calculation-3-year-avg']['historical_data']['earningsYield'],
                    values['calculation-5-year-avg']['historical_data']['earningsYield'],
                    true
                ),

                return_obj(
                    "FCF Yield (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['fcfYield'],
                    values['iSqore']['fcfYieldTTMGrade'],
                    values['median_sector']['fcfMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['fcfYield'],
                    values['calculation-5-year-avg']['historical_data']['fcfYield'],
                    true
                ),
                return_obj(
                    "FCF Yield (Year)",
                    values['additional_cal']['fcfYield'],
                    values['iSqore']['fcfYieldYearGrade'],
                    values['median_sector']['fcfMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['fcfYield'],
                    values['calculation-5-year-avg']['historical_data']['fcfYield'],
                    true
                ),
            ],
            iSqore: {
                value: values['iSqore']['value'],
                grade: return_string(values['iSqore'], 'valueGrade')
            }
        }
        setData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreValueDark}
            lightBlockerImg={iSqoreValueLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row g-0">

                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ISqoreTable
                                    name={'Value'}
                                    data={data}/>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <CompanyOverviewFinancialsOverviewChart1 code={code} exchange={exchange}
                                                                         chartData={chartData}
                                                                         type={'annual'}
                                                                         colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>

                        </div>
                    </>
            }
        />
    );
}

export default ValuesTab;