import React, {useEffect, useState} from 'react';
import {numberWithCommas, return_per_div, return_strong_num, return_value_in_million} from "../Utils";
import Config from "../../Config";
import axios from "axios";
import FinancialOverviewBarTab from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialOverviewBarTab";

function ValuationDataTable({tableData}) {

    return (
        <>
            {tableData &&
                <div className="row g-0">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-12 mb-2">
                        <FinancialOverviewBarTab
                            text={"Expected Growth"}
                            fairValue={true}
                            year3={tableData['cagr-3']['expected']}
                            showYear3={true}
                            year5={0}
                            showYear5={false}
                            avg={false}
                            valuation_class={true}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-12 mb-2">
                        <FinancialOverviewBarTab
                            text={"Revenue Growth"}
                            fairValue={true}
                            year3={tableData['cagr-3']['totalRevenue']}
                            showYear3={true}
                            year5={0}
                            showYear5={false}
                            avg={false}
                            valuation_class={true}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-12 mb-2">
                        <FinancialOverviewBarTab
                            text={"EPS Growth"}
                            fairValue={true}
                            year3={tableData['cagr-3']['eps']}
                            showYear3={true}
                            year5={0}
                            showYear5={false}
                            avg={false}
                            valuation_class={true}
                        />
                    </div>
                </div>
            }
            <div className={"table-responsive generic-lg-table-container"}>
                <table className="table generic-lg-table company-overview-financial-table">
                    <thead>
                    <tr>
                        <th style={{borderRadius: "10px 0 0 0", width: 120, fontSize: 14}}>

                        </th>

                        <th style={{textAlign: "center", width: 95, fontSize: 14}}>
                            Revenue
                        </th>

                        <th style={{width: 40, fontSize: 12}}></th>

                        <th style={{textAlign: "center", width: 75, fontSize: 14}}>
                            EPS
                        </th>

                        <th style={{width: 40, fontSize: 12}}></th>

                        <th style={{textAlign: "right", fontSize: 14, width: 40}}>
                            P/E
                        </th>

                        <th style={{textAlign: "right", fontSize: 14}}>
                            # of Analysts
                        </th>
                    </tr>
                    </thead>

                    {tableData &&
                        <>
                            <tbody>
                            {tableData.expected.date.map((item, index) => {
                                return (
                                    <tr>
                                        <td style={{fontWeight: 600}}>
                                            {item}
                                        </td>

                                        <td style={{textAlign: "right"}}>
                                            <div className={'d-flex'}>
                                                {numberWithCommas(return_value_in_million(tableData.expected.revenue[index]))}
                                                {return_per_div(return_strong_num(tableData.expected['revenue-growth'][index] * 100))}
                                            </div>
                                        </td>

                                        <td></td>

                                        <td style={{textAlign: "right"}}>
                                            <div className={'d-flex'}>
                                                {return_strong_num(tableData.expected['eps'][index])}
                                                {return_per_div(return_strong_num(tableData.expected['eps-growth'][index] * 100))}
                                            </div>
                                        </td>

                                        <td></td>

                                        <td style={{textAlign: "right"}}>
                                            {return_strong_num(tableData['expected']['pe_forward'][index])}
                                        </td>

                                        <td style={{textAlign: "right"}}>
                                            {tableData.expected.num_analysts[index]}
                                        </td>
                                    </tr>
                                )
                            })}
                            <div style={{width: "100%", height: 3}}/>
                            {tableData.historical.date.slice(0, tableData.historical.date.length - 1).map((item, index) => {
                                return (
                                    <tr>
                                        <td style={{fontWeight: 600}}>
                                            {item}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <div className={'d-flex'}>
                                                {numberWithCommas(return_value_in_million(tableData['historical'].revenue[index]))}
                                                {return_per_div(return_strong_num(tableData['historical']['revenue-growth'][index] * 100))}
                                            </div>
                                        </td>

                                        <td></td>

                                        <td style={{textAlign: "right"}}>
                                            <div className={'d-flex'}>
                                                {return_strong_num(tableData['historical']['eps'][index])}
                                                {return_per_div(return_strong_num(tableData['historical']['eps-growth'][index] * 100))}
                                            </div>
                                        </td>

                                        <td></td>

                                        <td style={{textAlign: "right"}}>
                                            {return_strong_num(tableData['historical']['pe_forward'][index])}
                                        </td>

                                        <td style={{textAlign: "right"}}>
                                            -
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </>
                    }

                </table>
            </div>
        </>
    );
}

export default ValuationDataTable;