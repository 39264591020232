import InstitutionalsTab from "./InstitutionalsTab";

function Investors({code, exchange}) {

    return (
        <InstitutionalsTab code={code} exchange={exchange}/>
    );
}

export default Investors;
