import React, {useContext} from 'react';
import GaugeChart from 'react-gauge-chart';
import {ThemeContext} from "../../../Context/ThemeContext";

function MarketMoverGaugeChart({price, text, fearGauge = false}) {

    const {webTheme} = useContext(ThemeContext);

    let pricePer = 0

    if (fearGauge) {
        let tempPrice = price;
        if (price > 40 && price < 48) {
            tempPrice = 40
        } else if (price > 52 && price < 60) {
            tempPrice = 60
        }
        pricePer = tempPrice / 100
    } else {
        if (price >= 40) {
            pricePer = 1
        } else {
            pricePer = price / 40
        }
    }

    return (
        <>
            <div className={'fear-greed-gauge-container'}>
                <GaugeChart
                    id="fear-greed-gauge"
                    nrOfLevels={5}
                    animate={false}
                    percent={pricePer}
                    colors={fearGauge ? ['#FF5F6D', '#FFC371', '#00E396'] : ['#00E396', '#FFC371', '#FF5F6D']}
                    arcWidth={0.1}
                    needleColor={webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"}
                    hideText={true}
                />
            </div>
            <div className="gauge-text mx-auto">
                <h5>{price}</h5>
                <p>{text}</p>
            </div>
        </>
    );
}

export default MarketMoverGaugeChart;