import React from 'react';
import {return_per_div, return_strong_num, return_td_color} from "../Utils";

function IndexStatPerformanceTable({title, performance}) {
    return (
        <div className={'index-stat-table-container'}>

            <div className={'index-stat-table-title'}>
                <p>{title}</p>
            </div>

            <div className={'index-stat-table-body-container'}>
                <table className={'table generic-table-body indices-sm-table-body performance-table'}>
                    <tbody>
                    <tr>
                        <td>
                            Ytd
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['ytd'])}}>
                            {return_per_div(performance['ytd'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Week
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['week'])}}>
                            {return_per_div(performance['week'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            1 Month
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['one_month'])}}>
                            {return_per_div(performance['one_month'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            3 Month
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['three_month'])}}>
                            {return_per_div(performance['three_month'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            1 Year
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['one_year'])}}>
                            {return_per_div(performance['one_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            3 Year
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['three_year'])}}>
                            {return_per_div(performance['three_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            5 Year
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['five_year'])}}>
                            {return_per_div(performance['five_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            10 Year
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['ten_year'])}}>
                            {return_per_div(performance['ten_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Ø p.a.(5y)
                        </td>
                        <td className={'item-right-align-td'} style={{color: return_td_color(performance['five_average'])}}>
                            {return_per_div(performance['five_average'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default IndexStatPerformanceTable;


