import React from 'react';
import {AiOutlinePlusCircle} from "react-icons/ai";

function EmptyISqoreTab({handleOpenModal}) {
    return (
        <div className={'company-isqore-container'}>
            <AiOutlinePlusCircle
                onClick={handleOpenModal}
                style={{color: '#676767', margin: '0 auto', cursor: 'pointer'}}/>
        </div>
    );
}

export default EmptyISqoreTab;