import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../../../Config";
import {numberWithCommas, return_strong_num, return_value_in_million} from "../../../Utils";
import NewFinancialTable from "./NewFinancialTable";
import FinancialOverviewAvgTabs from "./FinancialOverviewAvgTabs";
import FinancialOverviewPolarAreaChart from "./FinancialOverviewPolarAreaChart";
import FinancialOverviewLineChart from "./FinancialOverviewLineChart";
import FinancialOverviewDebtEquityChart from "./FinancialOverviewDebtEquityChart";

const financial_overview_summary_table = async (code, exchange, active) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-summary/${code}/${exchange}/${active}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_revenue_geographic_segmentation = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-revenue-geographic-segmentation/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_revenue_product_segmentation = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-revenue-product-segmentation/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function FinancialOverviewSummaryTable({code, exchange, active}) {

    const [groupedData, setGroupedData] = useState(null)

    const [groupedDataSec, setGroupedDataSec] = useState(null)

    const {data: summaryData, isLoading: summaryIsLoading} = useQuery({
        queryKey: [`company-overview-financial-summary/${code}/${exchange}/${active}/`, code, exchange, active],
        queryFn: () => financial_overview_summary_table(code, exchange, active),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (summaryData && !summaryIsLoading) {
            let data = {
                dates: [],
                content: {
                    revenue: {
                        name: 'Financials', data: {
                            'Revenue': [],

                            'Gross Profit': [],
                            '% Gross Margin': [],

                            'Net Income': [],
                            '% Profit Margin': [],

                            'Free Cash Flow': [],
                            '% FCF Margin': [],

                            'Capital Expenditure': [],

                            'Cash & Equivalents': [],
                            'Short Term Debt': [],
                            'Total Debt': [],

                            'GAAP EPS': [],
                            'Non-GAAP EPS': [],

                            'Shares Outstanding': []
                        }
                    }
                }
            }

            let dataSec = {
                revenue: {
                    name: 'Financials', data: {
                        'Revenue': [],

                        'Gross Profit': [],
                        '% Gross Margin': [],

                        'Net Income': [],
                        '% Profit Margin': [],

                        'Free Cash Flow': [],
                        '% FCF Margin': [],

                        'Capital Expenditure': [],

                        'Cash & Equivalents': [],
                        'Short Term Debt': [],
                        'Total Debt': [],

                        'GAAP EPS': [],
                        'Non-GAAP EPS': [],

                        'Shares Outstanding': []
                    }
                }
            }

            summaryData['primary'].filter(item => item.date !== 'YoY').map((item) => {
                data['dates'].push(item.date)

                // Revenue
                data['content']['revenue']['data'][`Revenue`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalRevenue']))),
                    bold: true,
                    per: false,
                    container_per: false,
                    color: null
                })

                data['content']['revenue']['data']['Gross Profit'].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['grossProfit']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })
                data['content']['revenue']['data']['% Gross Margin'].push({
                    value: return_strong_num(item['additional']['grossMargin'] * 100),
                    bold: false,
                    per: true,
                    container_per: false
                })

                data['content']['revenue']['data'][`Net Income`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['netIncome']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })
                data['content']['revenue']['data']['% Profit Margin'].push({
                    value: return_strong_num(item['additional']['netMargin'] * 100),
                    bold: false,
                    per: true,
                    container_per: false,
                })

                data['content']['revenue']['data'][`Free Cash Flow`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['freeCashFlow']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })
                data['content']['revenue']['data']['% FCF Margin'].push({
                    value: return_strong_num(item['additional']['fcfMargin'] * 100),
                    bold: false,
                    per: true,
                    container_per: false,
                })

                data['content']['revenue']['data']['Capital Expenditure'].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['capitalExpenditures']))),
                    bold: true,
                    per: false,
                    container_per: false,
                })

                data['content']['revenue']['data'][`Cash & Equivalents`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['cashAndShortTermInvestments']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })

                data['content']['revenue']['data']['Short Term Debt'].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['shortTermDebt']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })

                data['content']['revenue']['data'][`Total Debt`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalDebt']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })

                data['content']['revenue']['data'][`GAAP EPS`].push({
                    value: return_strong_num(item['income_statement']['earningsPerShare']),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })
                data['content']['revenue']['data'][`Non-GAAP EPS`].push({
                    value: return_strong_num(item['earning']['epsActual']),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })

                data['content']['revenue']['data'][`Shares Outstanding`].push({
                    value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['commonStockSharesOutstanding']))),
                    bold: true,
                    per: false,
                    container_per: false, color: null
                })
            })

            let item = summaryData['primary'][2]

            data['dates'].push(item.date)

            data['content']['revenue']['data'][`Revenue`].push({
                value: return_strong_num(item['income_statement']['totalRevenue'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['totalRevenue'] > 0 ? "#4ebe96" : "red"
            })

            data['content']['revenue']['data']['Gross Profit'].push({
                value: return_strong_num(item['income_statement']['grossProfit'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['grossProfit'] > 0 ? "#4ebe96" : "red"
            })
            data['content']['revenue']['data']['% Gross Margin'].push({
                value: return_strong_num(item['additional']['grossMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            data['content']['revenue']['data'][`Net Income`].push({
                value: return_strong_num(item['income_statement']['netIncome'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['grossProfit'] > 0 ? "#4ebe96" : "red",
            })
            data['content']['revenue']['data']['% Profit Margin'].push({
                value: return_strong_num(item['additional']['netMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            data['content']['revenue']['data'][`Free Cash Flow`].push({
                value: return_strong_num(item['cash_flow']['freeCashFlow'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['cash_flow']['freeCashFlow'] > 0 ? "#4ebe96" : "red"
            })
            data['content']['revenue']['data']['% FCF Margin'].push({
                value: return_strong_num(item['additional']['fcfMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            data['content']['revenue']['data']['Capital Expenditure'].push({
                value: return_strong_num(item['cash_flow']['capitalExpenditures'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['cash_flow']['capitalExpenditures'] > 0 ? "#4ebe96" : "red"
            })

            data['content']['revenue']['data'][`Cash & Equivalents`].push({
                value: return_strong_num(item['balance_sheet']['cashAndShortTermInvestments'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['balance_sheet']['cashAndShortTermInvestments'] > 0 ? "#4ebe96" : "red"
            })

            data['content']['revenue']['data']['Short Term Debt'].push({
                value: return_strong_num(item['balance_sheet']['shortTermDebt'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['shortTermDebt'] > 0 ? "#4ebe96" : "red",
            })

            data['content']['revenue']['data'][`Total Debt`].push({
                value: return_strong_num(item['balance_sheet']['totalDebt'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['totalDebt'] > 0 ? "#4ebe96" : "red"
            })

            data['content']['revenue']['data'][`GAAP EPS`].push({
                value: return_strong_num(item['income_statement']['earningsPerShare'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['earningsPerShare'] > 0 ? "#4ebe96" : "red"
            })
            data['content']['revenue']['data'][`Non-GAAP EPS`].push({
                value: return_strong_num(item['earning']['epsActual'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['earning']['epsActual'] > 0 ? "#4ebe96" : "red"
            })

            data['content']['revenue']['data'][`Shares Outstanding`].push({
                value: return_strong_num(item['balance_sheet']['commonStockSharesOutstanding'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['commonStockSharesOutstanding'] > 0 ? "#4ebe96" : "red"
            })


            data['dates'].push("")

            item = summaryData['secondary'][0]

            data['dates'].push(item.date)

            dataSec['revenue']['data'][`Revenue`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalRevenue']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })

            dataSec['revenue']['data']['Gross Profit'].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['grossProfit']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })
            dataSec['revenue']['data']['% Gross Margin'].push({
                value: return_strong_num(item['additional']['grossMargin'] * 100),
                bold: false,
                per: true,
                container_per: false
            })

            dataSec['revenue']['data'][`Net Income`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['netIncome']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })
            dataSec['revenue']['data']['% Profit Margin'].push({
                value: return_strong_num(item['additional']['netMargin'] * 100),
                bold: false,
                per: true,
                container_per: false
            })

            dataSec['revenue']['data'][`Free Cash Flow`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['freeCashFlow']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })
            dataSec['revenue']['data']['% FCF Margin'].push({
                value: return_strong_num(item['additional']['fcfMargin'] * 100),
                bold: false,
                per: true,
                container_per: false
            })

            dataSec['revenue']['data']['Capital Expenditure'].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['capitalExpenditures']))),
                bold: true,
                per: false,
                container_per: false
            })

            dataSec['revenue']['data'][`Cash & Equivalents`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['cashAndShortTermInvestments']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })

            dataSec['revenue']['data']['Short Term Debt'].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['shortTermDebt']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })

            dataSec['revenue']['data'][`Total Debt`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalDebt']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })

            dataSec['revenue']['data'][`GAAP EPS`].push({
                value: return_strong_num(item['income_statement']['earningsPerShare']),
                bold: true,
                per: false,
                container_per: false, color: null
            })
            dataSec['revenue']['data'][`Non-GAAP EPS`].push({
                value: return_strong_num(item['earning']['epsActual']),
                bold: true,
                per: false,
                container_per: false, color: null,
            })

            dataSec['revenue']['data'][`Shares Outstanding`].push({
                value: numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['commonStockSharesOutstanding']))),
                bold: true,
                per: false,
                container_per: false, color: null
            })

            item = summaryData['secondary'][1]

            data['dates'].push(item.date)

            dataSec['revenue']['data'][`Revenue`].push({
                value: return_strong_num(item['income_statement']['totalRevenue'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['totalRevenue'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data']['Gross Profit'].push({
                value: return_strong_num(item['income_statement']['grossProfit'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['grossProfit'] > 0 ? "#4ebe96" : "red"
            })
            dataSec['revenue']['data']['% Gross Margin'].push({
                value: return_strong_num(item['additional']['grossMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            dataSec['revenue']['data'][`Net Income`].push({
                value: return_strong_num(item['income_statement']['netIncome'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['grossProfit'] > 0 ? "#4ebe96" : "red"
            })
            dataSec['revenue']['data']['% Profit Margin'].push({
                value: return_strong_num(item['additional']['netMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            dataSec['revenue']['data'][`Free Cash Flow`].push({
                value: return_strong_num(item['cash_flow']['freeCashFlow'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['cash_flow']['freeCashFlow'] > 0 ? "#4ebe96" : "red"
            })
            dataSec['revenue']['data']['% FCF Margin'].push({
                value: return_strong_num(item['additional']['fcfMargin'] * 100),
                bold: false,
                per: false,
                container_per: false,
                color: null,
                pp: true
            })

            dataSec['revenue']['data']['Capital Expenditure'].push({
                value: return_strong_num(item['cash_flow']['capitalExpenditures'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['cash_flow']['capitalExpenditures'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data'][`Cash & Equivalents`].push({
                value: return_strong_num(item['balance_sheet']['cashAndShortTermInvestments'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['balance_sheet']['cashAndShortTermInvestments'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data']['Short Term Debt'].push({
                value: return_strong_num(item['balance_sheet']['shortTermDebt'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['shortTermDebt'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data'][`Total Debt`].push({
                value: return_strong_num(item['balance_sheet']['totalDebt'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['totalDebt'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data'][`GAAP EPS`].push({
                value: return_strong_num(item['income_statement']['earningsPerShare'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['income_statement']['earningsPerShare'] > 0 ? "#4ebe96" : "red"
            })
            dataSec['revenue']['data'][`Non-GAAP EPS`].push({
                value: return_strong_num(item['earning']['epsActual'] * 100),
                bold: true,
                per: true,
                container_per: true,
                color: item['earning']['epsActual'] > 0 ? "#4ebe96" : "red"
            })

            dataSec['revenue']['data'][`Shares Outstanding`].push({
                value: return_strong_num(item['balance_sheet']['commonStockSharesOutstanding'] * 100),
                bold: true,
                per: true,
                container_per: true,
                reverse_container_per: true,
                color: item['balance_sheet']['commonStockSharesOutstanding'] > 0 ? "#4ebe96" : "red"
            })

            setGroupedData(data)

            setGroupedDataSec(dataSec)
        }
    }, [summaryData, summaryIsLoading]);

    const [revenueGeographicSegmentation, setRevenueGeographicSegmentation] = useState({})
    const [revenueSegmentationChartIsLoading, setRevenueSegmentationChartIsLoading] = useState(true)

    const {data: revenueGeographicSegmentationData, isLoading: revenueGeographicSegmentationIsLoading} = useQuery({
        queryKey: [`company-revenue-geographic-segmentation/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_revenue_geographic_segmentation(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (revenueGeographicSegmentationData && !revenueGeographicSegmentationIsLoading) {
            setRevenueGeographicSegmentation(revenueGeographicSegmentationData)
            setRevenueSegmentationChartIsLoading(false)
        }
    }, [revenueGeographicSegmentationData, revenueGeographicSegmentationIsLoading])


    const [revenueProductSegmentation, setRevenueProductSegmentationData] = useState({})
    const [revenueProductSegmentationChartIsLoading, setRevenueProductSegmentationChartIsLoading] = useState(true)

    const {data: revenueProductSegmentationData, isLoading: revenueProductSegmentationIsLoading} = useQuery({
        queryKey: [`company-revenue-product-segmentation/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_revenue_product_segmentation(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (revenueProductSegmentationData && !revenueProductSegmentationIsLoading) {
            setRevenueProductSegmentationData(revenueProductSegmentationData)
            setRevenueProductSegmentationChartIsLoading(false)
        }
    }, [revenueProductSegmentationData, revenueProductSegmentationIsLoading])

    return (
        <div className={'row g-0'}>
            <div className={'col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 px-2'}>
                {groupedData &&
                    <>
                        <FinancialOverviewAvgTabs code={code} exchange={exchange}/>
                        <NewFinancialTable groupedData={groupedData} groupedDataSec={groupedDataSec} active={active}
                                           boldKeys={["Revenue", 'Capital Expenditure', 'Short Term Debt',"Gross Profit", "GAAP EPS", "Non-GAAP EPS", "Cash & Equivalents", 'Net Income', "Free Cash Flow", "Total Debt", "Earnings Per Share", 'Shares Outstanding']}/>
                    </>
                }
            </div>
            <div className={'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'}>
                {!revenueSegmentationChartIsLoading && (
                    revenueGeographicSegmentation?.segmentation ?
                        <FinancialOverviewPolarAreaChart
                            revenueGeographicSegmentation={revenueGeographicSegmentation}/>
                        :
                        <FinancialOverviewDebtEquityChart
                            quarterData={revenueGeographicSegmentation}/>
                )}

                {!revenueSegmentationChartIsLoading && (
                    <FinancialOverviewLineChart
                        margins={revenueProductSegmentation} />
                )}

            </div>
        </div>
    );
}

export default FinancialOverviewSummaryTable;
