import marketoverview from "../assets/market-overflow.svg";
import marketoverviewhover from "../assets/market-overflow-hover.svg";
import marketoverviewlight from "../assets/market-overflow-light.svg";
import watchlist from "../assets/watchlist_white.svg";
import watchlisthover from "../assets/watchlist_blue.svg";
import watchlistlight from '../assets/watchlist_grey.svg'

import indexes from "../assets/indexes.svg";
import indexeshover from "../assets/indexeshover.svg";
import indexeslight from "../assets/indexeshoverlight.svg";

import etf from "../assets/ETFs.svg";
import etfhover from "../assets/ETFs-hover.svg";
import etflight from "../assets/ETFs-light.svg";

import companyoverview from "../assets/company-overview.svg";
import companyoverviewhover from "../assets/company-overview-hover.svg";
import companyoverviewlight from "../assets/company-overview-light.svg";

import investor from "../assets/Investor_white.svg";
import investorhover from "../assets/investor_blue.svg";
import investorlight from "../assets/investor_grey.svg";

import calender from '../assets/calendar_white.svg';
import calenderhover from '../assets/calendar_blue.svg';
import calenderlight from '../assets/calendar_grey.svg';

import screenerhover from "../assets/screener-hover.svg";

import etfdatabase from "../assets/etf-database.svg";
import etfdatabaselight from "../assets/etf-database-light.svg";

import contactUs from '../assets/contact_white.svg'
import contactUsHover from '../assets/contact_blue.svg'
import contactUsLight from '../assets/contact_grey.svg'

import compareStockHover from '../assets/Compare_Stocks_blue.svg';
import compareStockLight from '../assets/Compare_Stocks_grey.svg';
import compareStockDark from '../assets/Compare_Stocks_white.svg';

import {useSelector} from "react-redux";
import React, {useContext} from "react";
import clsx from "clsx";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../Context/ThemeContext";

const SideBar = ({openSideBar}) => {
    const common_stock = useSelector(state => state.actions.common_stock)
    const location = useLocation()
    const navigate = useNavigate()

    const {webTheme} = useContext(ThemeContext);
    const sidebar_icons = [
        {
            icon: webTheme === 'dark' ? marketoverview : marketoverviewlight,
            text: "Market Overview",
            hover_icon: marketoverviewhover,
            link: '/market-overview/'
        },
        {
            icon: webTheme === 'dark' ? watchlist : watchlistlight,
            text: "Watchlist & Alerts",
            hover_icon: watchlisthover,
            link: '/watchlist/'
        },
        {
            icon: webTheme === 'dark' ? indexes : indexeslight,
            text: "Indices",
            hover_icon: indexeshover,
            link: '/indices/'
        },
        {
            icon: webTheme === 'dark' ? etf : etflight,
            text: "ETFS",
            hover_icon: etfhover,
            link: '/etfs/'
        },
        {
            icon: webTheme === 'dark' ? companyoverview : companyoverviewlight,
            text: "Company Overview",
            hover_icon: companyoverviewhover,
            link: '/company-overview/'
        },
        {
            icon: webTheme === 'dark' ? compareStockDark : compareStockLight,
            text: "Compare Stocks",
            hover_icon: compareStockHover,
            link: '/compare-stocks/'
        },
        {
            icon: webTheme === 'dark' ? etfdatabase : etfdatabaselight,
            text: "Screener",
            hover_icon: screenerhover,
            link: '/screener/'
        },
        {
            icon: webTheme === 'dark' ? calender : calenderlight,
            text: "Calendar",
            hover_icon: calenderhover,
            link: '/calendar/'
        },
        {
            icon: webTheme === 'dark' ? investor : investorlight,
            text: "Gurus & Investors",
            hover_icon: investorhover,
            link: '/investor/'
        },
        {
            icon: webTheme === 'dark' ? contactUs : contactUsLight,
            text: "Contact Us",
            hover_icon: contactUsHover,
            link: ''
        }
    ]

    return (
        <div className={clsx('sidebar', openSideBar ? 'sidebar-open' : 'sidebar-closed')}>
            {sidebar_icons.map((item, index) => {
                if (
                    location.pathname === item.link ||
                    (item.link === '/company-overview/' && location.pathname.includes('/company-overview/')) ||
                    (item.link === '/investor/' && location.pathname.includes('/investor/')) ||
                    (item.link === '/etfs/' && location.pathname.includes('/etfs/')) ||
                    (item.link === '/indices/' && location.pathname.includes('/indices/'))
                ) {
                    return (
                        <Link to={location.pathname} style={{textDecoration: "none"}}>
                            <div key={index} className={clsx('sidebar-list-item-selected')}>
                                <img className={'sidebar-icon'} src={item.hover_icon}/>
                                <p className={clsx('sidebar-text')}>{item.text}</p>
                            </div>
                        </Link>
                    )
                } else {
                    if (item.text === 'Company Overview') {
                        return (
                            <Link to={item.link + common_stock.code + '/' + common_stock.exchange + '/'}
                                  style={{textDecoration: "none"}}>
                                <div key={index} className={clsx('sidebar-list-item')}>
                                    <img className={'icon'} src={item.icon}/>
                                    <img className={'icon-hover'} src={item.hover_icon}/>
                                    <p className={clsx('sidebar-text')}>{item.text}</p>
                                </div>
                            </Link>
                        )
                    } else if (item.text === 'Contact Us') {
                        return (
                            // <a key={index} href={'https://web.finqube.io/contact/'} target={"_blank"}
                            //    style={{textDecoration: "None"}} className={clsx('sidebar-list-item')}>
                            //     <img className={'icon'} src={item.icon}/>
                            //     <img className={'icon-hover'} src={item.hover_icon}/>
                            //     <p className={clsx('sidebar-text')}>{item.text}</p>
                            // </a>
                            <a key={index} href={'https://web.finqube.io/contact/'} target={"_blank"}
                               style={{textDecoration: "none"}}>
                                <div key={index} className={clsx('sidebar-list-item')}>
                                    <img className={'icon'} src={item.icon}/>
                                    <img className={'icon-hover'} src={item.hover_icon}/>
                                    <p className={clsx('sidebar-text')}>{item.text}</p>
                                </div>
                            </a>
                        )
                    } else {
                        return (
                            <Link to={item.link} style={{textDecoration: "none"}}>
                                <div key={index} className={clsx('sidebar-list-item')}>
                                    <img className={'icon'} src={item.icon}/>
                                    <img className={'icon-hover'} src={item.hover_icon}/>
                                    <p className={clsx('sidebar-text')}>{item.text}</p>
                                </div>
                            </Link>
                        )
                    }
                }
            })}
        </div>
    );
}

export default SideBar;
