import React, {useState} from 'react';
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import {a11yProps, tab_locked, TabPanel} from "../Utils";
import SwipeableViews from "react-swipeable-views";

import ValuesTab from "./ValuesTab";
import GrowthTab from "./GrowthTab";
import HealthTab from "./HealthTab";
import ProbabilityGrowth from "./ProbabilityGrowth";
import ISqoreMatrixTab from "../ISqoreTabs/iSqoreMatrixTab";
import QualityTab from "../ISqoreTabs/QualityTab";
import {useSelector} from "react-redux";
import {FaLock} from "react-icons/fa";

function ISqoreTab({logoURL, code, exchange}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    let locked = tab_locked(isAuthenticated, user, code, exchange)

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <div className={"generic-tabs-container child-tabs-container"}>
                <Box display="flex" justifyContent="left" width="100%">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor={'secondary'}
                        scrollButtons="auto"
                    >
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>iSqore Metrics</span>
                            </Box>
                        } {...a11yProps(0)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Value</span>
                            </Box>
                        } {...a11yProps(1)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Profitability</span>
                            </Box>
                        } {...a11yProps(2)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Growth</span>
                            </Box>
                        } {...a11yProps(3)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Health</span>
                            </Box>
                        } {...a11yProps(4)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Quality</span>
                            </Box>
                        } {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <div className="tabsPanelContainer">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        disabled={true}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel className={'generic-tab-panel'} value={value} index={0} dir={theme.direction}>
                            <ISqoreMatrixTab logoURL={logoURL} code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                            <ValuesTab code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                            <ProbabilityGrowth code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={3} dir={theme.direction}>
                            <GrowthTab code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={4} dir={theme.direction}>
                            <HealthTab code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={5} dir={theme.direction}>
                            <QualityTab code={code} exchange={exchange}/>
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </div>
        </>
    );
}

export default ISqoreTab;