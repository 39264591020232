import {useEffect, useState} from 'react';
import axios from "axios";
import {return_per_div} from "../../Utils";
import {AiOutlineArrowRight} from "react-icons/ai";
import Config from "../../../Config";

function SummaryLatestActiveTable({}) {
    const [tableState, setTableState] = useState(true)

    const [news, setNews] = useState([])
    const [active, setActive] = useState([])

    useEffect(() => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-stock-news/`, config)
            .then(response => setNews(response.data))

        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-stock-market/`, config)
            .then(response => setActive(response.data))
    }, [])

    const [lettersLength, setLettersLength] = useState(35)

    function handleResize() {
        if (window.innerWidth <= 768) {
            setLettersLength(15)
        } else {
            setLettersLength(35)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                {/*<p>Equity Market</p> | <p>Watchlist</p>*/}
                <p className={tableState ? 'selected' : 'unselected'} onClick={() => {
                    setTableState(true)
                }}>Latest News</p>
                <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                <p className={!tableState ? 'selected' : 'unselected'} onClick={() => {
                    setTableState(false)
                }}>Most Active</p>
            </div>

            {tableState ?
                <div className={'market-overview-summary-table-body-container'} style={{
                    borderRadius: '0 0 10px 10px',
                }}>
                    <div className={'news-container'}>
                        {news.map((item) => {
                            return (
                                <a className={'news-inner-container'} target={"_blank"} href={item.url}>
                                    <img src={item.image}/>
                                    <div>
                                        <p className={'title'}>{item.title.length > 55 ? item.title.substring(0, 55) + "..." : item.title}</p>
                                        <p className={'publishedDate'}>{item.site} | {item.publishedDate}</p>
                                        <p className={'read-more'}>Read More <AiOutlineArrowRight/></p>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
                :
                <div className={'market-overview-summary-table-body-container'} style={{
                    borderRadius: '0 0 10px 10px',
                }}>
                    <div className={'news-container'}>
                        <div style={{padding: "0 5px 0 0"}}>
                            <table className={'table generic-table-body'}>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight: 600}}>
                                        Stocks
                                    </td>
                                    <td style={{width: 60, fontWeight: 600}}>
                                        Symbol
                                    </td>
                                    <td style={{width: 65, textAlign: 'right', fontWeight: 600}}>
                                        Price
                                    </td>
                                    <td style={{width: 75, textAlign: 'right', fontWeight: 600}}>
                                        Change %
                                    </td>
                                </tr>
                                {active.sort((a, b) => b.changesPercentage - a.changesPercentage).filter(item => item.price > 1).slice(0, 25).map((item) => {
                                    return (
                                        <tr>
                                            <td className={item.name.length > lettersLength && "hovertext"}
                                                data-hover={item.name}>
                                                {item.name.length > lettersLength ? item.name.substring(0, lettersLength) + "..." : item.name}
                                            </td>
                                            <td>
                                                {item.symbol}
                                            </td>
                                            <td style={{
                                                textAlign: 'right'
                                            }}>
                                                {item.price}
                                            </td>
                                            <td style={{
                                                display: 'flex',
                                                paddingRight: 2
                                            }}>
                                                {return_per_div(item.changesPercentage)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}

export default SummaryLatestActiveTable;