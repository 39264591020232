import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../Config";
import FinancialsControls from "./FinancialTabs/FinancialsControls";
import CompanyOverColumnCharts from "./CompanyOverColumnCharts";

const company_overview_charts = async (code, exchange, active) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-charts/${code}/${exchange}/${active}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function CompanyOverviewCharts({code, exchange, active}) {

    const [loadingCharts, setLoadingCharts] = useState(true)

    const [chartData, setChartData] = useState(null)

    const {data: companyOverviewChartReturn, isLoading: companyOverviewChartIsLoading} = useQuery({
        queryKey: [`company-overview-charts`, code, exchange, active],
        queryFn: () => company_overview_charts(code, exchange, active),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!companyOverviewChartIsLoading && companyOverviewChartReturn) {
            setChartData(companyOverviewChartReturn)
            setLoadingCharts(false)
        }
    }, [companyOverviewChartIsLoading, companyOverviewChartReturn]);

    return (
        <div>
            {loadingCharts ?
                <div style={{width: '100%', height: '100%', display: ' flex'}}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                <div className={'row g-0'}>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Revenue (mln)"}
                            color={["#3e9fed"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'Revenue',
                                data: chartData.totalRevenue
                            }]}/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Net Income (mln)"}
                            color={["#58ffc5"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'Net Income',
                                data: chartData.netIncome
                            }]}/>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Cash Flow (mln)"}
                            color={["#fe605c", "#ee1981"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'Operating Cash Flow',
                                data: chartData.totalCashFromOperatingActivities
                            }, {
                                name: 'Free Cash Flow',
                                data: chartData.freeCashFlow
                            }]}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Shares Outstanding (mln)"}
                            color={["#a410c2"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'Shares Outstanding',
                                data: chartData.commonStockSharesOutstanding
                            }]}
                            yaxis={{
                                min: 0.33 * Math.max(...chartData.commonStockSharesOutstanding),
                                tickAmount: 5
                            }}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Cash & Debt (mln)"}
                            color={["#29f3a7", "#c50da7"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'Total Cash',
                                data: chartData.totalCash
                            }, {
                                name: 'Total Debt',
                                data: chartData.totalDebt
                            }]}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <CompanyOverColumnCharts
                            title={"Operating Expenses (mln)"}
                            color={["#3e9fed", "#cbe93c"]}
                            dates={chartData.dates}
                            series={[{
                                name: 'R&D',
                                data: chartData.researchDevelopment
                            }, {
                                name: 'SG&A',
                                data: chartData.sellingGeneralAdministrative
                            }]}
                            stacked={true}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default CompanyOverviewCharts;