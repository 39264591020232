import {useDispatch} from "react-redux";
import {paypal_subscription} from "../actions/actions";
import {useNavigate} from "react-router-dom";
import {PayPalButton} from "react-paypal-button-v2";
import {toast} from "react-hot-toast";

function PaymentForm({euro, selectedPackage}) {

    const get_paypal_price_id = (type) => {
        if (euro) {
            if (type === "Monthly") {
                return process.env.REACT_APP_PAYPAL_EURO_MONTHLY
            } else {
                return process.env.REACT_APP_PAYPAL_EURO_YEARLY
            }
        } else {
            if (type === "Monthly") {
                return process.env.REACT_APP_PAYPAL_DOLLAR_MONTHLY
            } else {
                return process.env.REACT_APP_PAYPAL_DOLLAR_YEARLY
            }
        }
    }

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const make_paypal_payment = (data) => {
        const body = {
            subscriptionID: data.subscriptionID,
            package_name: selectedPackage.name + ' ' + selectedPackage.type,
            package_type: selectedPackage.type,
            price: selectedPackage.actual_price,
            price_id: get_paypal_price_id(selectedPackage.type)
        }
        dispatch(paypal_subscription(body, navigate))
    }

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            'plan_id': get_paypal_price_id(selectedPackage.type)
        });
    }

    const onApprove = (data, actions) => {
        make_paypal_payment(data)
    };

    const onError = (err) => {
        console.log(err)
        toast.error(<p style={{fontSize: 10, fontWeight: 600}}>There is an error, please try
            again</p>, {duration: 6000})
    }

    const onCancel = () => {
        toast(
            "You cancelled the payment. Try again by clicking the PayPal button",
            {
                duration: 6000
            })
    }

    return (
        <div className="row g-0 mb-2">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className={'payment-info-container'}>
                    <div>
                        <div style={{width: '100%', display: 'flex', marginTop: 20}}>
                            <p className={'selected-package-name'}>{selectedPackage.name}</p>
                            <p className={'selected-package-price'}>
                                <span className={'selected-package-price-subtext'}>{euro ? "€" : "$"}</span>
                                {selectedPackage.price}
                                <span
                                    className={'selected-package-price-subtext'}>{selectedPackage.sub_price}</span>
                            </p>
                        </div>
                        <p className={'selected-package-description'}>{selectedPackage.description}</p>

                        {selectedPackage.items.map((item, index) => {
                            return (
                                <div key={index} style={{display: 'flex', margin: "5px 0"}}>
                                    <p className={'selected-package-list-item'}><span
                                        className={'tick'}>✓</span> {item}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div style={{
                    display: 'flex',
                    height: '100%',
                    width: "100%",
                    padding: 20,
                    flexDirection: 'column'
                }}>
                    <p style={{fontSize: 14, fontWeight: 600, color: '#3E3E3E'}}>Choose Payment method</p>
                    <div style={{width: '100%', margin: 'auto'}}>
                        <PayPalButton
                            amount={selectedPackage.actual_price}
                            currency={"USD"}
                            createSubscription={(data, details) => createSubscription(data, details)}
                            onApprove={(data, details) => onApprove(data, details)}
                            onError={onError}
                            catchError={(err) => onError(err)}
                            onCancel={(err) => onCancel(err)}
                            options={{
                                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                vault: true,
                                intent: 'subscription',
                                disableFunding: 'credit'
                            }}
                            style={{
                                label: 'subscribe',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentForm;
