import {useEffect, useState} from "react";
import axios from "axios";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";

const company_overview_profile = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-profile/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const ProfileTabs = ({code, exchange}) => {

    const [data, setData] = useState(null)

    const {data: companyOverviewProfileReturn, isLoading: companyOverviewProfileIsLoading} = useQuery({
        queryKey: [`company-overview-profile/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_profile(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if(!companyOverviewProfileIsLoading) {
            setData(companyOverviewProfileReturn)
        }
    },[companyOverviewProfileReturn, companyOverviewProfileIsLoading])

    return (
        data &&
        <>
            <div className={'company-overview-profile-header-container'}>
                <p className={'company-overview-profile-header-text'}>{data.name}</p>
            </div>
            <div className={'company-overview-profile-body-container'}>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                        <p className={'company-overview-profile-point-text'}>
                            {data['addressData']['Street']}
                        </p>
                        <p className={'company-overview-profile-point-text'}>
                            {data['addressData']['City']}
                        </p>
                        <p className={'company-overview-profile-point-text'}>
                            {data['addressData']['Country']}
                        </p>
                        <a className={'company-overview-profile-point-link'}
                           target={"_blank"}
                           href={data['company_general_details']['WebURL']}>
                            {data['company_general_details']['WebURL']}</a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                        <p className={'company-overview-profile-point-text'}>Country: {data['company_general_details']['CountryISO']}</p>
                        <p className={'company-overview-profile-point-text'}>Sector: {data['company_general_details']['Sector']}</p>
                        <p className={'company-overview-profile-point-text'}>Industry: {data['company_general_details']['Industry']}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <p className={'company-overview-profile-description-heading'}
                    >Description</p>
                    <p className={'company-overview-profile-description-text'}>{data['company_general_details']['Description']} </p>
                </div>
            </div>
        </>
    );
}

export default ProfileTabs;