import React, {useState} from 'react';
import {Helmet} from "react-helmet-async";
import DashboardLayout from "../Layout/DashboardLayout";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import CompareISqore from "../Container/ISqoreTabs/CompareISqore";
import CompareStocksPro from "../Container/CompareStocks/CompareStocksPro";
import {a11yProps, TabPanel} from "../Container/Utils";
import SwipeableViews from "react-swipeable-views";

function CompareStock() {

    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <Helmet>
                <title>Compare Stocks</title>
                <meta name={"description"}
                      content={"Compare Stock"}/>
            </Helmet>
            <DashboardLayout>
                <div className={"generic-tabs-container"}>
                    <Box display="flex" justifyContent="left" width="100%">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            indicatorColor={'primary'}
                            scrollButtons="auto"
                        >
                            <Tab label="Compare Stocks" {...a11yProps(0)} />
                            <Tab label={
                                <Box display="flex" alignItems="center">
                                    <p>iSqore PRO</p>
                                    <div style={{
                                        borderRadius: 5,
                                        padding: '0 5px',
                                        backgroundColor: '#28c07f33',
                                        color: "#28c07f",
                                        marginLeft: 5,
                                        marginBottom: 'auto',
                                        fontSize: 10
                                    }}>
                                        beta
                                    </div>
                                </Box>
                            } style={{}} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <div className="tabsPanelContainer company-overview-panel">
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            disabled={true}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={0}
                                      dir={theme.direction}>
                                <CompareISqore/>
                            </TabPanel>

                            <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                                <CompareStocksPro/>
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </div>
            </DashboardLayout>
        </>
    );
}

export default CompareStock;