import logo from '../../assets/Finqube-05.png'
import logoLgLight from '../../assets/Finqube-logo-light.svg';
import logoLgDark from '../../assets/Finqube-logo-dark.svg';
import finqubeLogo from '../../assets/NewFinqubeLogo.png';
import {BsSearch} from "react-icons/bs";
import React, {useContext, useState} from "react";
import clsx from "clsx";
import {GiHamburgerMenu} from "react-icons/gi";
import {FormControlLabel, Modal, Switch} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {change_theme, Logout} from "../../actions/actions";
import {useNavigate, Link} from "react-router-dom";
import SearchModalBody from "./SearchModalBody";
import {ThemeContext} from "../../Context/ThemeContext";
import {MdLightMode, MdNightlightRound} from "react-icons/md";

const Navbar = ({handleOpenSidebar}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {webTheme, changeTheme} = useContext(ThemeContext);

    const user = useSelector(state => state.actions.user)
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)

    const [searchModal, setSearchModal] = useState(false)
    const handleOpenSearchModal = () => {
        setSearchModal(true)
    }
    const handleCloseSearchModal = () => {
        setSearchModal(false)
    }

    return (
        <div
            className={clsx('navbar-container')}>

            <GiHamburgerMenu className={clsx('hamburger')}
                             onClick={handleOpenSidebar}/>


            <Link to={'/market-overview/'} className={'logo-lg-a'}>
                <img className={'logo-lg'} alt={"logo"} src={webTheme === 'light' ? logoLgLight : finqubeLogo}/>
            </Link>

            <Link to={'/market-overview/'} className={'logo-sm-a'}>
                <img className={'logo-sm'} src={logo} alt={"logo"}/>
            </Link>

            <div style={{position: 'relative', margin: "auto auto auto 0"}}>
                <div onClick={handleOpenSearchModal}
                     className={clsx('search-bar-container-lg')}>
                    <p className={'search-bar-container-text'}>Search Here</p>
                    <BsSearch className={clsx('search-bar-icon')}/>
                </div>
                <div onClick={handleOpenSearchModal}
                     className={clsx('search-bar-container-sm')}>
                    <BsSearch className={clsx('search-bar-icon')}/>
                </div>
            </div>

            {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                <button className={'btn nav-subscribe-btn'}
                        onClick={() => {
                            navigate('/payment/')
                        }}
                >
                    Subscribe
                </button>
            }

            <div className={'navbar-theme-switch'}>
                <MdLightMode style={{color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"}}/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={webTheme === 'dark'}
                            onChange={() => {
                                changeTheme(webTheme === 'dark' ? 'light' : 'dark')
                                dispatch(change_theme(webTheme === 'dark' ? 'light' : 'dark'))
                            }}
                            color="primary"
                        />
                    }
                />
                <MdNightlightRound style={{color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"}}/>
            </div>

            {isAuthenticated &&
                <div className={'profile-image-container'} onClick={() => navigate(`/profile/`)}
                     style={{backgroundImage: user.img !== '' && `url(${user.img})`}}
                >
                </div>
            }

            {isAuthenticated ?
                <button className={'btn btn-warning'}
                        onClick={() => {
                            dispatch(Logout())
                            navigate('/market-overview/')
                        }}
                        style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                    Logout
                </button>
                :
                <button className={'btn btn-warning'}
                        onClick={() => navigate('/login')}
                        style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                    Login
                </button>
            }

            <Modal
                open={searchModal}
                onClose={handleCloseSearchModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SearchModalBody handleCloseSearchModal={handleCloseSearchModal}/>
            </Modal>


        </div>
    );
}

export default Navbar;