import {useState} from "react";
import SectorTab from "./SectorTab/SectorTab";
import {a11yProps, TabPanel} from "../../Utils";
import IndicesTab from "./IndicesTab/IndicesTab";
import SummaryTab from "./SummaryTab/SummaryTab";
import SwipeableViews from "react-swipeable-views";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import PriceTargetsTab from "../../MarketOverview/PriceTargetsTab";
import MarketMovers from "../../MarketOverview/MarketMovers/MarketMovers";

const DashboardTabs = () => {
    const theme = useTheme();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={'generic-tabs-container'}>

            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Summary" {...a11yProps(0)} />
                    <Tab label="Indices"
                         style={{}} {...a11yProps(1)} />
                    <Tab label="Sectors"
                         {...a11yProps(2)} />
                    <Tab label="Market Movers"
                         {...a11yProps(3)} />
                    {/*<Tab label="Price Targets"*/}
                    {/*     {...a11yProps(3)} />*/}
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction} className={'generic-tab-panel'}>
                    <SummaryTab/>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction} className={'generic-tab-panel'}>
                    <IndicesTab dashboard={true}/>
                </TabPanel>

                <TabPanel value={value} index={2} dir={theme.direction} className={'generic-tab-panel'}>
                    <SectorTab/>
                </TabPanel>

                <TabPanel value={value} index={3} dir={theme.direction} className={'generic-tab-panel'}>
                    <MarketMovers/>
                </TabPanel>
                {/*<TabPanel value={value} index={3} dir={theme.direction} className={'generic-tab-panel'}>*/}
                {/*    <PriceTargetsTab/>*/}
                {/*</TabPanel>*/}
            </SwipeableViews>

        </div>
    );
}

export default DashboardTabs;