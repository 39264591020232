import {BsFacebook, BsTwitter, BsYoutube} from "react-icons/bs";
import LandingPageNavbar from "../Container/LandingPage/landing-page-navbar";
import {Helmet} from "react-helmet-async";
import {return_bg_grade_color, return_grade_color} from "../Container/Utils";
import ISqoreRatingModel from '../assets/iSqore Rating Model.png'
import SimplifyComplexity from '../assets/Simplify Complexity.png'
import KnowTheFairValue from '../assets/Know the Fair Value.png'

import GetBigPicture from '../assets/Get the big picture.png'
import InformationVis from '../assets/Informative Visualization_glow.png'
import {FaLinkedinIn, FaTiktok} from "react-icons/fa";


function AccountsFooter() {

    return (
        <footer className={'landing-page-footer'}>
            <div style={{display: 'flex'}}>
                <div style={{margin: "auto"}}>
                    <a href={'https://www.facebook.com/finqube'} target={"_blank"}>
                        <BsFacebook className={'socials'}/>
                    </a>
                    <a href={'https://www.linkedin.com/company/finqube'} target={"_blank"}>
                        <FaLinkedinIn className={'socials'}/>
                    </a>
                    <a href={'https://www.youtube.com/@finqube'} target={"_blank"}>
                        <BsYoutube className={'socials'}/>
                    </a>
                    <a href={'https://twitter.com/finqube'} target={"_blank"}>
                        <BsTwitter className={'socials'}/>
                    </a>
                    <a href={'https://www.tiktok.com/@finqube'} target={"_blank"}>
                        <FaTiktok className={'socials'}/>
                    </a>
                </div>
            </div>

            <hr/>

            <p>
                This site cannot substitute for professional investment advice or independent factual verification. We
                would like to remind you that the data contained on finqube.io and via API is not necessarily real-time
                nor accurate. To use our financial platform, you must accept our&nbsp;
                <a href={'https://web.finqube.io/terms-of-use/'} target={'_blank'}>Terms of Use</a>&nbsp;,
                <a href={'https://web.finqube.io/privacy-policy/'} target={'_blank'}>Privacy Policy</a> and our&nbsp;
                <a href={'https://web.finqube.io/disclaimer/'} target={'_blank'}>Disclaimer</a> |&nbsp;
                <a href={'https://web.finqube.io/imprint/'} target={'_blank'}>Imprint</a>&nbsp;
                of Finqube GmbH | Copyright © 2024
            </p>
        </footer>
    )
}

const LandingPage = () => {

    return (<>
        <Helmet>
            <title>Finqube.io: Invest Smart, Financial Charts, Analysis and Stock Screener</title>
            <meta name={"description"}
                  content={"Innovative Financial Platform for Screening, Researching and Analyzing Stocks, ETFs & Indices. Our powerful analytical tools will bring stock-picking possibilities to the next level. Finqube.io simplifying financial data for you."}/>
        </Helmet>

        <div className={'landing-page-container'}>

            <div className={'ornament-2'}/>

            <div className={'landing-page'}>
                <div className="container">

                    <LandingPageNavbar landing={true}/>

                    <main>
                        <section className={'landing-page-section-full-page'}>
                            <div className={'landing-page-section-div'}>
                                <h2 className={'landing-page-section-h2'}>
                                    Get the big picture
                                </h2>
                                <h3 className={'landing-page-section-h3'}>
                                    Finqube.io offers an in-depth stock analysis, effortlessly providing you an
                                    insight
                                    of the strengths and weaknesses on your
                                    favorite companies within an intuitive interface.
                                </h3>

                                <div className={'landing-page-section-image-container'}>
                                    <img
                                        src={GetBigPicture}
                                        alt={"Landing Page Image"}
                                        className={'landing-page-laptop'}/>
                                </div>
                            </div>
                        </section>


                        <section className={'landing-page-section-full-page'}>
                            <div className={'landing-page-section-div'}>
                                <h2 className={'landing-page-section-h2'}>
                                    iSqore Rating Model
                                </h2>
                                <div style={{width: '100%', display: 'flex', marginBottom: 20}}>
                                    <h3 className={'landing-page-section-h3'}
                                        style={{margin: "auto", maxWidth: 1000}}>
                                        With the “iSqorePro” everyone can analyze a company by their own selected
                                        criteria
                                        which makes Finqube so powerful, as
                                        everyone can become an analyst.
                                    </h3>
                                </div>

                                <div style={{display: 'flex', width: '100%', margin: '0 0 20px 0'}}>
                                    <ul style={{
                                        display: 'flex', margin: "10px auto", padding: 0, listStyleType: "none"
                                    }}>
                                        {['A', 'B', 'C', 'D', "E", 'F'].map((grade, index) => {
                                            return (<li key={index}
                                                        className={'grade_p grade_p_sm_table'}
                                                        style={{
                                                            width: 45,
                                                            textAlign: "center",
                                                            margin: "0 3px",
                                                            fontSize: 14,
                                                            border: `1px solid ${return_grade_color(grade)}`,
                                                            color: return_grade_color(grade),
                                                            backgroundColor: return_bg_grade_color(grade)
                                                        }}>
                                                {grade}
                                            </li>)
                                        })}
                                    </ul>
                                </div>


                                <div className={'landing-page-section-image-container'}>
                                    <img
                                        src={ISqoreRatingModel}
                                        alt={"ISqore Rating Image"}
                                        className={'landing-page-laptop glow-image'}/>
                                </div>
                            </div>
                        </section>


                        <section className={'landing-page-section-full-page'}>
                            <div className={'landing-page-section-div'}>
                                <h2 className={'landing-page-section-h2'}>
                                    Simplify Complexity
                                </h2>
                                <h3 className={'landing-page-section-h3'}>
                                    We simplify financial data and analyze a company by different criterias for
                                    their
                                    Valuation, Profitability, Growth, Health and
                                    Quality for essential insights.
                                </h3>

                                <div className={'landing-page-section-image-container'}>
                                    <img
                                        src={SimplifyComplexity}
                                        alt={"Simplify Complexity Image"}
                                        className={'landing-page-laptop glow-image'}/>
                                </div>
                            </div>
                        </section>


                        <section className={'landing-page-section-full-page'}>
                            <div className={'landing-page-section-div'}>
                                <h2 className={'landing-page-section-h2'}>
                                    Informative Visualization
                                </h2>
                                <h3 className={'landing-page-section-h3'}>
                                    Get insight with the help of visualization and informative graphics about the
                                    company’s financials, historical dividends,
                                    revenue breakdown and many more.
                                </h3>

                                <div className={'landing-page-section-image-container'}>
                                    <img
                                        src={InformationVis}
                                        alt={"Informative Visualizations"}
                                        className={'landing-page-laptop'}/>
                                </div>
                            </div>
                        </section>

                        <section className={'landing-page-section-full-page'}>
                            <div className={'landing-page-section-div'}>
                                <h2 className={'landing-page-section-h2'}>
                                    Know the Fair Value
                                </h2>
                                <h3 className={'landing-page-section-h3'}>
                                    Never overpay for a stock you wanna invest in. With our fair value tool, we want
                                    help to get some idea about the valuation
                                    of any company based on three different scenarios.
                                </h3>

                                <div className={'landing-page-section-image-container'}>
                                    <img
                                        src={KnowTheFairValue}
                                        alt={"Fair Value Image"}
                                        className={'landing-page-laptop glow-image'}/>
                                </div>
                            </div>
                        </section>

                        <section className={'landing-page-section'}
                                 style={{maxHeight: '40vh'}}>
                            <div style={{margin: "auto", textAlign: "center"}}>
                                <h3 className={'landing-page-section-h3'}>What are you waiting
                                    for?</h3>

                                <h2 className={'landing-page-section-h2'}>START
                                    MAKING INFORMED DECISIONS</h2>
                            </div>
                        </section>
                    </main>

                    <AccountsFooter/>
                </div>

            </div>
        </div>
    </>);
}

export default LandingPage;