import axios from "axios";
import {useState} from "react";
import {is_number, return_strong_num, return_td_color} from "../../../Utils";
import Config from "../../../../Config";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {GiGears} from "react-icons/gi";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";

const IndicesTab = ({change_index, dashboard = false}) => {
    const navigate = useNavigate()
    const [loadingIndices, setLoadingIndices] = useState(true)
    const [tableData, setTableData] = useState({});

    const make_index_obj = (flag, name, code, country, exchange_code, currency, fmp) => {
        return {
            flag: flag,
            name: name,
            code: code,
            fmp: fmp,
            country: country,
            exchange_code: exchange_code,
            currency: currency
        }
    }

    const indexData = {
        "GSPC.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "S&P 500", "GSPC", "USA", "US", "USD", "^GSPC"),
        "SP100.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "S&P 100", "SP100", "USA", "US", "USD", "^OEX"),
        "DJI.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Dow Jones", "DJI", "USA", "US", "USD", "^DJI"),
        "NDX.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Nasdaq 100", "NDX", "USA", "US", "USD", "^NDX"),
        "RUI.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Russell 1000", "RUI", "USA", "US", "USD", "^RUI"),
        "RUT.INDX": make_index_obj("https://flagcdn.com/108x81/us.png", "Russell 2000", "RUT", "USA", "US", "USD", "^RUT"),

        "STOXX.INDX": make_index_obj("https://flagcdn.com/108x81/eu.png", "Europe Stoxx 600", "STOXX", "Europe", "XETRA", "USD", "^STOXX"),
        "STOXX50E.INDX": make_index_obj("https://flagcdn.com/108x81/eu.png", "EuroStoxx 50", "STOXX50E", "Europe", "XETRA", "USD", "^STOXX50E"),

        "GDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX40", "GDAXI", "Germany", "XETRA", "USD", "^GDAXI"),
        "MDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX Midcap", "MDAXI", "Germany", "XETRA", "USD", "^MDAXI"),
        "SDAXI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "DAX Smallcap", "SDAXI", "Germany", "XETRA", "USD", "^SDAXI"),
        "TECDAX.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "TecDAX", "TECDAX", "Germany", "XETRA", "USD", "^TECDAX"),
        "GDAXHI.INDX": make_index_obj("https://flagcdn.com/108x81/de.png", "HDAX", "GDAXHI", "Germany", "XETRA", "USD", ""),

        "FTSE.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 100", "FTSE", "United Kingdom", "LSE", "USD", "^FTSE"),
        "FTMC.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 250", "FTMC", "United Kingdom", "LSE", "USD", "^FTMC"),
        "FTLC.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE 350", "FTLC", "United Kingdom", "LSE", "USD", "^FTLC"),
        // "FTAS.INDX": make_index_obj("https://flagcdn.com/108x81/gb.png", "FTSE All-Share", "FTAS", "United Kingdom", "LSE", "USD", ""),

        "FCHI.INDX": make_index_obj("https://flagcdn.com/108x81/fr.png", "CAC 40", "FCHI", "France", "PA", "USD", "^FCHI"),
        "CACT.INDX": make_index_obj("https://flagcdn.com/108x81/fr.png", "CAC All-Tradable", "CACT", "France", "PA", "USD", ""),
        "SPMIB.INDX": make_index_obj("https://flagcdn.com/108x81/it.png", "FTSE MIB Index", "SPMIB", "Italy", "MI", "USD", "FTSEMIB.MI"),

        "IBEX.INDX": make_index_obj("https://flagcdn.com/108x81/es.png", "IBEX 35 Index", "IBEX", "Spain", "MC", "USD", "^IBEX"),

        "AEX.INDX": make_index_obj("https://flagcdn.com/108x81/nl.png", "AEX Amsterdam Index", "AEX", "Netherlands", "AS", "USD", "^AEX"),

        "ATX.INDX": make_index_obj("https://flagcdn.com/108x81/at.png", "Austrian Traded Index", "ATX", "Austria", "VI", "USD", "^ATX"),

        "BEL20.INDX": make_index_obj("https://flagcdn.com/108x81/be.png", "BEL-20 Index", "BEL20", "Belgium", "BR", "USD", "^BFX"),

        "OMXC20.INDX": make_index_obj("https://flagcdn.com/108x81/dk.png", "OMX Copenhagen 20 Index", "OMXC20", "Denmark", "CO", "USD", ""),
        "OMXCGI.INDX": make_index_obj("https://flagcdn.com/108x81/dk.png", "OMX Copenhagen All shares", "OMXCGI", "Denmark", "CO", "USD", ""),

        "OMXH25.INDX": make_index_obj("https://flagcdn.com/108x81/fi.png", "OMX Helsinki 25", "OMXH25", "Finland", "HE", "USD", ""),
        "OMXHPI.INDX": make_index_obj("https://flagcdn.com/108x81/fi.png", "OMX Helsinki All Shares", "OMXHPI", "Finland", "HE", "USD", ""),

        "OBX.INDX": make_index_obj("https://flagcdn.com/108x81/no.png", "OBX Oslo 25", "OBX", "Norway", "OL", "USD", ""),
        "OSEAX.INDX": make_index_obj("https://flagcdn.com/108x81/no.png", "OBX Oslo All Shares", "OSEAX", "Norway", "OL", "USD", ""),

        "OMXS30GI.INDX": make_index_obj("https://flagcdn.com/108x81/se.png", "OMX Stockholm 30", "OMXS30GI", "Sweden", "ST", "USD", ""),
        "OMXSPI.INDX": make_index_obj("https://flagcdn.com/108x81/se.png", "OMX Stockholm All Shares", "OMXSPI", "Sweden", "ST", "USD", ""),

        "IETP.INDX": make_index_obj("https://flagcdn.com/108x81/ie.png", "ISEQ 20", "IETP", "Ireland", "IR", "USD", ""),

        "TA35.INDX": make_index_obj("https://flagcdn.com/108x81/il.png", "Tel Aviv 35 Index", "TA35", "Israel", "TA", "USD", ""),
        "TA125.INDX": make_index_obj("https://flagcdn.com/108x81/il.png", "Tel Aviv 100 Index", "TA125", "Israel", "TA", "USD", "^TA125.TA"),

        "WIG30.INDX": make_index_obj("https://flagcdn.com/108x81/pl.png", "WIG 30", "WIG30", "Poland", "WAR", "USD", ""),
        "WIG.INDX": make_index_obj("https://flagcdn.com/108x81/pl.png", "WIG All Shares", "WIG", "Poland", "WAR", "USD", "WIG.WA"),

        "PSI20.INDX": make_index_obj("https://flagcdn.com/108x81/pt.png", "PSI 20 Stock Index", "PSI20", "Portugal", "LS", "USD", ""),

        "SSMI.INDX": make_index_obj("https://flagcdn.com/108x81/ch.png", "Swiss Market Index", "SSMI", "Switzerland", "SW", "USD", "^SSMI"),
        "SSHI.INDX": make_index_obj("https://flagcdn.com/108x81/ch.png", "Swiss Performance Index", "SSHI", "Switzerland", "SW", "USD", ""),

        "XU030.INDX": make_index_obj("https://flagcdn.com/108x81/tr.png", "BIST 30", "XU030", "Turkey", "IS", "USD", ""),
        "XU100.INDX": make_index_obj("https://flagcdn.com/108x81/tr.png", "BIST 100", "XU100", "Turkey", "IS", "USD", "XU100.IS"),

        "AXAF.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 50", "AXAF", "Australia", "AU", "USD", "^AFLI"),
        "ATOI.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 100", "ATOI", "Australia", "AU", "USD", ""),
        "AXAT.INDX": make_index_obj("https://flagcdn.com/108x81/au.png", "ASX 200", "AXAT", "Australia", "AU", "USD", "^AXJO"),

        "IBX50.INDX": make_index_obj("https://flagcdn.com/108x81/br.png", "Bovespa Brazil 50", "IBX50", "Brazil", "SA", "USD", ""),
        "BVSP.INDX": make_index_obj("https://flagcdn.com/108x81/br.png", "Bovespa", "BVSP", "Brazil", "SA", "USD", "^BVSP"),

        "GSPTSE.INDX": make_index_obj("https://flagcdn.com/108x81/ca.png", "TSX 60", "GSPTSE", "Canada", "TO", "USD", "TX60.TS"),

        "SPIPSA.INDX": make_index_obj("https://flagcdn.com/108x81/cl.png", "S&P CLX IPSA", "SPIPSA", "Chile", "SN", "USD", ""),

        "N225.INDX": make_index_obj("https://flagcdn.com/108x81/jp.png", "Nikkei 225", "N225", "Japan", "TSE", "USD", "^N225"),

        "SZSC.INDX": make_index_obj("https://flagcdn.com/108x81/cn.png", "SZSE Composite", "SZSC", "China", "SHG", "USD", ""),
        "SSE180.INDX": make_index_obj("https://flagcdn.com/108x81/cn.png", "SSE 180 Index", "SSE180", "China", "SHG", "USD", "000001.SS"),

        "HSI.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng", "HSI", "Hong Kong", "HK", "USD", "^HSI"),
        "HSCE.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng China Enterprise", "HSCE", "Hong Kong", "HK", "USD", "^HSI"),
        "HSCI.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng Composite", "HSCI", "Hong Kong", "HK", "USD", ""),
        "HSTECH.INDX": make_index_obj("https://flagcdn.com/108x81/hk.png", "Hang Seng Tech", "HSTECH", "Hong Kong", "HK", "USD", ""),

        "NSEI.INDX": make_index_obj("https://flagcdn.com/108x81/in.png", "Nifty 50", "NSEI", "India", "NSE", "USD", "^NSEI"),

        "KOSDAQ.INDX": make_index_obj("https://flagcdn.com/108x81/kr.png", "Kosdaq Composite", "KOSDAQ", "Korea", "KQ", "USD", ""),

        "KLSE.INDX": make_index_obj("https://flagcdn.com/108x81/my.png", "FTSE Malaysia KLCI", "KLSE", "Malaysia", "KLSE", "USD", "^KLSE"),

        "KSE100.INDX": make_index_obj("https://flagcdn.com/108x81/pk.png", "Karachi 100", "KSE100", "Pakistan", "KAR", "USD", ""),

        "PSEI.INDX": make_index_obj("https://flagcdn.com/108x81/ph.png", "PSEI Index", "PSEI", "Philippines", "PSE", "USD", ""),

        "TWII.INDX": make_index_obj("https://flagcdn.com/108x81/tw.png", "Taiwan Weighted Index", "TWII", "Taiwan", "TW", "USD", "^TWII"),

        "TASI.INDX": make_index_obj("https://flagcdn.com/108x81/sa.png", "Tadawul All Share", "TASI", "Saudi Arabia", "SR", "USD", "^TASI.SR"),

        "SET.INDX": make_index_obj("https://flagcdn.com/108x81/th.png", "Thailand SET Index", "SET", "Thailand", "BK", "USD", "^SET.BK")
    }

    const load_real_time_indices = async (_data) => {
        if (!_data) {
            _data = tableData
        }

        const index_live = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/index-live-prices/`, Config())

        Object.entries(indexData).map(([key, value]) => {
            const index = index_live.data.filter(item => item['symbol'] === indexData[key]['fmp'])[0]

            console.log(index)

            if (index) {
                _data[indexData[key]['code'] + ".INDX"] = {
                    ..._data[indexData[key]['code'] + ".INDX"],
                    "Last Price": return_strong_num(index['price']),
                    "Change %": return_strong_num(index['changesPercentage'])
                }
            }
        })

        const index_performance = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/index-live-performances/`, Config())

        Object.entries(indexData).map(([key, value]) => {
            const index = index_performance.data.filter(item => item['symbol'] === indexData[key]['fmp'])[0]
            if (index) {
                _data[indexData[key]['code'] + ".INDX"] = {
                    ..._data[indexData[key]['code'] + ".INDX"],
                    "YTD": return_strong_num(index['ytd']),
                    "Week": return_strong_num(index['5D']),
                    "1 Month": return_strong_num(index['1M']),
                    "1 Year": return_strong_num(index['1Y']),
                    "5 Year": return_strong_num(index['5Y'])
                }
            }
        })

        columns.map((column) => {
            if (column.sort) {
                if (column.state) {
                    const sortedData = Object.entries(_data).sort((a, b) => {
                        a = a[1][column.name]
                        if (is_number(a)) {
                            a = parseFloat(a)
                        }
                        b = b[1][column.name]
                        if (is_number(b)) {
                            b = parseFloat(b)
                        }
                        if (a > b) {
                            return -1;
                        }
                        if (a < b) {
                            return 1;
                        }
                        return 0;
                    })
                    _data = Object.fromEntries(sortedData);
                } else {
                    const sortedData = Object.entries(tableData).sort((a, b) => {
                        a = a[1][column.name]
                        if (is_number(a)) {
                            a = parseFloat(a)
                        }
                        b = b[1][column.name]
                        if (is_number(b)) {
                            b = parseFloat(b)
                        }
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    })
                    _data = Object.fromEntries(sortedData);
                }
            }
        })

        setTableData(_data)
        setLoadingIndices(false)
    }

    const {data} = useQuery({
        queryKey: ['get-indices-data'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-indices-data/`, Config())
            return res.data
        },
        onSuccess: (data) => {
            let _data = {}
            if (Object.entries(tableData).length === 0) {
                Object.entries(indexData).map(([key, value]) => {
                    const index = data.filter(item => item['code'].split('.')[0] === indexData[key]['code'])[0]
                    if (index) {
                        _data[indexData[key]['code'] + ".INDX"] = {
                            "img": indexData[key]['flag'],
                            "Name": indexData[key]['name'],
                            "Code": indexData[key]['code'],
                            "Country": indexData[key]['country'],
                            "Last Price": return_strong_num(index['close']),
                            "Change %": return_strong_num(index['change_p']),
                            "YTD": return_strong_num(index['ytd'] * 100),
                            "Week": return_strong_num(index['week'] * 100),
                            "1 Month": return_strong_num(index['one_month'] * 100),
                            "1 Year": return_strong_num(index['one_year'] * 100),
                            "5 Year": return_strong_num(index['five_year'] * 100)
                        }
                    }
                })
                void load_real_time_indices(_data)
            } else {
                Object.entries(tableData).map(([key, value]) => {
                    const index = data.filter(item => item['code'].split('.')[0] === indexData[key]['code'])[0]
                    if (index) {
                        _data[indexData[key]['code'] + ".INDX"] = {
                            "img": indexData[key]['flag'],
                            "Name": indexData[key]['name'],
                            "Code": indexData[key]['code'],
                            "Country": indexData[key]['country'],
                            "Last Price": return_strong_num(index['close']),
                            "Change %": return_strong_num(index['change_p']),
                            "YTD": return_strong_num(index['ytd'] * 100),
                            "Week": return_strong_num(index['week'] * 100),
                            "1 Month": return_strong_num(index['one_month'] * 100),
                            "1 Year": return_strong_num(index['one_year'] * 100),
                            "5 Year": return_strong_num(index['five_year'] * 100)
                        }
                    }
                })
                void load_real_time_indices(_data)
            }
        },
        refetchInterval: 30000,
    })


    const [columns, setColumns] = useState([
        {name: "", state: 0, sort: false, class_name: "indices-table-index-flag"},
        {name: "Name", state: 0, sort: false, class_name: "indices-table-index-name"},
        {name: "Code", state: 0, sort: false, class_name: ""},
        {name: "Country", state: 0, sort: false, class_name: ""},
        {name: "Last Price", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "Change %", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "YTD", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "Week", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "1 Month", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "1 Year", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "5 Year", state: 0, sort: false, class_name: "item-right-align-th"}
    ])

    function sortDataByKey(name, order) {
        setColumns(columns.map((item) => {
            if (item.name === name) {
                item.sort = true
                item.state = !item.state
                return item
            } else {
                item.sort = false
                item.state = 0
                return item
            }
        }))

        if (order) {
            const sortedData = Object.entries(tableData).sort((a, b) => {
                a = a[1][name]
                if (is_number(a)) {
                    a = parseFloat(a)
                }
                b = b[1][name]
                if (is_number(b)) {
                    b = parseFloat(b)
                }

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            })
            setTableData(Object.fromEntries(sortedData))
        } else {
            const sortedData = Object.entries(tableData).sort((a, b) => {
                a = a[1][name]
                if (is_number(a)) {
                    a = parseFloat(a)
                }
                b = b[1][name]
                if (is_number(b)) {
                    b = parseFloat(b)
                }
                if (a > b) {
                    return -1;
                }
                if (a < b) {
                    return 1;
                }
                return 0;
            })
            setTableData(Object.fromEntries(sortedData))
        }

    }

    return (
        loadingIndices ?
            <div style={{width: '100%', height: '100%', display: 'flex'}}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <>
                <div className="table-responsive generic-lg-table-container">
                    <table className="table generic-lg-table indices-lg-table">
                        <thead>
                        <tr>
                            {columns.map((item, index) => {
                                return (
                                    <>
                                        <th key={index} className={item.class_name} onClick={() => {
                                            sortDataByKey(item.name, item.state)
                                        }}>
                                            {item.name === '' ?
                                                <>
                                                    {item.name}
                                                </>
                                                :
                                                <>
                                                    {item.name} {item.state ? <BsArrowUp/> : <BsArrowDown/>}
                                                </>
                                            }
                                        </th>
                                    </>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>

                        {Object.keys(tableData).map((key, index) => {
                            return (
                                <>
                                    <tr>
                                        <td>
                                            <img style={{width: 23}} src={tableData[key]['img']}/>
                                        </td>
                                        <td onClick={() => {
                                            if (dashboard) {
                                                navigate(`/indices/${tableData[key]['Code']}/`)
                                            } else {
                                                change_index(tableData[key]['Code'])
                                            }
                                        }}
                                            className={tableData[key]['Name'].length > 25 && "hovertext"}
                                            data-hover={tableData[key]['Name']}>
                                            {tableData[key]['Name'].length > 25 ? tableData[key]['Name'].substring(0, 25) + "..." : tableData[key]['Name']}
                                        </td>
                                        <td onClick={() => {
                                            change_index(tableData[key]['Code'])
                                        }}>{tableData[key]['Code']}</td>
                                        <td>{tableData[key]['Country']}</td>
                                        <td className={'item-right-align-th'}>{tableData[key]['Last Price']}</td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['Change %'])
                                            }}>{return_strong_num(tableData[key]['Change %'])} %</p>
                                        </td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['YTD'])
                                            }}>{return_strong_num(tableData[key]['YTD'])} %</p>
                                        </td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['Week'])
                                            }}>{return_strong_num(tableData[key]['Week'])} %</p>
                                        </td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['1 Month'])
                                            }}>{return_strong_num(tableData[key]['1 Month'])} %</p>
                                        </td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['1 Year'])
                                            }}>{return_strong_num(tableData[key]['1 Year'])} %</p>
                                        </td>
                                        <td className={'item-right-align-th'}>
                                            <p style={{
                                                fontWeight: 600,
                                                color: return_td_color(tableData[key]['5 Year'])
                                            }}>{return_strong_num(tableData[key]['5 Year'])} %</p>
                                        </td>
                                    </tr>
                                    <div style={{marginTop: 3, width: "100%"}}/>
                                </>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default IndicesTab;
