import React, {useEffect, useState} from 'react';
import {return_float, return_per_div, return_strong_num} from "../../Utils";
import clsx from "clsx";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";

function SummaryTable({setChartData, title, header, body, header_table = false}) {

    const [rows, setRows] = useState(body)

    const [lettersLength, setLettersLength] = useState(30)

    const [changeSort, setChangeSort] = useState(false)
    const sort = (order) => {
        setChangeSort(!changeSort)
        if (order) {
            setRows([...rows].sort((a, b) => {
                if (a['change_p'] < b['change_p']) {
                    return -1;
                }
                if (a['change_p'] > b['change_p']) {
                    return 1;
                }
                return 0;
            }))
        } else {
            setRows([...rows].sort((a, b) => {
                if (a['change_p'] > b['change_p']) {
                    return -1;
                }
                if (a['change_p'] < b['change_p']) {
                    return 1;
                }
                return 0;
            }));
        }
    }

    function handleResize() {
        if (window.innerWidth <= 768) {
            setLettersLength(15)
        } else {
            setLettersLength(30)
        }
    }

    useEffect(() => {
        sort(false)
        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])

    return (
        <div className={'market-overview-summary-table-container'}>
            {header_table &&
                <div className={'market-overview-summary-table-title'}>
                    <p>{title}</p>
                </div>
            }

            <div className={'market-overview-summary-table-body-container'} style={{
                borderRadius: !header_table ? 10 : '0 0 10px 10px',
            }}>
                <table className={'table generic-table-body'} style={{marginBottom: 0}}>
                    <tbody>
                    <tr>
                        <td style={{fontWeight: 600}}>
                            {header}
                        </td>
                        <td style={{width: 60, fontWeight: 600}}>
                            Symbol
                        </td>
                        <td style={{width: 65, textAlign: 'right', fontWeight: 600}}>
                            Price
                        </td>
                        <td style={{width: 78, textAlign: 'right', fontWeight: 600, cursor: "pointer"}} onClick={() => {
                            sort(changeSort)
                        }}>
                            Chg %{!changeSort ? <BsArrowUp/> : <BsArrowDown/>}
                        </td>
                    </tr>
                    {rows.map((item) => {
                        return (
                            <tr style={{borderBottom: "none"}}
                                onClick={() => {
                                    setChartData({
                                        name: item.name,
                                        code: item.code,
                                        exchange: item.exchange,
                                        type: item.type
                                    })
                                }}>
                                <td className={item.name.length > lettersLength && "hovertext"} data-hover={item.name}>
                                    {item.name.length > lettersLength ? item.name.substring(0, lettersLength) + "..." : item.name}
                                </td>
                                <td>
                                    {item.code}
                                </td>
                                <td style={{
                                    textAlign: 'right'
                                }}>
                                    {!isNaN(item.price) ? item.price.toFixed(2) : ""}
                                </td>
                                <td style={{
                                    display: 'flex',
                                    paddingRight: 2
                                }}>
                                    {return_per_div(item.change_p)}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SummaryTable;