import React, {useEffect, useState} from 'react';
import {return_float, return_strong_num} from "../Utils";
import SectorWeight from "./SectorWeight";

function EtfSectorWeights({data}) {

    const [sectorData, setSectorData] = useState([])
    const [labels, setLabels] = useState([])
    let sectors = [
        'Basic Materials',
        'Energy',
        'Real Estate',
        'Communication Services',
        'Financial Services',
        'Utilities',
        'Consumer Cyclicals',
        'Healthcare',
        'Technology',
        'Consumer Defensive',
        'Industrials',
    ]

    useEffect(() => {
        let _labels = []
        let _series = []

        data.sector_weights.map((item) => {
            if (return_strong_num(item['equity_per']) > 0) {
                _labels.push(item['sector'])
                _series.push(return_float(item['equity_per']).toFixed(2))
            }
        })

        sectors.map((sector) => {
            if (!_labels.includes(sector)) {
                _labels.push(sector)
                _series.push(0)
            }
        })

        setLabels(_labels)
        setSectorData(_series)
    }, [data])


    return (
        <div className={'etf-profiler-stat-table-container'}>
            <div className={'index-stat-table-title'}>
                <p className={'selected'}>Sector Weights</p>
            </div>
            <SectorWeight data={sectorData} labels={labels}/>
        </div>
    );
}

export default EtfSectorWeights;