import React, {useContext, useEffect, useState} from 'react';
import DashboardLayout from "../Layout/DashboardLayout";
import {Box, Modal, Tab, Tabs, useTheme} from "@material-ui/core";
import axios from "axios";
import Config from "../Config";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";
import WatchlistModal from "../Container/Watchlist/WatchlistModal";
import {useSelector} from "react-redux";
import {a11yProps, TabPanel} from "../Container/Utils";
import WatchlistSelectableTable from "../Container/Watchlist/WatchlistSelectableTable";
import LoginPaymentBlocker from "../Container/LoginPaymentBlocker";
import {toast} from "react-hot-toast";
import watchlistblockerlight from '../assets/blockers/watchlist-blocker-light.png'
import watchlistblockerdark from '../assets/blockers/watchlist-blocker-dark.png'
import {ThemeContext} from "../Context/ThemeContext";
import {Helmet} from "react-helmet-async";
import DeleteWatchlistModal from "../Container/Watchlist/DeleteWatchlistModal";
import CompanySummaryTab from "../Container/CompanyTabs/CompanySummaryTab";
import ProfileTabs from "../Container/CompanyTabs/ProfileTabs";
import UserWatchlists from "../Container/Watchlist/UserWatchlists";
import NotesPriceAlertsTab from "../Container/Profile/NotesPriceAlertsTab";


function WatchList() {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <Helmet>
                <title>Personalized Stocks Watchlist</title>
                <meta name={"description"}
                      content={"Create and track your favorite stocks with a personalized watchlist."}/>
            </Helmet>
            <DashboardLayout>
                <div className={"generic-tabs-container"}>
                    <Box display="flex" justifyContent="left" width="100%">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            indicatorColor={'primary'}
                            scrollButtons="auto"
                        >
                            <Tab label="Watchlist" {...a11yProps(0)} />
                            <Tab label="Notes/ Price Alerts"
                                 style={{}} {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <div className="tabsPanelContainer company-overview-panel">
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            disabled={true}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={0}
                                      dir={theme.direction}>
                                <UserWatchlists />
                            </TabPanel>

                            <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                                <NotesPriceAlertsTab />
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </div>
            </DashboardLayout>
        </>
    );
}

export default WatchList;