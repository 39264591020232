import React, {useEffect, useState} from 'react';
import EarningsTable from "./EarningsTable";
import DividendsTable from "./DividendsTable";
import axios from "axios";
import Config from "../../Config";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {Modal} from "@material-ui/core";
import CalenderExchangeModal from "./CalenderExchangeModal";
import {BsFilterLeft} from "react-icons/bs";
import {toast} from "react-hot-toast";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";

const get_calender_data = (type, period, exchanges) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/get-calender-data/`,
        {
            type: type,
            period: period,
            exchanges: exchanges
        }, Config())
}

function CalenderSubTabPeriod({type, period}) {
    const queryClient = useQueryClient();

    const [tableData, setTableData] = useState([])

    const [checkedExchange, setCheckedExchange] = useState([])

    const {isLoading, data: apiData} = useQuery({
        queryKey: [`get-calender-data-${type}-${period}`, type, period, checkedExchange],
        queryFn: async () => {
            const res = await get_calender_data(type, period, checkedExchange)
            return res.data
        },
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (apiData) {
            return_companies(apiData)
        }
    }, [apiData])

    const return_companies = (data) => {
        setTableData(data.sort((a, b) => b.mcap - a.mcap))
    }

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [selectedTicker, setSelectedTicker] = useState({})

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                const updateData = tableData.map((item) => item.company_id === selectedTicker.company_id ? {
                    ...item,
                    watchlist: response.data['ticker_exists']
                } : item)

                setTableData(updateData)

                queryClient.setQueryData([`get-calender-data-${type}-${period}`, type, period, checkedExchange], updateData)

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseWatchlistModal()
            })
            .catch(err => console.log(err.message))
    }

    const [openWatchlistModal, setOpenWatchlistModal] = useState(false)
    const handleOpenWatchlistModal = (item) => {
        setSelectedTicker(item)
        setOpenWatchlistModal(true)
    }
    const handleCloseWatchlistModal = () => {
        setOpenWatchlistModal(false)
    }

    return (
        <>
            <div className={'isqore-filter-btn'} onClick={handleOpenModal}>
                <p>Filter
                    <span>
                        <BsFilterLeft style={{fontSize: 20}}/>
                    </span>
                </p>
            </div>
            {
                isLoading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    : type === 'earnings' ?
                        <EarningsTable tableData={tableData} handleOpenModal={handleOpenWatchlistModal}/> :
                        <DividendsTable tableData={tableData} handleOpenModal={handleOpenWatchlistModal}/>
            }

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CalenderExchangeModal
                    handleCloseModal={handleCloseModal}
                    checkedExchange={checkedExchange}
                    setCheckedExchange={setCheckedExchange}/>
            </Modal>


            <Modal
                open={openWatchlistModal}
                onClose={handleCloseWatchlistModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseWatchlistModal}
                />
            </Modal>
        </>
    );
}

export default CalenderSubTabPeriod;