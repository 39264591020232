import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getModalStyle} from "../Utils";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import Config from "../../Config";
import axios from "axios";

function DeleteWatchlistModal({deleteWatchlistId, DeleteWatchList, handleCloseModal}) {
    const [modalStyle] = useState(getModalStyle);

    return (
        <div style={modalStyle}
             className={clsx('watchlist-modal-body')}>
            <div style={{display: 'flex'}}>
                <p className={'watchlist-modal-title'}>Are you sure you want to delete this watchlist</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn settings-ticker-btn'} onClick={handleCloseModal} style={{marginRight: "auto"}}>No
                </button>
                <button className={'btn delete-ticker-btn'} onClick={() => {
                    DeleteWatchList(deleteWatchlistId)
                    handleCloseModal()
                }} style={{marginLeft: "auto"}}>Delete
                </button>
            </div>

        </div>
    );
}

export default DeleteWatchlistModal;