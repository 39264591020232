import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {return_per_div, return_strong_num, return_td_color} from "../Utils";
import DividendDonutGraph from "./CalenderTabs/DividendDonutGraph";
import DividendLineGraph from "./CalenderTabs/DividendLineGraph";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";
import FinancialOverviewBarTab from "./FinancialTabs/FinancialOverview/FinancialOverviewBarTab";
import clsx from "clsx";
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {FaCheckCircle} from "react-icons/fa";
import {MdCancel} from "react-icons/md";
import {ThemeContext} from "../../Context/ThemeContext";

const company_overview_dividends = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-dividends/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const DividendTab = ({code, exchange}) => {
    const [loading, setLoading] = useState(true);
    const [dividends, setDividends] = useState(null)

    const [dividendsTable, setDividendsTable] = useState(null)

    const {data: dividendReturn, isLoading: dividendsIsLoading} = useQuery({
        queryKey: [`company-overview-dividends/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_dividends(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!dividendsIsLoading && dividendReturn) {
            setDividends(dividendReturn)
            setLoading(false)
        }
    }, [dividendReturn, dividendsIsLoading]);

    const DividendTab = ({text, value, value_type, currency}) => {
        return (
            <div
                className={clsx('financial-overview-bar-tab fair-overview-bar-tab dividend-tabs')}>
                <p className={'financial-overview-bar-tab-text'}>{text}</p>

                <div className="financial-overview-bar-container">
                    {value_type === 'date' ?
                        <div className={'financial-overview-bar-per-container'}
                             style={{
                                 padding: 6,
                                 fontSize: 12,
                                 color: value ? '#4ebe96' : 'white',
                                 backgroundColor: value ? "#4ebe9629" : '#89898966',
                                 fontWeight: 600,
                                 margin: "12px auto 0 auto",
                             }}>
                            {value ? value : "-"}
                        </div>
                        :
                        <div className={'financial-overview-bar-per-container'}
                             style={{
                                 padding: "6px",
                                 fontSize: 12,
                                 margin: "12px auto 0 auto",
                                 color: return_td_color(value),
                                 backgroundColor: (value === null || return_strong_num(value) === '0.00' || return_strong_num(value) === '-100.00') ? '#89898966' : return_strong_num(value) > 0 ? '#4ebe9629' : '#d84f6829',
                             }}>
                            {value ? return_strong_num(value) : "-"} {currency}
                        </div>
                    }
                </div>
            </div>
        )
    }

    function Row(props) {
        const {row} = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{'& > *': {borderBottom: 'unset'}}}
                          className={open ? "dividend-table-open" : "dividend-table-close"}>
                    <TableCell className={'dividend-table-arrow'}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <IoIosArrowUp style={{fontSize: 12}}/> : <IoIosArrowDown style={{fontSize: 12}}/>}
                        </IconButton>
                    </TableCell>
                    <TableCell className={'dividend-table-date'} style={{fontWeight: 600}}>
                        {row.date}
                    </TableCell>
                    <TableCell className={'dividend-table-payment-date'} style={{fontWeight: 600}}
                               align="right">{row.paymentDate}</TableCell>
                    <TableCell className={'dividend-table-dividend'} style={{fontWeight: 600}}
                               align="right">{return_strong_num(row.dividend)} {row.group.length > 0 && row.group[0].currency}</TableCell>
                    <TableCell className={'dividend-table-growth'} style={{fontWeight: 600}} align="right">
                        {return_per_div(row.growth, 11, 60, "0 0 0 auto")}
                    </TableCell>
                    <TableCell className={'dividend-table-yield'} style={{fontWeight: 600}}
                               align="right">{row.yield ? return_strong_num(row.yield) : '-'} %</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{padding: 0}} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table aria-label="collapsible table" className={'generic-lg-table'}>
                                <TableBody>
                                    {row.group.map((item, index) => {
                                        return (
                                            <TableRow sx={{'& > *': {borderBottom: 'unset'}}} key={index}>
                                                <TableCell className={'dividend-table-arrow'}/>
                                                <TableCell className={'dividend-table-date'}>
                                                    {item.date}
                                                </TableCell>
                                                <TableCell className={'dividend-table-payment-date'}
                                                           align="right">{item.paymentDate}</TableCell>
                                                <TableCell className={'dividend-table-dividend'}
                                                           align="right">{return_strong_num(item.value)} {item.currency}</TableCell>
                                                <TableCell className={'dividend-table-growth'} align="right">
                                                    {return_per_div(item.growth, 11, 60, "0 0 0 auto")}
                                                </TableCell>
                                                <TableCell className={'dividend-table-yield'}
                                                           align="right">{item.yield ? return_strong_num(item.yield) : '-'} %</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
                <div style={{marginTop: 3, width: "100%"}}/>
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (dividends) {
            let rows = {}

            const dividends_arr = dividends['dividends']

            dividends_arr.map((item, index) => {
                const year = new Date(item.date).getFullYear()

                let growth = null

                if (index + 1 < dividends_arr.length) {
                    const prev_dividend = dividends_arr[index + 1]

                    growth = ((item.value / prev_dividend.value) - 1) * 100
                }

                if (year in rows) {
                    rows[year].dividend += item.value ? item.value : 0
                    rows[year].group.push({...item, growth})
                } else {
                    rows[year] = {
                        'date': year,
                        'paymentDate': '',
                        'dividend': item.value ? item.value : 0,
                        'growth': 0,
                        'yield': 0,
                        'group': [
                            {
                                ...item,
                                growth
                            }
                        ]
                    }
                }
            })

            let temp_rows = Object.entries(rows).map(([key, value]) => value).reverse()

            temp_rows.map((row, index) => {
                let year_yield = 0
                row.group.map((item, index) => {
                    year_yield += item.yield
                })

                row.yield = year_yield

                if (index === 0 && row.group.length > 0 && row.group[0].period === 'Quarterly' && row.group.length < 4) {
                    return row
                }

                if (index + 1 < temp_rows.length) {
                    const prev_dividend = temp_rows[index + 1]
                    row.growth = ((row.dividend / prev_dividend.dividend) - 1) * 100
                }

                return row
            })

            setDividendsTable(temp_rows)
        }
    }, [dividends]);

    const cal_cagr = (x, y, power) => {
        return (((((x - y) + Math.abs(y))) / Math.abs(y)) ** (1 / power)) - 1
    }

    const [cagr, setCagr] = useState({
        cagr_3: null,
        cagr_5: null,
    })

    useEffect(() => {

        if (dividendsTable) {
            let cagr_3 = null;
            let cagr_5 = null;

            let period_type = null

            if (dividendsTable.length > 0 && dividendsTable[0].group.length > 0) {
                period_type = dividendsTable[0].group[0].period
            }

            let index = 0

            if (period_type === 'Quarterly' && dividendsTable[0].group.length < 4) {
                index = 1
            }

            if (dividendsTable.length > 5) {
                cagr_3 = cal_cagr(dividendsTable[index].dividend, dividendsTable[3 + index].dividend, 3)
            }
            if (dividendsTable.length > 7) {
                cagr_5 = cal_cagr(dividendsTable[index].dividend, dividendsTable[5 + index].dividend, 5)
            }

            setCagr({
                cagr_3,
                cagr_5
            })
        }
    }, [dividendsTable]);

    const {webTheme} = useContext(ThemeContext);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(11);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        loading ?
            <div style={{width: '100%', height: '100%', display: ' flex'}}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <>
                <div className="row g-0">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className={'company-overview-dividend-header-container'}>
                            <div className={'company-overview-dividend-header'}>
                                <p>Dividend Overview</p>
                            </div>
                            <div className="row g-0 my-auto" style={{margin: "auto 0"}}>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                                    <DividendTab
                                        text={'Next Payment'}
                                        value={dividends['split_dividends']['DividendDate']}
                                        value_type={'date'}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                                    <DividendTab
                                        text={'Annual Dividend'}
                                        value={dividends['split_dividends']['ForwardAnnualDividendRate']}
                                        value_type={'number'}
                                        currency={(dividendsTable && dividendsTable.length > 0) ? dividendsTable[0].group[0].currency : ""}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                                    <FinancialOverviewBarTab
                                        text={"Dividend Yield"}
                                        fairValue={true}
                                        expectedCARG={dividends['split_dividends']['ForwardAnnualDividendYield']}
                                        expected={true}
                                        year3={0}
                                        showYear3={true}
                                        year5={0}
                                        showYear5={true}
                                        avg={false}
                                        dividend_class={true}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                                    <FinancialOverviewBarTab
                                        text={"Dividend Growth"}
                                        fairValue={true}
                                        year3={cagr.cagr_3}
                                        showYear3={true}
                                        year5={cagr.cagr_5}
                                        showYear5={true}
                                        avg={false}
                                        dividend_class={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className={'row g-0'}>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <div
                                    className={'company-overview-dividend-header-container company-overview-dividend-header-container-second'}>
                                    <div className={'company-overview-dividend-header'}>
                                        <p>Keyfacts</p>
                                    </div>
                                    <div className={'company-overview-dividend-header-body'}>
                                        <div className={'company-overview-dividend-header-body-point'}>
                                            {dividends['dividend-payment']
                                                ?
                                                <FaCheckCircle className={'check'}
                                                               style={{color: "rgb(41, 187, 99)"}}/>
                                                :
                                                <MdCancel
                                                    style={{color: "rgb(211, 54, 83)"}}/>
                                            }
                                            <p>Dividend Payment more then 5 years</p>
                                        </div>
                                        <div className={'company-overview-dividend-header-body-point'}>
                                            {dividends['dividend-growth']
                                                ?
                                                <FaCheckCircle className={'check'}
                                                               style={{color: "rgb(41, 187, 99)"}}/>
                                                :
                                                <MdCancel
                                                    style={{color: "rgb(211, 54, 83)"}}/>
                                            }
                                            <p>Dividend Growth more then 5 years</p>
                                        </div>
                                        <div className={'company-overview-dividend-header-body-point'}>
                                            {dividends['split_dividends']['ForwardAnnualDividendYield'] * 100 > 2
                                                ?
                                                <FaCheckCircle className={'check'}
                                                               style={{color: "rgb(41, 187, 99)"}}/>
                                                :
                                                <MdCancel
                                                    style={{color: "rgb(211, 54, 83)"}}/>
                                            }
                                            <p>Dividend Yield above 2%</p>
                                        </div>
                                        <div className={'company-overview-dividend-header-body-point'}>
                                            {dividends['split_dividends']['PayoutRatio'] * 100 > 20 && dividends['split_dividends']['PayoutRatio'] * 100 < 80
                                                ?
                                                <FaCheckCircle className={'check'}
                                                               style={{color: "rgb(41, 187, 99)"}}/>
                                                :
                                                <MdCancel
                                                    style={{color: "rgb(211, 54, 83)"}}/>
                                            }
                                            <p>Payout Ratio between 20 - 80%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <DividendDonutGraph dividends={dividends}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'row g-0'}>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Table aria-label="collapsible table" className={'generic-lg-table dividend-table'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'dividend-table-arrow'}/>
                                    <TableCell className={'dividend-table-date'}>Ex-Date</TableCell>
                                    <TableCell className={'dividend-table-payment-date'} align="right">Pay-Date</TableCell>
                                    <TableCell className={'dividend-table-dividend'} align="right">Dividend</TableCell>
                                    <TableCell className={'dividend-table-growth'} align="right">Growth %</TableCell>
                                    <TableCell className={'dividend-table-yield'} align="right">Yield %</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dividendsTable && dividendsTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <Row key={row.date} row={row}/>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            style={{color: webTheme === 'dark' ? 'white' : 'black'}}
                            rowsPerPageOptions={false}
                            component="div"
                            count={dividendsTable ? dividendsTable.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <DividendLineGraph dividends={dividends}/>
                    </div>
                </div>
            </>
    );
}

export default DividendTab;
