import React, {useContext, useEffect, useState} from 'react';
import {Box, Modal, Tab, Tabs, useTheme} from "@material-ui/core";
import {ThemeContext} from "../../Context/ThemeContext";
import {useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import Config from "../../Config";
import axios from "axios";
import LoginPaymentBlocker from "../LoginPaymentBlocker";
import watchlistblockerdark from "../../assets/blockers/watchlist-blocker-dark.png";
import watchlistblockerlight from "../../assets/blockers/watchlist-blocker-light.png";
import clsx from "clsx";
import {a11yProps, TabPanel} from "../Utils";
import SwipeableViews from "react-swipeable-views";
import WatchlistSelectableTable from "./WatchlistSelectableTable";
import WatchlistModal from "./WatchlistModal";
import DeleteWatchlistModal from "./DeleteWatchlistModal";

function UserWatchlists({}) {
    const theme = useTheme();
    const {webTheme} = useContext(ThemeContext)

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const [userWatchList, setUserWatchList] = useState([])

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (userWatchList.length > 0) {
            setValue(userWatchList.length - 1)
        }
    }, [userWatchList]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [openWatchListModal, setOpenWatchListModal] = useState(false)

    const handleOpenWatchListModal = () => {
        if (!isAuthenticated) {
            toast.error("You have to be logged in to use this feature.")
            return
        }
        if (user.subscription.payment_method === 'Free' && userWatchList.length >= 1) {
            toast.error("Free package allows only one watchlist, please subscribe for our Basic Plan to get up to 10 watchlists")
            return
        }
        if (user.subscription.payment_method !== 'Free' && userWatchList.length >= 10) {
            toast.error("You have reached the maximum number of watchlists")
            return
        }
        setOpenWatchListModal(true)
    }

    const handleCloseWatchListModal = () => {
        setOpenWatchListModal(false)
    }

    const DeleteWatchList = (id) => {
        if (isAuthenticated) {
            const config = Config()
            const body = {id: id}
            axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/delete-watchlist/`, body, config)
                .then(response => {
                    setUserWatchList(response.data)
                    setValue(0)
                })
                .catch(err => console.log(err.message))
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            const config = Config()
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-user-watchlists/`, config)
                .then(response => setUserWatchList(response.data))
                .catch(err => console.log(err.message))
        }
    }, [isAuthenticated])

    const [openDeleteWatchlistModal, setOpenDeleteWatchlistModal] = useState(false)
    const [deleteWatchlistId, setDeleteWatchlistId] = useState(null)

    const handleOpenDeleteWatchlistModal = (id) => {
        setDeleteWatchlistId(id)
        setOpenDeleteWatchlistModal(true)
    }

    const handleCloseDeleteWatchlistModal = () => {
        setOpenDeleteWatchlistModal(false)
    }

    return (
        <>
            <div className={'entire-body-lg'}>

                {!isAuthenticated &&
                    <LoginPaymentBlocker condition={'You must be logged in to use this feature'}
                                         lg={true} login={true} subscription={false}/>
                }

                {!isAuthenticated &&
                    <img src={webTheme === 'dark' ? watchlistblockerdark : watchlistblockerlight}
                         className={'blocker-img'}/>
                }

                {isAuthenticated &&
                    <div
                        style={{position: 'relative', width: '100%', height: '100%'}}>
                        <div style={{display: 'flex'}}>
                            <button className={'btn add-watchlist-btn add-watchlist-btn-lg'}
                                    style={{margin: 'auto 0 auto auto'}}
                                    onClick={handleOpenWatchListModal}>
                                Add Watchlist +
                            </button>
                        </div>

                        <div className={clsx("generic-tabs-container")}>
                            <Box display="flex" justifyContent="left" width="100%">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    indicatorColor={'secondary'}
                                    scrollButtons="auto"
                                >
                                    {userWatchList.map((item, index) => {
                                        return (
                                            <Tab label={item.name} {...a11yProps(index)} />
                                        )
                                    })}
                                </Tabs>
                            </Box>

                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                disabled={true}
                                onChangeIndex={handleChangeIndex}
                            >
                                {userWatchList.map((item, index) => {
                                    return (
                                        <TabPanel className={'generic-tab-panel'} value={value}
                                                  index={index} dir={theme.direction}>
                                            <WatchlistSelectableTable setUserWatchList={setUserWatchList}
                                                                      watchListName={item.name}
                                                                      watchListID={item.id}
                                                                      watchlist={item}
                                                                      handleOpenDeleteWatchlistModal={handleOpenDeleteWatchlistModal}
                                            />
                                        </TabPanel>
                                    )
                                })}
                            </SwipeableViews>

                        </div>
                    </div>
                }
            </div>

            <Modal
                open={openWatchListModal}
                onClose={handleCloseWatchListModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <WatchlistModal setUserWatchList={setUserWatchList}
                                handleCloseModal={handleCloseWatchListModal}/>
            </Modal>

            <Modal
                open={openDeleteWatchlistModal}
                onClose={handleCloseDeleteWatchlistModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DeleteWatchlistModal
                    deleteWatchlistId={deleteWatchlistId}
                    DeleteWatchList={DeleteWatchList}
                    handleCloseModal={handleCloseDeleteWatchlistModal}/>
            </Modal>

        </>
    );
}

export default UserWatchlists;