import React from 'react';
import RangeInputComponent from "./RangeInputComponent";


function FilterComponentRange({
                                  groupKey,
                                  componentKey,
                                  filterScreener,
                                  setFilterScreener,
                                  dropDown = true,
                                  currency,
                                  setCurrency
                              }) {

    return (
        <div className={'filter-component'}>

            <p className={'filter-component-title'}>{filterScreener[groupKey]['components'][componentKey]['name']}</p>


            {filterScreener[groupKey]['components'][componentKey]['name'] === 'MCap' ?
                <div className={'d-flex'}>
                    <RangeInputComponent
                        filterScreener={filterScreener}
                        setFilterScreener={setFilterScreener}
                        groupKey={groupKey}
                        componentKey={componentKey}
                        dropDown={dropDown}/>
                    <select
                        value={currency}
                        className="form-select price-compare"
                        onChange={(e) => {
                            setCurrency(e.target.value)
                        }}>
                        <option value="usd">USD</option>
                        <option value="euro">EUR</option>
                        <option value="local">Local</option>
                    </select>
                </div>
                :
                <RangeInputComponent
                    filterScreener={filterScreener}
                    setFilterScreener={setFilterScreener}
                    groupKey={groupKey}
                    componentKey={componentKey}
                    dropDown={dropDown}/>
            }

        </div>
    );
}

export default FilterComponentRange;