function RangeInputComponent({filterScreener, setFilterScreener, groupKey, componentKey, dropDown}) {

    const handleChangeValue = (event) => {
        setFilterScreener({
            ...filterScreener,
            [groupKey]: {
                ...filterScreener[groupKey],
                components: {
                    ...filterScreener[groupKey].components,
                    [componentKey]: {
                        ...filterScreener[groupKey].components[componentKey],
                        value: {
                            ...filterScreener[groupKey].components[componentKey].value,
                            [event.target.name]: event.target.value
                        }
                    }
                }
            }
        })
    }

    return (
        <div className={'filter-component-price-div mt-1 mb-2'}>
            {dropDown &&
                <select
                    value={filterScreener[groupKey].components[componentKey].compare}
                    onChange={(e) => {
                        setFilterScreener({
                            ...filterScreener,
                            [groupKey]: {
                                ...filterScreener[groupKey],
                                components: {
                                    ...filterScreener[groupKey].components,
                                    [componentKey]: {
                                        ...filterScreener[groupKey].components[componentKey],
                                        value: {
                                            ...filterScreener[groupKey].components[componentKey].value,
                                            compare: e.target.value
                                        }
                                    }
                                }
                            }
                        })
                    }}
                    className="form-select price-compare">
                    <option value="greater-than">Greater than</option>
                    <option value="less-than">Less than</option>
                    <option value="equal-to">Equals to</option>
                    <option value="between">Between</option>
                </select>
            }

            {filterScreener[groupKey].components[componentKey].value.compare !== 'between' &&
                <div style={{display: 'flex'}}>
                    <input
                        required={true}
                        value={filterScreener[groupKey].components[componentKey].value.value1}
                        onChange={handleChangeValue}
                        name={'value1'}
                        type={'number'}
                        step="any"
                        placeholder={`enter number ${filterScreener[groupKey].components[componentKey].value.type === 'percentage' ? "(%)" : ""}`}
                        className={'form-control price-input'}/>
                </div>
            }
            {filterScreener[groupKey].components[componentKey].value.compare === 'between' &&
                <div style={{display: "flex"}}>
                    <input
                        required={true}
                        value={filterScreener[groupKey].components[componentKey].value.value1}
                        onChange={handleChangeValue}
                        name={'value1'}
                        type={'number'}
                        step="any"
                        placeholder={`greater than ${filterScreener[groupKey].components[componentKey].value.type === 'percentage' ? "(%)" : ""}`}
                        className={'form-control price-input'}/>
                    <p className={"price-input-separator"}> - </p>
                    <input
                        required={true}
                        value={filterScreener[groupKey].components[componentKey].value.value2}
                        onChange={handleChangeValue}
                        name={'value2'}
                        type={'number'}
                        step="any"
                        placeholder={`less than ${filterScreener[groupKey].components[componentKey].value.type === 'percentage' ? "(%)" : ""}`}
                        className={'form-control price-input'}/>
                </div>
            }
        </div>
    );
}

export default RangeInputComponent;