import React from 'react';
import TextInputField from "./TextInputField";
import {AiFillCamera} from "react-icons/ai";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";

function ProfilePersonalInformation({userData, setUserData, edit}) {

    const onUserDataChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const image = e.target.files[0]

            if (image.size / 1000 > 1024) {
                toast.error('File size has to be smaller than 1 MB');
                return;
            }

            const accepted_formats = ["image/png", "image/jpeg", "image/jpg"]

            if (!accepted_formats.includes(image.type)) {
                toast.error('Accepted image formats are png, jpeg and jpg only.');
                return;
            }

            const config = Config()
            let formData = new FormData()
            formData.append("image", image)

            axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/save-image/`, formData, config)
                .then(response => {
                    setUserData({...userData, img: response.data.image})
                })
                .catch(error => {
                    console.log('error: ', error)
                })
        }
    };


    return (
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                <TextInputField label={'First Name'} onChange={onUserDataChange} value={userData.first_name}
                                name={'first_name'}
                                type={'text'} placeholder={'Enter First Name'} edit={edit}/>

                <TextInputField label={'Last Name'} onChange={onUserDataChange} value={userData.last_name}
                                name={'last_name'}
                                type={'text'} placeholder={'Enter Last Name'} edit={edit}/>

                <TextInputField label={'Birthday'} onChange={onUserDataChange} value={userData.birthday}
                                name={'birthday'}
                                type={'date'} placeholder={''} edit={edit}/>

                <TextInputField label={'Country'} onChange={onUserDataChange} value={userData.country}
                                name={'country'}
                                type={'text'} placeholder={'Enter Country'} edit={edit} select={true} textarea={true}/>

                <TextInputField label={'City'} onChange={onUserDataChange} value={userData.city}
                                name={'city'}
                                type={'text'} placeholder={'Enter City'} edit={edit}/>

                <TextInputField label={'Zip Code'} onChange={onUserDataChange} value={userData.zip_code}
                                name={'zip_code'}
                                type={'text'} placeholder={'Enter Zip Code'} edit={edit}/>

                <TextInputField label={'Address'} onChange={onUserDataChange} value={userData.address}
                                name={'address'}
                                type={'text'} placeholder={'Enter Address'} edit={edit} textarea={true}/>

                {/*  Add for Email  */}

            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{margin: 'auto', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor="read-file" style={{margin: '0 auto'}}>
                            <div style={{position: 'relative', cursor: edit && 'pointer'}}>
                                {edit &&
                                    <AiFillCamera
                                        className={'profile-camera'}/>
                                }
                                <div className={'user-info-avatar'}
                                     style={{backgroundImage: userData.img !== '' && `url(${userData.img})`}}>
                                </div>
                            </div>
                        </label>
                        {edit &&
                            <input type="file"
                                   onChange={handleImageChange}
                                   onClick={(event) => {
                                       event.target.value = null
                                   }} className="read-file" name="read-file" id="read-file"
                                   style={{display: "none"}}
                            />
                        }
                        {edit &&
                            <p className={'profile-size-warning'}>Image size has to be smaller than 1 MB. Accepted formats png,
                                jpeg and jpg only.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePersonalInformation;