import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getModalStyle} from "../Utils";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import Config from "../../Config";
import axios from "axios";

function AddNewScreenerModal({setUserScreeners, handleCloseModal}) {
    const [modalStyle] = useState(getModalStyle);

    const [screenerName, setScreenerName] = useState("")

    const save_screener = () => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/user-screeners/`, {name: screenerName}, config)
            .then(response => {
                setUserScreeners((prev) => [...prev, response.data])
            })
            .catch(err => console.log(err.message))
        handleCloseModal()
    }

    return (
        <div style={modalStyle}
             className={clsx('watchlist-modal-body')}>
            <div style={{display: 'flex'}}>
                <p className={'watchlist-modal-title'}>Create New Screener</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>
            <input
                value={screenerName}
                onChange={(e) => {
                    setScreenerName(e.target.value)
                }}
                className={'form-control watchlist-input-field'}
                placeholder={'Enter Watchlist title'}
            />
            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn save-watchlist-btn'} onClick={save_screener} style={{marginLeft: "auto"}}>Save
                </button>
            </div>
        </div>
    );
}

export default AddNewScreenerModal;
