import React, {useEffect, useState} from 'react';
import {BsArrowDown, BsArrowUp, BsPencilSquare} from "react-icons/bs";
import Config from "../../Config";
import axios from "axios";
import building from "../../assets/default-building.png";
import {return_strong_num} from "../Utils";
import CompanyNotesModal from "../CompanyDetailTab/CompanyNotesModal";
import {Modal} from "@material-ui/core";
import {useQuery} from "@tanstack/react-query";
import {IoMdTrash} from "react-icons/io";
import {toast} from "react-hot-toast";



function NotesPriceAlertsTab({}) {
    const [loadingAlerts, setLoadingAlerts] = useState(true)

    const [columns, setColumns] = useState([
        {name: "", state: 0, sort: false, class_name: "watchlist-table-index"},
        {name: "", state: 0, sort: false, class_name: "watchlist-table-index"},
        {name: "Ticker", state: 0, sort: false, class_name: "price-alerts-ISIN"},
        {name: "Company Name", state: 0, sort: false, class_name: "indices-table-index-name"},
        {name: "ISIN", state: 0, sort: false, class_name: "price-alerts-ISIN"},
        {name: "Notes", state: 0, sort: false, class_name: "indices-table-index-name"},

        {name: "Current Price", state: 0, sort: false, class_name: "item-right-align-th"},
        {name: "Price Alert Low", state: 0, sort: false, class_name: "item-right-align-th price-alert-th"},
        {name: "Price Alert High", state: 0, sort: false, class_name: "item-right-align-th price-alert-th"},
        {name: "Active", state: 0, sort: false, class_name: "price-alert-active-th item-right-align-th"},
    ])

    const [notes, setNotes] = useState([])

    const get_user_notes_price_alert = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/user-notes-price-alert/`, Config());
            const data =  response.data;

            setNotes(data)
            setLoadingAlerts(false)
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        void get_user_notes_price_alert()

        const intervalId = setInterval(() => {
            void get_user_notes_price_alert();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const [openNotesModal, setOpenNotesModal] = useState(false);

    const [notesCompany, setNotesCompany] = useState({
        code: "",
        exchange: "",
        name: "",
        currency: "",
        priceData: {
            price: 0,
            change: 0,
            changesPercentage: 0,
        }
    })

    const handleOpenNotesModal = (code, exchange, name, currency, price, perChange) => {
        setNotesCompany({
            code, exchange, name, currency,
            priceData: {
                price: price,
                change: return_strong_num((return_strong_num(price * perChange) / 100) * 100),
                changesPercentage: return_strong_num(perChange * 100)
            }
        })
        setOpenNotesModal(true)
    }

    const handleCloseNotesModal = () => {
        setOpenNotesModal(false)
    }

    const delete_note = (code, exchange) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/finqube-api/company-notes/${code}/${exchange}/`, Config())
            .then(response => {
                const id = response.data?.id
                setNotes(notes.filter(note => note.id !== id))
                toast.success("Notes Deleted", {duration: 1500})
            })
            .catch(err => console.log(err.message))
    }

    const update_active = (code, exchange, active) => {
        axios.patch(`${process.env.REACT_APP_API_URL}/finqube-api/company-notes/${code}/${exchange}/`, {active: !active}, Config())
            .then(response => {
                const id = response.data?.id
                setNotes(notes.map(note => {
                    if (note.id === id) {
                        note.active = !active
                    }

                    return note
                }))
                toast.success("Notes Updated", {duration: 1500})
            })
            .catch(err => console.log(err.message))
    }

    return (
        <div className="profile-container">
            <div style={{display: 'flex', marginBottom: 15}}>
                <p className={'profile-header'}>Notes/Price Alerts Settings</p>
            </div>

            {
                loadingAlerts ?
                    <div style={{width: '100%', height: '100%', display: 'flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="table-responsive generic-lg-table-container">
                            <table className="table generic-lg-table indices-lg-table price-alerts-table">
                                <thead>
                                <tr>
                                    {columns.map((item, index) => {
                                        return (
                                            <>
                                                <th key={index} className={item.class_name}
                                                    //onClick={() => { sortDataByKey(item.name, item.state)}}
                                                >
                                                    {item.name}
                                                    {/*{item.name === '' ?*/}
                                                    {/*    <>*/}
                                                    {/*        {item.name}*/}
                                                    {/*    </>*/}
                                                    {/*    :*/}
                                                    {/*    <>*/}
                                                    {/*        {item.name} {item.state ? <BsArrowUp/> : <BsArrowDown/>}*/}
                                                    {/*    </>*/}
                                                    {/*}*/}
                                                </th>
                                            </>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {notes.map((note, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className={'delete-watchlist-ticker'}>
                                                    <IoMdTrash className={'delete-watchlist-ticker'}
                                                               onClick={() => {
                                                                   delete_note(note['code'], note['exchange_code'])
                                                               }}/>
                                                </td>
                                                <td style={{width: 20}}>
                                                    <BsPencilSquare className={'delete-watchlist-ticker'}
                                                                    onClick={() => {
                                                                        handleOpenNotesModal(
                                                                            note.code,
                                                                            note.exchange_code,
                                                                            note.name,
                                                                            note.currency,
                                                                            note.adjusted_close,
                                                                            note.yesterday_change
                                                                        )
                                                                    }}/>
                                                </td>
                                                <td><img className={'price-target-company-logo'}
                                                         src={note.logoURL && note.logoURL !== '' ? note.logoURL : building}
                                                         onError={({currentTarget}) => {
                                                             currentTarget.onerror = null;
                                                             currentTarget.src = building;
                                                         }}/>
                                                    {note.code}</td>
                                                <td>{note.name}</td>
                                                <td>{note.ISIN}</td>
                                                <td className={'ellipsis'}>{note.notes}</td>
                                                <td style={{textAlign: "right"}}>{return_strong_num(note.adjusted_close)} {note.currency}</td>
                                                <td style={{textAlign: "right"}}>{note.low}</td>
                                                <td style={{textAlign: "right"}}>{note.high}</td>
                                                <td className={'delete-watchlist-ticker'}>
                                                    <input className="form-check-input" style={{float: "right", clear: "both"}}
                                                           checked={note.active}
                                                           onChange={() => update_active(note['code'], note['exchange_code'], note.active)}
                                                           type="checkbox" value="" id="flexCheckChecked" />
                                                </td>
                                            </tr>
                                            <div style={{marginTop: 3, width: "100%"}}/>
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
            }

            <Modal
                open={openNotesModal}
                onClose={handleCloseNotesModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompanyNotesModal
                    name={notesCompany.name}
                    currency={notesCompany.currency}
                    priceData={notesCompany.priceData}
                    code={notesCompany.code}
                    exchange={notesCompany.exchange}
                    handleCloseModal={handleCloseNotesModal}

                    get_user_notes_price_alert={get_user_notes_price_alert}
                />
            </Modal>

        </div>
    );
}

export default NotesPriceAlertsTab;