import React from 'react';
import MarketMoverGaugeChart from "./MarketMoverGaugeChart";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../../Config";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Tooltip} from "react-tooltip";

function FearAndGreedGauge() {

    const {data, isLoading} = useQuery({
        queryKey: ['fear-and-greed'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fear-and-greed-current/`, Config())
            return res.data
        },
        refetchInterval: 30000
    })

    return (
        <div className={'fear-and-greed-container'}>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
                <h4 style={{margin: '0 auto', textAlign: 'center', flexGrow: 1}}>Fear & Greed</h4>
                <AiOutlineInfoCircle
                    data-tooltip-id="volatility-index-tooltip"
                    style={{fontSize: 17}}
                    data-tooltip-html={
                        `<div style="text-align: left; font-size: 10px; width: 300px;">
                            <p>The Fear and Greed Index is a tool that measures the emotions driving the stock market, specifically fear and greed. 
                            It compiles various indicators to gauge whether investors are feeling optimistic (greedy) or pessimistic (fearful). 
                            When the index is high, it indicates that greed is prevalent and investors are confident. 
                            When the index is low, it suggests that fear is dominating and investors are worried about the market.
                            </p>
                            <ul style="margin: 0 0 2px 5px; padding: 5px;">
                                 <li>< 25 Extreme Fear,  Investors are extremely cautious, expecting significant market downturns.</li>
                                 <li>25 - 45 Fear, Investors are generally nervous, preferring low-risk investments.</li>
                                 <li>45 - 55 Neutral, Market sentiment is balanced, with no strong bias towards fear or greed.</li>
                                 <li>55 - 75 Greed,  Increased optimism, with investors actively seeking opportunities.</li>
                                 <li>> 75 Extreme Greed, Investors are highly confident, often ignoring risks in pursuit of high returns.</li>                       
                            </ul>
                        </div>`
                    }
                />
                <Tooltip id="volatility-index-tooltip" opacity={1}/>
            </div>

            {!isLoading &&
                <MarketMoverGaugeChart
                    price={data?.score}
                    text={data?.rating}
                    fearGauge={true}
                />
            }

        </div>
    );
}

export default FearAndGreedGauge;
