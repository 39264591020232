import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import Config from "../../../../Config";
import SummaryIndicesWatchlistTable from "../../../MarketOverview/Summary/SummaryIndicesWatchlistTable";
import SummaryTabGraphContainer from "../../../MarketOverview/Summary/SummaryTabGraphContainer";
import SummaryTable from "../../../MarketOverview/Summary/SummaryTable";
import SummaryLatestActiveTable from "../../../MarketOverview/Summary/SummaryLatestActiveTable";


const SummaryTab = () => {
    const [data, setData] = useState(null)
    const [order, setOrder] = useState(true)

    function handleResize() {
        if (window.innerWidth <= 992) {
            setOrder(false)
        } else {
            setOrder(true)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])

    const [chartData, setChartData] = useState({name: "S&P 500", code: "GSPC", exchange: "INDX", type: "index"})

    const [isLoading, setLoading] = useState(true)

    const {isLoading: apiDataIsLoading, data: apiData} = useQuery({
        queryKey: ['market-overview-index-data'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-index-data/`, Config())
            return res.data
        },
        refetchInterval: 30000
    })

    useEffect(() => {
        if (apiData && !apiDataIsLoading) {
            let data = {}
            apiData.map(item => {
                data[item['symbol']] = item
            })
            setData(data)
            setLoading(false)
        }
    }, [apiData, apiDataIsLoading])

    const [watchlists, setWatchList] = useState([])

    useEffect(() => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-first-watchlist/`, config)
            .then(response => {
                setWatchList(response.data)
            })
    }, [])

    const handleSetChartData = useCallback((newChartData) => {
        setChartData(newChartData);
    }, []);

    return (
        // row g-0
        <div>
            {isLoading ?
                <div className={'d-flex'}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                order ?
                    <div className={'row g-0'}>
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <SummaryIndicesWatchlistTable
                                handleSetChartData={handleSetChartData}
                                data={data}
                                watchlists={watchlists}
                                setWatchList={setWatchList}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <SummaryTabGraphContainer chartData={chartData}
                                                      container={'market-overview-chart-container'}
                                                      innerContainer={'market-overview-inner-container'}/>

                            <SummaryTable
                                setChartData={setChartData}
                                title={"US Sectors"}
                                header={"Sector ETFs"}
                                body={[
                                    {
                                        name: 'Basic Material',
                                        code: 'XLB',
                                        exchange: "US",
                                        price: data['XLB']['price'],
                                        change_p: data['XLB']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Communication Defensive',
                                        code: 'XLP',
                                        exchange: "US",
                                        price: data['XLP']['price'],
                                        change_p: data['XLP']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Communication Service',
                                        code: 'XLC',
                                        exchange: "US",
                                        price: data['XLC']['price'],
                                        change_p: data['XLC']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Consumer Cyclical',
                                        code: 'XLY',
                                        exchange: "US",
                                        price: data['XLY']['price'],
                                        change_p: data['XLY']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Energy',
                                        code: 'XLE',
                                        exchange: "US",
                                        price: data['XLE']['price'],
                                        change_p: data['XLE']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Financial Services',
                                        code: 'XLF',
                                        exchange: "US",
                                        price: data['XLF']['price'],
                                        change_p: data['XLF']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Healthcare',
                                        code: 'XLV',
                                        exchange: "US",
                                        price: data['XLV']['price'],
                                        change_p: data['XLV']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Industrials',
                                        code: 'XLI',
                                        exchange: "US",
                                        price: data['XLI']['price'],
                                        change_p: data['XLI']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Real Estate',
                                        code: 'XLRE',
                                        exchange: "US",
                                        price: data['XLRE']['price'],
                                        change_p: data['XLRE']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Technology',
                                        code: 'XLK',
                                        exchange: "US",
                                        price: data['XLK']['price'],
                                        change_p: data['XLK']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Utilities',
                                        code: 'XLU',
                                        exchange: "US",
                                        price: data['XLU']['price'],
                                        change_p: data['XLU']['changesPercentage'],
                                        type: "ETF"
                                    }
                                ]}
                                header_table={true}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <SummaryLatestActiveTable/>

                            <SummaryTable
                                setChartData={setChartData}
                                title={"Thematic ETFS"}
                                header={"Top ETFs"}
                                body={[
                                    {
                                        name: 'ARK Innovation ETF',
                                        code: 'ARKK',
                                        exchange: "US",
                                        price: data['ARKK']['price'],
                                        change_p: data['ARKK']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'iShares Semiconductor ETF',
                                        code: 'SOXX',
                                        exchange: "US",
                                        price: data['SOXX']['price'],
                                        change_p: data['SOXX']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'iShares Biotechnology ETF',
                                        code: 'IBB',
                                        exchange: "US",
                                        price: data['IBB']['price'],
                                        change_p: data['IBB']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'iShares Global Clean Energy ETF',
                                        code: 'ICLN',
                                        exchange: "US",
                                        price: data['ICLN']['price'],
                                        change_p: data['ICLN']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Invesco Solar ETF',
                                        code: 'TAN',
                                        exchange: "US",
                                        price: data['TAN']['price'],
                                        change_p: data['TAN']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'First Trust NASDAQ Cybersecurity ETF',
                                        code: 'CIBR',
                                        exchange: "US",
                                        price: data['CIBR']['price'],
                                        change_p: data['CIBR']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'First Trust Cloud Computing ETF',
                                        code: 'SKYY',
                                        exchange: "US",
                                        price: data['SKYY']['price'],
                                        change_p: data['SKYY']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Global X Lithium & Battery ETF',
                                        code: 'LIT',
                                        exchange: "US",
                                        price: data['LIT']['price'],
                                        change_p: data['LIT']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Global X Robotics and Artificial Intelligence ETF',
                                        code: 'BOTZ',
                                        exchange: "US",
                                        price: data['BOTZ']['price'],
                                        change_p: data['BOTZ']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'Global X Video Games & Esports ETF',
                                        code: 'HERO',
                                        exchange: "US",
                                        price: data['HERO']['price'],
                                        change_p: data['HERO']['changesPercentage'],
                                        type: "ETF"
                                    },
                                    {
                                        name: 'The Emerging Markets Internet & Ecommerce ETF',
                                        code: 'EMQQ',
                                        exchange: "US",
                                        price: data['EMQQ']['price'],
                                        change_p: data['EMQQ']['changesPercentage'],
                                        type: "ETF"
                                    }
                                ]}
                                header_table={true}
                            />
                        </div>
                    </div>
                    :
                    <>
                        <SummaryLatestActiveTable/>
                        <SummaryTabGraphContainer chartData={chartData} container={'market-overview-chart-container'}
                                                  innerContainer={'market-overview-inner-container'}/>
                        <SummaryIndicesWatchlistTable
                            handleSetChartData={handleSetChartData}
                            data={data}
                            watchlists={watchlists}
                            setWatchList={setWatchList}
                        />
                        <SummaryTable
                            setChartData={setChartData}
                            title={"US Sectors"}
                            header={"Sector ETFs"}
                            body={[
                                {
                                    name: 'Basic Material',
                                    code: 'XLB',
                                    exchange: "US",
                                    price: data['XLB']['price'],
                                    change_p: data['XLB']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Communication Defensive',
                                    code: 'XLP',
                                    exchange: "US",
                                    price: data['XLP']['price'],
                                    change_p: data['XLP']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Communication Service',
                                    code: 'XLC',
                                    exchange: "US",
                                    price: data['XLC']['price'],
                                    change_p: data['XLC']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Consumer Cyclical',
                                    code: 'XLY',
                                    exchange: "US",
                                    price: data['XLY']['price'],
                                    change_p: data['XLY']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Energy',
                                    code: 'XLE',
                                    exchange: "US",
                                    price: data['XLE']['price'],
                                    change_p: data['XLE']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Financial Services',
                                    code: 'XLF',
                                    exchange: "US",
                                    price: data['XLF']['price'],
                                    change_p: data['XLF']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Healthcare',
                                    code: 'XLV',
                                    exchange: "US",
                                    price: data['XLV']['price'],
                                    change_p: data['XLV']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Industrials',
                                    code: 'XLI',
                                    exchange: "US",
                                    price: data['XLI']['price'],
                                    change_p: data['XLI']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Real Estate',
                                    code: 'XLRE',
                                    exchange: "US",
                                    price: data['XLRE']['price'],
                                    change_p: data['XLRE']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Technology',
                                    code: 'XLK',
                                    exchange: "US",
                                    price: data['XLK']['price'],
                                    change_p: data['XLK']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Utilities',
                                    code: 'XLU',
                                    exchange: "US",
                                    price: data['XLU']['price'],
                                    change_p: data['XLU']['changesPercentage'],
                                    type: "ETF"
                                }
                            ]}
                            header_table={true}
                        />

                        <SummaryTable
                            setChartData={setChartData}
                            title={"Thematic ETFS"}
                            header={"Top ETFs"}
                            body={[
                                {
                                    name: 'ARK Innovation ETF',
                                    code: 'ARKK',
                                    exchange: "US",
                                    price: data['ARKK']['price'],
                                    change_p: data['ARKK']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'iShares Semiconductor ETF',
                                    code: 'SOXX',
                                    exchange: "US",
                                    price: data['SOXX']['price'],
                                    change_p: data['SOXX']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'iShares Biotechnology ETF',
                                    code: 'IBB',
                                    exchange: "US",
                                    price: data['IBB']['price'],
                                    change_p: data['IBB']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'iShares Global Clean Energy ETF',
                                    code: 'ICLN',
                                    exchange: "US",
                                    price: data['ICLN']['price'],
                                    change_p: data['ICLN']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Invesco Solar ETF',
                                    code: 'TAN',
                                    exchange: "US",
                                    price: data['TAN']['price'],
                                    change_p: data['TAN']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'First Trust NASDAQ Cybersecurity ETF',
                                    code: 'CIBR',
                                    exchange: "US",
                                    price: data['CIBR']['price'],
                                    change_p: data['CIBR']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'First Trust Cloud Computing ETF',
                                    code: 'SKYY',
                                    exchange: "US",
                                    price: data['SKYY']['price'],
                                    change_p: data['SKYY']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Global X Lithium & Battery ETF',
                                    code: 'LIT',
                                    exchange: "US",
                                    price: data['LIT']['price'],
                                    change_p: data['LIT']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Global X Robotics and Artificial Intelligence ETF',
                                    code: 'BOTZ',
                                    exchange: "US",
                                    price: data['BOTZ']['price'],
                                    change_p: data['BOTZ']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'Global X Video Games & Esports ETF',
                                    code: 'HERO',
                                    exchange: "US",
                                    price: data['HERO']['price'],
                                    change_p: data['HERO']['changesPercentage'],
                                    type: "ETF"
                                },
                                {
                                    name: 'The Emerging Markets Internet & Ecommerce ETF',
                                    code: 'EMQQ',
                                    exchange: "US",
                                    price: data['EMQQ']['price'],
                                    change_p: data['EMQQ']['changesPercentage'],
                                    type: "ETF"
                                }
                            ]}
                            header_table={true}
                        />
                    </>
            }
        </div>
    );
}

export default SummaryTab;