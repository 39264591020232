import React, {useCallback, useEffect, useState} from 'react';
import MarketMoversUs from "./MarketMoversUS";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../../Config";
import MarketMoversEu from "./MarketMoversEU";
import MarketMoversApac from "./MarketMoversAPAC";
import SummaryTabGraphContainer from "../Summary/SummaryTabGraphContainer";
import MarketOverviewTopFlop from "./MarketOverviewTopFlop";
import FearAndGreedGauge from "./FearAndGreedGauge";
import PerformanceVixFearGreedTable from "./PerformanceVIXFearGreedTable";
import VixGauge from "./VIXGauge";

function MarketMovers({}) {
    const [data, setData] = useState(null)

    const {data: apiData, isLoadingAPIData} = useQuery({
        queryKey: ['market-overview-index-data'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-index-data/`, Config())
            return res.data
        },
        refetchInterval: 30000
    })

    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        if (apiData && !isLoadingAPIData) {
            let _data = {}

            apiData.map(item => {
                _data[item['symbol']] = item
            })
            setData(_data)
            setLoading(false)
        }
    }, [isLoadingAPIData, apiData])

    const [chartData, setChartData] = useState({name: "S&P 500", code: "GSPC", exchange: "INDX", type: "index"})

    const [indexCompanies, setIndexCompanies] = useState({code: "^GSPC", name: "S&P 500"})

    const handleSetChartData = useCallback((newChartData) => {
        setChartData(newChartData);
    }, []);

    const handleSetIndexCompanies = useCallback((newIndexCompanies) => {
        setIndexCompanies(newIndexCompanies);
    }, []);

    const [tableState, setTableState] = useState("performance")

    return (
        !isLoading &&
        <div className={'row g-0'} style={{overflow: "hidden"}}>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <MarketMoversUs data={data}
                                handleSetChartData={handleSetChartData}
                                handleSetIndexCompanies={handleSetIndexCompanies}/>

                <MarketMoversEu data={data}
                                handleSetChartData={handleSetChartData} setChartData={setChartData}
                                handleSetIndexCompanies={handleSetIndexCompanies}
                                setIndexCompanies={setIndexCompanies}/>

                <MarketMoversApac data={data}
                                  handleSetChartData={handleSetChartData} setChartData={setChartData}
                                  handleSetIndexCompanies={handleSetIndexCompanies}
                                  setIndexCompanies={setIndexCompanies}/>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <MarketOverviewTopFlop indexCompanies={indexCompanies} setChartData={setChartData}/>
            </div>

            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <SummaryTabGraphContainer chartData={chartData} container={'market-overview-chart-container'}
                                          innerContainer={'market-overview-inner-container market-mover-inner-container'}/>

                <div className="row g-0">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 radial-chart-container"
                         onClick={() => setTableState('vix')}>
                        <VixGauge data={data}/>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 radial-chart-container"
                         onClick={() => setTableState('fear')}>
                        <FearAndGreedGauge/>
                    </div>
                </div>

                <PerformanceVixFearGreedTable tableState={tableState} setTableState={setTableState}/>

            </div>
        </div>
    );
}

export default MarketMovers;
