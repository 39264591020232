import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../../Config";
import {return_strong_num} from "../../Utils";

function PerformanceTable({}) {

    const [performance, setPerformance] = useState({
        IVE: {color: "", value: 0},
        IVV: {color: "", value: 0},
        IVW: {color: "", value: 0},

        IJJ: {color: "", value: 0},
        IJH: {color: "", value: 0},
        IJK: {color: "", value: 0},

        IWN: {color: "", value: 0},
        IWM: {color: "", value: 0},
        IWO: {color: "", value: 0},
    })

    const positiveColors = [
        "#3ee74a",
        "#2ada3c",
        "#16ce2e",
        "#00c220",
        "#00b61a",
        "#00aa14",
        "#009e0e",
        "#00940d",
        "#018b0d"
    ]

    const negativeColors = [
        "#9e0e0e",
        "#aa1414",
        "#b61a1a",
        "#c22020",
        "#ce2e2e",
        "#da3c3c",
        "#e74a4a",
        "#f35858",
        "#ff6666"
    ]

    const {data: apiData, isLoadingAPIData} = useQuery({
        queryKey: ['market-movers-performance'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-movers-performance/`, Config())
            return res.data
        },
        refetchInterval: 30000
    })

    useEffect(() => {
        if (apiData && !isLoadingAPIData) {
            let data = {}

            const entries = Object.entries(apiData);

            let positiveNumbers = []

            let negativeNumbers = []

            entries.sort((a, b) => a[1] - b[1]);

            entries.map((item) => {
                if (item[1] > 0) {
                    positiveNumbers.push(item)
                } else {
                    negativeNumbers.push(item)
                }
            })

            positiveNumbers = Object.fromEntries(positiveNumbers);
            negativeNumbers = Object.fromEntries(negativeNumbers);

            Object.keys(positiveNumbers).map((key, index) => {
                data[key] = {color: positiveColors[index], value: `${return_strong_num(apiData[key])} %`}
            })

            Object.keys(negativeNumbers).map((key, index) => {
                data[key] = {color: negativeColors[index], value: `${return_strong_num(apiData[key])} %`}
            })

            setPerformance(data)
        }
    }, [isLoadingAPIData, apiData])

    return (
        <div style={{padding: 10, height: 262}}>
            <p style={{fontSize: 12, fontWeight: 600, marginLeft: 5}}>1 Day Performance</p>

            <table className={'table generic-table-body market-movers-performance-table'}>
                <tbody>
                <tr>
                    <td></td>
                    <td className={'tb-heading'}>Value</td>
                    <td className={'tb-heading'}>Core</td>
                    <td className={'tb-heading'}>Growth</td>
                </tr>
                <tr>
                    <td className={'tb-heading'}>Large</td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IVE.color}}
                            className={'tb-value-container'}>
                            {performance.IVE.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div  style={{backgroundColor: performance.IVV.color}}
                            className={'tb-value-container'}>
                            {performance.IVV.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IVW.color}}
                            className={'tb-value-container'}>
                            {performance.IVW.value}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={'tb-heading'}>Mid</td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IJJ.color}}
                            className={'tb-value-container'}>
                            {performance.IJJ.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IJH.color}}
                            className={'tb-value-container'}>
                            {performance.IJH.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IJK.color}}
                            className={'tb-value-container'}>
                            {performance.IJK.value}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={'tb-heading'}>Small</td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IWN.color}}
                            className={'tb-value-container'}>
                            {performance.IWN.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IWM.color}}
                            className={'tb-value-container'}>
                            {performance.IWM.value}
                        </div>
                    </td>
                    <td className={'tb-value'}>
                        <div style={{backgroundColor: performance.IWO.color}}
                            className={'tb-value-container'}>
                            {performance.IWO.value}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default PerformanceTable;
