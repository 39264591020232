import {return_float} from "../../Utils";
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../Context/ThemeContext";
import React, {useContext, useEffect, useState} from 'react';

function RevenueBySegmentsPieChart({groupedData, series, labels}) {
    const {webTheme} = useContext(ThemeContext);

    // const [data, setData] = useState([])
    // const [labels, setLabels] = useState([])

    // useEffect(() => {
    //     let _labels = []
    //     let _series = []
    //     console.log("groupedData['content'] =>", groupedData['content'])
    //     Object.keys(groupedData['content']).map(key => {
    //         Object.keys(groupedData['content'][key]['data']).map((subKey) => {
    //             console.log("jey =>", subKey)
    //             if (subKey.includes('% Total of Revenue') && groupedData['content'][key]['data'][subKey].length) {
    //                 _labels.push(key)
    //                 _series.push(return_float(return_float(groupedData['content'][key]['data'][subKey][0].replace(' %', '')).toFixed(2)))
    //             }
    //         })
    //     })
    //
    //     let sum = 0
    //     _series.map(item => sum = sum + item)
    //     if (sum < 100) {
    //         _series.push(100 - sum)
    //         _labels.push('Others')
    //     }
    //
    //
    //     const indices = Array.from(_series.keys());
    //
    //     indices.sort((i, j) => _series[j] - _series[i]);
    //
    //     // Sort both arrays based on the sorted indices
    //     _series = indices.map(i => _series[i]);
    //     _labels = indices.map(i => _labels[i]);
    //
    //     setLabels(_labels)
    //     setData(_series)
    // }, [groupedData])

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const state = {
        series: series,
        options: {
            chart: {
                type: 'pie',
                foreColor: graphVar.color,
                width: 300,
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#ee1981', '#dc1294', '#c50da7', '#a410c2', '#8929cb', '#6d46d4', '#5a5ddb', '#506bdf', '#467be3', '#4090e9', '#3e9fed'],
            legend: {
                position: 'left',
                formatter: function (seriesName, opts) {
                    return [opts.w.globals.series[opts.seriesIndex].toFixed(2) + " %", ' - ', seriesName.length > 30 ? seriesName.slice(0, 30) + '...' : seriesName]
                }
            },
            labels: labels,
            responsive: [{
                breakpoint: 490,
                options: {
                    chart: {
                        width: 310
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }]
        },
    };

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        series.length > 0 &&
        <div className={'company-overview-pie-chart-container'}>
            <div className={'company-overview-pie-chart-inner-container'}>
                <p className={'company-overview-pie-chart-title'}>Revenue By Segment</p>
                <ReactApexChart options={state.options} series={series} type="pie" width={'100%'}
                                height={'100%'}
                                minHeight={'100%'}
                />
            </div>
        </div>
    );
}

export default RevenueBySegmentsPieChart;