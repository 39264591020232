const ScreenerModalFilterJson = {
    "Company Information": {
        "m_cap_trading": {
            tree: false,
            key: "m_cap_trading",
            name: "Market Cap",
            selected: false,
            display_name: "Market Cap"
        },
        "sharesOutstanding": {
            tree: false,
            key: "sharesOutstanding",
            name: "Shares Outstanding",
            selected: false,
            display_name: "Shares Outstanding"
        },
        "sharesFloat": {
            tree: false,
            key: "sharesFloat",
            name: "Float Shares",
            selected: false,
            display_name: "Float Shares"
        },
        "percentInsiders": {
            tree: false,
            key: "percentInsiders",
            name: "Insiders %",
            selected: false,
            display_name: "Insiders %"
        },
        "percentInstitutions": {
            tree: false,
            key: "percentInstitutions",
            name: "Institutionals %",
            selected: false,
            display_name: "Institutionals %"
        },
        "shortPercentFloat": {
            tree: false,
            key: "shortPercentFloat",
            name: "Short % of Float",
            selected: false,
            display_name: "Short % of Float"
        },
    },
    "Performance": {
        "performances": {
            tree: true,
            name: "Performances",
            components: {
                "ytd": {
                    key: "ytd",
                    name: "YTD",
                    selected: false,
                    display_name: "Performance YTD"
                },
                "week": {
                    key: "week",
                    name: "Week",
                    selected: false,
                    display_name: "Performance Week"
                },
                "one_month": {
                    key: "one_month",
                    name: "1 month",
                    selected: false,
                    display_name: "Performance 1 Month"
                },
                "three_month": {
                    key: "three_month",
                    name: "3 month",
                    selected: false,
                    display_name: "Performance 3 Months"
                },
                "one_year": {
                    key: "one_year",
                    name: "1 year",
                    selected: false,
                    display_name: "Performance 1 Year"
                },
                "three_year": {
                    key: "three_year",
                    name: "3 year",
                    selected: false,
                    display_name: "Performance 3 Year"
                },
                "five_year": {
                    key: "five_year",
                    name: "5 year",
                    selected: false,
                    display_name: "Performance 5 Year"
                },
                "five_year_cagr": {
                    key: "five_year_cagr",
                    name: "5 year CAGR",
                    selected: false,
                    display_name: "Performance 5 Year CAGR"
                },
                "ten_year": {
                    key: "ten_year",
                    name: "10 years",
                    selected: false,
                    display_name: "Performance 10 Years"
                }
            }
        }
    },
    "Analyst & Trading": {
        "adjusted_close": {
            tree: false,
            key: "adjusted_close",
            name: "Close Price",
            selected: false,
            display_name: "Close Price",
        },
        "analyst_price_target": {
            tree: false,
            key: "analyst_price_target",
            name: "Analyst Price Target",
            selected: false,
            display_name: "Analyst Price Target"
        },
        "upside_potential": {
            tree: false,
            key: "upside_potential",
            name: "Analyst Potential",
            selected: false,
            display_name: "Analyst Potential"
        },
        "number_of_analyst": {
            tree: false,
            key: "number_of_analyst",
            name: "# of Analyst",
            selected: false,
            display_name: "# of Analyst"
        },
    },
    "TSI": {
        "trend_score": {
            key: "trend_score",
            name: "TSI Score",
            selected: false,
            display_name: "TSI Score"
        },
        "ranking_trend_score": {
            key: "ranking_trend_score",
            name: "TSI Rank",
            selected: false,
            display_name: "TSI Rank"
        },
    },
    "iSqore": {
        "iSqore": {
            key: "iSqore",
            name: "iSqore Rating",
            selected: false,
            display_name: "iSqore Rating"
        },
        "value": {
            key: "value",
            name: "Value",
            selected: false,
            display_name: "Value"
        },
        "profitability": {
            key: "profitability",
            name: "Profit",
            selected: false,
            display_name: "Profit"
        },
        "growth": {
            key: "growth",
            name: "Growth",
            selected: false,
            display_name: "Growth"
        },
        "health": {
            key: "health",
            name: "Health",
            selected: false,
            display_name: "Health"
        },
        "quality": {
            key: "quality",
            name: "Quality",
            selected: false,
            display_name: "Quality"
        }
    },
    "Value": {
        "p_e": {
            tree: true,
            name: "P/Earnings",
            components: {
                "forwardPE": {
                    key: "forwardPE",
                    name: "Forward",
                    selected: false,
                    display_name: "P/Earnings Forward"
                },
                "current_priceEarnings": {
                    key: "current_priceEarnings",
                    name: "Current",
                    selected: false,
                    display_name: "P/Earnings Current"
                },
                "additionalcalculations_annual_priceEarnings": {
                    key: "additionalcalculations_annual_priceEarnings",
                    name: "Year",
                    selected: false,
                    display_name: "P/Earnings Year"
                },
                "calculation_3_year_average_priceEarnings": {
                    key: "calculation_3_year_average_priceEarnings",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "P/Earnings 3Y AVG"
                },
                "calculation_5_year_average_priceEarnings": {
                    key: "calculation_5_year_average_priceEarnings",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "P/Earnings 5Y AVG"
                },
            }
        },
        "p_s": {
            tree: true,
            name: "P/Sales",
            components: {
                "current_priceSalesForward": {
                    key: "current_priceSalesForward",
                    name: "Forward",
                    selected: false,
                    display_name: "P/Sales Forward"
                },
                "current_priceSales": {
                    key: "current_priceSales",
                    name: "Current",
                    selected: false,
                    display_name: "P/Sales Current"
                },
                "additionalcalculations_annual_priceSales": {
                    key: "additionalcalculations_annual_priceSales",
                    name: "Year",
                    selected: false,
                    display_name: "P/Sales Year"
                },
                "calculation_3_year_average_priceSales": {
                    key: "calculation_3_year_average_priceSales",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "P/Sales 3Y AVG"
                },
                "calculation_5_year_average_priceSales": {
                    key: "calculation_5_year_average_priceSales",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "P/Sales 5Y AVG"
                },
            }
        },
        "p_b": {
            tree: true,
            name: "P/Book",
            components: {
                "current_priceBook": {
                    key: "current_priceBook",
                    name: "Current",
                    selected: false,
                    display_name: "P/Book Current"
                },
                "additionalcalculations_annual_priceBook": {
                    key: "additionalcalculations_annual_priceBook",
                    name: "Year",
                    selected: false,
                    display_name: "P/Book Year"
                },
                "calculation_3_year_average_priceBook": {
                    key: "calculation_3_year_average_priceBook",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "P/Book 3Y AVG"
                },
                "calculation_5_year_average_priceBook": {
                    key: "calculation_5_year_average_priceBook",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "P/Book 5Y AVG"
                },
            }
        },
        "p_fcf": {
            tree: true,
            name: "P/Free Cash Flow",
            components: {
                "current_priceFreeCashFlow": {
                    key: "current_priceFreeCashFlow",
                    name: "Current",
                    selected: false,
                    display_name: "P/FCF Current"
                },
                "additionalcalculations_annual_priceFreeCashFlow": {
                    key: "additionalcalculations_annual_priceFreeCashFlow",
                    name: "Year",
                    selected: false,
                    display_name: "P/FCF Year"
                },
                "calculation_3_year_average_priceFreeCashFlow": {
                    key: "calculation_3_year_average_priceFreeCashFlow",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "P/FCF 3Y AVG"
                },
                "calculation_5_year_average_priceFreeCashFlow": {
                    key: "calculation_5_year_average_priceFreeCashFlow",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "P/FCF 5Y AVG"
                },
            }
        },
        "fcf_yield": {
            tree: true,
            name: "FCF Yield",
            components: {
                "ttm_fcfYield": {
                    key: "ttm_fcfYield",
                    name: "TTM",
                    selected: false,
                    display_name: "FCF Yield (TTM)"
                },
                "additionalcalculations_annual_fcfYield": {
                    key: "additionalcalculations_annual_fcfYield",
                    name: "Year",
                    selected: false,
                    display_name: "FCF Yield (Year)"
                },

                "calculation_3_year_average_fcfYield": {
                    key: "calculation_3_year_average_fcfYield",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "FCF Yield (3Y AVG)"
                },
                "calculation_5_year_average_fcfYield": {
                    key: "calculation_5_year_average_fcfYield",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "FCF Yield (5Y AVG)"
                },
            }
        },
        "pegRatio": {
            tree: true,
            name: "Peg Ratio",
            components: {
                "current_pegRatioForward": {
                    key: "current_pegRatioForward",
                    name: "Forward",
                    selected: false,
                    display_name: "Peg Ratio Forward"
                },
                "current_pegRatio": {
                    key: "current_pegRatio",
                    name: "Current",
                    selected: false,
                    display_name: "Peg Ratio Current"
                },
                "additionalcalculations_annual_pegRatio": {
                    key: "additionalcalculations_annual_pegRatio",
                    name: "Year",
                    selected: false,
                    display_name: "Peg Ratio Year"
                },
                "calculation_3_year_average_pegRatio": {
                    key: "calculation_3_year_average_pegRatio",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Peg Ratio 3Y AVG"
                },
                "calculation_5_year_average_pegRatio": {
                    key: "calculation_5_year_average_pegRatio",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Peg Ratio 5Y AVG"
                },
            }
        },
        "earningsYield": {
            tree: true,
            name: "Earnings Yield",
            components: {
                "current_earningsYieldForward": {
                    key: "current_earningsYieldForward",
                    name: "Forward",
                    selected: false,
                    display_name: "Earnings Yield Forward"
                },
                "current_earningsYield": {
                    key: "current_earningsYield",
                    name: "Current",
                    selected: false,
                    display_name: "Earnings Yield Current"
                },
                "additionalcalculations_annual_earningsYield": {
                    key: "additionalcalculations_annual_earningsYield",
                    name: "Year",
                    selected: false,
                    display_name: "Earnings Yield Year"
                },
                "calculation_3_year_average_earningsYield": {
                    key: "calculation_3_year_average_earningsYield",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Earnings Yield 3Y AVG"
                },
                "calculation_5_year_average_earningsYield": {
                    key: "calculation_5_year_average_earningsYield",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Earnings Yield 5Y AVG"
                },
            }
        },
        "evRevenue": {
            tree: true,
            name: "EV Revenue",
            components: {
                "current_evRevenue": {
                    key: "current_evRevenue",
                    name: "Current",
                    selected: false,
                    display_name: "EV Revenue Current"
                },
                "additionalcalculations_annual_evRevenue": {
                    key: "additionalcalculations_annual_evRevenue",
                    name: "Year",
                    selected: false,
                    display_name: "EV Revenue Year"
                },
                "calculation_3_year_average_evRevenue": {
                    key: "calculation_3_year_average_evRevenue",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EV Revenue 3Y AVG"
                },
                "calculation_5_year_average_evRevenue": {
                    key: "calculation_5_year_average_evRevenue",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EV Revenue 5Y AVG"
                },
            }
        },
        "eveBit": {
            tree: true,
            name: "EV/EBIT",
            components: {
                "current_eveBit": {
                    key: "current_eveBit",
                    name: "Current",
                    selected: false,
                    display_name: "EV/EBIT Current"
                },
                "additionalcalculations_annual_eveBit": {
                    key: "additionalcalculations_annual_eveBit",
                    name: "Year",
                    selected: false,
                    display_name: "EV/EBIT Year"
                },
                "calculation_3_year_average_eveBit": {
                    key: "calculation_3_year_average_eveBit",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EV/EBIT 3Y AVG"
                },
                "calculation_5_year_average_eveBit": {
                    key: "calculation_5_year_average_eveBit",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EV/EBIT 5Y AVG"
                },
            }
        },
        "eveBitda": {
            tree: true,
            name: "EV/EBITDA",
            components: {
                "current_eveBitda": {
                    key: "current_eveBitda",
                    name: "Current",
                    selected: false,
                    display_name: "EV/EBITDA Current"
                },
                "additionalcalculations_annual_eveBitda": {
                    key: "additionalcalculations_annual_eveBitda",
                    name: "Year",
                    selected: false,
                    display_name: "EV/EBITDA Year"
                },
                "calculation_3_year_average_eveBitda": {
                    key: "calculation_3_year_average_eveBitda",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EV/EBITDA 3Y AVG"
                },
                "calculation_5_year_average_eveBitda": {
                    key: "calculation_5_year_average_eveBitda",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EV/EBITDA 5Y AVG"
                },
            }
        }
    },
    "Profit": {
        "return_on_assets": {
            tree: true,
            name: "Return On Assets",
            components: {
                "ttm_returnOnAssets": {
                    key: "ttm_returnOnAssets",
                    name: "TTM",
                    selected: false,
                    display_name: "Return On Assets (TTM)"
                },
                "additionalcalculations_annual_returnOnAssets": {
                    key: "additionalcalculations_annual_returnOnAssets",
                    name: "Year",
                    selected: false,
                    display_name: "Return On Assets (Year)"
                },

                "calculation_3_year_average_returnOnAssets": {
                    key: "calculation_3_year_average_returnOnAssets",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Return On Assets (3Y AVG)"
                },
                "calculation_5_year_average_returnOnAssets": {
                    key: "calculation_5_year_average_returnOnAssets",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Return On Assets (5Y AVG)"
                },
            }
        },
        "return_on_equity": {
            tree: true,
            name: "Return On Equity",
            components: {
                "ttm_returnOnEquity": {
                    key: "ttm_returnOnEquity",
                    name: "TTM",
                    selected: false,
                    display_name: "Return On Equity (TTM)"
                },
                "additionalcalculations_annual_returnOnEquity": {
                    key: "additionalcalculations_annual_returnOnEquity",
                    name: "Year",
                    selected: false,
                    display_name: "Return On Equity (Year)"
                },

                "calculation_3_year_average_returnOnEquity": {
                    key: "calculation_3_year_average_returnOnEquity",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Return On Equity (3Y AVG)"
                },
                "calculation_5_year_average_returnOnEquity": {
                    key: "calculation_5_year_average_returnOnEquity",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Return On Equity (5Y AVG)"
                },
            }
        },
        "return_on_capital": {
            tree: true,
            name: "Return On Capital",
            components: {
                "ttm_returnOnCapital": {
                    key: "ttm_returnOnCapital",
                    name: "TTM",
                    selected: false,
                    display_name: "ROC (TTM)"
                },
                "additionalcalculations_annual_returnOnCapital": {
                    key: "additionalcalculations_annual_returnOnCapital",
                    name: "Year",
                    selected: false,
                    display_name: "ROC (Year)"
                },

                "calculation_3_year_average_returnOnCapital": {
                    key: "calculation_3_year_average_returnOnCapital",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "ROC (3Y AVG)"
                },
                "calculation_5_year_average_returnOnCapital": {
                    key: "calculation_5_year_average_returnOnCapital",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "ROC (5Y AVG)"
                },
            }
        },
        "return_on_capital_employed": {
            tree: true,
            name: "Return On Capital Employed",
            components: {
                "ttm_returnOnCapitalEmployed": {
                    key: "ttm_returnOnCapitalEmployed",
                    name: "TTM",
                    selected: false,
                    display_name: "ROC Employed (TTM)"
                },
                "additionalcalculations_annual_returnOnCapitalEmployed": {
                    key: "additionalcalculations_annual_returnOnCapitalEmployed",
                    name: "Year",
                    selected: false,
                    display_name: "ROC Employed (Year)"
                },

                "calculation_3_year_average_returnOnCapitalEmployed": {
                    key: "calculation_3_year_average_returnOnCapitalEmployed",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "ROC Employed (3Y AVG)"
                },
                "calculation_5_year_average_returnOnCapitalEmployed": {
                    key: "calculation_5_year_average_returnOnCapitalEmployed",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "ROC Employed (5Y AVG)"
                },
            }
        },
        "return_on_invested_capital": {
            tree: true,
            name: "Return On Invested Capital",
            components: {
                "ttm_returnOnInvestedCapital": {
                    key: "ttm_returnOnInvestedCapital",
                    name: "TTM",
                    selected: false,
                    display_name: "Return On Invested Capital (TTM)"
                },
                "additionalcalculations_annual_returnOnInvestedCapital": {
                    key: "additionalcalculations_annual_returnOnInvestedCapital",
                    name: "Year",
                    selected: false,
                    display_name: "Return On Invested Capital (Year)"
                },

                "calculation_3_year_average_returnOnInvestedCapital": {
                    key: "calculation_3_year_average_returnOnInvestedCapital",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Return On Invested Capital (3Y AVG)"
                },
                "calculation_5_year_average_returnOnInvestedCapital": {
                    key: "calculation_5_year_average_returnOnInvestedCapital",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Return On Invested Capital (5Y AVG)"
                },
            }
        },
        "gross_margin": {
            tree: true,
            name: "Gross Margin",
            components: {
                "ttm_grossMargin": {
                    key: "ttm_grossMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "Gross Margin (TTM)"
                },
                "additionalcalculations_annual_grossMargin": {
                    key: "additionalcalculations_annual_grossMargin",
                    name: "Year",
                    selected: false,
                    display_name: "Gross Margin (Year)"
                },

                "calculation_3_year_average_grossMargin": {
                    key: "calculation_3_year_average_grossMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Gross Margin (3Y AVG)"
                },
                "calculation_5_year_average_grossMargin": {
                    key: "calculation_5_year_average_grossMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Gross Margin (5Y AVG)"
                },
            }
        },
        "operating_margin": {
            tree: true,
            name: "Operating Margin",
            components: {
                "ttm_operatingMargin": {
                    key: "ttm_operatingMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "Operating Margin (TTM)"
                },
                "additionalcalculations_annual_operatingMargin": {
                    key: "additionalcalculations_annual_operatingMargin",
                    name: "Year",
                    selected: false,
                    display_name: "Operating Margin (Year)"
                },

                "calculation_3_year_average_operatingMargin": {
                    key: "calculation_3_year_average_operatingMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Operating Margin (3Y AVG)"
                },
                "calculation_5_year_average_operatingMargin": {
                    key: "calculation_5_year_average_operatingMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Operating Margin (5Y AVG)"
                },
            }
        },
        "net_margin": {
            tree: true,
            name: "Net Margin",
            components: {
                "ttm_netMargin": {
                    key: "ttm_netMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "Net Margin (TTM)"
                },
                "additionalcalculations_annual_netMargin": {
                    key: "additionalcalculations_annual_netMargin",
                    name: "Year",
                    selected: false,
                    display_name: "Net Margin (Year)"
                },

                "calculation_3_year_average_netMargin": {
                    key: "calculation_3_year_average_netMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Net Margin (3Y AVG)"
                },
                "calculation_5_year_average_netMargin": {
                    key: "calculation_5_year_average_netMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Net Margin (5Y AVG)"
                },
            }
        },
        "ebit_margin": {
            tree: true,
            name: "EBIT Margin",
            components: {
                "ttm_ebitMargin": {
                    key: "ttm_ebitMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "EBIT Margin (TTM)"
                },
                "additionalcalculations_annual_ebitMargin": {
                    key: "additionalcalculations_annual_ebitMargin",
                    name: "Year",
                    selected: false,
                    display_name: "EBIT Margin (Year)"
                },

                "calculation_3_year_average_ebitMargin": {
                    key: "calculation_3_year_average_ebitMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EBIT Margin (3Y AVG)"
                },
                "calculation_5_year_average_ebitMargin": {
                    key: "calculation_5_year_average_ebitMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EBIT Margin (5Y AVG)"
                },
            }
        },
        "ebitda_margin": {
            tree: true,
            name: "EBITDA Margin",
            components: {
                "ttm_ebitdaMargin": {
                    key: "ttm_ebitdaMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "EBITDA Margin (TTM)"
                },
                "additionalcalculations_annual_ebitdaMargin": {
                    key: "additionalcalculations_annual_ebitdaMargin",
                    name: "Year",
                    selected: false,
                    display_name: "EBITDA Margin (Year)"
                },

                "calculation_3_year_average_ebitdaMargin": {
                    key: "calculation_3_year_average_ebitdaMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EBITDA Margin (3Y AVG)"
                },
                "calculation_5_year_average_ebitdaMargin": {
                    key: "calculation_5_year_average_ebitdaMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EBITDA Margin (5Y AVG)"
                },
            }
        },
        "fcf_margin": {
            tree: true,
            name: "FCF Margin",
            components: {
                "ttm_fcfMargin": {
                    key: "ttm_fcfMargin",
                    name: "TTM",
                    selected: false,
                    display_name: "FCF Margin (TTM)"
                },
                "additionalcalculations_annual_fcfMargin": {
                    key: "additionalcalculations_annual_fcfMargin",
                    name: "Year",
                    selected: false,
                    display_name: "FCF Margin (Year)"
                },

                "calculation_3_year_average_fcfMargin": {
                    key: "calculation_3_year_average_fcfMargin",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "FCF Margin (3Y AVG)"
                },
                "calculation_5_year_average_fcfMargin": {
                    key: "calculation_5_year_average_fcfMargin",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "FCF Margin (5Y AVG)"
                },
            }
        },

        "asset_turnover": {
            tree: true,
            name: "Asset Turnover",
            components: {
                "ttm_assetTurnOver": {
                    key: "ttm_assetTurnOver",
                    name: "TTM",
                    selected: false,
                    display_name: "Asset Turn Over (TTM)"
                },
                "additionalcalculations_annual_assetTurnOver": {
                    key: "additionalcalculations_annual_assetTurnOver",
                    name: "Year",
                    selected: false,
                    display_name: "Asset Turn Over (Year)"
                },

                "calculation_3_year_average_assetTurnOver": {
                    key: "calculation_3_year_average_assetTurnOver",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Asset Turn Over (3Y AVG)"
                },
                "calculation_5_year_average_assetTurnOver": {
                    key: "calculation_5_year_average_assetTurnOver",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Asset Turn Over (5Y AVG)"
                },
            }
        },
    },
    "Growth": {
        "revenue_growth": {
            tree: true,
            name: "Revenue Growth",
            components: {
                "growth_quarterly_totalRevenueForward": {
                    key: "growth_quarterly_totalRevenueForward",
                    name: "Forward",
                    selected: false,
                    display_name: "Revenue Growth Forward"
                },
                "growth_quarterly_ttm_totalRevenue": {
                    key: "growth_quarterly_ttm_totalRevenue",
                    name: "TTM",
                    selected: false,
                    display_name: "Revenue Growth (TTM)"
                },
                "growth_quarterly_totalRevenue": {
                    key: "growth_quarterly_totalRevenue",
                    name: "QoQ",
                    selected: false,
                    display_name: "Revenue Growth (QoQ)"
                },
                "growth_quarterly_yoy_totalRevenue": {
                    key: "growth_quarterly_yoy_totalRevenue",
                    name: "YoY",
                    selected: false,
                    display_name: "Revenue Growth (YoY)"
                },
                "growth_yearly_totalRevenue": {
                    key: "growth_yearly_totalRevenue",
                    name: "Year",
                    selected: false,
                    display_name: "Revenue Growth (Year)"
                },
                "calculation_3_cagr_totalRevenue": {
                    key: "calculation_3_cagr_totalRevenue",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "Revenue (3Y CAGR)"
                },
                "calculation_5_cagr_totalRevenue": {
                    key: "calculation_5_cagr_totalRevenue",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "Revenue (5Y CAGR)"
                },
            }
        },
        "earnings_growth": {
            tree: true,
            name: "Earnings Growth",
            components: {
                "growth_quarterly_netIncome_income_statementForward": {
                    key: "growth_quarterly_netIncome_income_statementForward",
                    name: "Forward",
                    selected: false,
                    display_name: "Net Income Growth Forward"
                },
                "growth_quarterly_ttm_netIncome_income_statement": {
                    key: "growth_quarterly_ttm_netIncome_income_statement",
                    name: "TTM",
                    selected: false,
                    display_name: "Net Income Growth (TTM)"
                },
                "growth_quarterly_netIncome_income_statement": {
                    key: "growth_quarterly_netIncome_income_statement",
                    name: "QoQ",
                    selected: false,
                    display_name: "Net Income Growth (QoQ)"
                },
                "growth_quarterly_yoy_netIncome_income_statement": {
                    key: "growth_quarterly_yoy_netIncome_income_statement",
                    name: "YoY",
                    selected: false,
                    display_name: "Net Income Growth (YoY)"
                },
                "growth_yearly_netIncome_income_statement": {
                    key: "growth_yearly_netIncome_income_statement",
                    name: "Year",
                    selected: false,
                    display_name: "Net Income Growth (Year)"
                },
                "calculation_3_cagr_netIncome_income_statement": {
                    key: "calculation_3_cagr_netIncome_income_statement",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "Net Income (3Y CAGR)"
                },
                "calculation_5_cagr_netIncome_income_statement": {
                    key: "calculation_5_cagr_netIncome_income_statement",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "Net Income (5Y CAGR)"
                },
            }
        },
        "eps_growth": {
            tree: true,
            name: "EPS Growth",
            components: {
                "growth_quarterly_earningsPerShareForward": {
                    key: "growth_quarterly_earningsPerShareForward",
                    name: "Forward",
                    selected: false,
                    display_name: "Earnings Per Share Growth Forward"
                },
                "growth_quarterly_ttm_earningsPerShare": {
                    key: "growth_quarterly_ttm_earningsPerShare",
                    name: "TTM",
                    selected: false,
                    display_name: "Earnings Per Share Growth (TTM)"
                },
                "growth_quarterly_earningsPerShare": {
                    key: "growth_quarterly_earningsPerShare",
                    name: "QoQ",
                    selected: false,
                    display_name: "Earnings Per Share Growth (QoQ)"
                },
                "growth_quarterly_yoy_earningsPerShare": {
                    key: "growth_quarterly_yoy_earningsPerShare",
                    name: "YoY",
                    selected: false,
                    display_name: "Earnings Per Share Growth (YoY)"
                },
                "growth_yearly_earningsPerShare": {
                    key: "growth_yearly_earningsPerShare",
                    name: "Year",
                    selected: false,
                    display_name: "Earnings Per Share Growth (Year)"
                },
                "calculation_3_cagr_earningsPerShare": {
                    key: "calculation_3_cagr_earningsPerShare",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "Earnings Per Share (3Y CAGR)"
                },
                "calculation_5_cagr_earningsPerShare": {
                    key: "calculation_5_cagr_earningsPerShare",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "Earnings Per Share (5Y CAGR)"
                },
            }
        },
        "ebit_growth": {
            tree: true,
            name: "EBIT Growth",
            components: {
                "growth_quarterly_ttm_ebit": {
                    key: "growth_quarterly_ttm_ebit",
                    name: "TTM",
                    selected: false,
                    display_name: "EBIT Growth (TTM)"
                },
                "growth_quarterly_ebit": {
                    key: "growth_quarterly_ebit",
                    name: "QoQ",
                    selected: false,
                    display_name: "EBIT Growth (QoQ)"
                },
                "growth_quarterly_yoy_ebit": {
                    key: "growth_quarterly_yoy_ebit",
                    name: "YoY",
                    selected: false,
                    display_name: "EBIT Growth (YoY)"
                },
                "growth_yearly_ebit": {
                    key: "growth_yearly_ebit",
                    name: "Year",
                    selected: false,
                    display_name: "EBIT Growth (Year)"
                },
                "calculation_3_cagr_ebit": {
                    key: "calculation_3_cagr_ebit",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "EBIT (3Y CAGR)"
                },
                "calculation_5_cagr_ebit": {
                    key: "calculation_5_cagr_ebit",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "EBIT (5Y CAGR)"
                },
            }
        },
        "ebitda_growth": {
            tree: true,
            name: "EBITDA Growth",
            components: {
                "growth_quarterly_ttm_ebitda": {
                    key: "growth_quarterly_ttm_ebitda",
                    name: "TTM",
                    selected: false,
                    display_name: "EBITDA Growth (TTM)"
                },
                "growth_quarterly_ebitda": {
                    key: "growth_quarterly_ebitda",
                    name: "QoQ",
                    selected: false,
                    display_name: "EBITDA Growth (QoQ)"
                },
                "growth_quarterly_yoy_ebitda": {
                    key: "growth_quarterly_yoy_ebitda",
                    name: "YoY",
                    selected: false,
                    display_name: "EBITDA Growth (YoY)"
                },
                "growth_yearly_ebitda": {
                    key: "growth_yearly_ebitda",
                    name: "Year",
                    selected: false,
                    display_name: "EBITDA Growth (Year)"
                },
                "calculation_3_cagr_ebitda": {
                    key: "calculation_3_cagr_ebitda",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "EBITDA (3Y CAGR)"
                },
                "calculation_5_cagr_ebitda": {
                    key: "calculation_5_cagr_ebitda",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "EBITDA (5Y CAGR)"
                },
            }
        },
        "fcf_growth": {
            tree: true,
            name: "FCF Growth",
            components: {
                "growth_quarterly_ttm_freeCashFlow": {
                    key: "growth_quarterly_ttm_freeCashFlow",
                    name: "TTM",
                    selected: false,
                    display_name: "FCF Growth (TTM)"
                },
                "growth_quarterly_freeCashFlow": {
                    key: "growth_quarterly_freeCashFlow",
                    name: "QoQ",
                    selected: false,
                    display_name: "FCF Growth (QoQ)"
                },
                "growth_quarterly_yoy_freeCashFlow": {
                    key: "growth_quarterly_yoy_freeCashFlow",
                    name: "YoY",
                    selected: false,
                    display_name: "FCF Growth (YoY)"
                },
                "growth_yearly_freeCashFlow": {
                    key: "growth_yearly_freeCashFlow",
                    name: "Year",
                    selected: false,
                    display_name: "FCF Growth (Year)"
                },
                "calculation_3_cagr_freeCashFlow": {
                    key: "calculation_3_cagr_freeCashFlow",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "FCF (3Y CAGR)"
                },
                "calculation_5_cagr_freeCashFlow": {
                    key: "calculation_5_cagr_freeCashFlow",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "FCF (5Y CAGR)"
                },
            }
        },
        "cap_ex_growth": {
            tree: true,
            name: "CapEx Growth",
            components: {
                "growth_quarterly_ttm_capitalExpenditures": {
                    key: "growth_quarterly_ttm_capitalExpenditures",
                    name: "TTM",
                    selected: false,
                    display_name: "Capital Expenditures Growth (TTM)"
                },
                "growth_quarterly_capitalExpenditures": {
                    key: "growth_quarterly_capitalExpenditures",
                    name: "QoQ",
                    selected: false,
                    display_name: "Capital Expenditures Growth (QoQ)"
                },
                "growth_quarterly_yoy_capitalExpenditures": {
                    key: "growth_quarterly_yoy_capitalExpenditures",
                    name: "YoY",
                    selected: false,
                    display_name: "Capital Expenditures Growth (YoY)"
                },
                "growth_yearly_capitalExpenditures": {
                    key: "growth_yearly_capitalExpenditures",
                    name: "Year",
                    selected: false,
                    display_name: "Capital Expenditures Growth (Year)"
                },
                "calculation_3_cagr_capitalExpenditures": {
                    key: "calculation_3_cagr_capitalExpenditures",
                    name: "3Y CAGR",
                    selected: false,
                    display_name: "Capital Expenditures (3Y CAGR)"
                },
                "calculation_5_cagr_capitalExpenditures": {
                    key: "calculation_5_cagr_capitalExpenditures",
                    name: "5Y CAGR",
                    selected: false,
                    display_name: "Capital Expenditures (5Y CAGR)"
                },
            }
        },
        "cash_and_equivalent_growth": {
            tree: true,
            name: "Cash & Equivalents Growth",
            components: {
                "growth_quarterly_cashAndShortTermInvestments": {
                    key: "growth_quarterly_cashAndShortTermInvestments",
                    name: "QoQ",
                    selected: false,
                    display_name: "Cash and Short Term Investments Growth (QoQ)"
                },
                "growth_quarterly_yoy_cashAndShortTermInvestments": {
                    key: "growth_quarterly_yoy_cashAndShortTermInvestments",
                    name: "YoY",
                    selected: false,
                    display_name: "Cash And Short Term Investments Growth (YoY)"
                },
                "growth_yearly_cashAndShortTermInvestments": {
                    key: "growth_yearly_cashAndShortTermInvestments",
                    name: "Year",
                    selected: false,
                    display_name: "Cash And Short Term Investments Growth (Year)"
                },
                "calculation_3_year_average_cashAndShortTermInvestments": {
                    key: "calculation_3_year_average_cashAndShortTermInvestments",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Cash And Short Term Investments (3Y Avg)"
                },
                "calculation_5_year_average_cashAndShortTermInvestments": {
                    key: "calculation_5_year_average_cashAndShortTermInvestments",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Cash and Short Term Investments (5Y Avg)"
                },
            }
        },
        "total_cash_growth": {
            tree: true,
            name: "Total Cash Growth",
            components: {
                "growth_quarterly_totalCash": {
                    key: "growth_quarterly_totalCash",
                    name: "QoQ",
                    selected: false,
                    display_name: "Total Cash Growth (QoQ)"
                },
                "growth_quarterly_yoy_totalCash": {
                    key: "growth_quarterly_yoy_totalCash",
                    name: "YoY",
                    selected: false,
                    display_name: "Total Cash Growth (YoY)"
                },
                "growth_yearly_totalCash": {
                    key: "growth_yearly_totalCash",
                    name: "Year",
                    selected: false,
                    display_name: "Total Cash Growth (Year)"
                },
                "calculation_3_year_average_totalCash": {
                    key: "calculation_3_year_average_totalCash",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Total Cash (3Y Avg)"
                },
                "calculation_5_year_average_totalCash": {
                    key: "calculation_5_year_average_totalCash",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Total Cash (5Y Avg)"
                },
            }
        },
        "total_debt_growth": {
            tree: true,
            name: "Total Debt Growth",
            components: {
                "growth_quarterly_totalDebt": {
                    key: "growth_quarterly_totalDebt",
                    name: "QoQ",
                    selected: false,
                    display_name: "Total Debt Growth (QoQ)"
                },
                "growth_quarterly_yoy_totalDebt": {
                    key: "growth_quarterly_yoy_totalDebt",
                    name: "YoY",
                    selected: false,
                    display_name: "Total Debt Growth (YoY)"
                },
                "growth_yearly_totalDebt": {
                    key: "growth_yearly_totalDebt",
                    name: "Year",
                    selected: false,
                    display_name: "Total Debt Growth (Year)"
                },
                "calculation_3_year_average_totalDebt": {
                    key: "calculation_3_year_average_totalDebt",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Total Debt (3Y Avg)"
                },
                "calculation_5_year_average_totalDebt": {
                    key: "calculation_5_year_average_totalDebt",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Total Debt (5Y Avg)"
                },
            }
        },
    },
    "Health": {
        "cash_ratio": {
            tree: true,
            name: "Cash Ratio",
            components: {
                "additionalcalculations_quarter_cashRatio": {
                    key: "additionalcalculations_quarter_cashRatio",
                    name: "Quarter",
                    selected: false,
                    display_name: "Cash Ratio (Quarter)"
                },
                "additionalcalculations_annual_cashRatio": {
                    key: "additionalcalculations_annual_cashRatio",
                    name: "Year",
                    selected: false,
                    display_name: "Cash Ratio (Year)"
                },
                "calculation_3_year_average_cashRatio": {
                    key: "calculation_3_year_average_cashRatio",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Cash Ratio (3Y Avg)"
                },
                "calculation_5_year_average_cashRatio": {
                    key: "calculation_5_year_average_cashRatio",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Cash Ratio (5Y Avg)"
                },
            }
        },
        "quick_ratio": {
            tree: true,
            name: "Quick Ratio",
            components: {
                "additionalcalculations_quarter_quickRatio": {
                    key: "additionalcalculations_quarter_quickRatio",
                    name: "Quarter",
                    selected: false,
                    display_name: "Quick Ratio (Quarter)"
                },
                "additionalcalculations_annual_quickRatio": {
                    key: "additionalcalculations_annual_quickRatio",
                    name: "Year",
                    selected: false,
                    display_name: "Quick Ratio (Year)"
                },
                "calculation_3_year_average_quickRatio": {
                    key: "calculation_3_year_average_quickRatio",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Quick Ratio (3Y Avg)"
                },
                "calculation_5_year_average_quickRatio": {
                    key: "calculation_5_year_average_quickRatio",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Quick Ratio (5Y Avg)"
                },
            }
        },
        "current_ratio": {
            tree: true,
            name: "Current Ratio",
            components: {
                "additionalcalculations_quarter_currentRatio": {
                    key: "additionalcalculations_quarter_currentRatio",
                    name: "Quarter",
                    selected: false,
                    display_name: "Current Ratio (Quarter)"
                },
                "additionalcalculations_annual_currentRatio": {
                    key: "additionalcalculations_annual_currentRatio",
                    name: "Year",
                    selected: false,
                    display_name: "Current Ratio (Year)"
                },
                "calculation_3_year_average_currentRatio": {
                    key: "calculation_3_year_average_currentRatio",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Current Ratio (3Y Avg)"
                },
                "calculation_5_year_average_currentRatio": {
                    key: "calculation_5_year_average_currentRatio",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Current Ratio (5Y Avg)"
                },
            }
        },
        "equity_assets": {
            tree: true,
            name: "Equity/Assets",
            components: {
                "additionalcalculations_quarter_equityAssets": {
                    key: "additionalcalculations_quarter_equityAssets",
                    name: "Quarter",
                    selected: false,
                    display_name: "Equity Assets (Quarter)"
                },
                "additionalcalculations_annual_equityAssets": {
                    key: "additionalcalculations_annual_equityAssets",
                    name: "Year",
                    selected: false,
                    display_name: "Equity Assets (Year)"
                },
                "calculation_3_year_average_equityAssets": {
                    key: "calculation_3_year_average_equityAssets",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Equity Assets (3Y Avg)"
                },
                "calculation_5_year_average_equityAssets": {
                    key: "calculation_5_year_average_equityAssets",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Equity Assets (5Y Avg)"
                },
            }
        },
        "debt_equity": {
            tree: true,
            name: "Debt/Equity",
            components: {
                "additionalcalculations_quarter_debtEquity": {
                    key: "additionalcalculations_quarter_debtEquity",
                    name: "Quarter",
                    selected: false,
                    display_name: "Debt Equity (Quarter)"
                },
                "additionalcalculations_annual_debtEquity": {
                    key: "additionalcalculations_annual_debtEquity",
                    name: "Year",
                    selected: false,
                    display_name: "Debt Equity (Year)"
                },
                "calculation_3_year_average_debtEquity": {
                    key: "calculation_3_year_average_debtEquity",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Debt Equity (3Y Avg)"
                },
                "calculation_5_year_average_debtEquity": {
                    key: "calculation_5_year_average_debtEquity",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Debt Equity (5Y Avg)"
                },
            }
        },
        "net_debt_equity": {
            tree: true,
            name: "NetDebt/Equity",
            components: {
                "additionalcalculations_quarter_netDebtEquity": {
                    key: "additionalcalculations_quarter_netDebtEquity",
                    name: "Quarter",
                    selected: false,
                    display_name: "Net Debt Equity (Quarter)"
                },
                "additionalcalculations_annual_netDebtEquity": {
                    key: "additionalcalculations_annual_netDebtEquity",
                    name: "Year",
                    selected: false,
                    display_name: "Net Debt Equity (Year)"
                },
                "calculation_3_year_average_netDebtEquity": {
                    key: "calculation_3_year_average_netDebtEquity",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Net Debt Equity (3Y Avg)"
                },
                "calculation_5_year_average_netDebtEquity": {
                    key: "calculation_5_year_average_netDebtEquity",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Net Debt Equity (5Y Avg)"
                },
            }
        },
        "debt_asset": {
            tree: true,
            name: "Debt Asset",
            components: {
                "additionalcalculations_quarter_debtAsset": {
                    key: "additionalcalculations_quarter_debtAsset",
                    name: "Quarter",
                    selected: false,
                    display_name: "Debt Asset (Quarter)"
                },
                "additionalcalculations_annual_debtAsset": {
                    key: "additionalcalculations_annual_debtAsset",
                    name: "Year",
                    selected: false,
                    display_name: "Debt Asset (Year)"
                },
                "calculation_3_year_average_debtAsset": {
                    key: "calculation_3_year_average_debtAsset",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Debt Asset (3Y Avg)"
                },
                "calculation_5_year_average_debtAsset": {
                    key: "calculation_5_year_average_debtAsset",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Debt Asset (5Y Avg)"
                },
            }
        },
        "net_debt_asset": {
            tree: true,
            name: "NetDebt/Asset",
            components: {
                "additionalcalculations_quarter_netDebtAsset": {
                    key: "additionalcalculations_quarter_netDebtAsset",
                    name: "Quarter",
                    selected: false,
                    display_name: "Net Debt Asset (Quarter)"
                },
                "additionalcalculations_annual_netDebtAsset": {
                    key: "additionalcalculations_annual_netDebtAsset",
                    name: "Year",
                    selected: false,
                    display_name: "Net Debt Asset (Year)"
                },
                "calculation_3_year_average_netDebtAsset": {
                    key: "calculation_3_year_average_netDebtAsset",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Net Debt Asset (3Y Avg)"
                },
                "calculation_5_year_average_netDebtAsset": {
                    key: "calculation_5_year_average_netDebtAsset",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Net Debt Asset (5Y Avg)"
                },
            }
        },

        "debt_ebit": {
            tree: true,
            name: "Debt/EBIT",
            components: {
                "ttm_debtEBIT": {
                    key: "ttm_debtEBIT",
                    name: "TTM",
                    selected: false,
                    display_name: "Debt EBIT (TTM)"
                },
                "additionalcalculations_annual_debtEBIT": {
                    key: "additionalcalculations_annual_debtEBIT",
                    name: "Year",
                    selected: false,
                    display_name: "Debt EBIT (Year)"
                },
                "calculation_3_year_average_debtEBIT": {
                    key: "calculation_3_year_average_debtEBIT",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Debt EBIT (3Y Avg)"
                },
                "calculation_5_year_average_debtEBIT": {
                    key: "calculation_5_year_average_debtEBIT",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Debt EBIT (5Y Avg)"
                },
            }
        },
        "net_debt_ebit": {
            tree: true,
            name: "NetDebt/EBIT",
            components: {
                "ttm_netDebtEBIT": {
                    key: "ttm_netDebtEBIT",
                    name: "TTM",
                    selected: false,
                    display_name: "Net Debt EBIT (TTM)"
                },
                "additionalcalculations_annual_netDebtEBIT": {
                    key: "additionalcalculations_annual_netDebtEBIT",
                    name: "Year",
                    selected: false,
                    display_name: "Net Debt EBIT (Year)"
                },
                "calculation_3_year_average_netDebtEBIT": {
                    key: "calculation_3_year_average_netDebtEBIT",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Net Debt EBIT (3Y Avg)"
                },
                "calculation_5_year_average_netDebtEBIT": {
                    key: "calculation_5_year_average_netDebtEBIT",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Net Debt EBIT (5Y Avg)"
                },
            }
        },

        "debt_ebitda": {
            tree: true,
            name: "Debt/EBITDA",
            components: {
                "ttm_debtEBITDA": {
                    key: "ttm_debtEBITDA",
                    name: "TTM",
                    selected: false,
                    display_name: "Debt EBITDA (TTM)"
                },
                "additionalcalculations_annual_debtEBITDA": {
                    key: "additionalcalculations_annual_debtEBITDA",
                    name: "Year",
                    selected: false,
                    display_name: "Debt EBITDA (Year)"
                },
                "calculation_3_year_average_debtEBITDA": {
                    key: "calculation_3_year_average_debtEBITDA",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Debt EBITDA (3Y Avg)"
                },
                "calculation_5_year_average_debtEBITDA": {
                    key: "calculation_5_year_average_debtEBITDA",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Debt EBITDA (5Y Avg)"
                },
            }
        },

        "net_debt_ebitda": {
            tree: true,
            name: "NetDebt/EBITDA",
            components: {
                "ttm_netDebtEBITDA": {
                    key: "ttm_netDebtEBITDA",
                    name: "TTM",
                    selected: false,
                    display_name: "Net Debt EBITDA (TTM)"
                },
                "additionalcalculations_annual_netDebtEBITDA": {
                    key: "additionalcalculations_annual_netDebtEBITDA",
                    name: "Year",
                    selected: false,
                    display_name: "Net Debt EBITDA (Year)"
                },
                "calculation_3_year_average_netDebtEBITDA": {
                    key: "calculation_3_year_average_netDebtEBITDA",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Net Debt EBITDA (3Y Avg)"
                },
                "calculation_5_year_average_netDebtEBITDA": {
                    key: "calculation_5_year_average_netDebtEBITDA",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Net Debt EBITDA (5Y Avg)"
                },
            }
        },

        "fcf_debt": {
            tree: true,
            name: "FCF Debt",
            components: {
                "additionalcalculations_quarter_fcfDebt": {
                    key: "additionalcalculations_quarter_fcfDebt",
                    name: "Quarter",
                    selected: false,
                    display_name: "FCF/Debt (Quarter)"
                },
                "additionalcalculations_annual_fcfDebt": {
                    key: "additionalcalculations_annual_fcfDebt",
                    name: "Year",
                    selected: false,
                    display_name: "FCF/Debt (Year)"
                },
                "calculation_3_year_average_fcfDebt": {
                    key: "calculation_3_year_average_fcfDebt",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "FCF/Debt (3Y Avg)"
                },
                "calculation_5_year_average_fcfDebt": {
                    key: "calculation_5_year_average_fcfDebt",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "FCF/Debt (5Y Avg)"
                }
            }
        },

        "cash_debt": {
            tree: true,
            name: "Cash Debt",
            components: {
                "additionalcalculations_quarter_cashTotalDebt": {
                    key: "additionalcalculations_quarter_cashTotalDebt",
                    name: "Quarter",
                    selected: false,
                    display_name: "Cash Total Debt (Quarter)"
                },
                "additionalcalculations_annual_cashTotalDebt": {
                    key: "additionalcalculations_annual_cashTotalDebt",
                    name: "Year",
                    selected: false,
                    display_name: "Cash Total Debt (Year)"
                },
                "calculation_3_year_average_cashTotalDebt": {
                    key: "calculation_3_year_average_cashTotalDebt",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Cash Total Debt (3Y Avg)"
                },
                "calculation_5_year_average_cashTotalDebt": {
                    key: "calculation_5_year_average_cashTotalDebt",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Cash Total Debt (5Y Avg)"
                },
            }
        },

        "debt_capital": {
            tree: true,
            name: "Debt Capital",
            components: {
                "additionalcalculations_quarter_debtCapital": {
                    key: "additionalcalculations_quarter_debtCapital",
                    name: "Quarter",
                    selected: false,
                    display_name: "Debt Capital (Quarter)"
                },
                "additionalcalculations_annual_debtCapital": {
                    key: "additionalcalculations_annual_debtCapital",
                    name: "Year",
                    selected: false,
                    display_name: "Debt Capital (Year)"
                },
                "calculation_3_year_average_debtCapital": {
                    key: "calculation_3_year_average_debtCapital",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Debt Capital (3Y Avg)"
                },
                "calculation_5_year_average_debtCapital": {
                    key: "calculation_5_year_average_debtCapital",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Debt Capital (5Y Avg)"
                },
            }
        },

        "piotroski_f_score": {
            tree: true,
            name: "Piotroski F-Score",
            components: {
                "current_piotroskiFScore": {
                    key: "current_piotroskiFScore",
                    name: "Current",
                    selected: false,
                    display_name: "Piotroski F-Score (Curr.)"
                },
                "additionalcalculations_quarter_piotroskiFScore": {
                    key: "additionalcalculations_quarter_piotroskiFScore",
                    name: "Quarter",
                    selected: false,
                    display_name: "Piotroski F-Score (Quarter)"
                },
                "additionalcalculations_annual_piotroskiFScore": {
                    key: "additionalcalculations_annual_piotroskiFScore",
                    name: "Year",
                    selected: false,
                    display_name: "Piotroski F-Score (Year)"
                },
                "calculation_3_year_average_piotroskiFScore": {
                    key: "calculation_3_year_average_piotroskiFScore",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Piotroski-FScore (3Y Avg)"
                },
                "calculation_5_year_average_piotroskiFScore": {
                    key: "calculation_5_year_average_piotroskiFScore",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Piotroski-FScore (5Y Avg)"
                },
            }
        },

        "altman_z_score": {
            tree: true,
            name: "Altman Z-Score",
            components: {
                "current_altmanZScore": {
                    key: "current_altmanZScore",
                    name: "Current",
                    selected: false,
                    display_name: "Altman-ZScore (Curr.)"
                },
                "additionalcalculations_quarter_altmanZScore": {
                    key: "additionalcalculations_quarter_altmanZScore",
                    name: "Quarter",
                    selected: false,
                    display_name: "Altman-ZScore (Quarter)"
                },
                "additionalcalculations_annual_altmanZScore": {
                    key: "additionalcalculations_annual_altmanZScore",
                    name: "Year",
                    selected: false,
                    display_name: "Altman-ZScore (Year)"
                },
                "calculation_3_year_average_altmanZScore": {
                    key: "calculation_3_year_average_altmanZScore",
                    name: "3Y Avg",
                    selected: false,
                    display_name: "Altman-ZScore (3Y Avg)"
                },
                "calculation_5_year_average_altmanZScore": {
                    key: "calculation_5_year_average_altmanZScore",
                    name: "5Y Avg",
                    selected: false,
                    display_name: "Altman-ZScore (5Y Avg)"
                },
            }
        },
    },
    "Dividend": {
        'payoutRatio': {
            tree: false,
            key: "payoutRatio",
            name: "Payout Ratio",
            selected: false,
            display_name: "Payout Ratio"
        },
        'forwardAnnualDividendRate': {
            tree: false,
            key: "forwardAnnualDividendRate",
            name: "Dividend Rate",
            selected: false,
            display_name: "Dividend Rate"
        },
        'forwardAnnualDividendYield': {
            tree: false,
            key: "forwardAnnualDividendYield",
            name: "Dividend Yield",
            selected: false,
            display_name: "Dividend Yield"
        },
    },
    "Financials": {
        "totalRevenue": {
            tree: true,
            name: "Total Revenue",
            components: {
                "ttm_totalRevenue": {
                    key: "ttm_totalRevenue",
                    name: "TTM",
                    selected: false,
                    display_name: "Total Revenue (TTM)"
                },
                "financialsincomestatement_quarter_totalRevenue": {
                    key: "financialsincomestatement_quarter_totalRevenue",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Revenue (Quarter)"
                },
                "financialsincomestatement_annual_totalRevenue": {
                    key: "financialsincomestatement_annual_totalRevenue",
                    name: "Year",
                    selected: false,
                    display_name: "Total Revenue (Year)"
                }
            }
        },
        "grossProfit": {
            tree: true,
            name: "Gross Profit",
            components: {
                "ttm_grossProfit": {
                    key: "ttm_grossProfit",
                    name: "TTM",
                    selected: false,
                    display_name: "Gross Profit (TTM)"
                },
                "financialsincomestatement_quarter_grossProfit": {
                    key: "financialsincomestatement_quarter_grossProfit",
                    name: "Quarter",
                    selected: false,
                    display_name: "Gross Profit (Quarter)"
                },
                "financialsincomestatement_annual_grossProfit": {
                    key: "financialsincomestatement_annual_grossProfit",
                    name: "Year",
                    selected: false,
                    display_name: "Gross Profit (Year)"
                }
            }
        },

        'operatingIncome': {
            tree: true,
            name: "Operating Income",
            components: {
                "ttm_operatingIncome": {
                    key: "ttm_operatingIncome",
                    name: "TTM",
                    selected: false,
                    display_name: "Operating Income (TTM)"
                },
                "financialsincomestatement_quarter_operatingIncome": {
                    key: "financialsincomestatement_quarter_operatingIncome",
                    name: "Quarter",
                    selected: false,
                    display_name: "Operating Income (Quarter)"
                },
                "financialsincomestatement_annual_operatingIncome": {
                    key: "financialsincomestatement_annual_operatingIncome",
                    name: "Year",
                    selected: false,
                    display_name: "Operating Income (Year)"
                }
            }
        },
        'netIncome': {
            tree: true,
            name: "Net Income",
            components: {
                "ttm_netIncome_income_statement": {
                    key: "ttm_netIncome_income_statement",
                    name: "TTM",
                    selected: false,
                    display_name: "Net Income (TTM)"
                },
                "financialsincomestatement_quarter_netIncome": {
                    key: "financialsincomestatement_quarter_netIncome",
                    name: "Quarter",
                    selected: false,
                    display_name: "Net Income (Quarter)"
                },
                "financialsincomestatement_annual_netIncome": {
                    key: "financialsincomestatement_annual_netIncome",
                    name: "Year",
                    selected: false,
                    display_name: "Net Income (Year)"
                }
            }
        },
        'ebit': {
            tree: true,
            name: "EBIT",
            components: {
                "ttm_ebit": {
                    key: "ttm_ebit",
                    name: "TTM",
                    selected: false,
                    display_name: "EBIT (TTM)"
                },
                "financialsincomestatement_quarter_ebit": {
                    key: "financialsincomestatement_quarter_ebit",
                    name: "Quarter",
                    selected: false,
                    display_name: "EBIT (Quarter)"
                },
                "financialsincomestatement_annual_ebit": {
                    key: "financialsincomestatement_annual_ebit",
                    name: "Year",
                    selected: false,
                    display_name: "EBIT (Year)"
                }
            }
        },
        'ebitda': {
            tree: true,
            name: "EBITDA",
            components: {
                "ttm_ebitda": {
                    key: "ttm_ebitda",
                    name: "TTM",
                    selected: false,
                    display_name: "EBITDA (TTM)"
                },
                "financialsincomestatement_quarter_ebitda": {
                    key: "financialsincomestatement_quarter_ebitda",
                    name: "Quarter",
                    selected: false,
                    display_name: "EBITDA (Quarter)"
                },
                "financialsincomestatement_annual_ebitda": {
                    key: "financialsincomestatement_annual_ebitda",
                    name: "Year",
                    selected: false,
                    display_name: "EBITDA (Year)"
                }
            }
        },
        'capex': {
            tree: true,
            name: "Capital Expenditures",
            components: {
                "ttm_capitalExpenditures": {
                    key: "ttm_capitalExpenditures",
                    name: "TTM",
                    selected: false,
                    display_name: "Cap Ex (TTM)"
                },
                "financialscashflow_quarter_capitalExpenditures": {
                    key: "financialscashflow_quarter_capitalExpenditures",
                    name: "Quarter",
                    selected: false,
                    display_name: "Cap Ex (Quarter)"
                },
                "financialscashflow_annual_capitalExpenditures": {
                    key: "financialscashflow_annual_capitalExpenditures",
                    name: "Year",
                    selected: false,
                    display_name: "Cap Ex (Year)"
                }
            }
        },
        'freeCashFlow': {
            tree: true,
            name: "Free Cash Flow",
            components: {
                "ttm_freeCashFlow": {
                    key: "ttm_freeCashFlow",
                    name: "TTM",
                    selected: false,
                    display_name: "Free Cash Flow (TTM)"
                },
                "financialscashflow_quarter_freeCashFlow": {
                    key: "financialscashflow_quarter_freeCashFlow",
                    name: "Quarter",
                    selected: false,
                    display_name: "Free Cash Flow (Quarter)"
                },
                "financialscashflow_annual_freeCashFlow": {
                    key: "financialscashflow_annual_freeCashFlow",
                    name: "Year",
                    selected: false,
                    display_name: "Free Cash Flow (Year)"
                }
            }
        },
        'cash_and_equivalent': {
            tree: true,
            name: "Cash And Equivalent",
            components: {
                "financialsbalancesheets_quarter_cashAndShortTermInvestments": {
                    key: "financialsbalancesheets_quarter_cashAndShortTermInvestments",
                    name: "Quarter",
                    selected: false,
                    display_name: "Cash And Short Term Investment (Quarter)"
                },
                "financialsbalancesheets_annual_cashAndShortTermInvestments": {
                    key: "financialsbalancesheets_annual_cashAndShortTermInvestments",
                    name: "Year",
                    selected: false,
                    display_name: "Cash And Short Term Investment (Year)"
                }
            }
        },
        'totalCash': {
            tree: true,
            name: "Total Cash",
            components: {
                "financialsbalancesheets_quarter_totalCash": {
                    key: "financialsbalancesheets_quarter_totalCash",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Cash (Quarter)"
                },
                "financialsbalancesheets_annual_totalCash": {
                    key: "financialsbalancesheets_annual_totalCash",
                    name: "Year",
                    selected: false,
                    display_name: "Total Cash (Year)"
                }
            }
        },
        'Total Debt': {
            tree: true,
            name: "Total Debt",
            components: {
                "financialsbalancesheets_quarter_totalDebt": {
                    key: "financialsbalancesheets_quarter_totalDebt",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Debt (Quarter)"
                },
                "financialsbalancesheets_annual_totalDebt": {
                    key: "financialsbalancesheets_annual_totalDebt",
                    name: "Year",
                    selected: false,
                    display_name: "Total Debt (Year)"
                }
            }
        },
        'Net Debt': {
            tree: true,
            name: "Net Debt",
            components: {
                "financialsbalancesheets_quarter_netDebt": {
                    key: "financialsbalancesheets_quarter_netDebt",
                    name: "Quarter",
                    selected: false,
                    display_name: "Net Debt (Quarter)"
                },
                "financialsbalancesheets_annual_netDebt": {
                    key: "financialsbalancesheets_annual_netDebt",
                    name: "Year",
                    selected: false,
                    display_name: "Net Debt (Year)"
                }
            }
        },

        'Research Development': {
            tree: true,
            name: "Research Development",
            components: {
                "ttm_researchDevelopment": {
                    key: "ttm_researchDevelopment",
                    name: "TTM",
                    selected: false,
                    display_name: "Research Development (TTM)"
                },
                "financialsincomestatement_quarter_researchDevelopment": {
                    key: "financialsincomestatement_quarter_researchDevelopment",
                    name: "Quarter",
                    selected: false,
                    display_name: "Research Development (Quarter)"
                },
                "financialsincomestatement_annual_researchDevelopment": {
                    key: "financialsincomestatement_annual_researchDevelopment",
                    name: "Year",
                    selected: false,
                    display_name: "Research Development (Year)"
                }
            }
        },

        'totalCashFromOperatingActivities': {
            tree: true,
            name: "Total Cash From Operating Activities",
            components: {
                "ttm_totalCashFromOperatingActivities": {
                    key: "ttm_totalCashFromOperatingActivities",
                    name: "TTM",
                    selected: false,
                    display_name: "Total Cash From Operating Activities (TTM)"
                },
                "financialscashflow_quarter_totalCashFromOperatingActivities": {
                    key: "financialscashflow_quarter_totalCashFromOperatingActivities",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Cash From Operating Activities (Quarter)"
                },
                "financialscashflow_annual_totalCashFromOperatingActivities": {
                    key: "financialscashflow_annual_totalCashFromOperatingActivities",
                    name: "Year",
                    selected: false,
                    display_name: "Total Cash From Operating Activities (Year)"
                }
            }
        },

        'depreciation': {
            tree: true,
            name: "Depreciation",
            components: {
                "ttm_depreciation": {
                    key: "ttm_depreciation",
                    name: "TTM",
                    selected: false,
                    display_name: "Depreciation (TTM)"
                },
                "financialscashflow_quarter_depreciation": {
                    key: "financialscashflow_quarter_depreciation",
                    name: "Quarter",
                    selected: false,
                    display_name: "Depreciation (Quarter)"
                },
                "financialscashflow_annual_depreciation": {
                    key: "financialscashflow_annual_depreciation",
                    name: "Year",
                    selected: false,
                    display_name: "Depreciation (Year)"
                }
            }
        },

        'cash': {
            tree: true,
            name: "Cash",
            components: {
                "financialsbalancesheets_quarter_cash": {
                    key: "financialsbalancesheets_quarter_cash",
                    name: "Quarter",
                    selected: false,
                    display_name: "Cash (Quarter)"
                },
                "financialsbalancesheets_annual_cash": {
                    key: "financialsbalancesheets_annual_cash",
                    name: "Year",
                    selected: false,
                    display_name: "Cash (Year)"
                }
            }
        },


        'totalAssets': {
            tree: true,
            name: "Total Assets",
            components: {
                "financialsbalancesheets_quarter_totalAssets": {
                    key: "financialsbalancesheets_quarter_totalAssets",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Assets (Quarter)"
                },
                "financialsbalancesheets_annual_totalAssets": {
                    key: "financialsbalancesheets_annual_totalAssets",
                    name: "Year",
                    selected: false,
                    display_name: "Total Assets (Year)"
                }
            }
        },

        'totalLiab': {
            tree: true,
            name: "Total Liab",
            components: {
                "financialsbalancesheets_quarter_totalLiab": {
                    key: "financialsbalancesheets_quarter_totalLiab",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Liab (Quarter)"
                },
                "financialsbalancesheets_annual_totalLiab": {
                    key: "financialsbalancesheets_annual_totalLiab",
                    name: "Year",
                    selected: false,
                    display_name: "Total Liab (Year)"
                }
            }
        },

        'totalStockholderEquity': {
            tree: true,
            name: "Total Stock Holder Equity",
            components: {
                "financialsbalancesheets_quarter_totalStockholderEquity": {
                    key: "financialsbalancesheets_quarter_totalStockholderEquity",
                    name: "Quarter",
                    selected: false,
                    display_name: "Total Stock Holder Equity (Quarter)"
                },
                "financialsbalancesheets_annual_totalStockholderEquity": {
                    key: "financialsbalancesheets_annual_totalStockholderEquity",
                    name: "Year",
                    selected: false,
                    display_name: "Total Stock Holder Equity (Year)"
                }
            }
        },
    },
}

export default ScreenerModalFilterJson;
