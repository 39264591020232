import React, {useEffect, useState} from 'react';
import axios from "axios";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";
import {
    numberWithCommas,
    return_quarter_earnings_row,
    return_strong_num,
    return_value_in_million
} from "../Utils";

const company_overview_earnings_quarter = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-earnings-quarter/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function EarningsQuarterEarnings({code, exchange}) {

    const [earningsQuarter, setEarningsQuarter] = useState([])

    const {data: earningsQuarterReturn, isLoading: earningsQuarterIsLoading} = useQuery({
        queryKey: [`company-overview-earnings-quarter/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_earnings_quarter(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!earningsQuarterIsLoading) {
            setEarningsQuarter(earningsQuarterReturn)
        }
    }, [earningsQuarterIsLoading]);

    return (
        <div className={'row g-0'}>
            {earningsQuarter.map((item, index) => {
                return (
                    <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className={'company-overview-table-container'}>
                            <div
                                className={'company-overview-table-body-container company-overview-bottom-table-body-container'}>
                                <div className={'earnings-table-heading'}>
                                    <p>{item.name}</p>
                                </div>
                                <div style={{margin: "5px 0", height: 2, width: "100%", backgroundColor: "#265862"}}/>
                                {return_quarter_earnings_row("Announce Date", item.date)}
                                {return_quarter_earnings_row(index === 0 ? "EPS Non-GAAP" : "EPS Non-GAAP Estimate", `${return_strong_num(item.eps)} ${item.currency}`, false, item.epsSurprise, return_strong_num(item.epsSurpriseValue))}
                                {return_quarter_earnings_row("EPS Growth YoY", return_strong_num(item.epsGrowthYoY * 100), true)}
                                {return_quarter_earnings_row(index === 0 ? "Revenue Actual (mil.)" : "Revenue Estimate (mil.)", `${numberWithCommas(return_value_in_million(item.revenue))} ${item.currency}`, false, item.revenueSurprise, return_strong_num(item.revenueSurpriseValue))}
                                {return_quarter_earnings_row("Revenue Growth YoY", return_strong_num(item.revenueGrowthYoY * 100), true)}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default EarningsQuarterEarnings;