import React, {useContext, useEffect, useState} from 'react';
import {return_strong_num, return_value_in_million} from "../../../Utils";
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../../Context/ThemeContext";

function FinancialOverviewLineChart({margins}) {
    const [series, setSeries] = useState([
        {
            name: 'Gross Margin',
            data: []
        },
        {
            name: 'Net Margin',
            data: []
        },
        {
            name: 'FCF Margin',
            data: []
        },
    ])

    useEffect(() => {
        let totalDebt = []
        let totalStockHolderEquity = []
        let cashAndShortTermInvestments = []

        margins.dates.map((date, index) => {
            totalDebt.push({
                x: date,
                y: return_strong_num(margins['Gross Margin'][index] * 100)
            })
            totalStockHolderEquity.push({
                x: date,
                y: return_strong_num(margins['Net Margin'][index] * 100)
            })
            cashAndShortTermInvestments.push({
                x: date,
                y: return_strong_num(margins['FCF Margin'][index] * 100)
            })
        })

        setSeries([
            {
                name: 'Gross Margin',
                data: totalDebt
            },
            {
                name: 'Net Margin',
                data: totalStockHolderEquity
            },
            {
                name: 'FCF Margin',
                data: cashAndShortTermInvestments
            },
        ])
    }, [margins]);

    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })
    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const lineOptions = {
        chart: {
            type: 'area',
            width: '100%',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: ["#3e9fed", "#29f3a7", "#ee1981"],
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        legend: {
            show: true,
            position: 'top',
            formatter: function (seriesName, opts) {
                return seriesName
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return `${val}%`
                }
            }
        },
        fill: {
            opacity: 0.5
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return `${value}%`
                }
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        }
    }

    return (
        <div className={'rule-of-forty-chart-container'}>
            <div className={'rule-of-forty-inner-container'} style={{height: 294, padding: "10px 10px 0 10px"}}>
                <p className={'market-overview-chart-name'}>Margins</p>
                <ReactApexChart options={lineOptions} series={series} type={'line'}
                                width={'100%'}
                                height={'90%'}/>
            </div>
        </div>
    );
}

export default FinancialOverviewLineChart;