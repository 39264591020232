import Select from "react-select";
import {selected_table_styles} from "../Utils";
import {useCallback, useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../Context/ThemeContext";
import ScreenerSelectableTable from "./ScreenerSelectableTable";
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";
import _debounce from "lodash/debounce";

const get_screener_settings = async (screener_id) => {
    if (screener_id) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/screener-settings/${screener_id}/`, Config());
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

const save_screener_settings = async (screener_id, labels) => {
    if (screener_id) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/screener-settings/${screener_id}/`, labels, Config());
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

function ScreenerTable({
                           data,
                           setData,
                           loadingCompanies,
                           setLoadingCompanies,
                           seeMore,
                           componentLoader,
                           setComponentLoader,
                           filterScreener,
                           dataStats,
                           currency,
                           screener_id
                       }) {
    const {webTheme} = useContext(ThemeContext);
    const selectedTableStyles = selected_table_styles(webTheme);
    const debounceFn = useCallback(_debounce(save_screener_settings, 700), []);

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const options = [
        {value:"code", label: "Ticker", isFixed: true, state: 0, class_name: "screener-ticker", per: false, ops: ""},
        {value:"name", label: "Company Name", isFixed: true, state: 0, class_name: "screener-company-name", per: false, ops: ""},
        {value:"exchange", label: "Exchange", isFixed: false, state: 0, class_name: "", per: false, ops: ""},
        {value:"exchange_code", label: "Exchange Code", isFixed: false, state: 0, class_name: "", per: false, ops: ""},

        {value:"ISIN", label: "ISIN", isFixed: false, state: 0, class_name: "", per: false, ops: ""},
        {value:"sector", label: "Sector", isFixed: true, state: 0, class_name: "screener-company-industry", per: false, ops: ""},
        {value:"industry", label: "Industry", isFixed: false, state: 0, class_name: "screener-company-industry", per: false, ops: ""},
        {value:"ipoDate", label: "IPO Date", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"analyst_price_target", label: "Analyst Price Target", isFixed: false, state: 0, class_name: "item-right-align-th screener-analyst-potential", per: false, ops: ""},
        {value:"upside_potential", label: "Analyst Potential", isFixed: false, state: 0, class_name: "item-right-align-th screener-analyst-potential", per: false, ops: ""},
        {value:"number_of_analyst", label: "# Analyst", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"m_cap_trading", label: "MCap", isFixed: true, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"adjusted_close", label: "Close Price", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"yesterday_change", label: "Yesterday's Change %", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        // {value:"country", label: "", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        ...(isAuthenticated && user.subscription.package_name !== 'Free') ? [
            {value: 'trend_score', label: 'TSI Score', isFixed: false, state: 0, class_name: "item-right-align-th", selected: false, per: true, ops: "multiply"},
            {value: 'ranking_trend_score', label: 'TSI Rank', isFixed: false, state: 0, class_name: "item-right-align-th", selected: false, per: false, ops: ""},

            {value:"iSqore", label: "iSqore", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
            {value:"value", label: "Value", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
            {value:"profitability", label: "Profitability", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
            {value:"growth", label: "Growth", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
            {value:"health", label: "Health", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
            {value:"quality", label: "Quality", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        ] : [],

        {value:"payoutRatio", label: "Payout Ratio", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"forwardAnnualDividendRate", label: "Dividend", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"forwardAnnualDividendYield", label: "Dividend Yield", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"sharesOutstanding", label: "Shares Out.", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "comma"},
        {value:"sharesFloat", label: "Shares Float", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "comma"},
        {value:"percentInsiders", label: "Insiders %", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: ""},
        {value:"percentInstitutions", label: "Institutions %", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: ""},
        {value:"shortPercentFloat", label: "Short % Float", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ytd", label: "Perf. YTD", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"week", label: "Perf. Week", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"one_month", label: "Perf. 1M", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"three_month", label: "Perf. 3M", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"one_year", label: "Perf. 1Y", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"three_year", label: "Perf. 3Y", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"five_year", label: "Perf. 5Y", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"five_year_cagr", label: "Perf. 5Y CAGR", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"ten_year", label: "Perf. 10Y", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"additionalcalculations_annual_highGrowthRatio", label: "High Growth Ratio", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "multiply"},
        {value:"ttm_highGrowthRatio", label: "High Growth Ratio (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_highGrowthScore", label: "High Growth Score", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "multiply"},
        {value:"ttm_highGrowthScore", label: "High Growth Score (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "multiply"},

        {value:"forwardPE", label: "Forward P/E", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"current_priceEarnings", label: "P/E (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_priceEarnings", label: "P/E (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_priceEarnings", label: "P/E (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_priceEarnings", label: "P/E (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_priceSales", label: "P/S (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"current_priceSalesForward", label: "Forward P/S", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_priceSales", label: "P/S (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_priceSales", label: "P/S (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_priceSales", label: "P/S (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_priceFreeCashFlow", label: "P/FCF (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_priceFreeCashFlow", label: "P/FCF (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_priceFreeCashFlow", label: "P/FCF (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_priceFreeCashFlow", label: "P/FCF (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_priceBook", label: "P/B (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_priceBook", label: "P/B (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_priceBook", label: "P/B (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_priceBook", label: "P/B (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_evRevenue", label: "EV/Revenue (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_evRevenue", label: "EV/Revenue (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_evRevenue", label: "EV/Revenue (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_evRevenue", label: "EV/Revenue (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_eveBit", label: "EV/EBIT (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_eveBit", label: "EV/EBIT (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_eveBit", label: "EV/EBIT (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_eveBit", label: "EV/EBIT (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_eveBitda", label: "EV/EBITDA (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_eveBitda", label: "EV/EBITDA (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_eveBitda", label: "EV/EBITDA (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_eveBitda", label: "EV/EBITDA (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_pegRatioForward", label: "Forward PEG Ratio", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"current_pegRatio", label: "PEG Ratio (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_pegRatio", label: "PEG Ratio (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_pegRatio", label: "PEG Ratio (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_pegRatio", label: "PEG Ratio (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_earningsYieldForward", label: "Forward Earnings Yield", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"current_earningsYield", label: "Earnings Yield (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_earningsYield", label: "Earnings Yield (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_earningsYield", label: "Earnings Yield (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_earningsYield", label: "Earnings Yield (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_returnOnAssets", label: "ROA % (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_returnOnAssets", label: "ROA % (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_returnOnAssets", label: "ROA % (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_returnOnAssets", label: "ROA % (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_returnOnEquity", label: "ROE % (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_returnOnEquity", label: "ROE % (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_returnOnEquity", label: "ROE % (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_returnOnEquity", label: "ROE % (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_returnOnCapital", label: "ROC (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_returnOnCapital", label: "ROC (Year)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_returnOnCapital", label: "ROC (3Y Avg)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_returnOnCapital", label: "ROC (5Y Avg)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_returnOnCapitalEmployed", label: "ROCE % (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_returnOnCapitalEmployed", label: "ROCE % (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_returnOnCapitalEmployed", label: "ROCE % (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_returnOnCapitalEmployed", label: "ROCE % (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_returnOnInvestedCapital", label: "ROIC % (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_returnOnInvestedCapital", label: "ROIC % (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_returnOnInvestedCapital", label: "ROIC % (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_returnOnInvestedCapital", label: "ROIC % (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_grossMargin", label: "Gross Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_grossMargin", label: "Gross Margin (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_grossMargin", label: "Gross Margin (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_grossMargin", label: "Gross Margin (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_operatingMargin", label: "Operating Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_operatingMargin", label: "Operating Margin (Year)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_operatingMargin", label: "Operating Margin (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_operatingMargin", label: "Operating Margin (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_netMargin", label: "Net Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_netMargin", label: "Net Margin (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_netMargin", label: "Net Margin (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_netMargin", label: "Net Margin (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_ebitMargin", label: "EBIT Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_ebitMargin", label: "EBIT Margin (Year)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_ebitMargin", label: "EBIT Margin (3Y Avg)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_ebitMargin", label: "EBIT Margin (5Y Avg)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_ebitdaMargin", label: "EBITDA Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_ebitdaMargin", label: "EBITDA Margin (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_ebitdaMargin", label: "EBITDA Margin (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_ebitdaMargin", label: "EBITDA Margin (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_fcfMargin", label: "FCF Margin (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_fcfMargin", label: "FCF Margin (Year)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_fcfMargin", label: "FCF Margin (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_fcfMargin", label: "FCF Margin (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_fcfYield", label: "FCF Yield (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"additionalcalculations_annual_fcfYield", label: "FCF Yield (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_fcfYield", label: "FCF Yield (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_fcfYield", label: "FCF Yield (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"ttm_assetTurnOver", label: "Asset Turnover (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_assetTurnOver", label: "Asset Turnover (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_assetTurnOver", label: "Asset Turnover (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_assetTurnOver", label: "Asset Turnover (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"growth_quarterly_totalRevenueForward", label: "Forward Rev. Growth", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_ttm_totalRevenue", label: "Rev. Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_totalRevenue", label: "Rev. Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_totalRevenue", label: "Rev. Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_totalRevenue", label: "Rev. Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_totalRevenue", label: "Rev. Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_totalRevenue", label: "Rev. Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_netIncome_income_statementForward", label: "Forward Profit Growth", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_ttm_netIncome_income_statement", label: "Profit Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_netIncome_income_statement", label: "Profit Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_netIncome_income_statement", label: "Profit Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_netIncome_income_statement", label: "Profit Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_netIncome_income_statement", label: "Profit Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_netIncome_income_statement", label: "Profit Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},


        {value:"growth_quarterly_earningsPerShareForward", label: "Forward EPS Growth", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_ttm_earningsPerShare", label: "EPS Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_earningsPerShare", label: "EPS Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_earningsPerShare", label: "EPS Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_earningsPerShare", label: "EPS Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_earningsPerShare", label: "EPS Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_earningsPerShare", label: "EPS Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_ttm_ebit", label: "EBIT Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_ebit", label: "EBIT Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_ebit", label: "EBIT Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_ebit", label: "EBIT Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_ebit", label: "EBIT Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_ebit", label: "EBIT Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_ttm_ebitda", label: "EBITDA Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_ebitda", label: "EBITDA Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_ebitda", label: "EBITDA Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_ebitda", label: "EBITDA Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_ebitda", label: "EBITDA Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_ebitda", label: "EBITDA Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_ttm_freeCashFlow", label: "FCF Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_freeCashFlow", label: "FCF Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_freeCashFlow", label: "FCF Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_freeCashFlow", label: "FCF Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_freeCashFlow", label: "FCF Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_freeCashFlow", label: "FCF Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_ttm_capitalExpenditures", label: "CapEx. Growth (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_capitalExpenditures", label: "CapEx. Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_capitalExpenditures", label: "CapEx. Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_capitalExpenditures", label: "CapEx. Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_cagr_capitalExpenditures", label: "CapEx. Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_cagr_capitalExpenditures", label: "CapEx. Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_cashAndShortTermInvestments", label: "Cash and Equiv. Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_cashAndShortTermInvestments", label: "Cash and Equiv. Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_cashAndShortTermInvestments", label: "Cash and Equiv. Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_cashAndShortTermInvestments", label: "Cash and Equiv. Growth (3Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_cashAndShortTermInvestments", label: "Cash and Equiv. Growth (5Y CAGR)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_totalCash", label: "Total Cash Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_totalCash", label: "Total Cash Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_totalCash", label: "Total Cash Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_totalCash", label: "Total Cash Growth (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_totalCash", label: "Total Cash Growth (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"growth_quarterly_totalDebt", label: "Total Debt Growth (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_quarterly_yoy_totalDebt", label: "Total Debt Growth (YoY)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"growth_yearly_totalDebt", label: "Total Debt Growth (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_3_year_average_totalDebt", label: "Total Debt Growth (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},
        {value:"calculation_5_year_average_totalDebt", label: "Total Debt Growth (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: true, ops: "multiply"},

        {value:"additionalcalculations_quarter_cashRatio", label: "Cash Ratio (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_cashRatio", label: "Cash Ratio (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_cashRatio", label: "Cash Ratio (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_cashRatio", label: "Cash Ratio (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_quickRatio", label: "Quick Ratio (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_quickRatio", label: "Quick Ratio (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_quickRatio", label: "Quick Ratio (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_quickRatio", label: "Quick Ratio (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_currentRatio", label: "Current Ratio (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_currentRatio", label: "Current Ratio (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_currentRatio", label: "Current Ratio (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_currentRatio", label: "Current Ratio (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_equityAssets", label: "Equity/Assets (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_equityAssets", label: "Equity/Assets (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_equityAssets", label: "Equity/Assets (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_equityAssets", label: "Equity/Assets (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_debtEquity", label: "Debt/Equity (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_debtEquity", label: "Debt/Equity (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_debtEquity", label: "Debt/Equity (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_debtEquity", label: "Debt/Equity (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_netDebtEquity", label: "NetDebt/Equity (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_netDebtEquity", label: "NetDebt/Equity (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_netDebtEquity", label: "NetDebt/Equity (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_netDebtEquity", label: "NetDebt/Equity (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_debtAsset", label: "Debt/Asset (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_debtAsset", label: "Debt Asset (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_debtAsset", label: "Debt/Asset (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_debtAsset", label: "Debt/Asset (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_netDebtAsset", label: "NetDebt/Asset (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_netDebtAsset", label: "NetDebt/Asset (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_netDebtAsset", label: "NetDebt/Asset (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_netDebtAsset", label: "NetDebt/Asset (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"ttm_debtEBIT", label: "Debt/EBIT (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_debtEBIT", label: "Debt/EBIT (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_debtEBIT", label: "Debt/EBIT (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_debtEBIT", label: "Debt/EBIT (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"ttm_netDebtEBIT", label: "NetDebt/EBIT (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_netDebtEBIT", label: "NetDebt/EBIT (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_netDebtEBIT", label: "NetDebt/EBIT (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_netDebtEBIT", label: "NetDebt/EBIT (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"ttm_debtEBITDA", label: "Debt/EBITDA (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_debtEBITDA", label: "Debt/EBITDA (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_debtEBITDA", label: "Debt/EBITDA (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_debtEBITDA", label: "Debt/EBITDA (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"ttm_netDebtEBITDA", label: "NetDebt/EBITDA (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_netDebtEBITDA", label: "NetDebt/EBITDA (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_netDebtEBITDA", label: "NetDebt/EBITDA (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_netDebtEBITDA", label: "NetDebt/EBITDA (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"ttm_fcfDebt", label: "FCF/Debt (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_quarter_fcfDebt", label: "FCF/Debt (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_fcfDebt", label: "FCF/Debt (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_fcfDebt", label: "FCF/Debt (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_fcfDebt", label: "FCF/Debt (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_cashTotalDebt", label: "Cash/Debt (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_cashTotalDebt", label: "Cash/Debt (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_cashTotalDebt", label: "Cash/Debt (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_cashTotalDebt", label: "Cash/Debt (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"additionalcalculations_quarter_debtCapital", label: "Debt/Capital (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_debtCapital", label: "Debt/Capital (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_debtCapital", label: "Debt/Capital (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_debtCapital", label: "Debt/Capital (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_piotroskiFScore", label: "Piotroski F-Score (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_quarter_piotroskiFScore", label: "Piotroski F-Score (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_piotroskiFScore", label: "Piotroski F-Score (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_piotroskiFScore", label: "Piotroski F-Score (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_piotroskiFScore", label: "Piotroski F-Score (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},

        {value:"current_altmanZScore", label: "Altman Z-Score (Curr.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_quarter_altmanZScore", label: "Altman Z-Score (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"additionalcalculations_annual_altmanZScore", label: "Altman Z-Score (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_3_year_average_altmanZScore", label: "Altman Z-Score (3Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},
        {value:"calculation_5_year_average_altmanZScore", label: "Altman Z-Score (5Y avg.)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: ""},


        {value:"ttm_totalRevenue", label: "Revenue (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_totalRevenue", label: "Revenue (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_totalRevenue", label: "Revenue (Y)", isFixed: false, state: 0, class_name: "item-right-align-th",per: false, ops: "million-comma"},

        {value:"ttm_grossProfit", label: "Gross Profit (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_grossProfit", label: "Gross Profit (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_grossProfit", label: "Gross Profit (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_operatingIncome", label: "Operating Income (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_operatingIncome", label: "Operating Income (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_operatingIncome", label: "Operating Income (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_netIncome_income_statement", label: "Net Income (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_netIncome", label: "Net Income (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_netIncome", label: "Net Income (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_ebit", label: "EBIT (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_ebit", label: "EBIT (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_ebit", label: "EBIT (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_ebitda", label: "EBITDA (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_ebitda", label: "EBITDA (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_ebitda", label: "EBITDA (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_capitalExpenditures", label: "CapEx. (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_quarter_capitalExpenditures", label: "CapEx. (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_annual_capitalExpenditures", label: "CapEx. (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_freeCashFlow", label: "FCF (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_quarter_freeCashFlow", label: "FCF (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_annual_freeCashFlow", label: "FCF (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_cashAndShortTermInvestments", label: "Cash & Equiv. (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_cashAndShortTermInvestments", label: "Cash & Equiv. (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_totalCash", label: "Total Cash (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_totalCash", label: "Total Cash (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_totalDebt", label: "Total Debt (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_totalDebt", label: "Total Debt (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_netDebt", label: "NetDebt (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_netDebt", label: "NetDebt (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_researchDevelopment", label: "R&D (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsincomestatement_quarter_researchDevelopment", label: "R&D (Quarter)", isFixed: false, state: 0, class_name: "item-right-align-th",per: false, ops: "million-comma"},
        {value:"financialsincomestatement_annual_researchDevelopment", label: "R&D (Year)", isFixed: false, state: 0, class_name: "item-right-align-th",per: false, ops: "million-comma"},

        {value:"ttm_totalCashFromOperatingActivities", label: "CFO (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_quarter_totalCashFromOperatingActivities", label: "CFO (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_annual_totalCashFromOperatingActivities", label: "CFO (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"ttm_depreciation", label: "Depreciation (TTM)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_quarter_depreciation", label: "Depreciation (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialscashflow_annual_depreciation", label: "Depreciation (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_cash", label: "Cash (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_cash", label: "Cash (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_totalAssets", label: "Total Assets (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_totalAssets", label: "Total Assets (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_totalLiab", label: "Total Liab (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_totalLiab", label: "Total Liab (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},

        {value:"financialsbalancesheets_quarter_totalStockholderEquity", label: "Equity (Q)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
        {value:"financialsbalancesheets_annual_totalStockholderEquity", label: "Equity (Y)", isFixed: false, state: 0, class_name: "item-right-align-th", per: false, ops: "million-comma"},
    ]

    const [columns, setColumns] = useState({
        Ticker: {value:"code", label: "Ticker", isFixed: true, state: 0, class_name: "screener-ticker"},
        'Company Name': {value:"name", label: "Company Name", isFixed: true, state: 0, class_name: "screener-company-name"},
        Sector: {value:"sector", label: "Sector", isFixed: true, state: 0, class_name: "screener-company-industry"},
        'MCap': {value:"m_cap_trading", label: "MCap", isFixed: true, state: 0, class_name: "item-right-align-th"}
    })

    const {data: screenerSettingsReturn, isLoading: screenerSettingsIsLoading} = useQuery({
        queryKey: [`screener-settings/${screener_id}/`, screener_id],
        queryFn: () => get_screener_settings(screener_id),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })

    useEffect(() => {
        if (screenerSettingsReturn && !screenerSettingsIsLoading) {
            const selectedOptions = options.filter(item => item.isFixed || screenerSettingsReturn.includes(item.label))

            let columns = {}

            selectedOptions.map(item => {
                columns[item.label] = item
            })

            setColumns(columns)
        }
    }, [screenerSettingsReturn, screenerSettingsIsLoading]);

    useEffect(() => {
        if (screener_id) {
            const labels = Object.entries(columns).map(([key, column]) => column).filter(item => !item.isFixed).map(item => item.label)

            void debounceFn(screener_id, labels)
        }
    }, [columns, screener_id]);

    useEffect(() => {
        let data = {}

        Object.entries(filterScreener).map(([key, filter]) => {
            if (filter.display && key !== 'General') {
                const components = filter.components
                Object.entries(components).map(([key, component]) => {
                    if(component.display) {
                        let option = options.filter(item => item.value === key)
                        if (option.length > 0) {
                            option = option[0]
                            data[option.label] = option
                        }
                    }
                })
            }
        })

        setColumns({
            ...columns,
            ...data
        })
    }, [filterScreener])

    const handleChange = (e) => {
        const data = {}
        options.map(item => {
            if (item.isFixed) {
                data[item.label] = item
            }
        })

        e.map((item) => {
            data[item.label] = item
        })

        setColumns(data)
    }

    const [screenerData, setScreenerData] = useState([])

    useEffect(() => {
        let _data = [...data]

        setScreenerData(_data.sort((a, b) => {
            let key = "m_cap_trading"
            if (currency === 'usd') {
                key = 'm_cap_usd'
            } else if (currency === 'euro') {
                key = 'm_cap_euro'
            }
            if (a[key] > b[key]) {
                return -1;
            }
            if (a[key] < b[key]) {
                return 1;
            }
            return 0;
        }));
    }, [data, currency])

    return (
        <div>
            <Select
                onChange={handleChange}
                isClearable={options.some((v) => !v.isFixed)}
                closeMenuOnSelect={false}
                defaultValue={options.filter(item => !item.isFixed)}
                value={Object.entries(columns).map(([key, column]) => column)}
                styles={selectedTableStyles}
                options={options}
                isMulti={true}
                theme={(theme) => ({
                    ...theme,
                    color: {
                        ...theme.colors,
                        primary: 'black'
                    }
                })}
            />

            <ScreenerSelectableTable columns={columns} setColumns={setColumns}
                                     options={options}
                                     data={screenerData} setData={setScreenerData}
                                     loadingCompanies={loadingCompanies}
                                     setLoadingCompanies={setLoadingCompanies}
                                     seeMore={seeMore}
                                     dataStats={dataStats}
                                     componentLoader={componentLoader}
                                     setComponentLoader={setComponentLoader}
                                     currency={currency}
            />
        </div>
    );
}

export default ScreenerTable;
