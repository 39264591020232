import Index from "./Index";
import 'simplebar/dist/simplebar.min.css';
import clsx from "clsx";
import axios from "axios";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";

const MajorIndices = () => {
    const names = {
        "URTH": "MSCI World",
        "^GSPC": "S&P 500",
        "^DJI": "Dow Jones",
        "^NDX": "Nasdaq 100",
        "^STOXX50E": "EuroStoxx 50",
        "^GDAXI": "DAX40",
        "^FTSE": "FTSE",
        "^HSI": "Hang Seng",
        "^N225": "Nikkei",
    }

    const {data} = useQuery({
        queryKey: ['market-overview-index-top-data'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/market-overview-index-top-data/`, Config())
            return res.data
        },
        refetchInterval: 10000,
    })

    return (
        <div className={clsx('indices-container')}>
            {data &&
                Object.entries(names).map(([key, value]) => {
                        return (
                            <Index names={names} indexKey={key} data={data}/>
                        )
                    }
                )}
        </div>
    );
}

export default MajorIndices;