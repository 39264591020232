import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../../Context/ThemeContext";
import {TablePagination} from "@material-ui/core";
import axios from "axios";
import {return_strong_num, return_td_color, sort} from "../../../Utils";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import Config from "../../../../Config";

const SectorTab = () => {

    const [tableData, setTableData] = useState([]);
    const [loadingSector, setSectorLoading] = useState(true)

    const sectorStaticData = {
        "XLB": "Basic Material",
        "XLP": "Communication Defensive",
        "XLC": "Communication Service",
        "XLY": "Consumer Cyclical",
        "XLE": "Energy",
        "XLF": "Financial Services",
        "XLV": "Healthcare",
        "XLI": "Industrials",
        "XLRE": "Real Estate",
        "XLK": "Technology",
        "XLU": "Utilities",
    }

    const [columns, setColumns] = useState([
        {name: "Sector", state: 0, class_name: "market-overview-sector-th"},
        {name: "Symbol", state: 0, class_name: ""},
        {name: "Price", state: 0, class_name: ""},
        {name: "Change %", state: 0, class_name: "item-right-align-th"},
        {name: "Week", state: 0, class_name: "item-right-align-th"},
        {name: "YTD", state: 0, class_name: "item-right-align-th"},
        {name: "1 Month", state: 0, class_name: "item-right-align-th"},
        {name: "1 Year", state: 0, class_name: "item-right-align-th"},
        {name: "5 Year", state: 0, class_name: "item-right-align-th"},
        {name: "Ø P.A.(5years)", state: 0, class_name: "item-right-align-th"},
    ])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-sector-data/`, Config())
            .then(response => {
                let data = []

                response.data.map((item) => {
                    if (item['code'] in sectorStaticData) {
                        data.push({
                            "Sector": sectorStaticData[item['code']],
                            "Symbol": item['code'],
                            "Price": return_strong_num(item['close']),
                            "Change %": return_strong_num(item['change_p']),
                            "Week": return_strong_num(item['week'] * 100),
                            "YTD": return_strong_num(item['ytd'] * 100),
                            "1 Month": return_strong_num(item['one_month'] * 100),
                            "1 Year": return_strong_num(item['one_year'] * 100),
                            "5 Year": return_strong_num(item['five_year'] * 100),
                            "Ø P.A.(5years)": return_strong_num(item['five_average'] * 100)
                        })
                    }
                })

                setTableData(data)
                setSectorLoading(false)
            })
            .catch(err => console.log(err.message))
    }, [])

    return (
        loadingSector ?
            <div style={{width: '100%', height: '100%', display: 'flex'}}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <div className="table-responsive generic-lg-table-container">
                <table className="table generic-lg-table market-overview-sector-table">
                    <thead>
                    <tr>
                        {columns.map((item, index) => {
                            return (
                                <th key={index} className={item.class_name} onClick={() => {
                                    sort(item.name, item.state, setColumns, columns, tableData, setTableData)
                                }}>
                                    {item.name} {item.state ? <BsArrowUp/> : <BsArrowDown/>}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <>
                                <tr key={index}>
                                    <td>{item['Sector']}</td>
                                    <td>{item['Symbol']}</td>
                                    <td>{item['Price']}</td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['Change %'])}}>
                                        {item['Change %']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['Week'])}}>
                                        {item['Week']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['YTD'])}}>
                                        {item['YTD']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['1 Month'])}}>
                                        {item['1 Month']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['1 Year'])}}>
                                        {item['1 Year']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['5 Year'])}}>
                                        {item['5 Year']} %
                                    </td>
                                    <td className={'item-right-align-th'}
                                        style={{fontWeight: 600, color: return_td_color(item['Ø P.A.(5years)'])}}>
                                        {item['Ø P.A.(5years)']} %
                                    </td>
                                </tr>
                                <div style={{marginTop: 3, width: "100%"}}/>
                            </>
                        )
                    })}
                    </tbody>
                </table>
            </div>
    )
}

export default SectorTab;
