import React, {useCallback, useContext, useEffect, useState} from 'react';
import Config from "../../../Config";
import axios from "axios";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {toast} from "react-hot-toast";
import SelectWatchListModal from "./SelectWatchListModal";
import {Modal} from "@material-ui/core";
import {ThemeContext} from "../../../Context/ThemeContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import _debounce from "lodash/debounce";
import clsx from "clsx";
import LoginPaymentBlocker from "../../LoginPaymentBlocker";
import indexComponentBlockerDark from "../../../assets/blockers/index-components-blocker-dark.png";
import indexComponentBlockerLight from "../../../assets/blockers/index-components-blocker-light.png";
import LgSelectableTable from "../../LgSelectableTable";

const get_index_components = async (url, code) => {
    try {
        const response = await axios.post(url, {code}, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const search_index_components = async (code, search) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/search-index-components/`, {
            code,
            search
        }, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function IndexComponentsTable({
                                  selectedIndexData,
                                  options,
                                  defaultColumns,
                                  columns,
                                  handleChange,
                                  setColumns,
                                  return_row,
                                  selectedTicker,
                                  openModal,
                                  handleCloseModal,
                                  selectableTable = false
                              }) {
    const {webTheme} = useContext(ThemeContext);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const queryClient = useQueryClient();

    const [selectedIndexComponents, setSelectedIndexComponents] = useState([]);
    const [filteredIndexComponents, setFilterIndexComponents] = useState([]);
    const [loadingSelectedComponentData, setLoadingSelectedComponentData] = useState(true);

    const url = `${process.env.REACT_APP_API_URL}/finqube-api/get-index-components/?page=1`

    const [componentLoadingStat, setComponentLoadingStat] = useState({
        url: url, length: 0, see_more: false
    })

    useEffect(() => {
        console.log("componentLoadingStat => ", componentLoadingStat)
    }, [componentLoadingStat]);

    const {data: indexComponentsReturn, isLoading: indexComponentReturnIsLoading, refetch} = useQuery({
        queryKey: [`get_index_components`, componentLoadingStat.url, selectedIndexData.indices['real-time'].code],
        queryFn: () => get_index_components(componentLoadingStat.url, selectedIndexData.indices['real-time'].code),
        staleTime: 5 * 60000,
        enabled: false
    })

    useEffect(() => {
        if (!indexComponentReturnIsLoading && indexComponentsReturn) {
            load_components(indexComponentsReturn)
        }
    }, [indexComponentsReturn, indexComponentReturnIsLoading]);

    const load_components = (data) => {
        const indexComponents = return_row(data.results)

        setFilterIndexComponents([...selectedIndexComponents, ...indexComponents])
        setSelectedIndexComponents([...selectedIndexComponents, ...indexComponents])

        setComponentLoadingStat({
            url: data.next,
            length: data.count,
            see_more: data.next !== null
        })
        setLoadingSelectedComponentData(false)
    }


    useEffect(() => {
        if (isAuthenticated) {
            void refetch()
        }
    }, [selectedIndexData, isAuthenticated])

    const seeMore = () => {
        void refetch()
    }

    const [search, setSearch] = useState('')

    const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

    const onChange = (e) => {
        if (e.target.value === '') {
            setTimeout(() => {
                setSearch('')
                setFilterIndexComponents(selectedIndexComponents)
            }, 500);
        } else {
            setSearch(e.target.value)
            debounceFn(e.target.value)
            setLoadingSelectedComponentData(true)
        }
    }

    const {
        data: searchIndexComponentsReturn,
        isLoading: searchIndexComponentsIsLoading,
        refetch: searchIndexComponentsRefetch
    } = useQuery({
        queryKey: [`search-index-components`, selectedIndexData.indices['real-time'].code, search],
        queryFn: () => search_index_components(selectedIndexData.indices['real-time'].code, search),
        staleTime: 5 * 60000,
        enabled: false
    })

    function handleDebounceFn() {
        void searchIndexComponentsRefetch()
    }

    useEffect(() => {
        if (!searchIndexComponentsIsLoading && searchIndexComponentsReturn) {
            const indexComponents = return_row(searchIndexComponentsReturn)

            setFilterIndexComponents(indexComponents)
            setLoadingSelectedComponentData(false)
        }
    }, [searchIndexComponentsReturn, searchIndexComponentsIsLoading])

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                setSelectedIndexComponents(selectedIndexComponents.map(component => {
                        if (component.name === selectedTicker.company_id) {
                            return {
                                ...component,
                                rowItems: [
                                    {
                                        ...component.rowItems[0],
                                        content: response.data['ticker_exists'] ?
                                            <AiFillStar className={'watchlist-star'}/> :
                                            <AiOutlineStar className={'watchlist-star'}/>
                                    },
                                    ...component.rowItems.slice(1, component.rowItems.length)
                                ]
                            }
                        }
                        return component
                    })
                )

                setFilterIndexComponents(selectedIndexComponents.map(component => {
                        if (component.name === selectedTicker.company_id) {
                            return {
                                ...component,
                                rowItems: [
                                    {
                                        ...component.rowItems[0],
                                        content: response.data['ticker_exists'] ?
                                            <AiFillStar className={'watchlist-star'}/> :
                                            <AiOutlineStar className={'watchlist-star'}/>
                                    },
                                    ...component.rowItems.slice(1, component.rowItems.length)
                                ]
                            }
                        }
                        return component
                    })
                )

                const queries = queryClient.getQueryCache().getAll().filter(query => query.queryKey.includes(selectedIndexData.indices['real-time'].code));

                queries.map((query) => {
                    const previousData = queryClient.getQueryData(query.queryKey);

                    if (previousData) {
                        queryClient.setQueryData(query.queryKey, {
                            ...previousData,
                            results: previousData.results.map((item) => {
                                if (item.company_id === selectedTicker.company_id) {
                                    item.watchlistticker_exists = response.data['ticker_exists']
                                }
                                return item
                            })
                        });
                    }
                })

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseModal()
            })
            .catch(err => console.log(err.message))
    }

    return (
        <div className={clsx('entire-body-lg')} style={{margin: '10px 0'}}>


            {!isAuthenticated &&
                <LoginPaymentBlocker condition={'You must be logged in to use this feature'} login={true}/>
            }

            {!isAuthenticated &&
                <img src={webTheme === 'dark' ? indexComponentBlockerDark : indexComponentBlockerLight}
                     className={'blocker-img'}/>
            }

            {isAuthenticated &&
                <div
                    style={{position: 'relative', width: '100%', height: '100%'}}>

                    {selectableTable ?
                        <LgSelectableTable
                            columns={columns}
                            setColumns={setColumns}
                            tableData={filteredIndexComponents}
                            setTableData={setFilterIndexComponents}

                            inputField={true}
                            inputOnChange={onChange}

                            selectableField={true}
                            options={options}
                            defaultColumns={defaultColumns}
                            handleChange={handleChange}
                        />
                        :
                        <LgSelectableTable
                            columns={columns}
                            setColumns={setColumns}
                            tableData={filteredIndexComponents}
                            setTableData={setFilterIndexComponents}

                            inputField={true}
                            inputOnChange={onChange}
                        />
                    }


                    <div style={{display: 'flex', width: "100%"}}>
                        <p className={'show-results-number'}>Showing
                            results {filteredIndexComponents.length} of {componentLoadingStat.length}</p>
                        {search === '' && !loadingSelectedComponentData && componentLoadingStat.see_more &&
                            <p className={'see-more-option'}
                               onClick={() => {
                                   setLoadingSelectedComponentData(true)
                                   seeMore()
                               }}
                            >See More
                            </p>
                        }
                    </div>

                    {search !== '' && !loadingSelectedComponentData && filteredIndexComponents.length === 0 &&
                        <p className={'index-no-component-found'}>No Components Found</p>}

                    {loadingSelectedComponentData && <div style={{display: 'flex', width: '100%', height: '100%'}}>
                        <div className="spinner-border text-warning" role="status" style={{margin: "auto"}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    }
                </div>
            }


            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>
        </div>
    );
}

export default IndexComponentsTable;