import clsx from "clsx";
import axios from "axios";
import {useState} from 'react';
import Config from "../../Config";
import {getModalStyle} from "../Utils";
import {CgClose} from "react-icons/cg";
import {useSelector} from "react-redux";

function WatchlistModal({setUserWatchList, handleCloseModal}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const [modalStyle] = useState(getModalStyle);

    const [watchListName, setWatchListName] = useState("")

    const saveWatchList = () => {
        if (isAuthenticated) {
            const config = Config()
            axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/add-watchlist/`, {name: watchListName}, config)
                .then(response => {
                    setUserWatchList((prev) => [...prev, response.data])
                })
                .catch(err => console.log(err.message))
            handleCloseModal()
        }
    }

    return (
        <div style={modalStyle}
             className={clsx('watchlist-modal-body')}>
            <div style={{display: 'flex'}}>
                <p className={'watchlist-modal-title'}>Create New WatchList</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>
            <input
                value={watchListName}
                onChange={(e) => {
                    setWatchListName(e.target.value)
                }}
                className={'form-control watchlist-input-field'}
                placeholder={'Enter Watchlist title'}
            />
            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn save-watchlist-btn'} onClick={saveWatchList} style={{marginLeft: "auto"}}>Save
                </button>
            </div>
        </div>
    );
}

export default WatchlistModal;