import LandingPageNavbar from "../Container/LandingPage/landing-page-navbar";
import React from "react";
import logo from "../assets/finqube-navbar-logo.png";
import {useNavigate} from "react-router-dom";

function CheckInbox({}) {
    const navigate = useNavigate()

    return (
        <div className={'landing-page-container'}>
            <div className={'ornament-2'}/>
            <div className={'landing-page'}>
                <div className="container">
                    <LandingPageNavbar/>
                    <div className={'check-inbox-container'}>
                        <p className={'check-inbox-thankyou-heading'}>Thanks for Joining</p>

                        <div style={{display: "flex", width: "100%"}}>
                            <img onClick={() => {
                                navigate('/market-overview/')
                            }} src={logo} className={'check-inbox-logo'}/>
                        </div>

                        <hr />

                        <p className={'check-inbox-container-heading'}>Check your inbox</p>
                        <br/>
                        <p className={'check-inbox-container-text'}>We have sent an email to your email with a link to activate
                            your account.</p>
                        <br/>
                        <p className={'check-inbox-container-text'}>Didn't received an email from us? Please check your spam
                            folder and if it's not there, please
                            contact <span style={{fontWeight: 700, color: "#1BA2DA"}}>support@finqube.de</span></p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckInbox;
