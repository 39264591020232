import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../Context/ThemeContext";
import clsx from "clsx";
import FinancialsControls from "./FinancialsControls";
import FinancialsTable from "./FinancialsTable";
import {numberWithCommas, return_num, return_strong_num, return_value_in_million} from "../../Utils";
import axios from "axios";
import Config from "../../../Config";

const BalanceSheetTab = ({code, exchange}) => {
    const [loading, setLoading] = useState(true)

    const [groupedData, setGroupedData] = useState(null)

    const [active, setActive] = useState('annual')

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`, Config())
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-balance-sheet/${code}/${exchange}/${active}`, Config())
            .then(response => {
                group_balance_sheets(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [active, code, exchange])

    const group_balance_sheets = (_data) => {
        let data = {
            dates: [],
            content: {
                current_assets: {
                    name: 'Current Assets',
                    data: {
                        'Cash': [],
                        'Short Term Investments': [],
                        'Cash & Short Term Investments': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                        'Net Receivable': [],
                        'Inventory': [],
                        'Other Current Assets': [],
                        'Total Current Assets': [],
                        ...(active === 'annual' ? {'% Growth Yearly ': []} : {'% Growth Quarterly ': []}),
                    }
                },
                non_current_assets: {
                    name: 'Non-Current Assets',
                    data: {
                        'Property, Plant & Equipment': [],
                        'Long Term Investment': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                        'Goodwill': [],
                        'Total other Long Term Assets': [],
                        'Total Non-Current Assets': [],
                        ...(active === 'annual' ? {'% Growth Yearly  ': []} : {'% Growth Quarterly  ': []}),
                        'Total Assets': [],
                        ...(active === 'annual' ? {'% Growth Yearly   ': []} : {'% Growth Quarterly   ': []}),
                    }
                },
                current_liabilities: {
                    name: "Current Liabilities",
                    data: {
                        'Accounts Payable': [],
                        'Other Current Liabilities': [],
                        'Short Term Debt': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                        'Total Current Liabilities': [],
                        ...(active === 'annual' ? {'% Growth Yearly    ': []} : {'% Growth Quarterly    ': []}),
                    }
                },
                non_current_liabilities: {
                    name: "Non Current Liabilities",
                    data: {
                        'Long Term Debt': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                        'Capital Lease': [],
                        'Other Liabilities': [],
                        'Total Non Current Liabilities': [],
                        ...(active === 'annual' ? {'% Growth Yearly     ': []} : {'% Growth Quarterly     ': []}),
                        'Total Liabilities': [],
                        ...(active === 'annual' ? {'% Growth Yearly      ': []} : {'% Growth Quarterly      ': []}),
                    }
                },
                equity: {
                    name: 'Equity',
                    data: {
                        'Common Stock': [],
                        'Retained Earnings': [],
                        'Other Stockholder Equity': [],
                        'Total Equity': [],
                        ...(active === 'annual' ? {'% Growth Yearly       ': []} : {'% Growth Quarterly       ': []}),
                        'Total Liabilities & Equity': [],
                        ...(active === 'annual' ? {'% Growth Yearly        ': []} : {'% Growth Quarterly        ': []}),
                    }
                },
                cash_debt: {
                    name: "Cash & Debt",
                    data: {
                        'Total Cash': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                        'Total Debt': [],
                        ...(active === 'annual' ? {'% Growth Yearly ': []} : {'% Growth Quarterly ': []}),
                        'Net Debt': [],
                        ...(active === 'annual' ? {'% Growth Yearly  ': []} : {'% Growth Quarterly  ': []}),
                    }
                },
                supplemental_items: {
                    name: "Supplemental Items",
                    data: {
                        'Net Tangible Assets': [],
                        'Book Value per Share': [],
                        'Excess Cash': [],
                        'Invested Capital': [],
                        'Capital Employed': [],
                        'Working Capital': []
                    }
                },
                shares_outstanding: {
                    name: "Shares Outstanding",
                    data: {
                        'Shares Outstanding': [],
                        ...(active === 'annual' ? {'% Growth Yearly': []} : {'% Growth Quarterly': []}),
                    }
                }
            }
        }

        if (active === 'annual' || active === 'quarter') {

            _data.map((item) => {
                data['dates'].push(item.date)

                // Current Assets
                data['content']['current_assets']['data']['Cash'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['cash']))))
                data['content']['current_assets']['data']['Short Term Investments'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['shortTermInvestments']))))
                data['content']['current_assets']['data']['Cash & Short Term Investments'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['cashAndShortTermInvestments']))))
                if (active === 'annual') {
                    data['content']['current_assets']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['cashAndShortTermInvestments'] * 100))
                } else {
                    data['content']['current_assets']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['cashAndShortTermInvestments'] * 100))
                }
                data['content']['current_assets']['data']['Net Receivable'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['netReceivables']))))
                data['content']['current_assets']['data']['Inventory'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['inventory']))))
                data['content']['current_assets']['data']['Other Current Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['otherCurrentAssets']))))
                data['content']['current_assets']['data']['Total Current Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalCurrentAssets']))))
                if (active === 'annual') {
                    data['content']['current_assets']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['totalCurrentAssets'] * 100))
                } else {
                    data['content']['current_assets']['data']['% Growth Quarterly '].push(return_strong_num(item['growth']['historical_data']['totalCurrentAssets'] * 100))
                }

                // Non current Assets
                data['content']['non_current_assets']['data']['Property, Plant & Equipment'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['propertyPlantEquipment']))))
                data['content']['non_current_assets']['data']['Long Term Investment'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['longTermInvestments']))))
                if (active === 'annual') {
                    data['content']['non_current_assets']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['longTermInvestments'] * 100))
                } else {
                    data['content']['non_current_assets']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['longTermInvestments'] * 100))
                }
                data['content']['non_current_assets']['data']['Goodwill'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['goodWill']))))
                data['content']['non_current_assets']['data']['Total other Long Term Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['otherAssets']))))
                data['content']['non_current_assets']['data']['Total Non-Current Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['nonCurrentAssetsTotal']))))
                if (active === 'annual') {
                    data['content']['non_current_assets']['data']['% Growth Yearly  '].push(return_strong_num(item['growth']['historical_data']['nonCurrentAssetsTotal'] * 100))
                } else {
                    data['content']['non_current_assets']['data']['% Growth Quarterly  '].push(return_strong_num(item['growth']['historical_data']['nonCurrentAssetsTotal'] * 100))
                }
                data['content']['non_current_assets']['data']['Total Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalAssets']))))
                if (active === 'annual') {
                    data['content']['non_current_assets']['data']['% Growth Yearly   '].push(return_strong_num(item['growth']['historical_data']['totalAssets'] * 100))
                } else {
                    data['content']['non_current_assets']['data']['% Growth Quarterly   '].push(return_strong_num(item['growth']['historical_data']['totalAssets'] * 100))
                }

                // Current Liabilities
                data['content']['current_liabilities']['data'][`Accounts Payable`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['accountsPayable']))))
                data['content']['current_liabilities']['data'][`Other Current Liabilities`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['otherCurrentLiab']))))
                data['content']['current_liabilities']['data'][`Short Term Debt`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['shortLongTermDebt']))))
                if (active === 'annual') {
                    data['content']['current_liabilities']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['shortLongTermDebt'] * 100))
                } else {
                    data['content']['current_liabilities']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['shortLongTermDebt'] * 100))
                }
                data['content']['current_liabilities']['data'][`Total Current Liabilities`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalCurrentLiabilities']))))
                if (active === 'annual') {
                    data['content']['current_liabilities']['data']['% Growth Yearly    '].push(return_strong_num(item['growth']['historical_data']['totalCurrentLiabilities'] * 100))
                } else {
                    data['content']['current_liabilities']['data']['% Growth Quarterly    '].push(return_strong_num(item['growth']['historical_data']['totalCurrentLiabilities'] * 100))
                }

                // Non Current Liabilities
                data['content']['non_current_liabilities']['data'][`Long Term Debt`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['longTermDebt']))))
                if (active === 'annual') {
                    data['content']['non_current_liabilities']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['longTermDebt'] * 100))
                } else {
                    data['content']['non_current_liabilities']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['longTermDebt'] * 100))
                }
                data['content']['non_current_liabilities']['data']['Capital Lease'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['capitalLeaseObligations']))))
                data['content']['non_current_liabilities']['data']['Other Liabilities'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['otherLiab']))))
                data['content']['non_current_liabilities']['data'][`Total Non Current Liabilities`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['nonCurrentLiabilitiesTotal']))))
                if (active === 'annual') {
                    data['content']['non_current_liabilities']['data']['% Growth Yearly     '].push(return_strong_num(item['growth']['historical_data']['nonCurrentLiabilitiesTotal'] * 100))
                } else {
                    data['content']['non_current_liabilities']['data']['% Growth Quarterly     '].push(return_strong_num(item['growth']['historical_data']['nonCurrentLiabilitiesTotal'] * 100))
                }
                data['content']['non_current_liabilities']['data'][`Total Liabilities`].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalLiab']))))
                if (active === 'annual') {
                    data['content']['non_current_liabilities']['data']['% Growth Yearly      '].push(return_strong_num(item['growth']['historical_data']['totalLiab'] * 100))
                } else {
                    data['content']['non_current_liabilities']['data']['% Growth Quarterly      '].push(return_strong_num(item['growth']['historical_data']['totalLiab'] * 100))
                }

                // Equity
                data['content']['equity']['data']['Common Stock'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['commonStock']))))
                data['content']['equity']['data']['Retained Earnings'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['retainedEarnings']))))
                data['content']['equity']['data']['Other Stockholder Equity'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['otherStockholderEquity']))))
                data['content']['equity']['data']['Total Equity'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalStockholderEquity']))))
                if (active === 'annual') {
                    data['content']['equity']['data']['% Growth Yearly       '].push(return_strong_num(item['growth']['historical_data']['totalStockholderEquity'] * 100))
                } else {
                    data['content']['equity']['data']['% Growth Quarterly       '].push(return_strong_num(item['growth']['historical_data']['totalStockholderEquity'] * 100))
                }
                data['content']['equity']['data']['Total Liabilities & Equity'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['liabilitiesAndStockholdersEquity']))))
                if (active === 'annual') {
                    data['content']['equity']['data']['% Growth Yearly        '].push(return_strong_num(item['growth']['historical_data']['liabilitiesAndStockholdersEquity'] * 100))
                } else {
                    data['content']['equity']['data']['% Growth Quarterly        '].push(return_strong_num(item['growth']['historical_data']['liabilitiesAndStockholdersEquity'] * 100))
                }

                // cash_debt
                data['content']['cash_debt']['data']['Total Cash'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalCash']))))
                if (active === 'annual') {
                    data['content']['cash_debt']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['totalCash'] * 100))
                } else {
                    data['content']['cash_debt']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['totalCash'] * 100))
                }
                data['content']['cash_debt']['data']['Total Debt'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['totalDebt']))))
                if (active === 'annual') {
                    data['content']['cash_debt']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['totalDebt'] * 100))
                } else {
                    data['content']['cash_debt']['data']['% Growth Quarterly '].push(return_strong_num(item['growth']['historical_data']['totalDebt'] * 100))
                }
                data['content']['cash_debt']['data']['Net Debt'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['netDebt']))))
                if (active === 'annual') {
                    data['content']['cash_debt']['data']['% Growth Yearly  '].push(return_strong_num(item['growth']['historical_data']['netDebt'] * 100))
                } else {
                    data['content']['cash_debt']['data']['% Growth Quarterly  '].push(return_strong_num(item['growth']['historical_data']['netDebt'] * 100))
                }

                // Supplemental Items
                data['content']['supplemental_items']['data']['Net Tangible Assets'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['netTangibleAssets']))))
                data['content']['supplemental_items']['data']['Book Value per Share'].push(return_strong_num(item['balance_sheet']['bookValuePerShare']))
                data['content']['supplemental_items']['data']['Excess Cash'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['excessCash']))))
                data['content']['supplemental_items']['data']['Invested Capital'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['investedCapital']))))
                data['content']['supplemental_items']['data']['Capital Employed'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['capitalEmployed']))))
                data['content']['supplemental_items']['data']['Working Capital'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['workingCapital']))))

                // shares outstanding
                data['content']['shares_outstanding']['data']['Shares Outstanding'].push(numberWithCommas(return_value_in_million(return_strong_num(item['balance_sheet']['commonStockSharesOutstanding']))))
                if (active === 'annual') {
                    data['content']['shares_outstanding']['data']['% Growth Yearly'].push(return_strong_num(item['growth']['historical_data']['commonStockSharesOutstanding'] * 100))
                } else {
                    data['content']['shares_outstanding']['data']['% Growth Quarterly'].push(return_strong_num(item['growth']['historical_data']['commonStockSharesOutstanding'] * 100))
                }
            })
        }

        setGroupedData(data)
    }

    const handleActive = (type) => {
        setActive(type)
        setLoading(true)
    }

    return (
        <>
            <FinancialsControls
                options={[{label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}]}
                handleActive={handleActive} active={active} balance_sheet={true} meta={meta}/>

            {
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    groupedData &&
                    <FinancialsTable groupedData={groupedData}
                                     highlightHeadings={["Long Term Debt", "Short Term Debt", "Long Term Investment", "Total Cash", "Total Debt", "Net Debt", "Shares Outstanding"]}/>
            }
        </>
    );
}

export default BalanceSheetTab;