import React, {useState} from 'react';
import {a11yProps, TabPanel} from "../../Utils";
import EarningTabs from "../EarningTabs";
import DividendTab from "../DividendTab";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

function Calender({code, exchange}) {
    return (
        <DividendTab code={code} exchange={exchange}/>
    );
}

export default Calender;