import React, {useContext, useEffect, useState} from 'react';
import {AiOutlineCloseCircle, AiOutlinePlusCircle} from "react-icons/ai";
import {Modal} from "@material-ui/core";
import {
    CircularProgressWithLabel,
    divide,
    numberWithCommas, return_bg_grade_color, return_grade_background_color,
    return_grade_color,
    return_strong_num,
    return_strong_string,
    return_value_in_million, subtract
} from "../Utils";
import building from "../../assets/default-building.png";
import clsx from "clsx";
import {useSelector} from "react-redux";
import LoginPaymentBlocker from "../LoginPaymentBlocker";
import compareISqoreDark from "../../assets/blockers/compare-isqore-dark.png";
import compareISqoreLight from "../../assets/blockers/compare-isqore-light.png";
import {ThemeContext} from "../../Context/ThemeContext";
import CompareStocksSearch from "../CompareStocks/CompareStocksSearch";

const CompareISqore = () => {
    const user = useSelector(state => state.actions.user);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const {webTheme} = useContext(ThemeContext);

    const [openSideBar, setOpSideBar] = useState(true)

    const [sideBarOptions, setSideBarOptions] = useState([
        {id: 0, name: "Performance", open: false, options: ['YTD', '1 Month', '1 Year']},
        {id: 1, name: 'Financial', open: false, options: ['Revenue (ttm)', 'Net Income (ttm)', 'Free Cash Flow (ttm)']},
        {
            id: 2,
            name: 'Value',
            open: false,
            options: [
                'P/Earnings (fwd)',
                'P/Earnings (cur)',
                'P/Earnings (ttm)',
                'P/Earnings (year)',

                'P/Sales (cur)',
                'P/Sales (ttm)',
                'P/Sales (year)',

                'P/Book (cur)',
                'P/Book (year)',

                'P/FCF (cur)',
                'P/FCF (ttm)',
                'P/FCF (year)',

                'PEG Ratio (cur)',
                'PEG Ratio (ttm)',
                'PEG Ratio (year)',

                'EV/Revenue (cur)',
                'EV/Revenue (ttm)',
                'EV/Revenue (year)',

                'EV/EBIT (cur)',
                'EV/EBIT (ttm)',
                'EV/EBIT (year)',

                'EV/EBITDA (cur)',
                'EV/EBITDA (ttm)',
                'EV/EBITDA (year)',

                'Earnings Yield (cur)',
                'Earnings Yield (ttm)',
                'Earnings Yield (year)'
            ]
        },
        {
            id: 3,
            name: 'Profitability',
            open: false,
            options: [
                'Return on Assets(ttm)',
                'Return on Assets(year)',

                'Return on Equity(ttm)',
                'Return on Equity(year)',

                'Return on Capital(ttm)',
                'Return on Capital(year)',

                'Return on Cap. Empl. (ttm)',
                'Return on Cap. Empl. (year)',

                'Return on Inv.Cap.(ttm)',
                'Return on Inv.Cap.(year)',

                'Operating Margin(ttm)',
                'Operating Margin(year)',

                'Gross Margin(ttm)',
                'Gross Margin(year)',

                'Net Margin(ttm)',
                'Net Margin(year)',

                'EBIT Margin(ttm)',
                'EBIT Margin(year)',

                'EBITDA Margin(ttm)',
                'EBITDA Margin(year)',

                'FCF Margin(ttm)',
                'FCF Margin(year)',

                'FCF Yield (ttm)',
                'FCF Yield (year)',

                'Asset Turnover(ttm)',
                'Asset Turnover(year)',
            ]
        },
        {
            id: 4,
            name: 'Growth',
            open: false,
            options: [
                'Revenue Growth (fwd)',
                'Revenue Growth (ttm)',
                'Revenue Growth (yoy)',
                'Revenue Growth (year)',

                'Earnings Growth (fwd)',
                'Earnings Growth (ttm)',
                'Earnings Growth (yoy)',
                'Earnings Growth (year)',

                'EPS Growth (fwd)',
                'EPS Growth (ttm)',
                'EPS Growth (yoy)',
                'EPS Growth (year)',

                'EBIT Growth (ttm)',
                'EBIT Growth (yoy)',
                'EBIT Growth (year)',

                'EBITDA Growth (ttm)',
                'EBITDA Growth (yoy)',
                'EBITDA Growth (year)',

                'FCF Growth (ttm)',
                'FCF Growth (yoy)',
                'FCF Growth (year)',

                'High Growth Ratio (ttm)',
                'High Growth Ratio (year)'
            ]
        },
        {
            id: 5,
            name: 'Health',
            open: false,
            options: [
                'Cash/Ratio',
                'Cash/Ratio (Year)',
                'Debt/Equity',
                'Debt/Equity (Year)',
                'NetDebt/Equity',
                'NetDebt/Equity (Year)',
                'Equity/Assets',
                'Equity/Assets (Year)',
                'Debt/Asset',
                'Debt/Asset (Year)',
                'NetDebt/Asset',
                'NetDebt/Asset (Year)',
                'Debt/EBIT',
                'Debt/EBIT (Year)',
                'NetDebt/EBIT',
                'NetDebt/EBIT (Year)',
                'Debt/EBITDA',
                'Debt/EBITDA (Year)',
                'NetDebt/EBITDA',
                'NetDebt/EBITDA (Year)',
                'Current Ratio',
                'Current Ratio (Year)',
                'Quick Ratio',
                'Quick Ratio (Year)',
                'Altman Z-Score',
                'Altman Z-Score (Year)',
                'Piotroski F-Score',
                'Piotroski F-Score (Year)'
            ]
        },
        {
            id: 6,
            name: 'Quality',
            open: false,
            options: [
                'Revenue Growth (fwd)',
                'Revenue Growth (ttm)',
                'Revenue Growth (yoy)',
                'Revenue Growth (year)',

                'Earnings Growth (fwd)',
                'Earnings Growth (ttm)',
                'Earnings Growth (yoy)',
                'Earnings Growth (year)',

                'FCF Growth (ttm)',
                'FCF Growth (yoy)',
                'FCF Growth (year)',

                'Return on Equity (ttm)',
                'Return on Equity (year)',

                'Return on Cap. Empl. (ttm)',
                'Return on Cap. Empl. (year)',

                'Gross Margin (ttm)',
                'Gross Margin (year)',

                'Net Margin (ttm)',
                'Net Margin (year)',

                'FCF Margin (ttm)',
                'FCF Margin (year)',

                'Debt/Equity',
                'Debt/Equity (year)',

                'NetDebt/Equity',
                'NetDebt/Equity (year)',

                'Cash/Ratio (quarter)',
                'Cash/Ratio (Year)',

                'Debt/Asset (quarter)',
                'Debt/Asset (Year)',

                'NetDebt/Asset (quarter)',
                'NetDebt/Asset (Year)',

                'Debt/EBIT (quarter)',
                'Debt/EBIT (Year)',

                'NetDebt/EBIT (quarter)',
                'NetDebt/EBIT (Year)',
            ]
        },
    ])

    const [compareData, setCompareData] = useState([])

    const open_accordion = (id) => {
        setSideBarOptions(sideBarOptions.map(item => {
            if (item.id === id) {
                item.open = !item.open
            }
            return item
        }))
    }

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const return_compare_value_div = (value, grade, per = false) => {
        return (
            <div className={'isqore-sidebar-text isqore-sidebar-value'}>
                {per ?
                    <p>{value} %</p>
                    :
                    <p>{value}</p>
                }
                <p className={'grade_p'}
                   style={{
                       textAlign: "center",
                       border: `1px solid ${return_grade_color(grade)}`,
                       color: return_grade_color(grade),
                       backgroundColor: return_bg_grade_color(grade)
                }}>
                    {grade}</p>
            </div>
        )
    }


    return (
        <>
            <div className={'entire-body-lg'}>
                {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                    <LoginPaymentBlocker condition={'You must be paid subscriber to use  this feature'}
                                         lg={true} login={true} subscription={true}/>
                }

                {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                    <img src={webTheme === 'dark' ? compareISqoreDark : compareISqoreLight}
                         className={'blocker-img'}/>
                }

                {(isAuthenticated && user.subscription.package_name !== 'Free') &&
                    <div style={{position: 'relative', width: '100%', height: '100%'}}>
                        <div className={'compare-isqore-container'}>
                            {openSideBar &&
                                <div
                                    className={'iSqore-fields-container iSqore-fields-container-margin'}>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Company</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Symbol</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Exchange</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Sector</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Industry</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Country</p>
                                    </div>
                                    <div className={'isqore-sidebar-text'}>
                                        <p>Market cap (mln)</p>
                                    </div>

                                    {/*<div className={'isqore-sidebar-text'}>*/}
                                    {/*    <p>iSqore</p>*/}
                                    {/*</div>*/}

                                    <div className="accordion" id="accordionExample">
                                        {sideBarOptions.map((item, index) => {
                                            return (
                                                <div key={index} className="accordion-item">
                                                    <p className="accordion-header" id={`heading${item.id}`}
                                                       onClick={() => {
                                                           open_accordion(item.id)
                                                       }}>
                                                        <button className="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${item.id}`}
                                                                aria-expanded="false"
                                                                aria-controls={`collapse${item.id}`}>
                                                            <p style={{fontSize: 12}}>{item.name}</p>
                                                        </button>
                                                    </p>
                                                    <div id={`collapse${item.id}`}
                                                         className="accordion-collapse collapse"
                                                         aria-labelledby={`heading${item.id}`}>
                                                        <div className="accordion-body">
                                                            {item.options.map((point, index) => {
                                                                return (
                                                                    <div key={index} className={'isqore-sidebar-text isqore-sidebar-border'}>
                                                                        <p>{point}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            }
                            {compareData.map((item, index) => {
                                return (
                                    <div key={index} className={'company-isqore-container'}>
                                        <AiOutlineCloseCircle
                                            onClick={() => {
                                                setCompareData(compareData.filter(company => company.index !== item.index))
                                            }}
                                            style={{color: '#676767', margin: '0 auto', cursor: 'pointer'}}/>
                                        <div style={{display: 'flex'}}>
                                            <img className={'company-company-logo'}
                                                 src={item.general.LogoURL && item.general.LogoURL !== "" ? item.general.LogoURL : building}
                                                 onError={({currentTarget}) => {
                                                     currentTarget.onerror = null;
                                                     currentTarget.src = building;
                                                 }}
                                            />
                                            <CircularProgressWithLabel
                                                value={item['iSqore']['iSqore']}
                                                grade={item.iSqore.iSqoreGrade}
                                                compare_stocks={true}
                                            />
                                        </div>

                                        <div className={'company-text-compare-area'}>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['name']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['code']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['exchange']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['general']['Sector']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['general']['Industry']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['stock_exchange']['country']}</p>
                                            </div>
                                            <div className={'isqore-sidebar-text'}>
                                                <p>{item['stock_exchange']["currency"]} {numberWithCommas(return_value_in_million(item['market_cap']['m_cap_trading']))}</p>
                                            </div>

                                            {/*<div className={'isqore-sidebar-text isqore-sidebar-value'}>*/}
                                            {/*    <p style={{marginRight: 'auto', fontWeight: 600}}>iSqore</p>*/}
                                            {/*    <div className={'isqore-lg-table-td-div'}>*/}
                                            {/*        <p>{Math.min(return_strong_num(item['iSqore']['iSqore'] * 100), 100)}</p>*/}
                                            {/*        <p className={'isqore-td-separator'}>|</p>*/}
                                            {/*        <p className={'grade_p'}*/}
                                            {/*           style={{*/}
                                            {/*               border: `1px solid ${return_grade_color(item.iSqore.iSqoreGrade)}`,*/}
                                            {/*               color: return_grade_color(item.iSqore.iSqoreGrade),*/}
                                            {/*               backgroundColor: return_bg_grade_color(item.iSqore.iSqoreGrade)*/}
                                            {/*           }}>*/}
                                            {/*            {item.iSqore.iSqoreGrade}</p>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}


                                            <div className={'isqore-sidebar-text'}>
                                                <p>Performances</p>
                                            </div>
                                            {sideBarOptions.filter(item => item.id === 0)[0].open &&
                                                <>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{return_strong_num(item['performances']['ytd'] * 100)} %</p>
                                                    </div>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{return_strong_num(item['performances']['one_month'] * 100)} %</p>
                                                    </div>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{return_strong_num(item['performances']['one_year'] * 100)} %</p>
                                                    </div>
                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p>Financial</p>
                                            </div>
                                            {sideBarOptions.filter(item => item.id === 1)[0].open &&
                                                <>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{item['cash_flow']["currency_symbol"]} {numberWithCommas(return_value_in_million(item['calculation-quarterly-ttm']['historical_data']['totalRevenue']))}</p>
                                                    </div>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{item['cash_flow']["currency_symbol"]} {numberWithCommas(return_value_in_million(item['calculation-quarterly-ttm']['historical_data']['netIncome_income_statement']))}</p>
                                                    </div>
                                                    <div className={'isqore-sidebar-text'}>
                                                        <p>{item['cash_flow']["currency_symbol"]} {numberWithCommas(return_value_in_million(item['calculation-quarterly-ttm']['historical_data']['freeCashFlow']))}</p>
                                                    </div>
                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p style={{marginRight: 'auto', fontWeight: 600}}>Value</p>
                                                <div className={'isqore-lg-table-td-div'}>
                                                    <p>{Math.min(return_strong_num(item['iSqore']['value'] * 100), 100)
                                                    }</p>
                                                    <p className={'isqore-td-separator'}>|</p>
                                                    <p className={'grade_p'}
                                                       style={{
                                                           border: `1px solid ${return_grade_color(item.iSqore.valueGrade)}`,
                                                           color: return_grade_color(item.iSqore.valueGrade),
                                                           backgroundColor: return_bg_grade_color(item.iSqore.valueGrade)
                                                       }}>
                                                        {item.iSqore.valueGrade}</p>
                                                </div>
                                            </div>

                                            {sideBarOptions.filter(item => item.id === 2)[0].open &&
                                                <>
                                                    {return_compare_value_div(
                                                        return_strong_num(item['valuation']['ForwardPE']),
                                                        item['iSqore']['priceEarningsForwardGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['priceEarnings']),
                                                        item['iSqore']['priceEarningsCurrentGrade']
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['priceEarnings']),
                                                        item['iSqore']['priceEarningsTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['priceEarnings']),
                                                        item['iSqore']['priceEarningsYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['priceSales']),
                                                        item['iSqore']['priceSalesCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['priceSales']),
                                                        item['iSqore']['priceSalesTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['priceSales']),
                                                        item['iSqore']['priceSalesYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['priceBook']),
                                                        item['iSqore']['priceBookCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['priceBook']),
                                                        item['iSqore']['priceBookYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['priceFreeCashFlow']),
                                                        item['iSqore']['priceFreeCashFlowCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['priceFreeCashFlow']),
                                                        item['iSqore']['priceFreeCashFlowTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['priceFreeCashFlow']),
                                                        item['iSqore']['priceFreeCashFlowYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['pegRatio']),
                                                        item['iSqore']['pegRatioCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['pegRatio']),
                                                        item['iSqore']['pegRatioTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['pegRatio']),
                                                        item['iSqore']['pegRatioYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['evRevenue']),
                                                        item['iSqore']['evRevenueCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['evRevenue']),
                                                        item['iSqore']['evRevenueTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['evRevenue']),
                                                        item['iSqore']['evRevenueYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['eveBit']),
                                                        item['iSqore']['eveBitCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['eveBit']),
                                                        item['iSqore']['eveBitTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['eveBit']),
                                                        item['iSqore']['eveBitYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['eveBitda']),
                                                        item['iSqore']['eveBitdaCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['eveBitda']),
                                                        item['iSqore']['eveBitdaTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['eveBitda']),
                                                        item['iSqore']['eveBitdaYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['earningsYield'] * 100),
                                                        item['iSqore']['earningsYieldCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['earningsYield'] * 100),
                                                        item['iSqore']['earningsYieldTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['earningsYield'] * 100),
                                                        item['iSqore']['earningsYieldYearGrade'])}
                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p style={{marginRight: 'auto', fontWeight: 600}}>Profitability</p>

                                                <div className={'isqore-lg-table-td-div'}>
                                                    <p>{Math.min(return_strong_num(item['iSqore']['profitability'] * 100), 100)}</p>
                                                    <p className={'isqore-td-separator'}>|</p>
                                                    <p className={'grade_p'}
                                                       style={{
                                                           border: `1px solid ${return_grade_color(item.iSqore.profitabilityGrade)}`,
                                                           color: return_grade_color(item.iSqore.profitabilityGrade),
                                                           backgroundColor: return_bg_grade_color(item.iSqore.profitabilityGrade)
                                                       }}>
                                                        {item.iSqore.profitabilityGrade}</p>
                                                </div>
                                            </div>

                                            {sideBarOptions.filter(item => item.id === 3)[0].open &&
                                                <>
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnAssets'] * 100),
                                                        item['iSqore']['returnOnAssetsTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnAssets'] * 100),
                                                        item['iSqore']['returnOnAssetsYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnEquity'] * 100),
                                                        item['iSqore']['returnOnEquityTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnEquity'] * 100),
                                                        item['iSqore']['returnOnEquityYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnCapital'] * 100),
                                                        item['iSqore']['returnOnCapitalTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnCapital'] * 100),
                                                        item['iSqore']['returnOnCapitalYearGrade'],
                                                        true)}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnCapitalEmployed'] * 100),
                                                        item['iSqore']['returnOnCapitalEmployedTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnCapitalEmployed'] * 100),
                                                        item['iSqore']['returnOnCapitalEmployedYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnInvestedCapital'] * 100),
                                                        item['iSqore']['returnOnInvestedCapitalTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnInvestedCapital'] * 100),
                                                        item['iSqore']['returnOnInvestedCapitalYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['operatingMargin'] * 100),
                                                        item['iSqore']['operatingMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['operatingMargin'] * 100),
                                                        item['iSqore']['operatingMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['grossMargin'] * 100),
                                                        item['iSqore']['grossMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['grossMargin'] * 100),
                                                        item['iSqore']['grossMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['netMargin'] * 100),
                                                        item['iSqore']['netMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netMargin'] * 100),
                                                        item['iSqore']['netMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['ebitMargin'] * 100),
                                                        item['iSqore']['ebitMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['ebitMargin'] * 100),
                                                        item['iSqore']['ebitMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['ebitdaMargin'] * 100),
                                                        item['iSqore']['ebitdaMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['ebitdaMargin'] * 100),
                                                        item['iSqore']['ebitdaMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['fcfMargin'] * 100),
                                                        item['iSqore']['fcfMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['fcfMargin'] * 100),
                                                        item['iSqore']['fcfMarginYearGrade'],
                                                        true)}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['fcfYield'] * 100),
                                                        item['iSqore']['fcfMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['fcfYield'] * 100),
                                                        item['iSqore']['fcfMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['assetTurnOver']),
                                                        item['iSqore']['assetTurnOverTTMGrade'],
                                                        false)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['assetTurnOver']),
                                                        item['iSqore']['assetTurnOverYearGrade'],
                                                        false)}
                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p style={{marginRight: 'auto', fontWeight: 600}}>Growth</p>

                                                <div className={'isqore-lg-table-td-div'}>
                                                    <p>{Math.min(return_strong_num(item['iSqore']['growth'] * 100), 100)}</p>
                                                    <p className={'isqore-td-separator'}>|</p>
                                                    <p className={'grade_p'}
                                                       style={{
                                                           border: `1px solid ${return_grade_color(item.iSqore.growthGrade)}`,
                                                           color: return_grade_color(item.iSqore.growthGrade),
                                                           backgroundColor: return_bg_grade_color(item.iSqore.growthGrade)
                                                       }}>
                                                        {item.iSqore.growthGrade}</p>
                                                </div>
                                            </div>

                                            {sideBarOptions.filter(item => item.id === 4)[0].open &&
                                                <>
                                                    {return_compare_value_div(
                                                        return_strong_num(item['earning-trend']['revenueEstimateGrowth'] * 100),
                                                        item['iSqore']['totalRevenueGrowthEstimateGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthYearGrade'],
                                                        true
                                                    )}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['earning-trend']['earningsEstimateGrowth'] * 100),
                                                        item['iSqore']['earningsGrowthEstimateGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthYearGrade'],
                                                        true
                                                    )}

                                                    {return_compare_value_div(
                                                        return_strong_num(divide(subtract(item['earning-trend']['epsTrendCurrent'], item['earning-trend-0']['epsTrendCurrent']), Math.abs(item['earning-trend-0']['epsTrendCurrent']))),
                                                        item['iSqore']['earningsPerShareGrowthEstimateGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['earningsPerShare'] * 100),
                                                        item['iSqore']['earningsPerShareGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['earningsPerShare'] * 100),
                                                        item['iSqore']['earningsPerShareGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['earningsPerShare'] * 100),
                                                        item['iSqore']['earningsPerShareGrowthYearGrade'],
                                                        true
                                                    )}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['ebit'] * 100),
                                                        item['iSqore']['ebitGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['ebit'] * 100),
                                                        item['iSqore']['ebitGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['ebit'] * 100),
                                                        item['iSqore']['ebitGrowthYearGrade'],
                                                        true
                                                    )}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['ebitda'] * 100),
                                                        item['iSqore']['ebitdaGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['ebitda'] * 100),
                                                        item['iSqore']['ebitdaGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['ebitda'] * 100),
                                                        item['iSqore']['ebitdaGrowthYearGrade'],
                                                        true
                                                    )}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthYearGrade'],
                                                        true
                                                    )}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['highGrowthRatio'] * 100),
                                                        item['iSqore']['highGrowthRatioTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['highGrowthRatio'] * 100),
                                                        item['iSqore']['highGrowthRatioYearGrade'],
                                                        true
                                                    )}
                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p style={{marginRight: 'auto', fontWeight: 600}}>Health</p>

                                                <div className={'isqore-lg-table-td-div'}>
                                                    <p>{Math.min(return_strong_num(item['iSqore']['health'] * 100), 100)}</p>
                                                    <p className={'isqore-td-separator'}>|</p>
                                                    <p className={'grade_p'}
                                                       style={{
                                                           border: `1px solid ${return_grade_color(item.iSqore.healthGrade)}`,
                                                           color: return_grade_color(item.iSqore.healthGrade),
                                                           backgroundColor: return_bg_grade_color(item.iSqore.healthGrade)
                                                       }}>
                                                        {item.iSqore.healthGrade}</p>
                                                </div>
                                            </div>

                                            {sideBarOptions.filter(item => item.id === 5)[0].open &&
                                                <>
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['cashRatio']),
                                                        item['iSqore']['cashRatioGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['cashRatio']),
                                                        item['iSqore']['cashRatioYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['debtEquity']),
                                                        item['iSqore']['debtEquityGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtEquity']),
                                                        item['iSqore']['debtEquityYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['netDebtEquity']),
                                                        item['iSqore']['netDebtEquityGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtEquity']),
                                                        item['iSqore']['netDebtEquityYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['equityAssets']),
                                                        item['iSqore']['equityAssetsGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['equityAssets']),
                                                        item['iSqore']['equityAssetsYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['debtAsset']),
                                                        item['iSqore']['debtAssetGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtAsset']),
                                                        item['iSqore']['debtAssetYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['netDebtAsset']),
                                                        item['iSqore']['netDebtAssetGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtAsset']),
                                                        item['iSqore']['netDebtAssetYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['debtEBIT']),
                                                        item['iSqore']['debtEbitTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtEBIT']),
                                                        item['iSqore']['debtEbitYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['netDebtEBIT']),
                                                        item['iSqore']['netDebtEbitTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtEBIT']),
                                                        item['iSqore']['netDebtEbitYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['debtEBITDA']),
                                                        item['iSqore']['debtEbitdaTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtEBITDA']),
                                                        item['iSqore']['debtEbitdaYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['netDebtEBITDA']),
                                                        item['iSqore']['netDebtEbitdaTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtEBITDA']),
                                                        item['iSqore']['netDebtEbitdaYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['currentRatio']),
                                                        item['iSqore']['currentRatioGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['currentRatio']),
                                                        item['iSqore']['currentRatioYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['quickRatio']),
                                                        item['iSqore']['quickRatioGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['quickRatio']),
                                                        item['iSqore']['quickRatioYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['altmanZScore']),
                                                        item['iSqore']['altmanZScoreCurrentGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['altmanZScore']),
                                                        item['iSqore']['altmanZScoreYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['current-cal']['piotroskiFScore']),
                                                        item['iSqore']['piotroskiFScoreGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['piotroskiFScore']),
                                                        item['iSqore']['piotroskiFScoreYearGrade'])}

                                                </>
                                            }

                                            <div className={'isqore-sidebar-text'}>
                                                <p style={{marginRight: 'auto', fontWeight: 600}}>Quality</p>

                                                <div className={'isqore-lg-table-td-div'}>
                                                    <p>{Math.min(return_strong_num(item['iSqore']['quality'] * 100), 100)}</p>
                                                    <p className={'isqore-td-separator'}>|</p>
                                                    <p className={'grade_p'}
                                                       style={{
                                                           border: `1px solid ${return_grade_color(item.iSqore.qualityGrade)}`,
                                                           color: return_grade_color(item.iSqore.qualityGrade),
                                                           backgroundColor: return_bg_grade_color(item.iSqore.qualityGrade)
                                                       }}>
                                                        {item.iSqore.qualityGrade}</p>
                                                </div>
                                            </div>

                                            {sideBarOptions.filter(item => item.id === 6)[0].open &&
                                                <>
                                                    {return_compare_value_div(
                                                        return_strong_num(item['earning-trend']['revenueEstimateGrowth'] * 100),
                                                        item['iSqore']['totalRevenueGrowthEstimateGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['totalRevenue'] * 100),
                                                        item['iSqore']['revenueGrowthYearGrade'],
                                                        true
                                                    )}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['earning-trend']['earningsEstimateGrowth'] * 100),
                                                        item['iSqore']['earningsGrowthEstimateGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['netIncome_income_statement'] * 100),
                                                        item['iSqore']['earningsGrowthYearGrade'],
                                                        true
                                                    )}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-quarterly-ttm']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthTTMGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-yoy']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthYOYGrade'],
                                                        true
                                                    )}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-growth-yearly']['historical_data']['freeCashFlow'] * 100),
                                                        item['iSqore']['freeCashFlowGrowthYearGrade'],
                                                        true
                                                    )}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnEquity'] * 100),
                                                        item['iSqore']['returnOnEquityTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnEquity'] * 100),
                                                        item['iSqore']['returnOnEquityYearGrade'],
                                                        true)}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['returnOnCapitalEmployed'] * 100),
                                                        item['iSqore']['returnOnCapitalEmployedTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['returnOnCapitalEmployed'] * 100),
                                                        item['iSqore']['returnOnCapitalEmployedYearGrade'],
                                                        true)}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['grossMargin'] * 100),
                                                        item['iSqore']['grossMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['grossMargin'] * 100),
                                                        item['iSqore']['grossMarginYearGrade'],
                                                        true)}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['netMargin'] * 100),
                                                        item['iSqore']['netMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netMargin'] * 100),
                                                        item['iSqore']['netMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['fcfMargin'] * 100),
                                                        item['iSqore']['fcfMarginTTMGrade'],
                                                        true)}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['fcfMargin'] * 100),
                                                        item['iSqore']['fcfMarginYearGrade'],
                                                        true)}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['debtEquity']),
                                                        item['iSqore']['debtEquityGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtEquity']),
                                                        item['iSqore']['debtEquityYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['netDebtEquity']),
                                                        item['iSqore']['netDebtEquityGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtEquity']),
                                                        item['iSqore']['netDebtEquityYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['cashRatio']),
                                                        item['iSqore']['cashRatioGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['cashRatio']),
                                                        item['iSqore']['cashRatioYearGrade'])}


                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['debtAsset']),
                                                        item['iSqore']['debtAssetGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtAsset']),
                                                        item['iSqore']['debtAssetYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal_quarter']['netDebtAsset']),
                                                        item['iSqore']['netDebtAssetGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtAsset']),
                                                        item['iSqore']['netDebtAssetYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['debtEBIT']),
                                                        item['iSqore']['debtEbitTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['debtEBIT']),
                                                        item['iSqore']['debtEbitYearGrade'])}

                                                    {return_compare_value_div(
                                                        return_strong_num(item['calculation-quarterly-ttm']['historical_data']['netDebtEBIT']),
                                                        item['iSqore']['netDebtEbitTTMGrade'])}
                                                    {return_compare_value_div(
                                                        return_strong_num(item['additional_cal']['netDebtEBIT']),
                                                        item['iSqore']['netDebtEbitYearGrade'])}
                                                </>
                                            }

                                        </div>
                                    </div>
                                )
                            })}
                            <div className={'company-isqore-container'}>
                                <AiOutlinePlusCircle
                                    onClick={handleOpenModal}
                                    style={{color: '#676767', margin: '0 auto', cursor: 'pointer'}}/>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompareStocksSearch
                    compareData={compareData}
                    setCompareData={setCompareData}
                    handleCloseSearchModal={handleCloseModal}
                />
            </Modal>

        </>
    );
}

export default CompareISqore;
