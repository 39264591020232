import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {load_common_stock} from "../actions/actions";
import DashboardLayout from "../Layout/DashboardLayout";
import CompanyTabs from "../Container/CompanyTabs/CompanyTabs";
import CompanyDetailsTab from "../Container/CompanyDetailTab/CompanyDetailsTab";
import {Helmet} from "react-helmet-async";
import {useState} from "react";
import company_names from '../assets/company_names.json'

const CompanyOverview = () => {
    const {code, exchange} = useParams();
    const [logoURL, setLogoUrl] = useState(null)

    const dispatch = useDispatch();

    dispatch(load_common_stock(code, exchange))

    const company_name = company_names.hasOwnProperty(code + '.' + exchange) ? company_names[code + '.' + exchange] : ""

    return (
        <>
            <Helmet>
                <title>{company_name} ({code}) Stock Price, News, History and Ratios</title>
                <meta name={"description"}
                      content={`Get detailed insights on ${company_name} (${code}). Access to crucial financial data, Finqube Scoring, News, Ratios and more`}/>

            </Helmet>
            <DashboardLayout>
                <CompanyDetailsTab code={code} exchange={exchange} setLogoUrl={setLogoUrl}/>
                <CompanyTabs logoURL={logoURL} code={code} exchange={exchange}/>
            </DashboardLayout>
        </>
    );
}

export default CompanyOverview;
