import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../Context/ThemeContext";
import Select from "react-select";
import {Modal} from "@material-ui/core";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import * as Utils from "../Utils";
import {
    return_bg_grade_color,
    return_grade_background_color,
    return_grade_color,
    return_per_div,
    selected_table_styles
} from "../Utils";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import _debounce from "lodash/debounce";
import Config from "../../Config";
import axios from "axios";
import LoginPaymentBlocker from "../LoginPaymentBlocker";
import {useDispatch, useSelector} from "react-redux";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {load_common_stock} from "../../actions/actions";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import etfComponentBlockerDark from "../../assets/blockers/etf-components-blocker-dark.png";
import etfComponentBlockerLight from "../../assets/blockers/etf-components-blocker-light.png";
import building from "../../assets/default-building.png";

function EtfSelectableTable({code, exchange, etf_id, holdings_length}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const {webTheme} = useContext(ThemeContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const options = [
        {value: 'code', label: 'Ticker', isFixed: true, state: 0, class_name: "etf-profiler-code"},
        {value: 'name', label: 'Company Name', isFixed: true, state: 0, class_name: "etf-profiler-company-name"},
        {value: "exchange", label: "Exchange", isFixed: true, state: 0, class_name: "etf-profiler-exchange"},
        {value: 'ISIN', label: 'ISIN', isFixed: true, state: 0, class_name: "etf-profiler-ISIN"},

        {value: "sector", label: "Sector", isFixed: false, state: 0, class_name: ""},
        {value: "industry", label: "Industry", isFixed: false, state: 0, class_name: "etf-profiler-industry"},

        {
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            isFixed: false,
            state: 0,
            class_name: "item-right-align-th"
        },
        {
            value: 'adjusted_close',
            label: 'Last Close',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-lastprice item-right-align-th"
        },

        {value: 'change_p', label: 'Change %', isFixed: false, state: 0, class_name: "item-right-align-th"},

        {value: 'p_e', label: 'P/E', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {value: 'p_b', label: 'P/B', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {value: 'ev_ebit', label: 'EV/EBIT', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {value: 'price_target', label: 'Price Target', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {value: "weight_p", label: "Weight %", isFixed: true, state: 0, class_name: "item-right-align-th"},
        {
            value: 'iSqore',
            label: 'iSqore',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-isqore item-right-align-th"
        },
    ]

    const [columns, setColumns] = useState({
        'Ticker': {value: "code", label: "Ticker", isFixed: true, state: 0, class_name: "etf-profiler-code"},
        'Company Name': {
            value: "name",
            label: "Company Name",
            isFixed: true,
            state: 0,
            class_name: "etf-profiler-company-name"
        },
        "ISIN": {value: "ISIN", label: "ISIN", isFixed: true, state: 0, class_name: "etf-profiler-ISIN"},
        "Exchange": {
            value: "exchange",
            label: "Exchange",
            isFixed: true,
            state: 0,
            class_name: "etf-profiler-exchange"
        },
        "Industry": {
            value: "industry",
            label: "Industry",
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-industry"
        },
        "Last Close": {
            value: 'adjusted_close',
            label: 'Last Close',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-lastprice item-right-align-th"
        },
        "Change %": {
            value: 'change_p',
            label: 'Change %',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-change item-right-align-th"
        },
        "Market Cap (mln)": {
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            isFixed: false,
            state: 0,
            class_name: "item-right-align-th"
        },
        "Weight %": {
            value: "weight_p",
            label: "Weight %",
            isFixed: true,
            state: 0,
            class_name: "etf-profiler-weight item-right-align-th"
        },
        "iSqore": {
            value: 'iSqore',
            label: 'iSqore',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-isqore item-right-align-th"
        },
    })

    const defaultColumns = [
        {value: 'code', label: 'Ticker', isFixed: true, state: 0, class_name: "etf-profiler-code"},
        {value: 'name', label: 'Company Name', isFixed: true, state: 0, class_name: "etf-profiler-company-name"},
        {value: "exchange", label: "Exchange", isFixed: true, state: 0, class_name: "etf-profiler-exchange"},
        {value: 'ISIN', label: 'ISIN', isFixed: true, state: 0, class_name: "etf-profiler-ISIN"},
        {value: "industry", label: "Industry", isFixed: false, state: 0, class_name: "etf-profiler-industry"},
        {value: 'adjusted_close', label: 'Last Close', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {value: 'change_p', label: 'Change %', isFixed: false, state: 0, class_name: "item-right-align-th"},
        {
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            isFixed: false,
            state: 0,
            class_name: "item-right-align-th"
        },
        {value: "weight_p", label: "Weight %", isFixed: true, state: 0, class_name: "item-right-align-th"},
        {
            value: 'iSqore',
            label: 'iSqore',
            isFixed: false,
            state: 0,
            class_name: "etf-profiler-isqore item-right-align-th"
        }
    ]

    const selectedTableStyles = selected_table_styles(webTheme);

    const [search, setSearch] = useState('')

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    const onChange = (e) => {
        if (e.target.value === '') {
            setTimeout(() => {
                setSearch('')
                setFilterIndexComponents(selectedIndexComponents)
            }, 500);
        } else {
            setSearch(e.target.value)
            debounceFn(e.target.value)
            setLoadingSelectedComponentData(true)
        }
    }

    function handleDebounceFn(_search) {
        if (isAuthenticated) {
            const config = Config()
            axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/search-etf-holdings/`, {
                etf_id: etf_id, search: _search
            }, config)
                .then(response => {
                    setFilterIndexComponents(return_instruments(response.data))
                    setLoadingSelectedComponentData(false)
                })
        }
    }

    const url = `${process.env.REACT_APP_API_URL}/finqube-api/load-etf-holdings/${code}/${exchange}/?page=1`

    const [componentLoadingStat, setComponentLoadingStat] = useState({
        // start: 0, end: 20,
        url: url, length: 0, see_more: false
    })
    const [selectedIndexComponents, setSelectedIndexComponents] = useState([]);
    const [filteredIndexComponents, setFilterIndexComponents] = useState([]);
    const [loadingSelectedComponentData, setLoadingSelectedComponentData] = useState(true);

    const handleChange = (e) => {
        const data = {}
        e.map((item) => {
            data[item.label] = item
        })
        setColumns(data)
    }

    const sort = (key, order) => {
        setColumns({
            ...columns,
            [key]: {...columns[key], state: !columns[key].state}
        })

        if (order) {
            setFilterIndexComponents([...filteredIndexComponents].sort((a, b) => {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            }));
        } else {
            setFilterIndexComponents([...filteredIndexComponents].sort((a, b) => {
                if (a[key] > b[key]) {
                    return -1;
                }
                if (a[key] < b[key]) {
                    return 1;
                }
                return 0;
            }));
        }
    }

    const return_instruments = (data) => {
        let instruments = []
        data.map((item) => {
            instruments.push(
                {
                    "company_id": item.company_id,
                    "LogoURL": item.LogoURL,
                    'Ticker': item.code,
                    'Company Name': item.name,
                    'Exchange': item.exchange,
                    'Weight %': Utils.return_strong_num(item.assets_per),
                    'ISIN': item.ISIN,
                    'Sector': item.sector,
                    'Industry': item.industry,
                    'iSqore': Utils.return_strong_num(item.iSqore * 100),
                    'iSqoreGrade': item.iSqoreGrade,
                    'Last Close': Utils.return_strong_num(item.last_price),
                    "Change %": Utils.return_strong_num(item.per_change),
                    'Market Cap (mln)': Utils.return_value_in_million(item.m_cap_trading),
                    'P/E': Utils.return_strong_num(item.p_e),
                    'P/B': Utils.return_strong_num(item.p_b),
                    'EV/EBIT': Utils.return_strong_num(item.ev_ebit),
                    'Price Target': Utils.return_strong_num(item.price_target),
                    'watchlist': item.watchlist
                }
            )
        })
        return instruments
    }

    const load_instruments = () => {
        axios.get(componentLoadingStat.url,  Config())
            .then(response => {
                setSelectedIndexComponents([...selectedIndexComponents, ...return_instruments(response.data.results)])
                setFilterIndexComponents([...selectedIndexComponents, ...return_instruments(response.data.results)])
                setComponentLoadingStat({
                    // start: componentLoadingStat.start + 20, end: componentLoadingStat.end + 20,
                    url: response.data.next,
                    length: response.data.count,
                    see_more: response.data.next !== null
                })
                setLoadingSelectedComponentData(false)
            })
            .catch(error => {
                setComponentLoadingStat({
                    ...componentLoadingStat,
                    see_more: false
                })
                console.log(error)
            })
    }

    useEffect(() => {
        if (isAuthenticated) {
            load_instruments()
        }
    }, [isAuthenticated])

    const seeMore = () => {
        load_instruments()
    }

    const [selectedTicker, setSelectedTicker] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (item) => {
        setSelectedTicker(item)
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                setSelectedIndexComponents(selectedIndexComponents.map(component => {
                        if (component.company_id === selectedTicker.company_id) {
                            return {
                                ...component,
                                watchlist: response.data['ticker_exists']
                            }
                        }
                        return component
                    })
                )
                setFilterIndexComponents(filteredIndexComponents.map(component => {
                        if (component.company_id === selectedTicker.company_id) {
                            return {
                                ...component,
                                watchlist: response.data['ticker_exists']
                            }
                        }
                        return component
                    })
                )
                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseModal()
            })
            .catch(err => console.log(err.message))
    }

    return (
        <div className={'entire-body-lg'} style={{margin: '10px 0'}}>

            {!isAuthenticated &&
                <LoginPaymentBlocker
                    condition={'You must be logged in to use this feature'}
                    login={true}
                    subscription={true}
                />
            }

            {!isAuthenticated &&
                <div style={{overflowX: "auto"}}>
                    <img src={webTheme === 'dark' ? etfComponentBlockerDark : etfComponentBlockerLight}
                         className={'blocker-img'}/>
                </div>
            }

            {isAuthenticated &&
                <div
                    style={{position: 'relative', width: '100%', height: '100%'}}>
                    <Select
                        onChange={handleChange}
                        isClearable={options.some((v) => !v.isFixed)}
                        closeMenuOnSelect={false}
                        defaultValue={defaultColumns}
                        styles={selectedTableStyles}
                        options={options}
                        isMulti={true}
                        theme={(theme) => ({
                            ...theme,
                            color: {
                                ...theme.colors,
                                primary: 'black'
                            }
                        })}
                    />

                    <input
                        placeholder={'Search for Components'}
                        className={'form-control indices-search'}
                        onChange={onChange}
                    />

                    <div className={'table-responsive generic-lg-table-container'}>

                        <table className="table generic-lg-table etf-profiler-lg-table">
                            <thead>
                            <tr>
                                <th className={'etf-profiler-table-star'}>

                                </th>
                                {'Ticker' in columns &&
                                    <th className={columns['Ticker']['class_name']} onClick={() => {
                                        sort('Ticker', columns['Ticker']['state'])
                                    }}>
                                        Ticker {!columns['Ticker']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Company Name' in columns &&
                                    <th className={columns['Company Name']['class_name']} onClick={() => {
                                        sort('Company Name', columns['Company Name']['state'])
                                    }}>
                                        Company Name {!columns['Company Name']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                                {'Exchange' in columns &&
                                    <th className={columns['Exchange']['class_name']} onClick={() => {
                                        sort('Exchange', columns['Exchange']['state'])
                                    }}>
                                        Exchange {!columns['Exchange']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                                {'ISIN' in columns &&
                                    <th className={columns['ISIN']['class_name']} onClick={() => {
                                        sort('ISIN', columns['ISIN']['state'])
                                    }}>
                                        ISIN {!columns['ISIN']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                                {'Sector' in columns &&
                                    <th className={columns['Sector']['class_name']} onClick={() => {
                                        sort('Sector', columns['Sector']['state'])
                                    }}>
                                        Sector {!columns['Sector']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                                {'Industry' in columns &&
                                    <th className={columns['Industry']['class_name']} onClick={() => {
                                        sort('Industry', columns['Industry']['state'])
                                    }}>
                                        Industry {!columns['Industry']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Last Close' in columns &&
                                    <th className={columns['Last Close']['class_name']} onClick={() => {
                                        sort('Last Close', columns['Last Close']['state'])
                                    }}>
                                        Last Close {!columns['Last Close']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Change %' in columns &&
                                    <th className={columns['Change %']['class_name']} onClick={() => {
                                        sort('Change %', columns['Change %']['state'])
                                    }}>
                                        Change % {!columns['Change %']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Market Cap (mln)' in columns &&
                                    <th className={columns['Market Cap (mln)']['class_name']} onClick={() => {
                                        sort('Market Cap (mln)', columns['Market Cap (mln)']['state'])
                                    }}>
                                        Market Cap (mln) {!columns['Market Cap (mln)']['state'] ? <BsArrowUp/> :
                                        <BsArrowDown/>}
                                    </th>
                                }

                                {'P/E' in columns &&
                                    <th className={columns['P/E']['class_name']} onClick={() => {
                                        sort('P/E', columns['P/E']['state'])
                                    }}>
                                        P/E {!columns['P/E']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'P/B' in columns &&
                                    <th className={columns['P/B']['class_name']} onClick={() => {
                                        sort('P/B', columns['P/B']['state'])
                                    }}>
                                        P/B {!columns['P/B']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'EV/EBIT' in columns &&
                                    <th className={columns['EV/EBIT']['class_name']} onClick={() => {
                                        sort('EV/EBIT', columns['EV/EBIT']['state'])
                                    }}>
                                        EV/EBIT {!columns['EV/EBIT']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Price Target' in columns &&
                                    <th className={columns['Price Target']['class_name']} onClick={() => {
                                        sort('Price Target', columns['Price Target']['state'])
                                    }}>
                                        Price Target {!columns['Price Target']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }

                                {'Weight %' in columns &&
                                    <th className={columns['Weight %']['class_name']} onClick={() => {
                                        sort('Weight %', columns['Weight %']['state'])
                                    }}>
                                        Weight % {!columns['Weight %']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                                {'iSqore' in columns &&
                                    <th className={columns['iSqore']['class_name']} onClick={() => {
                                        sort('iSqore', columns['iSqore']['state'])
                                    }}>
                                        iSqore {!columns['iSqore']['state'] ? <BsArrowUp/> : <BsArrowDown/>}
                                    </th>
                                }
                            </tr>
                            </thead>

                            <tbody>
                            {filteredIndexComponents.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td
                                                onClick={() => {
                                                    handleOpenModal(item)
                                                }}>
                                                {item.watchlist ? <AiFillStar
                                                    style={{
                                                        color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                                    }}/> : <AiOutlineStar
                                                    style={{
                                                        color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                                    }}/>}
                                            </td>
                                            {'Ticker' in columns &&
                                                <td>
                                                    <img className={'price-target-company-logo'}
                                                         src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}
                                                         onError={({currentTarget}) => {
                                                             currentTarget.onerror = null;
                                                             currentTarget.src = building;
                                                         }}/>
                                                    {item['Ticker']}
                                                </td>
                                            }
                                            {'Company Name' in columns &&
                                                <td className={item['Company Name'].length > 25 && "hovertext"}
                                                    data-hover={item['Company Name']}
                                                    onClick={() => {
                                                        dispatch(load_common_stock(item['Ticker'], item.Exchange))
                                                        navigate('/company-overview/' + item['Ticker'] + '/' + item.Exchange + '/')
                                                    }}
                                                >
                                                    {item['Company Name'].length > 25 ? item['Company Name'].substring(0, 25) + "..." : item['Company Name']}
                                                </td>
                                            }
                                            {'Exchange' in columns &&
                                                <td>
                                                    {item.Exchange}
                                                </td>
                                            }
                                            {'ISIN' in columns &&
                                                <td>
                                                    {item.ISIN}
                                                </td>
                                            }
                                            {'Sector' in columns &&
                                                <td>
                                                    {item.Sector}
                                                </td>
                                            }
                                            {'Industry' in columns &&
                                                <td className={(item.Industry && item.Industry.length > 25) && "hovertext"}
                                                    data-hover={item.Industry}>
                                                    {(item.Industry && item.Industry.length > 25) ? item.Industry.substring(0, 25) + "..." : item.Industry}
                                                </td>
                                            }

                                            {'Last Close' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['Last Close']}
                                                </td>
                                            }
                                            {'Change %' in columns &&
                                                // <td style={{color: item['Change %']}}>
                                                //     {item['Change %']} %
                                                // </td>
                                                <td className={'item-right-align-td'} style={{
                                                    display: 'flex',
                                                    paddingRight: 2
                                                }}>
                                                    {return_per_div(item['Change %'])}
                                                </td>
                                            }
                                            {'Market Cap (mln)' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {Utils.numberWithCommas(item['Market Cap (mln)'])}
                                                </td>
                                            }
                                            {'P/E' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['P/E']}
                                                </td>
                                            }
                                            {'P/B' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['P/B']}
                                                </td>
                                            }
                                            {'EV/EBIT' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['EV/EBIT']}
                                                </td>
                                            }
                                            {'Price Target' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['Price Target']}
                                                </td>
                                            }

                                            {'Weight %' in columns &&
                                                <td className={'item-right-align-td'}>
                                                    {item['Weight %']} %
                                                </td>
                                            }
                                            {"iSqore" in columns &&
                                                <td className={'item-right-align-td isqore-lg-table-td'}>
                                                    <div className={'isqore-lg-table-td-div'}>
                                                        <p>{item['iSqore']}</p>
                                                        <p className={'isqore-td-separator'}>|</p>
                                                        {/*<p className={'grade_p'}*/}
                                                        {/*   style={{*/}
                                                        {/*       border: `1px solid ${return_grade_color(item['iSqoreGrade'])}`,*/}
                                                        {/*       color: return_grade_color(item['iSqoreGrade']),*/}
                                                        {/*       backgroundColor: return_bg_grade_color(item['iSqoreGrade'])*/}
                                                        {/*   }}>*/}
                                                        {/*    {item['iSqoreGrade']}</p>*/}
                                                        <p className={'grade_p'}
                                                           style={{
                                                               border: `1px solid ${return_grade_color(item['iSqoreGrade'])}`,
                                                               color: return_grade_color(item['iSqoreGrade']),
                                                               backgroundColor: return_bg_grade_color(item['iSqoreGrade'])
                                                           }}>
                                                            {item['iSqoreGrade']}
                                                        </p>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        <div key={index} style={{marginTop: 3, width: "100%"}}/>
                                    </>
                                )
                            })}


                            </tbody>

                        </table>

                    </div>

                    <div style={{display: 'flex', width: "100%"}}>
                        <p className={'show-results-number'}>Showing
                            results {filteredIndexComponents.length} of {componentLoadingStat.length}</p>
                        {search === '' && !loadingSelectedComponentData && componentLoadingStat.see_more &&
                            <p className={'see-more-option'}
                               onClick={() => {
                                   setLoadingSelectedComponentData(true)
                                   seeMore()
                               }}
                            >See More
                            </p>
                        }
                    </div>

                    {search !== '' && !loadingSelectedComponentData && filteredIndexComponents.length === 0 &&
                        <p className={'index-no-component-found'}>No Components Found</p>}

                    {loadingSelectedComponentData &&
                        <div style={{display: 'flex', width: '100%', height: '100%'}}>
                            <div className="spinner-border text-warning" role="status" style={{margin: "auto"}}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }

                </div>
            }

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>

        </div>
    );
}

export default EtfSelectableTable;