import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../../Context/ThemeContext";
import axios from "axios";
import Config from "../../../../Config";


function FinancialOverviewPolarAreaChart({revenueGeographicSegmentation}) {

    const [regions, setRegions] = useState([])
    const [series, setSeries] = useState([])

    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        let series = []
        let regions = []
        let sum = 0

        Object.entries(revenueGeographicSegmentation).map(([key, value]) => {
            sum += value
        })

        Object.entries(revenueGeographicSegmentation).map(([key, value]) => {
            if (key !== 'segmentation') {
                regions.push(key)
                series.push(parseFloat(((value / sum) * 100).toFixed(2)))
            }
        })

        setSeries(series)
        setRegions(regions)
    }, [revenueGeographicSegmentation])

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const state = {
        series: series,
        options: {
            chart: {
                type: 'polarArea',
                foreColor: graphVar.color,
                width: 300,
            },
            yaxis: {
                show: false
            },
            plotOptions: {
                polarArea: {
                    rings: {
                        strokeWidth: 0
                    },
                    spokes: {
                        strokeWidth: 0
                    },
                }
            },

            dataLabels: {
                enabled: true,
                style: {
                    fontSize: 10
                },
                formatter: function (seriesName, opts) {
                    const seriesIndex = opts['seriesIndex']
                    return [`${opts['w']['config']['labels'][seriesIndex].split('-')[0]}`, `${opts.w.globals.series[opts.seriesIndex]} %`]
                }
            },
            colors: ['#ee1981', '#8929cd', '#3e9fed', '#6d46d4'],
            legend: {
                show: false,
                position: 'top',
                formatter: function (seriesName, opts) {
                    return opts.w.globals.series[opts.seriesIndex] + "%" + ' - ' + seriesName
                }
            },
            labels: regions,
            responsive: [{
                breakpoint: 490,
                options: {
                    chart: {
                        width: 310
                    },
                    legend: {
                        position: 'top',
                        show: false
                    }
                }
            }]
        }
    };

    return (
        <div className={'rule-of-forty-chart-container'}>
            <div className={'rule-of-forty-inner-container'} style={{height: 294, padding: "10px 10px 0 10px"}}>
                <p className={'market-overview-chart-name'}>Revenue By Regions</p>
                <ReactApexChart options={state.options} series={state.series} type={'polarArea'}
                                width={'100%'}
                                height={'100%'}
                />
            </div>
        </div>
    );
}

export default FinancialOverviewPolarAreaChart;