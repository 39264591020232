import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../Context/ThemeContext";

function HoldingDonutChart({holdingData}) {
    const {webTheme} = useContext(ThemeContext);

    const [loading, setLoading] = useState(true)

    const [labels, setLabels] = useState([])
    const [series, setSeries] = useState([])

    useEffect(() => {
        let labels = []
        let series = []

        holdingData.map((item) => {
            if (parseFloat(item.weight).toFixed(4) > 0.0 && labels.length <= 9) {
                labels.push(item.code + ' - ' + item.name)
                series.push(parseFloat(item.weight.toFixed(2)))
            }
        })

        const sum = series.reduce((partialSum, a) => partialSum + a, 0);

        labels.push("Others-Others")
        series.push(parseFloat((100 - sum).toFixed(2)))

        setLabels(labels)
        setSeries(series)

    }, [holdingData])

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const options = {
        chart: {
            width: '100%',
            type: 'donut',
            foreColor: graphVar.color
        },
        grid: {
            show: false
        },
        labels: labels,
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val
                }
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: 10
            },
            formatter: function (seriesName, opts) {
                const seriesIndex = opts['seriesIndex']
                return [`${opts['w']['config']['labels'][seriesIndex].split('-')[0]}`, `${opts.w.globals.series[opts.seriesIndex]} %`]
            }
        },
        colors: ['#ee1981', '#dc1294', '#c50da7', '#a410c2', '#8929cb', '#6d46d4', '#5a5ddb', '#506bdf', '#467be3', '#4090e9', '#3e9fed'],
        legend: {
            show: true,
            position: 'left',
            formatter: function (seriesName, opts) {
                return [opts.w.globals.series[opts.seriesIndex] + " %", ' - ', seriesName.split('-')[1].length > 30 ? seriesName.split('-')[1].slice(0, 30) + '...' : seriesName.split('-')[1]]
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    // width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
        }]
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'dividend-chart-container dividend-chart-holding-container'} style={{padding: 0}}>
            <div className={'index-stat-table-title'}>
                <p className={'selected'}>Portfolio Structure</p>
            </div>
            <div className={'donut-chart-holding-inner-container'}>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type="donut" width={'100%'}
                                    height={'95%'}/>
                }
            </div>
        </div>
    );
}

export default HoldingDonutChart;
