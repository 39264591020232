import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import {return_strong_num, return_value_in_million} from "../../Utils";
import ReactApexChart from "react-apexcharts";

function FinancialOutlookChart({code, tableData}) {
    const {webTheme} = useContext(ThemeContext);
    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })
    const [graphVarChange, setGraphVarChange] = useState(false)
    const [loading, setLoading] = useState(true)

    const [dates, setDates] = useState([])

    const [series, setSeries] = useState(
        [
            {
                name: 'Revenue (mil.)',
                type: "column",
                data: []
            },
            {
                name: 'EPS',
                type: 'line',
                data: []
            },
        ]
    )

    useEffect(() => {
        if (tableData) {
            // console.log([...tableData.expected.eps.map(item => return_strong_num(item)), ...tableData.historical.eps.map(item => return_strong_num(item))].reverse())

            setSeries([
                {
                    name: 'Revenue (mil.)',
                    type: "column",
                    data: [...tableData.expected.revenue.map(item => return_value_in_million(item)), ...tableData.historical.revenue.slice(0, tableData.historical.revenue.length - 1).map(item => return_value_in_million(item))].reverse()
                },
                {
                    name: 'EPS',
                    type: 'line',
                    data: [...tableData.expected.eps.map(item => return_strong_num(item)), ...tableData.historical.eps.slice(0, tableData.historical.eps.length - 1).map(item => return_strong_num(item))].reverse()
                },
            ])

            setDates([...tableData.expected.date, ...tableData.historical.date.slice(0, tableData.historical.date.length - 1)].reverse())
            setLoading(false)
        }
    }, [tableData]);

    const options = {
        chart: {
            width: '100%',
            type: 'line',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        forecastDataPoints: {
            count: tableData.expected.date.length
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1], // Assuming series[1] is your EPS series
            formatter: function (val) {
                if (val) {
                    return val.toFixed(2);
                }
                 return
            }
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        yaxis: [{
            labels: {
                formatter: function (val) {
                    return val
                }
            }
        }, {
            opposite: true,
            labels: {
                formatter: function (val) {
                    return val.toFixed(2)
                }
            }
        }],
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
        },
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
            categories: dates,
        },
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'company-overview-chart-container financial-outlook-chart-container'}>
            <div className={'financial-outlook-chart-inner-container'}>
                <div id="chart">
                    <div className="analyst-toolbar">
                        <p className={'market-overview-chart-name'}>{code} Revenue & EPS</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <div style={{width: "100%", height: "90%", marginLeft: 5}}>
                        <ReactApexChart options={options} series={series} type={'line'} width={'100%'}
                                        height={'95%'}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default FinancialOutlookChart;