import React from 'react';
import clsx from "clsx";
import {return_per_div, return_strong_num, return_td_color} from "../../../Utils";

function NewFinancialTable({groupedData, groupedDataSec, boldKeys = [], tableClassName = "", active}) {
    let spaceIndex = null

    return (
        <div className={"table-responsive generic-lg-table-container"}>
            <table className="table generic-lg-table company-overview-financial-table">
                <thead>
                <tr>
                    <th className={clsx('company-overview-financial-table-field-names', tableClassName)}>
                    </th>
                    {groupedData['dates'].map((date, index) => {
                        if (date === "") {
                            spaceIndex = index
                        }

                        return (
                            <th key={index}
                                style={{paddingLeft: index > spaceIndex ? 10 : 0}}
                                className={clsx(date === "" && "financial-table-block-no-style")}>
                                {date}
                            </th>
                        )
                    })}
                </tr>
                </thead>
                {Object.entries(groupedData.content).map(([heading, index]) => (
                    <>
                        <thead>
                        <tr>
                            {groupedDataSec ?
                                <>
                                    {active !== 'quarter' ?
                                        <>
                                            <th style={{fontWeight: 700}}
                                                className={groupedData.content[heading]['name'].length > 25 && "hovertext"}
                                                data-hover={groupedData.content[heading]['name']}>
                                                {groupedData.content[heading]['name'].length > 25 ? groupedData.content[heading]['name'].substring(0, 25) + "..." : groupedData.content[heading]['name']}
                                            </th>
                                            {groupedData['dates'].map((date, index) => {
                                                return (
                                                    <th key={index}
                                                        className={clsx(date === "" && "financial-table-block-no-style")}>

                                                    </th>
                                                )
                                            })}
                                        </>
                                        :
                                        <>
                                            <th style={{fontWeight: 700}}
                                                className={groupedData.content[heading]['name'].length > 25 && "hovertext"}
                                                data-hover={groupedData.content[heading]['name']}>
                                                {groupedData.content[heading]['name'].length > 25 ? groupedData.content[heading]['name'].substring(0, 25) + "..." : groupedData.content[heading]['name']}
                                            </th>
                                            <th style={{fontWeight: 700, textAlign: "center"}} colSpan={2}>
                                                TTM
                                            </th>
                                            <th className={"financial-table-block-no-style"}>

                                            </th>
                                            <th style={{fontWeight: 700, textAlign: "center"}} colSpan={3}>
                                                Quarterly
                                            </th>
                                        </>
                                    }
                                </>
                                :
                                <th colSpan={groupedData['dates'].length + 1}
                                    style={{fontWeight: 700}}
                                    className={groupedData.content[heading]['name'].length > 25 && "hovertext"}
                                    data-hover={groupedData.content[heading]['name']}>
                                    {groupedData.content[heading]['name'].length > 25 ? groupedData.content[heading]['name'].substring(0, 25) + "..." : groupedData.content[heading]['name']}
                                </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(groupedData.content[heading]['data']).map(([key, value]) => (
                            <tr>
                                <td className={key.length > 25 && "hovertext"} data-hover={key}
                                    style={{fontWeight: boldKeys.includes(key) ? 700 : 400}}>
                                    <p style={{marginLeft: key.includes("Margin") ? 20 : 0}}>
                                        {key.length > 25 ? key.substring(0, 25) + "..." : key}
                                    </p>
                                </td>
                                {groupedData.content[heading]['data'][key].map((item) => {
                                    return (
                                        item.container_per ?
                                            "reverse_container_per" in item ?
                                                <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                    <div className={'per_container'}
                                                         style={{
                                                             color: (item.value === null || return_strong_num(item.value) === '0.00') ? 'white' : return_strong_num(item.value) > 0 ? "#d84f68" : "#4ebe96",
                                                             backgroundColor: (item.value === null || return_strong_num(item.value) === '0.00') ? '#89898966' : return_strong_num(item.value) > 0 ? '#d84f6829' : '#4ebe9629',
                                                         }}>
                                                        {(item.value !== null && return_strong_num(item.value) !== '0.00') ?
                                                            `${return_strong_num(item.value)} %`
                                                            :
                                                            '- %'
                                                        }
                                                    </div>
                                                </td>
                                                :
                                                <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                    {return_per_div(item.value, 11, 60, "0 auto 0 0")}
                                                </td>
                                            :
                                            'pp' in item && item.pp ?
                                                <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                    <div className={'per_container'}
                                                         style={{
                                                             color: (item.value === null || return_strong_num(item.value) === '0.00') ? 'white' : return_td_color(item.value),
                                                             backgroundColor: (item.value === null || return_strong_num(item.value) === '0.00') ? '#89898966' : return_strong_num(item.value) > 0 ? '#4ebe9629' : '#d84f6829',
                                                         }}>
                                                        {(item.value !== null && return_strong_num(item.value) !== '0.00') ?
                                                            `${return_strong_num(item.value)}pp`
                                                            :
                                                            '-pp'
                                                        }
                                                    </div>
                                                </td>
                                                :
                                                <td style={{
                                                    ...item.styles,
                                                    paddingLeft: index === 0 ? 10 : 0,
                                                    fontWeight: item.bold ? "700" : "400",
                                                    color: item.color,
                                                }}>
                                                    {item.value} {item.per && "%"}
                                                </td>
                                    )
                                })}
                                {groupedDataSec &&
                                    <>
                                        <td className={'financial-table-block-no-style'}>

                                        </td>
                                        {groupedDataSec[heading]['data'][key].map((item, index) => {
                                            return (
                                                item.container_per ?
                                                    "reverse_container_per" in item ?
                                                        <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                            <div className={'per_container'}
                                                                 style={{
                                                                     color: (item.value === null || return_strong_num(item.value) === '0.00') ? 'white' : return_strong_num(item.value) > 0 ? "#d84f68" : "#4ebe96",
                                                                     backgroundColor: (item.value === null || return_strong_num(item.value) === '0.00') ? '#89898966' : return_strong_num(item.value) > 0 ? '#d84f6829' : '#4ebe9629',
                                                                 }}>
                                                                {(item.value !== null && return_strong_num(item.value) !== '0.00') ?
                                                                    `${return_strong_num(item.value)} %`
                                                                    :
                                                                    '- %'
                                                                }
                                                            </div>
                                                        </td>
                                                        :
                                                        <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                            {return_per_div(item.value, 11, 60, "0 auto 0 0")}
                                                        </td>
                                                    :
                                                    'pp' in item && item.pp ?
                                                        <td style={{paddingLeft: index === 0 ? 10 : 0}}>
                                                            <div className={'per_container'}
                                                                style={{
                                                                color: (item.value === null || return_strong_num(item.value) === '0.00') ? 'white' : return_td_color(item.value),
                                                                backgroundColor: (item.value === null || return_strong_num(item.value) === '0.00') ? '#89898966' : return_strong_num(item.value) > 0 ? '#4ebe9629' : '#d84f6829',
                                                            }}>
                                                                {(item.value !== null && return_strong_num(item.value) !== '0.00') ?
                                                                    `${return_strong_num(item.value)}pp`
                                                                    :
                                                                    '-pp'
                                                                }
                                                            </div>
                                                        </td>
                                                        :
                                                        <td style={{
                                                            paddingLeft: index === 0 ? 10 : 0,
                                                            fontWeight: item.bold ? "700" : "400",
                                                            color: item.color
                                                        }}>
                                                            {item.value} {item.per && "%"}
                                                        </td>
                                            )
                                        })}
                                    </>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </>
                ))}
            </table>
        </div>
    );
}

export default NewFinancialTable;