import React from 'react';
import {return_strong_num} from "../Utils";

function RuleOfFortyHighlights({title, data, additional}) {
    return (
        <div className={'rule-of-40-table-container'}>
            <div className={'rule-of-40-table-title'}>
                <p>{title}</p>
            </div>

            <div className={'rule-of-40-table-body-container'} style={{
                borderRadius: '0 0 10px 10px',
            }}>
                <table className={'table generic-table-body rule-of-40-table'}>
                    <tbody>
                    {data.map((item) => {
                        return (
                            <tr>
                                <td>{item.key}</td>
                                <td style={{fontSize: 14, fontWeight: 600}}/>
                                <td>{return_strong_num(item.value)} %</td>
                            </tr>
                        )
                    })}
                    <tr style={{border: 'none'}}>
                        <td style={{fontSize: 14, fontWeight: 600}}>{additional.key}</td>
                        <td style={{fontSize: 14, fontWeight: 600}}/>
                        <td style={{
                            fontSize: 14,
                            fontWeight: 600
                        }}>{return_strong_num(additional.value)} %
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default RuleOfFortyHighlights;