import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    return_obj,
    return_string
} from "../Utils";
import DebtEquityCashRatioChart
    from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialCharts/DebtEquityCashRatioChart";
import RoicGrossMarginFcfMargin
    from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialCharts/ROICGrossMarginFCFMargin";
import RestrictedContent from "../RestrictedContent";
import ISqoreTable from "./ISqoreTable";
import iSqoreValueDark from "../../assets/blockers/iSqoreValueDark.png";
import iSqoreValueLight from "../../assets/blockers/iSqoreValueLight.png";
import Config from "../../Config";

function QualityTab({code, exchange}) {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-value/${code}/${exchange}/`, Config())
            .then(response => {
                format_data(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    const format_data = (values) => {
        const data = {
            tableData: [
                return_obj(
                    "Revenue Growth (Fwd)",
                    values['earning-trend']['revenueEstimateGrowth'],
                    values['iSqore']['totalRevenueGrowthEstimateGrade'],
                    values['median_sector']['totalRevenueGrowthEstimate'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthTTMGrade'],
                    values['median_sector']['revenueGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthYOYGrade'],
                    values['median_sector']['revenueGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),
                return_obj(
                    "Revenue Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['totalRevenue'],
                    values['iSqore']['revenueGrowthYearGrade'],
                    values['median_sector']['revenueGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['totalRevenue'],
                    values['calculation-5-year-avg']['historical_data']['totalRevenue'],
                    true
                ),


                return_obj(
                    "Net Income Growth (Fwd)",
                    values['earning-trend']['earningsEstimateGrowth'],
                    values['iSqore']['earningsGrowthEstimateGrade'],
                    values['median_sector']['earningsGrowthEstimate'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthTTMGrade'],
                    values['median_sector']['earningsGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthYOYGrade'],
                    values['median_sector']['earningsGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),
                return_obj(
                    "Net Income Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['netIncome_income_statement'],
                    values['iSqore']['earningsGrowthYearGrade'],
                    values['median_sector']['earningsGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['netIncome_income_statement'],
                    values['calculation-5-year-avg']['historical_data']['netIncome_income_statement'],
                    true
                ),


                return_obj(
                    "FCF Growth (TTM)",
                    values['calculation-growth-quarterly-ttm']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthTTMGrade'],
                    values['median_sector']['freeCashFlowGrowthTTM'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),
                return_obj(
                    "FCF Growth (YoY)",
                    values['calculation-quarterly-yoy']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthYOYGrade'],
                    values['median_sector']['freeCashFlowGrowthYOY'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),
                return_obj(
                    "FCF Growth (Year)",
                    values['calculation-growth-yearly']['historical_data']['freeCashFlow'],
                    values['iSqore']['freeCashFlowGrowthYearGrade'],
                    values['median_sector']['freeCashFlowGrowthYear'],
                    values['calculation-3-year-avg']['historical_data']['freeCashFlow'],
                    values['calculation-5-year-avg']['historical_data']['freeCashFlow'],
                    true
                ),

                return_obj(
                    "Return on Equity (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnEquity'],
                    values['iSqore']['returnOnEquityTTMGrade'],
                    values['median_sector']['returnOnEquityTTM'],
                    values['calculation-3-year-avg']['historical_data']['returnOnEquity'],
                    values['calculation-5-year-avg']['historical_data']['returnOnEquity'],
                    true
                ),
                return_obj(
                    "Return on Equity (Year)",
                    values['additional_cal']['returnOnEquity'],
                    values['iSqore']['returnOnEquityYearGrade'],
                    values['median_sector']['returnOnEquityYear'],
                    values['calculation-3-year-avg']['historical_data']['returnOnEquity'],
                    values['calculation-5-year-avg']['historical_data']['returnOnEquity'],
                    true
                ),


                return_obj(
                    "Return on Cap Empl. (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnCapitalEmployed'],
                    values['iSqore']['returnOnCapitalEmployedTTMGrade'],
                    values['median_sector']['returnOnCapitalEmployedTTM'],
                    values['calculation-3-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    true
                ),
                return_obj(
                    "Return on Cap Empl. (Year)",
                    values['additional_cal']['returnOnCapitalEmployed'],
                    values['iSqore']['returnOnCapitalEmployedYearGrade'],
                    values['median_sector']['returnOnCapitalEmployedYear'],
                    values['calculation-3-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    true
                ),

                return_obj(
                    "Return on Invested Capital. (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnInvestedCapital'],
                    values['iSqore']['returnOnInvestedCapitalTTMGrade'],
                    values['median_sector']['returnOnInvestedCapitalTTM'],
                    values['calculation-3-year-avg']['historical_data']['returnOnInvestedCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnInvestedCapital'],
                    true
                ),
                return_obj(
                    "Return on Invested Capital. (Year)",
                    values['additional_cal']['returnOnInvestedCapital'],
                    values['iSqore']['returnOnInvestedCapitalYearGrade'],
                    values['median_sector']['returnOnInvestedCapitalYear'],
                    values['calculation-3-year-avg']['historical_data']['returnOnInvestedCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnInvestedCapital'],
                    true
                ),

                return_obj(
                    "Gross Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['grossMargin'],
                    values['iSqore']['grossMarginTTMGrade'],
                    values['median_sector']['grossMarginTTM'],
                    values['calculation-3-year-avg']['historical_data']['grossMargin'],
                    values['calculation-5-year-avg']['historical_data']['grossMargin'],
                    true
                ),
                return_obj(
                    "Gross Margin (Year)",
                    values['additional_cal']['grossMargin'],
                    values['iSqore']['grossMarginYearGrade'],
                    values['median_sector']['grossMarginYear'],
                    values['calculation-3-year-avg']['historical_data']['grossMargin'],
                    values['calculation-5-year-avg']['historical_data']['grossMargin'],
                    true
                ),

                return_obj(
                    "Net Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['netMargin'],
                    values['iSqore']['netMarginTTMGrade'],
                    values['median_sector']['netMarginTTM'],
                    values['calculation-3-year-avg']['historical_data']['netMargin'],
                    values['calculation-5-year-avg']['historical_data']['netMargin'],
                    true
                ),
                return_obj(
                    "Net Margin (Year)",
                    values['additional_cal']['netMargin'],
                    values['iSqore']['netMarginYearGrade'],
                    values['median_sector']['netMarginYear'],
                    values['calculation-3-year-avg']['historical_data']['netMargin'],
                    values['calculation-5-year-avg']['historical_data']['netMargin'],
                    true
                ),

                return_obj(
                    "FCF Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['fcfMargin'],
                    values['iSqore']['fcfMarginTTMGrade'],
                    values['median_sector']['fcfMarginTTM'],
                    values['calculation-3-year-avg']['historical_data']['fcfMargin'],
                    values['calculation-5-year-avg']['historical_data']['fcfMargin'],
                    true
                ),
                return_obj(
                    "FCF Margin (Year)",
                    values['additional_cal']['fcfMargin'],
                    values['iSqore']['fcfMarginYearGrade'],
                    values['median_sector']['fcfMarginYear'],
                    values['calculation-3-year-avg']['historical_data']['fcfMargin'],
                    values['calculation-5-year-avg']['historical_data']['fcfMargin'],
                    true
                ),

                return_obj(
                    "Cash/Debt",
                    values['additional_cal_quarter']['cashTotalDebt'],
                    values['iSqore']['cashTotalDebtGrade'],
                    values['median_sector']['cashTotalDebt'],
                    values['calculation-3-year-avg']['historical_data']['cashTotalDebt'],
                    values['calculation-5-year-avg']['historical_data']['cashTotalDebt']
                ),
                return_obj(
                    "Cash/Debt (Year)",
                    values['additional_cal']['cashTotalDebt'],
                    values['iSqore']['cashTotalDebtYearGrade'],
                    values['median_sector']['cashTotalDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['cashTotalDebt'],
                    values['calculation-5-year-avg']['historical_data']['cashTotalDebt']
                ),


                return_obj(
                    "Debt/Capital",
                    values['additional_cal_quarter']['debtCapital'],
                    values['iSqore']['debtCapitalGrade'],
                    values['median_sector']['cashDebt'],
                    values['calculation-3-year-avg']['historical_data']['debtCapital'],
                    values['calculation-5-year-avg']['historical_data']['debtCapital']
                ),
                return_obj(
                    "Debt/Capital (Year)",
                    values['additional_cal']['debtCapital'],
                    values['iSqore']['debtCapitalYearGrade'],
                    values['median_sector']['cashDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['debtCapital'],
                    values['calculation-5-year-avg']['historical_data']['debtCapital']
                ),

                return_obj(
                    "FCF/Debt",
                    values['additional_cal_quarter']['fcfDebt'],
                    values['iSqore']['fcfDebtGrade'],
                    values['median_sector']['fcfDebt'],
                    values['calculation-3-year-avg']['historical_data']['fcfDebt'],
                    values['calculation-5-year-avg']['historical_data']['fcfDebt']
                ),
                return_obj(
                    "FCF/Debt (Year)",
                    values['additional_cal']['fcfDebt'],
                    values['iSqore']['fcfDebtYearGrade'],
                    values['median_sector']['fcfDebtYear'],
                    values['calculation-3-year-avg']['historical_data']['fcfDebt'],
                    values['calculation-5-year-avg']['historical_data']['fcfDebt']
                ),


                return_obj(
                    "Interest Coverage",
                    values['calculation-quarterly-ttm']['historical_data']['interestCoverage'],
                    values['iSqore']['interestCoverageTTMGrade'],
                    values['median_sector']['interestCoverageTTMGrade'],
                    values['calculation-3-year-avg']['historical_data']['interestCoverage'],
                    values['calculation-5-year-avg']['historical_data']['interestCoverage']
                ),
                return_obj(
                    "Interest Coverage (Year)",
                    values['additional_cal']['interestCoverage'],
                    values['iSqore']['interestCoverageYearGrade'],
                    values['median_sector']['interestCoverageYear'],
                    values['calculation-3-year-avg']['historical_data']['interestCoverage'],
                    values['calculation-5-year-avg']['historical_data']['interestCoverage']
                ),

                return_obj(
                    "Debt/Equity",
                    values['additional_cal_quarter']['debtEquity'],
                    values['iSqore']['debtEquityGrade'],
                    values['median_sector']['debtEquity'],
                    values['calculation-3-year-avg']['historical_data']['debtEquity'],
                    values['calculation-5-year-avg']['historical_data']['debtEquity']
                ),
                return_obj(
                    "Debt/Equity (Year)",
                    values['additional_cal']['debtEquity'],
                    values['iSqore']['debtEquityYearGrade'],
                    values['median_sector']['debtEquityYear'],
                    values['calculation-3-year-avg']['historical_data']['debtEquity'],
                    values['calculation-5-year-avg']['historical_data']['debtEquity']
                ),

                return_obj(
                    "NetDebt/Equity",
                    values['additional_cal_quarter']['netDebtEquity'],
                    values['iSqore']['netDebtEquityGrade'],
                    values['median_sector']['netDebtEquity'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEquity'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEquity']
                ),
                return_obj(
                    "NetDebt/Equity (Year)",
                    values['additional_cal']['netDebtEquity'],
                    values['iSqore']['netDebtEquityYearGrade'],
                    values['median_sector']['netDebtEquityYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEquity'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEquity']
                ),

                return_obj(
                    "Cash/Ratio (Quarter)",
                    values['additional_cal_quarter']['cashRatio'],
                    values['iSqore']['cashRatioGrade'],
                    values['median_sector']['cashRatio'],
                    values['calculation-3-year-avg']['historical_data']['cashRatio'],
                    values['calculation-5-year-avg']['historical_data']['cashRatio']
                ),
                return_obj(
                    "Cash/Ratio (Year)",
                    values['additional_cal']['cashRatio'],
                    values['iSqore']['cashRatioYearGrade'],
                    values['median_sector']['cashRatioYear'],
                    values['calculation-3-year-avg']['historical_data']['cashRatio'],
                    values['calculation-5-year-avg']['historical_data']['cashRatio']
                ),


                return_obj(
                    "Debt/Asset (Quarter)",
                    values['additional_cal_quarter']['debtAsset'],
                    values['iSqore']['debtAssetGrade'],
                    values['median_sector']['debtAsset'],
                    values['calculation-3-year-avg']['historical_data']['debtAsset'],
                    values['calculation-5-year-avg']['historical_data']['debtAsset']
                ),
                return_obj(
                    "Debt/Asset (Year)",
                    values['additional_cal']['debtAsset'],
                    values['iSqore']['debtAssetYearGrade'],
                    values['median_sector']['debtAssetYear'],
                    values['calculation-3-year-avg']['historical_data']['debtAsset'],
                    values['calculation-5-year-avg']['historical_data']['debtAsset']
                ),


                return_obj(
                    "NetDebt/Asset (Quarter)",
                    values['additional_cal_quarter']['netDebtAsset'],
                    values['iSqore']['netDebtAssetGrade'],
                    values['median_sector']['netDebtAsset'],
                    values['calculation-3-year-avg']['historical_data']['netDebtAsset'],
                    values['calculation-5-year-avg']['historical_data']['netDebtAsset']
                ),
                return_obj(
                    "NetDebt/Asset (Year)",
                    values['additional_cal']['netDebtAsset'],
                    values['iSqore']['netDebtAssetYearGrade'],
                    values['median_sector']['netDebtAssetYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtAsset'],
                    values['calculation-5-year-avg']['historical_data']['netDebtAsset']
                ),


                return_obj(
                    "Debt/EBIT (Quarter)",
                    values['calculation-quarterly-ttm']['historical_data']['debtEBIT'],
                    values['iSqore']['debtEbitTTMGrade'],
                    values['median_sector']['debtEbitTTM'],
                    values['calculation-3-year-avg']['historical_data']['debtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['debtEBIT']
                ),
                return_obj(
                    "Debt/EBIT (Year)",
                    values['additional_cal']['debtEBIT'],
                    values['iSqore']['debtEbitYearGrade'],
                    values['median_sector']['debtEbitYear'],
                    values['calculation-3-year-avg']['historical_data']['debtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['debtEBIT']
                ),


                return_obj(
                    "NetDebt/EBIT (Quarter)",
                    values['calculation-quarterly-ttm']['historical_data']['netDebtEBIT'],
                    values['iSqore']['netDebtEbitTTMGrade'],
                    values['median_sector']['netDebtEbitTTM'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBIT']
                ),
                return_obj(
                    "NetDebt/EBIT (Year)",
                    values['additional_cal']['netDebtEBIT'],
                    values['iSqore']['netDebtEbitYearGrade'],
                    values['median_sector']['netDebtEbitYear'],
                    values['calculation-3-year-avg']['historical_data']['netDebtEBIT'],
                    values['calculation-5-year-avg']['historical_data']['netDebtEBIT']
                ),
            ],
            iSqore: {
                value: values['iSqore']['quality'],
                grade: return_string(values['iSqore'], 'qualityGrade'),
            }
        }
        setData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreValueDark}
            lightBlockerImg={iSqoreValueLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row g-0">

                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ISqoreTable
                                    name={'Quality'}
                                    data={data}
                                />
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <RoicGrossMarginFcfMargin code={code} exchange={exchange} type={'annual'}
                                                          colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>

                                <DebtEquityCashRatioChart code={code} exchange={exchange} type={'annual'}
                                                          colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>

                        </div>
                    </>
            }
        />
    );
}

export default QualityTab;