import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../../../Context/ThemeContext";
import axios from "axios";
import Config from "../../../../../Config";
import {return_strong_num, return_value_in_million} from "../../../../Utils";
import ReactApexChart from "react-apexcharts";

function FinancialBreakdownChart({code, exchange, type, colors}) {
    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const [loading, setLoading] = useState(true)

    const [series, setSeries] = useState([
        {
            name: 'Total Stockholder Equity',
            data: []
        },
        {
            name: 'Total Current Liabilities',
            data: []
        },
        {
            name: 'Non-current Liabilities Total',
            data: []
        }
    ])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-snapshots-break-down/${code}/${exchange}/${type}/`, Config())
            .then(response => {
                const data = response.data

                let totalStockholderEquity = []
                let totalCurrentLiabilities = []
                let nonCurrentLiabilitiesTotal = []

                data.dates.map((date, index) => {
                    totalStockholderEquity.push({
                        x: date,
                        y: return_value_in_million(return_strong_num(data.totalStockholderEquity[index]))
                    })
                    totalCurrentLiabilities.push({
                        x: date,
                        y: return_value_in_million(return_strong_num(data.totalCurrentLiabilities[index]))
                    })
                    nonCurrentLiabilitiesTotal.push({
                        x: date,
                        y: return_value_in_million(return_strong_num(data.nonCurrentLiabilitiesTotal[index]))
                    })
                })

                setSeries([
                    {
                        name: 'Total Stockholder Equity',
                        data: totalStockholderEquity
                    },
                    {
                        name: 'Total Current Liabilities',
                        data: totalCurrentLiabilities
                    },
                    {
                        name: 'Non-current Liabilities Total',
                        data: nonCurrentLiabilitiesTotal
                    }
                ])
            })
    }, [code, exchange, type])

    const options = {
        chart: {
            type: 'area',
            width: '100%',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [0, 3],
            curve: 'smooth'
        },
        colors: colors,
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        yaxis: {},
        fill: {
            opacity: 0.5
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {},
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        }
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'company-overview-chart-container'}>
            <div className={'company-overview-chart-inner-container'}>
                <div id="chart">
                    <div className="toolbar">
                        <p className={'market-overview-chart-name'}>Financial Breakdown</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type={'area'} width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default FinancialBreakdownChart;