import React, {useContext, useEffect, useState} from 'react';
import EtfSelectableTable from "../../../ETF/ETFSelectableTable";
import {
    ISqoreDescription,
    numberWithCommas,
    return_float,
    return_strong_num,
    return_td_color,
    return_value_in_million
} from "../../../Utils";
import SummaryTabGraphContainer from "../../../MarketOverview/Summary/SummaryTabGraphContainer";
import PerformanceWeightsTable from "../../../ETF/PerformanceWeightsTable";
import EtfProfilerTable from "../../../ETF/EtfProfilerTable";
import building from "../../../../assets/default-building.png";
import LoginPaymentBlocker from "../../../LoginPaymentBlocker";
import {useSelector} from "react-redux";
import etfISqoreTopBarDark from "../../../../assets/blockers/etf-isqore-top-bar-dark.png";
import etfISqoreTopBarLight from "../../../../assets/blockers/etf-isqore-top-bar-light.png";
import {ThemeContext} from "../../../../Context/ThemeContext";
import SectorWeight from "../../../ETF/SectorWeight";
import EtfSectorWeights from "../../../ETF/ETFSectorWeights";

function EtfProfiler({code, exchange, chartData, data, iSqore, priceData, loader}) {
    const {webTheme} = useContext(ThemeContext);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    return (
        <div>
            {(loader || data === null) ?
                <div style={{width: '100%', height: '100%', display: ' flex'}}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                <>
                    <div className={'company-detail-tab'}>
                        <div className={'etf-detail-container'}>
                            <div className={'company-detail-name-container'}>
                                <div style={{display: 'flex'}}>
                                    <div style={{height: '100%', display: 'flex'}}>
                                        <img className={'company-overview-logo'}
                                             src={data.etfData.LogoURL && data.etfData.LogoURL !== '' ? data.etfData.LogoURL : building}
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null;
                                                 currentTarget.src = building;
                                             }}/>
                                    </div>
                                    <div>
                                        <p className={'company-overview-name'}>{data.code} - {data.name}</p>
                                        <p className={'company-overview-meta'}>ISIN: {data.ISIN}</p>
                                        <p className={'company-overview-meta'}>Category: {data.general.Category}</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', marginTop: 10}}>
                                    <p className={'etf-profiler-adjusted-close'}>{priceData.price} {data.general.CurrencySymbol}
                                        <span className={'etf-profiler-adjusted-close-percentage-1'}
                                              style={{color: return_td_color(priceData.change)}}>
                                                {return_strong_num(priceData.change)} &nbsp;</span>

                                        <span className={'etf-profiler-adjusted-close-percentage-2'}
                                              style={{color: return_td_color(priceData.changesPercentage)}}>
                                            ({priceData.changesPercentage.toFixed(2)}%)
                                        </span>
                                    </p>
                                </div>
                                <p className={'company-overview-meta'}>Price as of {priceData['adjusted_close_date']}</p>
                            </div>
                            <div className={'entire-body-sm'}>
                                {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                                    <LoginPaymentBlocker condition={'You must be paid subscriber to use  this feature'}
                                                         lg={false} login={true} subscription={true}/>
                                }

                                {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                                    <img src={webTheme === 'dark' ? etfISqoreTopBarDark : etfISqoreTopBarLight}
                                         className={'blocker-img'} style={{width: 550}}/>
                                }

                                {(isAuthenticated && user.subscription.package_name !== 'Free' && iSqore) &&
                                    <div
                                        style={{
                                            position: 'relative', width: '100%', height: '100%',
                                            display: 'flex', marginLeft: "auto"
                                        }}>
                                        <>
                                            <div style={{margin: "auto 20px"}}>
                                                <p className={'etf-top-bar-info'}>
                                                    <b>TER: {return_strong_num(data.etfData.NetExpenseRatio * 100)} %</b>
                                                </p>
                                                <p className={'etf-top-bar-info'}>
                                                    <b>AUM {data.general.CurrencySymbol} (mln
                                                        .): {numberWithCommas(return_value_in_million(data.etfData.TotalAssets))}</b>
                                                </p>
                                                <br/>
                                                <p className={'etf-top-bar-info'}>
                                                    <b>Holdings: {data.etfData.Holdings_Count}</b></p>
                                                <p className={'etf-top-bar-info'}><b>Top 10
                                                    Holdings: {return_strong_num(iSqore.average_weight)} %</b></p>
                                            </div>

                                            <div className={'company-overview-top-bar-separator'}/>

                                            <ISqoreDescription iSqore={iSqore.average_iSqore}
                                                               iSqoreGrade={iSqore.average_iSqoreGrade}
                                                               value={iSqore.average_value}
                                                               valueGrade={iSqore.average_valueGrade}
                                                               growth={iSqore.average_growth}
                                                               growthGrade={iSqore.average_growthGrade}
                                                               profit={iSqore.average_profit}
                                                               profitGrade={iSqore.average_profitGrade}
                                                               health={iSqore.average_health}
                                                               healthGrade={iSqore.average_healthGrade}
                                                               quality={iSqore.average_quality}
                                                               qualityGrade={iSqore.average_qualityGrade}
                                            />
                                        </>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={'row g-0 my-2'}>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                            <SummaryTabGraphContainer chartData={chartData}
                                                      container={'etf-profiler-line-chart-container'}
                                                      innerContainer={'etf-profiler-inner-line-chart-container'}/>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-8 col-sm-12 col-xs-12">
                            <PerformanceWeightsTable data={data}/>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-8 col-sm-12 col-xs-12">
                            <EtfSectorWeights data={data}/>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <EtfProfilerTable data={data}/>
                        </div>
                    </div>

                    {/*<div className={'etf-profiler-description-container'}>*/}
                    {/*    <div className={'etf-profiler-description-inner-container'}>*/}
                    {/*        <p className={'etf-profiler-description-inner-container-heading'}>Description</p>*/}
                    {/*        <p className={'etf-profiler-description-inner-container-text'}>*/}
                    {/*            {data.general.Description}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <EtfSelectableTable code={code} exchange={exchange} etf_id={data.id} holdings_length={data.holdings_length}/>
                </>
            }
        </div>
    );
}

export default EtfProfiler;