import React from 'react';

function ConnectedPortfoliosTab(props) {
    return (
        <div className="profile-container">
            <div style={{display: 'flex', marginBottom: 15}}>
                <p className={'profile-header'}>Connected Portfolio</p>
            </div>
            <p style={{fontSize: 14, fontWeight: 600}}>Coming Soon ...</p>
        </div>
    );
}

export default ConnectedPortfoliosTab;