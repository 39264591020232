import React, {useContext, useState} from 'react';
import {ThemeContext} from "../../Context/ThemeContext";
import {TablePagination} from "@material-ui/core";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {numberWithCommas, return_value_in_million} from "../Utils";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import building from "../../assets/default-building.png";

function DividendsTable({tableData, handleOpenModal}) {

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const navigate = useNavigate()

    const {webTheme} = useContext(ThemeContext);

    const [columns, setColumns] = useState(
        [
            {name: 'Watchlist', className: ''},
            {name: 'Ticker', className: ''},
            {name: 'Company', className: ''},
            {name: 'Exchange', className: ''},
            {name: 'Market Cap.', className: ''},
            {name: 'Dividend', className: ''},
            {name: 'Ex-Date', className: ''},
            {name: 'Record Date', className: ''},
            {name: 'Pay Date', className: ''}
        ]
    )

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className={'table-responsive'}>
            <table className="table generic-lg-table">
                <thead>
                <tr>
                    {columns.map((item, index) => {
                        return (
                            <th key={index} className={item.class_name} onClick={() => {
                            }}>
                                {item.name}
                            </th>
                        )
                    })}
                </tr>
                </thead>

                <tbody>
                {
                    tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                        return (
                            <>
                                <tr>
                                    <td
                                        className={'watchlist'}
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                toast.error("Please login to add stocks into watchlist")
                                                navigate('/login/')
                                                return
                                            }
                                            handleOpenModal(item)
                                        }}>
                                        {item.watchlist ? <AiFillStar
                                            style={{
                                                color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                            }}/> : <AiOutlineStar
                                            style={{
                                                color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                            }}/>}
                                    </td>

                                    <td>
                                        <img className={'price-target-company-logo'}
                                             src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null;
                                                 currentTarget.src = building;
                                             }}/>
                                        {item.ticker}
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.exchange}
                                    </td>
                                    <td>
                                        {numberWithCommas(return_value_in_million(item.mcap))}
                                    </td>
                                    <td>
                                        {item.dividend}
                                    </td>
                                    <td>
                                        {item['ex-date']}
                                    </td>
                                    <td>
                                        {item['record-date']}
                                    </td>
                                    <td>
                                        {item['pay-date']}
                                    </td>
                                </tr>
                                <div style={{marginTop: 3, width: "100%"}}/>
                            </>
                        )
                    })
                }
                </tbody>

            </table>
            <TablePagination
                style={{color: webTheme === 'dark' ? 'white' : 'black'}}
                rowsPerPageOptions={false}
                component="div"
                count={tableData ? tableData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default DividendsTable;