import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import Config from "../../Config";
import {useNavigate} from "react-router-dom";
import InvestorCard from "./InvestorCard";

const get_top_investor_data = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/top-investors/`, Config())
}

function TopInvestor({setValue, topInvestor, setTopInvestor}) {

    const investor_order = ['Warren Buffett', 'Charlie Munger', 'Bill Gates', 'Ray Dalio', 'Michael Burry', 'Bill Ackman', 'David Einhorn', 'Mohnish Pabrai', 'David Tepper', 'Joel Greenblatt', 'Li Lu', 'Paul Tudor Jones', 'Stanley Druckenmiller', 'Daniel Loeb', 'Carl Icahn', 'Ken Fisher', 'Tom Russo', 'George Soros', 'Steven Cohen', 'Jim Simons', 'John Paulson', 'Howard Marks', 'Van Duyn Dodge & E. Morris Cox', 'Benjamin Allen', 'David M. Polen', 'Chris Hohn', 'Andreas Halvorsen', 'Richard Pzena', 'Terry Smith', 'Philippe Laffont', 'Richard Greenberg & Jon Hartsel', 'Al Gore', 'Chris Davis', 'William Von Mueffling', 'Chase Coleman', 'Eric Schoenstein', 'Chuck Akre', 'Stephen Mandel', 'Donald Yacktman', 'John W. Rogers Jr.', 'Edgar Wachenheim III', 'Mario Gabelli', 'John Armitage', 'Thomas Gayner', 'Nicolai Tangen', 'Charles Brandes', 'Ruane Cunniff', 'Louis Moore Bacon', 'Cole Smead', 'Michael Lindsell & Nick Train', 'Lee Ainslie', 'Seth Klarman', 'Mark Massey', 'Glenn Greenberg', 'Sarah Ketterer', 'Donald Smith', 'Andrew Brenton', 'Dev Kantesaria', 'David Abrams', 'Harry Burn', 'Mason Hawkins', 'Francois Rochon', 'Bill Tweedy & Christopher Browne', 'Wallace Weitz', 'Bruce Berkowitz', 'Prem Watsa', 'Barry Rosenstein & Scott Ostfeld', 'Greg Alexander', 'Frederick (Shad) Rowe', 'Mairs and Power', 'Hamilton Helmer', 'Gregg J. Powers', 'Clifford Sosin', 'Pat Dorsey', 'Ken Heebner', 'Glenn Welling', 'Thomas Graham, Alan & Irving Kahns', 'Jeff Auxier', 'Dennis Hong', 'Robert Olstein', 'Robert Karr', 'Christopher Bloomstran', 'David Rolfe', 'Adam Wyden', 'John Hussman', 'Norbert Lou', 'Robert Vinall', 'Frank Sands', 'Michael Dell', 'Josh Tarasoff', 'Arnold Van Den Berg', 'Mark Hillman', 'Alex Roepers', 'Charles Jigarjian', 'Guy Spier', 'Bill Miller', 'Bryan Lawrence', 'Connor Haley', 'Francis Chou', 'Julian Robertson']

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    const [filterTopInvestor, setFilterTopInvestor] = useState([])

    const {data: investorDataReturn, isLoading: isLoadingInvestorData} = useQuery({
        queryKey: [`get-top-investor-data`],
        queryFn: () => get_top_investor_data(),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (investorDataReturn && !isLoadingInvestorData) {
            const sorted = investorDataReturn.data.sort((a, b) => {
                const orderA = investor_order.indexOf(a['name']);
                const orderB = investor_order.indexOf(b['name']);
                return orderA - orderB;
            });

            setTopInvestor(sorted)
            setFilterTopInvestor(sorted)
            setIsLoading(false)
        }
    }, [investorDataReturn, isLoadingInvestorData])

    const [search, setSearch] = useState('')

    useEffect(() => {
        if (topInvestor.length > 0 && !isLoading) {
            if (search !== '') {
                setFilterTopInvestor(topInvestor.filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.company.toLowerCase().includes(search.toLowerCase())))
            } else {
                setFilterTopInvestor(topInvestor)
            }
        }
    }, [search, topInvestor, isLoading])

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    const [sortBy, setSortBy] = useState("")

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    useEffect(() => {
        const sortedInvestors = [...filterTopInvestor].sort((a, b) => {
            if (sortBy === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortBy === 'marketValue' || sortBy === 'portfolioSize' || sortBy === 'weight' || sortBy === 'turnover') {
                return b[sortBy] - a[sortBy];
            }
            return 0;
        });

        setFilterTopInvestor(sortedInvestors);
    }, [sortBy]);

    return (
        <div>

            <div className={'guru-search-sort-bar'}>
                <input
                    placeholder={'Search for Companies'}
                    className={'form-control indices-search'}
                    onChange={onChange}
                />

                <select
                    value={sortBy}
                    onChange={handleSortChange}
                    className="form-select currency-selector-financials">
                    <option selected value="">Sort By</option>
                    <option value="name">Name</option>
                    <option value="marketValue">Market Value</option>
                    <option value="portfolioSize">Portfolio Size</option>
                    <option value="weight">Top 10 Weight</option>
                    <option value="turnover">Recent Turnover</option>
                </select>
            </div>

            {isLoading ?
                <div style={{width: '100%', height: '100%', display: ' flex'}}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                <div className={'row'}>
                    {filterTopInvestor.map((investor, index) =>
                        <div key={index}
                             className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 col-12 p-2">
                            <div className={'investor-holding'}
                                 onClick={() => {
                                     navigate(`/investor/${investor.name.replace(' ', '-')}/`)
                                     setValue(1)
                                 }}
                            >
                                <InvestorCard investor={investor}/>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export default TopInvestor;
