import logo from "../../assets/finqube-navbar-logo.png";
import logosm from "../../assets/Finqube-05.png";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {load_user, Logout, RefreshToken, VerifyToken} from "../../actions/actions";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {BsSearch} from "react-icons/bs";
import SearchModalBody from "../Navbar/SearchModalBody";
import {Modal} from "@material-ui/core";
import {gtag} from "ga-gtag";

function LandingPageNavbar({landing = false}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    gtag('event', 'conversion', {'send_to': 'AW-918911974/0MgcCMnIjosYEOb3lbYD'});

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const [searchModal, setSearchModal] = useState(false)

    const handleOpenSearchModal = () => {
        setSearchModal(true)
    }

    const handleCloseSearchModal = () => {
        setSearchModal(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(VerifyToken(navigate))
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setInterval(() => {
                dispatch(RefreshToken())
            }, 5 * 60000);
        }
    }, [isAuthenticated]);

    const stocks = [{
        code: "AAPL",
        img: "https://ik.imagekit.io/finqube/AAPL.svg",
        alt: "Apple Logo",
        link: "/company-overview/AAPL/US/"
    }, {
        code: "IBM", img: "https://ik.imagekit.io/finqube/IBM.svg", alt: "IBM Logo", link: "/company-overview/IBM/US/"
    }, {
        code: "KO",
        img: "https://ik.imagekit.io/finqube/KO.svg",
        alt: "Coca Cola Logo",
        link: "/company-overview/KO/US/"
    }, {
        code: "DIS",
        img: "https://ik.imagekit.io/finqube/DIS.svg",
        alt: "Disney Logo",
        link: "/company-overview/DIS/US/"
    },]

    return (
        <header className={clsx(landing ? 'landing-page-header' : "navbar-header" )}>
            <nav className={'landing-page-navbar'}>
                <ul>
                    <li className={'landing-page-navbar-img-link'}
                        style={{margin: "auto auto auto 0"}}>
                        <Link to={'/market-overview/'}>
                            <img alt={"logo"} src={logo} className={'landing-page-navbar-img'}/>
                        </Link>
                    </li>

                    <li className={'landing-page-navbar-img-sm-link'}
                        style={{margin: "auto auto auto 0"}}>
                        <Link to={'/market-overview/'}>
                            <img alt={"logo"} src={logosm} className={'landing-page-navbar-img-sm'}/>
                        </Link>
                    </li>

                    <li>
                        <a href={'https://web.finqube.io/about'} className={'landing-page-navbar-link'}>About</a>
                    </li>
                    <li>
                        <a href={'https://web.finqube.io/about'} className={'landing-page-navbar-link'}>FAQ</a>
                    </li>
                    <li>
                        <a href={'https://web.finqube.io/tools/'} className={'landing-page-navbar-link'}>Tools</a>
                    </li>
                    <li>
                        <Link to={'/payment/'}
                              className={'landing-page-navbar-link'}>Pricing</Link>
                    </li>

                    {isAuthenticated &&
                        <li>
                            <div className={'profile-image-container'} onClick={() => navigate(`/profile/`)}
                                 style={{backgroundImage: user.img !== '' && `url(${user.img})`}}
                            >
                            </div>
                        </li>
                    }

                    {isAuthenticated ?
                        <li>
                            <button className={'btn btn-warning'}
                                    onClick={() => dispatch(Logout())}
                                    style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                                Logout
                            </button>
                        </li>
                        :
                        <li>
                            <button className={'btn btn-warning'}
                                    onClick={() => navigate('/login')}
                                    style={{fontSize: 10, fontWeight: 700, height: 30, margin: "auto 0 auto 10px"}}>
                                Login
                            </button>
                        </li>
                    }
                </ul>
            </nav>

            {landing &&
                <section
                    className={'landing-page-section'}>
                    <div style={{margin: "auto"}}>
                        <h1 className={'landing-page-section-h1'}>
                            MAKE INFORMED DECISIONS<br/>
                            AND INVEST SMART
                        </h1>
                        <h3 className={'landing-page-section-h3'}>
                            Your All-in-One Stock Analysis Platform With Powerful Tools & Features
                        </h3>

                        <div style={{margin: 'auto', width: '100%'}}>
                            <div className={'landing-page-search-container'}>
                                <div
                                    onClick={handleOpenSearchModal}
                                    className={clsx('landing-page-search-bar-container')}>
                                    <p className={'search-bar-container-text'}>Search Here</p>
                                    <BsSearch className={clsx('search-bar-icon')} style={{color: "white"}}/>
                                </div>
                                <div style={{display: 'flex', width: "100%"}}>
                                    <ul style={{
                                        display: 'flex',
                                        margin: "10px auto",
                                        padding: 0,
                                        listStyleType: "none"
                                    }}>
                                        {stocks.map((stock) => {
                                            return (<li>
                                                <Link key={stock.code} to={stock.link}
                                                      style={{textDecoration: "none"}}>
                                                    <div className={'home-page-company-container'}>
                                                        <img
                                                            className={"home-page-company-logo"}
                                                            src={stock.img}
                                                            alt={stock.alt}
                                                        />
                                                        <p className={'home-page-company-label'}>{stock.code}</p>
                                                    </div>
                                                </Link>
                                            </li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <Modal
                open={searchModal}
                onClose={handleCloseSearchModal}
                aria-labelledby="Company Search Modal."
                aria-describedby="Search any company or etf in the finqube universe."
            >
                <SearchModalBody handleCloseSearchModal={handleCloseSearchModal}/>
            </Modal>
        </header>
    );
}

export default LandingPageNavbar;
