import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../../../Context/ThemeContext";
import axios from "axios";
import Config from "../../../../../Config";
import {return_strong_num} from "../../../../Utils";
import ReactApexChart from "react-apexcharts";

function GrossMarginOperatingNetChart({code, exchange, type, iSqoreClass = 'company-overview-chart-inner-container', colors}) {
    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const [loading, setLoading] = useState(true)

    const [series, setSeries] = useState([
        {
            name: 'Gross Margin',
            data: []
        },
        {
            name: 'Net Margin',
            data: []
        },
        {
            name: 'Free Cash Flow Margin',
            data: []
        },
    ])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-snapshots-gross-margin-operating-net-margin/${code}/${exchange}/${type}/`, Config())
            .then(response => {
                const data = response.data

                let grossMargin = []
                let freeCashFlowMargin = []
                let netMargin = []

                data.dates.map((date, index) => {
                    grossMargin.push({
                        x: date,
                        y: return_strong_num(data.grossMargin[index] * 100)
                    })
                    freeCashFlowMargin.push({
                        x: date,
                        y: return_strong_num(data.freeCashFlowMargin[index] * 100)
                    })
                    netMargin.push({
                        x: date,
                        y: return_strong_num(data.netMargin[index] * 100)
                    })
                })

                setSeries([
                    {
                        name: 'Gross Margin',
                        data: grossMargin
                    },
                    {
                        name: 'Net Margin',
                        data: netMargin
                    },
                    {
                        name: 'Free Cash Flow Margin',
                        data: freeCashFlowMargin
                    }
                ])
            })
    }, [code, exchange, type])

    const options = {
        chart: {
            type: 'area',
            width: '100%',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: colors,
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return `${val}%`;
                }
            }
        },
        fill: {
            opacity: 0.5
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return `${value.toFixed(2)} %`
                }
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        }
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'company-overview-chart-container'}>
            <div className={iSqoreClass}>
                <div id="chart">
                    <div className="toolbar">
                        <p className={'market-overview-chart-name'}>Margins</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type={'line'} width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default GrossMarginOperatingNetChart;