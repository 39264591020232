import RegisterLayout from "../Layout/RegisterLayout";
import {login} from "../actions/actions";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from "react-hot-toast";
import axios from "axios";
import Config from "../Config";
import {raise_error} from "../Container/Utils";
import {Modal} from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import ChangePassword from "./ChangePassword";
import {Helmet} from "react-helmet-async";

const Login = () => {
    const formRef = useRef()
    const captchaRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false)

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)

    const location = useLocation()

    if (isAuthenticated)
        navigate('/')

    useEffect(() => {
        const activation_code = location.pathname.split('/')[2]
        if (activation_code) {
            const config = Config()
            toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/activate-account/`, {activation_code: activation_code}, config), {
                loading: 'Activating Account...',
                success: (res) => res.data.message,
                error: (err) => raise_error(err)
            })
        }
    }, [])

    const [userData, setUserData] = useState({
        email: "",
        password: "",
    })

    const handle_text_change = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }

    const submit = () => {
        if (captchaResult === null) {
            toast.error("Please check the captcha before submitting !!")
        } else {
            dispatch(login({...userData, 'captcha_value': captchaResult}, navigate))
            setCaptchaResult(null)
            captchaRef.current.reset();
        }
    }

    const [forgotPasswordModal, setForgotPasswordModal] = useState(false)

    const handleCloseForgotPasswordModal = () => {
        setForgotPasswordModal(false)
    }

    const handleOpenForgotPasswordModal = () => {
        setForgotPasswordModal(true)
    }

    const [captchaResult, setCaptchaResult] = useState(null)

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
    }

    return (
        <>
            <Helmet>
                <title>Login</title>
                <meta name={"description"}
                      content={"Login to Finqube.io"}/>
            </Helmet>
            <RegisterLayout>
                <div className={'login-container'}>
                    <p style={{fontSize: 16, color: "#1BA2DA"}}>Welcome</p>
                    <p className={'accounts-message mt-2 mb-4'}>Sign into Finqube</p>
                    <form ref={formRef}>
                        <div className={'row'}>
                            <div className="my-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p className={'input-field-helper-text'}>Email</p>
                                <input
                                    value={userData.email}
                                    name={'email'}
                                    onChange={handle_text_change}
                                    required={true}
                                    className={'form-control input-field'}
                                    placeholder={'Email Address'}/>
                            </div>
                            <div className="my-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="d-flex">
                                    <p className={'input-field-helper-text'}>Password</p>
                                    <p onClick={handleOpenForgotPasswordModal}
                                       style={{
                                           margin: "0 0 0 auto",
                                           fontSize: 12,
                                           fontWeight: 400,
                                           color: "#969696",
                                           cursor: 'pointer'
                                       }}
                                    >
                                        Forgot Password?
                                    </p>
                                </div>
                                <input
                                    value={userData.password}
                                    name={'password'}
                                    onChange={handle_text_change}
                                    required={true}
                                    type={showPassword ? 'text' : 'password'}
                                    className={'form-control input-field'}
                                    placeholder={'Password'}/>
                                <div className="d-flex">
                                    <p onClick={() => {
                                        setShowPassword(!showPassword)
                                    }} className={'show-password'}>Show Password</p>
                                </div>
                            </div>
                        </div>
                        <div className="captcha" style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>
                            <ReCAPTCHA
                                className={'g-recaptcha'}
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef}
                                theme={'dark'}
                                onChange={handleRecaptcha}
                            />
                        </div>
                        <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    const form = formRef.current;
                                    if (!form.checkValidity()) {
                                        form.reportValidity()
                                    } else {
                                        submit()
                                    }
                                }}
                                // disabled={captchaResult === null}
                                className={'btn reg-control-btn reg-next-btn mt-4'}>Sign in
                            </button>
                        </div>
                    </form>

                    <div style={{display: 'flex'}}>
                        <p className={'have-an-account-text'} onClick={() => {
                            navigate('/register')
                        }}>
                            Create new account? Register
                        </p>
                    </div>

                </div>

                <Modal
                    open={forgotPasswordModal}
                    onClose={handleCloseForgotPasswordModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ChangePassword handleCloseForgotPasswordModal={handleCloseForgotPasswordModal}/>
                </Modal>

            </RegisterLayout>
        </>
    );
}

export default Login;
