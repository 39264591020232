import React, {useEffect, useState} from 'react';
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {return_per_div, return_strong_num} from "../Utils";

function MarketOverviewIndicesTable({body, setBody, handleSetChartData, handleSetIndexCompanies, height, applySort=false}) {
    const [changeSort, setChangeSort] = useState(false)
    const [lettersLength, setLettersLength] = useState(35)

    function handleResize() {
        if (window.innerWidth <= 768) {
            setLettersLength(15)
        } else {
            setLettersLength(35)
        }
    }

    const sort = (order) => {
        setChangeSort(!changeSort)
        if (order) {
            setBody([...body].sort((a, b) => {
                if (a['change_p'] < b['change_p']) {
                    return -1;
                }
                if (a['change_p'] > b['change_p']) {
                    return 1;
                }
                return 0;
            }))
        } else {
            setBody([...body].sort((a, b) => {
                if (a['change_p'] > b['change_p']) {
                    return -1;
                }
                if (a['change_p'] < b['change_p']) {
                    return 1;
                }
                return 0;
            }));
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        if (applySort) {
            sort(changeSort)
        }
    }, [])

    return (
        <div className={'market-overview-summary-table-body-container'} style={{
            borderRadius: '0 0 10px 10px',
            height: height,
            overflowY: "auto"
        }}>
            <table className={'table generic-table-body'} style={{marginBottom: 7}}>
                <tbody className={'test-body'}>
                <tr>
                    <td style={{width: 25}}></td>
                    <td style={{fontWeight: 600}}>
                        Indices
                    </td>
                    <td style={{width: 60, fontWeight: 600}}>
                        Symbol
                    </td>
                    <td style={{width: 65, textAlign: 'right', fontWeight: 600}}>
                        Price
                    </td>
                    <td style={{width: 78, textAlign: 'right', fontWeight: 600, cursor: "pointer"}} onClick={() => {
                        sort(changeSort)
                    }}>
                        Chg %{!changeSort ? <BsArrowUp/> : <BsArrowDown/>}
                    </td>
                </tr>
                {body.map((row, index) => {
                    return (
                        <tr key={index} style={{cursor: 'pointer', borderBottom: "none"}}
                            onClick={() => {
                                handleSetChartData({
                                    name: row.name,
                                    code: row.code,
                                    exchange: row.exchange,
                                    type: row.type
                                })
                                if (handleSetIndexCompanies) {
                                    handleSetIndexCompanies({code: row.fmp, name: row.name})
                                }
                            }}>
                            <td><img src={row.flag} style={{width: 20}}/></td>
                            <td
                                style={{
                                    maxWidth: 80,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                {row.name}
                            </td>
                            <td>
                                {row.symbol}
                            </td>
                            <td style={{
                                textAlign: 'right'
                            }}>
                                {!isNaN(row.price) ? return_strong_num(row.price) : ""}
                            </td>
                            <td style={{
                                display: 'flex',
                                paddingRight: 2
                            }}>
                                {return_per_div(row.change_p)}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

export default MarketOverviewIndicesTable;