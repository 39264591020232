import DashboardLayout from "../Layout/DashboardLayout";
import MajorIndices from "../Container/MajorIndices/MajorIndices";
import IndicesTabs from "../Container/Tabs/IndicesPageTabs/IndicesTabs";
import {Helmet} from "react-helmet-async";
import React from "react";
import {GiGears} from "react-icons/gi";

const Indices = () => {

    return (
        <>
            <Helmet>
                <title>Index Tracker</title>
                <meta name={"description"}
                      content={"Indices: Explore global indices and stay informed about the performance of different markets with our platform."}/>
            </Helmet>
            <DashboardLayout>
                <MajorIndices/>
                <IndicesTabs/>
            </DashboardLayout>
        </>
    );
}

export default Indices;
