import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../Context/ThemeContext";
import clsx from "clsx";
import {return_num, return_strong_num} from "../../Utils";
import FinancialsControls from "./FinancialsControls";
import FinancialsTable from "./FinancialsTable";
import axios from "axios";
import Config from "../../../Config";

const RatioTab = ({code, exchange}) => {
    const [active, setActive] = useState('annual')

    const [groupedData, setGroupedData] = useState(null)

    const [loading, setLoading] = useState(true)

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`, Config())
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-ratio/${code}/${exchange}/${active}/`, Config())
            .then(response => {
                group_datas(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [active, code, exchange])

    const group_datas = (_data) => {
        let data = {
            dates: [],
            content: {}
        }

        if (active !== 'quarter') {
            data["content"]["value"] = {
                name: 'Value',
                data: {
                    'P/Earnings': [],
                    'P/Sales': [],
                    'P/Book': [],
                    'P/FreeCashFlow': [],
                    'Earnings/Yield': [],
                    'PEG Ratio': [],
                    'EV/Revenue': [],
                    'EV/EBIT': [],
                    'EV/EBITDA': [],
                }
            }
        }

        data["content"] = {
            ...data['content'],
            profit: {
                name: 'Profit',
                data: {
                    'Return on Assets': [],
                    'Return on Equity': [],
                    'Return on Capital': [],
                    'Return on Capital Employed': [],
                    'Return on Invested Capital': [],
                    'Operating Margin': [],
                    'Gross Margin': [],
                    'Net Margin': [],
                    'EBIT/Margin': [],
                    'EBITDA/Margin': [],
                    'FCF/Margin': [],
                    'FCF/Yield': [],
                    'Asset Turnover': []
                }
            },
            health: {
                name: 'Health',
                data: {
                    'Cash/Ratio': [],
                    'Cash/Debt': [],
                    'Debt/Capital': [],
                    'Equity/Assets': [],
                    'Debt/Equity': [],
                    'NetDebt/Equity': [],
                    'Debt/Asset': [],
                    'NetDebt/Asset': [],
                    'Debt/EBIT': [],
                    'NetDebt/EBIT': [],
                    'Debt/EBITDA': [],
                    'NetDebt/EBITDA': [],
                    'Current Ratio': [],
                    'Quick Ratio': [],

                }
            },
            per_share: {
                name: 'Per Share',
                data: {
                    'Revenue per Share': [],
                    'Earnings per Share': [],
                    'FCF per Share': [],
                    'Book Value per Share': []
                }
            },
            // dividend: {
            //     name: "Dividend",
            //     data: {
            //         'Dividend per Share': [],
            //         'Dividend Yield': [],
            //         'Dividend Growth': [],
            //         'Payout Ratio': []
            //     }
            // }
        }

        if (active !== 'ttm') {
            data["content"] = {
                ...data['content'],
                health: {
                    ...data['content']['health'],
                    data: {
                        ...data['content']['health']['data'],
                        'Altman Z-Score': [],
                        'Piotroski F-Score': []
                    }
                }
            }
        }

        _data.map((item) => {
            data['dates'].push(item.date)

            if (active === 'annual' || active === 'quarter') {
                if (active === 'annual') {
                    data['content']['value']['data']['P/Earnings'].push(return_strong_num(item['additional_calculations']['priceEarnings']))
                    data['content']['value']['data']['P/Sales'].push(return_strong_num(item['additional_calculations']['priceSales']))

                    data['content']['value']['data']['P/Book'].push(return_strong_num(item['additional_calculations']['priceBook']))

                    data['content']['value']['data']['P/FreeCashFlow'].push(return_strong_num(item['additional_calculations']['priceFreeCashFlow']))

                    data['content']['value']['data']['Earnings/Yield'].push(`${return_strong_num(item['additional_calculations']['earningsYield'] * 100)} %`)
                    data['content']['value']['data']['PEG Ratio'].push(return_strong_num(item['additional_calculations']['pegRatio']))
                    data['content']['value']['data']['EV/Revenue'].push(return_strong_num(item['additional_calculations']['evRevenue']))
                    data['content']['value']['data']['EV/EBIT'].push(return_strong_num(item['additional_calculations']['eveBit']))
                    data['content']['value']['data']['EV/EBITDA'].push(return_strong_num(item['additional_calculations']['eveBitda']))
                }
                data['content']['profit']['data']['Return on Assets'].push(`${return_strong_num(item['additional_calculations']['returnOnAssets'] * 100)} %`)
                data['content']['profit']['data']['Return on Equity'].push(`${return_strong_num(item['additional_calculations']['returnOnEquity'] * 100)} %`)
                data['content']['profit']['data']['Return on Capital'].push(`${return_strong_num(item['additional_calculations']['returnOnCapital'] * 100)} %`)
                data['content']['profit']['data']['Return on Capital Employed'].push(`${return_strong_num(item['additional_calculations']['returnOnCapitalEmployed'] * 100)} %`)
                data['content']['profit']['data']['Return on Invested Capital'].push(`${return_strong_num(item['additional_calculations']['returnOnInvestedCapital'] * 100)} %`)
                data['content']['profit']['data']['Operating Margin'].push(`${return_strong_num(item['additional_calculations']['operatingMargin'] * 100)} %`)
                data['content']['profit']['data']['Gross Margin'].push(`${return_strong_num(item['additional_calculations']['grossMargin'] * 100)} %`)
                data['content']['profit']['data']['Net Margin'].push(`${return_strong_num(item['additional_calculations']['netMargin'] * 100)} %`)
                data['content']['profit']['data']['EBIT/Margin'].push(`${return_strong_num(item['additional_calculations']['ebitMargin'] * 100)} %`)
                data['content']['profit']['data']['EBITDA/Margin'].push(`${return_strong_num(item['additional_calculations']['ebitdaMargin'] * 100)} %`)
                data['content']['profit']['data']['FCF/Margin'].push(`${return_strong_num(item['additional_calculations']['fcfMargin'] * 100)} %`)
                data['content']['profit']['data']['FCF/Yield'].push(`${return_strong_num(item['additional_calculations']['fcfYield'] * 100)} %`)
                data['content']['profit']['data']['Asset Turnover'].push(return_strong_num(item['additional_calculations']['assetTurnOver']))

                data['content']['health']['data']['Cash/Ratio'].push(return_strong_num(item['additional_calculations']['cashRatio']))
                data['content']['health']['data']['Cash/Debt'].push(return_strong_num(item['additional_calculations']['cashTotalDebt']))
                data['content']['health']['data']['Debt/Capital'].push(return_strong_num(item['additional_calculations']['debtCapital']))
                data['content']['health']['data']['Equity/Assets'].push(return_strong_num(item['additional_calculations']['equityAssets']))
                data['content']['health']['data']['Debt/Equity'].push(return_strong_num(item['additional_calculations']['debtEquity']))
                data['content']['health']['data']['NetDebt/Equity'].push(return_strong_num(item['additional_calculations']['netDebtEquity']))
                data['content']['health']['data']['Debt/Asset'].push(return_strong_num(item['additional_calculations']['debtAsset']))
                data['content']['health']['data']['NetDebt/Asset'].push(return_strong_num(item['additional_calculations']['netDebtAsset']))
                data['content']['health']['data']['Debt/EBIT'].push(return_strong_num(item['additional_calculations']['debtEBIT']))
                data['content']['health']['data']['NetDebt/EBIT'].push(return_strong_num(item['additional_calculations']['netDebtEBIT']))
                data['content']['health']['data']['Debt/EBITDA'].push(return_strong_num(item['additional_calculations']['debtEBITDA']))
                data['content']['health']['data']['NetDebt/EBITDA'].push(return_strong_num(item['additional_calculations']['netDebtEBITDA']))
                data['content']['health']['data']['Current Ratio'].push(return_strong_num(item['additional_calculations']['currentRatio']))
                data['content']['health']['data']['Quick Ratio'].push(return_strong_num(item['additional_calculations']['quickRatio']))
                data['content']['health']['data']['Altman Z-Score'].push(return_strong_num(item['additional_calculations']['altmanZScore']))
                data['content']['health']['data']['Piotroski F-Score'].push(return_strong_num(item['additional_calculations']['piotroskiFScore']))

                data['content']['per_share']['data']['Revenue per Share'].push(return_strong_num(item['income_statement']['revenuePerShare']))
                data['content']['per_share']['data']['Earnings per Share'].push(return_strong_num(item['income_statement']['earningsPerShare']))
                data['content']['per_share']['data']['FCF per Share'].push(return_strong_num(item['cash_flow']['freeCashFlowPerShare']))
                data['content']['per_share']['data']['Book Value per Share'].push(return_strong_num(item['balance_sheet']['bookValuePerShare']))

                // data['content']['dividend']['data']['Dividend per Share'].push(return_strong_num(item['income_statement']['dividendPerShare']))
                // data['content']['dividend']['data']['Dividend Yield'].push(return_strong_num(item['income_statement']['dividendYield']))
                // data['content']['dividend']['data']['Dividend Growth'].push(return_strong_num(item['cash_flow']['dividendGrowth']))
                // data['content']['dividend']['data']['Payout Ratio'].push(return_strong_num(item['balance_sheet']['payoutRatio']))

            } else if (active === 'ttm') {
                data['content']['value']['data']['P/Earnings'].push(return_strong_num(item['ttm']['historical_data']['priceEarnings']))
                data['content']['value']['data']['P/Sales'].push(return_strong_num(item['ttm']['historical_data']['priceSales']))

                data['content']['value']['data']['P/Book'].push(return_strong_num(item['priceBook']))

                data['content']['value']['data']['P/FreeCashFlow'].push(return_strong_num(item['ttm']['historical_data']['priceFreeCashFlow']))

                data['content']['value']['data']['Earnings/Yield'].push(return_strong_num(item['ttm']['historical_data']['earningsYield']))
                data['content']['value']['data']['PEG Ratio'].push(return_strong_num(item['ttm']['historical_data']['pegRatio']))
                data['content']['value']['data']['EV/Revenue'].push(return_strong_num(item['ttm']['historical_data']['evRevenue']))
                data['content']['value']['data']['EV/EBIT'].push(return_strong_num(item['ttm']['historical_data']['eveBit']))
                data['content']['value']['data']['EV/EBITDA'].push(return_strong_num(item['ttm']['historical_data']['eveBitda']))

                data['content']['profit']['data']['Return on Assets'].push(`${return_strong_num(item['ttm']['historical_data']['returnOnAssets'] * 100)} %`)
                data['content']['profit']['data']['Return on Equity'].push(`${return_strong_num(item['ttm']['historical_data']['returnOnEquity'] * 100)} %`)
                data['content']['profit']['data']['Return on Capital'].push(`${return_strong_num(item['ttm']['historical_data']['returnOnCapital'] * 100)} %`)
                data['content']['profit']['data']['Return on Capital Employed'].push(`${return_strong_num(item['ttm']['historical_data']['returnOnCapitalEmployed'] * 100)} %`)
                data['content']['profit']['data']['Return on Invested Capital'].push(`${return_strong_num(item['ttm']['historical_data']['returnOnInvestedCapital'] * 100)} %`)
                data['content']['profit']['data']['Operating Margin'].push(`${return_strong_num(item['ttm']['historical_data']['operatingMargin'] * 100)} %`)
                data['content']['profit']['data']['Gross Margin'].push(`${return_strong_num(item['ttm']['historical_data']['grossMargin'] * 100)} %`)
                data['content']['profit']['data']['Net Margin'].push(`${return_strong_num(item['ttm']['historical_data']['netMargin'] * 100)} %`)
                data['content']['profit']['data']['EBIT/Margin'].push(`${return_strong_num(item['ttm']['historical_data']['ebitMargin'] * 100)} %`)
                data['content']['profit']['data']['EBITDA/Margin'].push(`${return_strong_num(item['ttm']['historical_data']['ebitdaMargin'] * 100)} %`)
                data['content']['profit']['data']['FCF/Margin'].push(`${return_strong_num(item['ttm']['historical_data']['fcfMargin'] * 100)} %`)
                data['content']['profit']['data']['FCF/Yield'].push(`${return_strong_num(item['ttm']['historical_data']['fcfYield'] * 100)} %`)
                data['content']['profit']['data']['Asset Turnover'].push(`${return_strong_num(item['ttm']['historical_data']['assetTurnOver'] * 100)} %`)

                data['content']['health']['data']['Cash/Ratio'].push(return_strong_num(item['ttm']['historical_data']['cashRatio']))
                data['content']['health']['data']['Cash/Debt'].push(return_strong_num(item['ttm']['historical_data']['cashTotalDebt']))
                data['content']['health']['data']['Debt/Capital'].push(return_strong_num(item['ttm']['historical_data']['debtCapital']))
                data['content']['health']['data']['Equity/Assets'].push(return_strong_num(item['ttm']['historical_data']['equityAssets']))
                data['content']['health']['data']['Debt/Equity'].push(return_strong_num(item['ttm']['historical_data']['debtEquity']))
                data['content']['health']['data']['NetDebt/Equity'].push(return_strong_num(item['ttm']['historical_data']['netDebtEquity']))
                data['content']['health']['data']['Debt/Asset'].push(return_strong_num(item['ttm']['historical_data']['debtAsset']))
                data['content']['health']['data']['NetDebt/Asset'].push(return_strong_num(item['ttm']['historical_data']['netDebtAsset']))
                data['content']['health']['data']['Debt/EBIT'].push(return_strong_num(item['ttm']['historical_data']['debtEBIT']))
                data['content']['health']['data']['NetDebt/EBIT'].push(return_strong_num(item['ttm']['historical_data']['netDebtEBIT']))
                data['content']['health']['data']['Debt/EBITDA'].push(return_strong_num(item['ttm']['historical_data']['debtEBITDA']))
                data['content']['health']['data']['NetDebt/EBITDA'].push(return_strong_num(item['ttm']['historical_data']['netDebtEBITDA']))
                data['content']['health']['data']['Current Ratio'].push(return_strong_num(item['ttm']['historical_data']['currentRatio']))
                data['content']['health']['data']['Quick Ratio'].push(return_strong_num(item['ttm']['historical_data']['quickRatio']))
                // data['content']['health']['data']['Altman Z-Score'].push(return_strong_num(item['ttm']['historical_data']['altmanZScore']))
                // data['content']['health']['data']['Piotroski F-Score'].push(return_strong_num(item['ttm']['historical_data']['piotroskiFScore']))

                data['content']['per_share']['data']['Revenue per Share'].push(return_strong_num(item['ttm']['historical_data']['revenuePerShare']))
                data['content']['per_share']['data']['Earnings per Share'].push(return_strong_num(item['ttm']['historical_data']['earningsPerShare']))
                data['content']['per_share']['data']['FCF per Share'].push(return_strong_num(item['ttm']['historical_data']['freeCashFlowPerShare']))
                data['content']['per_share']['data']['Book Value per Share'].push(return_strong_num(item['ttm']['historical_data']['bookValuePerShare']))

                // data['content']['dividend']['data']['Dividend per Share'].push(return_strong_num(item['ttm']['historical_data']['dividendPerShare']))
                // data['content']['dividend']['data']['Dividend Yield'].push(return_strong_num(item['ttm']['historical_data']['dividendYield']))
                // data['content']['dividend']['data']['Dividend Growth'].push(return_strong_num(item['ttm']['historical_data']['dividendGrowth']))
                // data['content']['dividend']['data']['Payout Ratio'].push(return_strong_num(item['ttm']['historical_data']['payoutRatio']))

            }
        })

        setGroupedData(data)
    }

    const handleActive = (type) => {
        setActive(type)
        setLoading(true)
    }

    return (
        <>
            <FinancialsControls
                options={[{label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}, {label: "TTM", key: 'ttm'}]}
                handleActive={handleActive} active={active} meta={meta}/>

            {
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    groupedData &&
                    <FinancialsTable groupedData={groupedData}/>
            }
        </>
    );
}

export default RatioTab;