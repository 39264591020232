import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../../../Context/ThemeContext";
import axios from "axios";
import Config from "../../../../../Config";
import {return_strong_num} from "../../../../Utils";
import ReactApexChart from "react-apexcharts";

function RevGrowthNetOCFFCFChart({code, exchange, type, colors}) {
    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const [loading, setLoading] = useState(true)

    const [series, setSeries] = useState([
        {
            name: 'Rev Growth',
            data: []
        },
        {
            name: 'Net Income',
            data: []
        },
        {
            name: 'OCF',
            data: []
        },
        {
            name: 'FCF',
            data: []
        }
    ])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-snapshots-rev-growth-net-ocf-fcf/${code}/${exchange}/${type}/`, Config())
            .then(response => {
                const data = response.data

                let revenueGrowth = []
                let netIncome = []
                let totalCashFromOperatingActivities = []
                let freeCashFlow = []

                data.dates.map((date, index) => {
                    revenueGrowth.push({
                        x: date,
                        y: return_strong_num(data.revGrowth[index])
                    })
                    netIncome.push({
                        x: date,
                        y: return_strong_num(data.netIncome[index])
                    })
                    totalCashFromOperatingActivities.push({
                        x: date,
                        y: return_strong_num(data.ocf[index])
                    })
                    freeCashFlow.push({
                        x: date,
                        y: return_strong_num(data.fcf[index])
                    })
                })

                setSeries([
                    {
                        name: 'Rev Growth',
                        data: revenueGrowth
                    },
                    {
                        name: 'Net Income',
                        data: netIncome
                    },
                    {
                        name: 'OCF',
                        data: totalCashFromOperatingActivities
                    },
                    {
                        name: 'FCF',
                        data: freeCashFlow
                    },
                ])
            })
    }, [code, exchange, type])

    const options = {
        chart: {
            type: 'area',
            width: '100%',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors:colors,
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        yaxis: {},
        fill: {
            opacity: 0.5
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return value.toFixed(2)
                }
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        }
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'company-overview-chart-container'}>
            <div className={'company-overview-chart-inner-container'}>
                <div id="chart">
                    <div className="toolbar">
                        <p className={'market-overview-chart-name'}>Growth of: Revenue | Net Income | OCF | FCF</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type={'line'} width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default RevGrowthNetOCFFCFChart;