import React, {useState} from 'react';
import {AiOutlineClose, AiOutlinePlus} from "react-icons/ai";
import {Modal} from "@material-ui/core";
import FilterComponentSectorModal from "./FilterComponentSectorModal";

function FilterComponentSector({componentKey, groupKey, type, filterScreener, setFilterScreener}) {

    const industry = [
        "Advertising Agencies",
        "Aerospace & Defense",
        "Agricultural Chemicals",
        "Agricultural Inputs",
        "Airlines",
        "Airports & Air Services",
        "Aluminum",
        "Apparel Manufacturing",
        "Apparel Retail",
        "Asset Management",
        "Auto Components",
        "Auto Manufacturers",
        "Automobiles & Auto Parts",
        "Auto Parts",
        "Auto & Truck Dealerships",
        "Banking Services",
        "Banks-Diversified",
        "Banks-Regional",
        "Beverages",
        "Beverages-Brewers",
        "Beverages-Non-Alcoholic",
        "Beverages-Wineries & Distilleries",
        "Beverages - Wineries & Distillers",
        "Biotechnology",
        "Biotechnology & Medical Research",
        "Broadcasting",
        "Building Materials",
        "Building Products & Equipment",
        "Business Equipment",
        "Business Equipment & Supplies",
        "Business Services",
        "Capital Markets",
        "Chemicals",
        "Chemicals - Major Diversified",
        "Coal",
        "Coking Coal",
        "Collective Investments",
        "Communication Equipment",
        "Communications & Networking",
        "Computer Based Systems",
        "Computer Hardware",
        "Computers, Phones & Household Electronics",
        "Confectioners",
        "Conglomerates",
        "Construction Materials",
        "Construction & Engineering",
        "Consulting Services",
        "Consumer Electronics",
        "Consumer Goods Conglomerates",
        "Containers & Packaging",
        "Copper",
        "Credit Services",
        "Department Stores",
        "Diagnostics & Research",
        "Discount Stores",
        "Diversified Industrial Goods Wholesale",
        "Diversified Machinery",
        "Diversified Retail",
        "Diversified Utilities",
        "Drug Manufacturers-General",
        "Drug Manufacturers-Specialty & Generic",
        "Education & Training Services",
        "Electrical Equipment & Parts",
        "Electrical Utilities & IPPs",
        "Electronic Components",
        "Electronic Equipment",
        "Electronic Equipment & Parts",
        "Electronic Gaming & Multimedia",
        "Electronics & Computer Distribution",
        "Engineering & Construction",
        "Entertainment",
        "Entertainment - Diversified",
        "Farm & Construction Machinery",
        "Farm & Heavy Construction Machinery",
        "Farm Products",
        "Financial Conglomerates",
        "Financial Data & Stock Exchanges",
        "Financial Technology (Fintech) & Infrastructure",
        "Food Distribution",
        "Food & Drug Retailing",
        "Food & Tobacco",
        "Footwear & Accessories",
        "Freight & Logistics Services",
        "Furnishings, Fixtures & Appliances",
        "Gambling",
        "General Building Materials",
        "General Contractors",
        "Gold",
        "Government Activity",
        "Grocery Stores",
        "Healthcare Equipment & Supplies",
        "Healthcare Plans",
        "Healthcare Providers & Services",
        "Health Information Services",
        "Homebuilding & Construction Supplies",
        "Home Furnishings & Fixtures",
        "Home Improvement Retail",
        "Hotels & Entertainment Services",
        "Household Goods",
        "Household & Personal Products",
        "Independent Oil & Gas",
        "Industrial Distribution",
        "Industrial Equipment Wholesale",
        "Industrial Metals & Minerals",
        "Information Technology Services",
        "Infrastructure Operations",
        "Insurance",
        "Insurance Brokers",
        "Insurance-Diversified",
        "Insurance-Life",
        "Insurance-Property & Casualty",
        "Insurance-Reinsurance",
        "Insurance-Specialty",
        "Integrated Freight & Logistics",
        "Integrated Hardware & Software",
        "Internet Content & Information",
        "Internet Retail",
        "Investment Banking & Investment Services",
        "Investment Brokerage - National",
        "Investment Holding Companies",
        "Leisure",
        "Leisure Products",
        "Lodging",
        "Lumber & Wood Production",
        "Luxury Goods",
        "Machinery, Tools, Heavy Vehicles, Trains & Ships",
        "Major Airlines",
        "Marine Shipping",
        "Media & Publishing",
        "Medical Care Facilities",
        "Medical Devices",
        "Medical Distribution",
        "Medical Instruments & Supplies",
        "Metal Fabrication",
        "Metals & Mining",
        "Miscellaneous Educational Service Providers",
        "Mortgage Finance",
        "Mortgage Investment",
        "Multiline Utilities",
        "Natural Gas Utilities",
        "Office Equipment",
        "Oil & Gas",
        "Oil & Gas Drilling",
        "Oil & Gas E&P",
        "Oil & Gas Equipment & Services",
        "Oil & Gas Integrated",
        "Oil & Gas Midstream",
        "Oil & Gas Refining & Marketing",
        "Oil & Gas Related Equipment and Services",
        "Other",
        "Other Industrial Metals & Mining",
        "Other Precious Metals & Mining",
        "Packaged Foods",
        "Packaging & Containers",
        "Paper & Forest Products",
        "Paper & Paper Products",
        "Passenger Transportation Services",
        "Personal & Household Products & Services",
        "Personal Products",
        "Personal Services",
        "Pharmaceutical Retailers",
        "Pharmaceuticals",
        "Pollution & Treatment Controls",
        "Professional & Business Education",
        "Professional & Commercial Services",
        "Property Management",
        "Publishing",
        "Railroads",
        "Real Estate-Development",
        "Real Estate-Diversified",
        "Real Estate Operations",
        "Real Estate Services",
        "Recreational Goods, Other",
        "Recreational Vehicles",
        "REIT-Diversified",
        "REIT-Healthcare Facilities",
        "REIT-Hotel & Motel",
        "REIT-Industrial",
        "REIT-Mortgage",
        "REIT-Office",
        "REIT-Residential",
        "REIT-Retail",
        "REIT-Specialty",
        "Renewable Energy",
        "Rental & Leasing Services",
        "Residential & Commercial REITs",
        "Residential Construction",
        "Resorts & Casinos",
        "Restaurants",
        "School, College & University",
        "Scientific & Technical Instruments",
        "Security & Protection Services",
        "Semiconductor Equipment & Materials",
        "Semiconductors",
        "Semiconductors & Semiconductor Equipment",
        "Shell Companies",
        "Shipping",
        "Silver",
        "Software-Application",
        "Software-Infrastructure",
        "Software & IT Services",
        "Solar",
        "Specialty Business Services",
        "Specialty Chemicals",
        "Specialty Industrial Machinery",
        "Specialty Retail",
        "Specialty Retailers",
        "Staffing & Employment Services",
        "Steel",
        "Steel & Iron",
        "Technical & System Software",
        "Telecommunications Services",
        "Telecom Services",
        "Textile Manufacturing",
        "Textiles & Apparel",
        "Thermal Coal",
        "Tobacco",
        "Tools & Accessories",
        "Transport Infrastructure",
        "Travel Services",
        "Trucking",
        "Uranium",
        "Utilities-Diversified",
        "Utilities-Independent Power Producers",
        "Utilities-Regulated Electric",
        "Utilities-Regulated Gas",
        "Utilities-Regulated Water",
        "Utilities-Renewable",
        "Waste Management",
        "Water & Related Utilities",
    ]

    const sectors = ["Basic Materials", "Energy", "Real Estate", "Communication Services", "Financial Services", "Utilities", "Consumer Cyclical", "Healthcare", "Technology", "Consumer Defensive", "Industrials"]

    const [filterType, setFilterType] = useState({
        type: type,
        list: type === 'sector' ? sectors : industry
    })

    const [openSectorModal, setOpenSectorModal] = useState(false)

    const handleCloseSectorModel = () => setOpenSectorModal(false)

    const handleOpenSectorModel = (typeModal) => {
        setFilterType({
            type: typeModal,
            list: typeModal === 'sector' ? sectors : industry
        })
        setOpenSectorModal(true)
    }

    return (
        <div className={'filter-component'}>
            <p className={'filter-component-title'}>{type === 'sector' ? "Sector" : "Industry"}</p>

            <div style={{display: 'flex', flexWrap: "wrap"}}>
                {filterScreener[groupKey]['components'][componentKey].value.map((item, index) => {
                    return (
                        <div className={'filter-component-region-tab'}
                             onClick={() => {
                                 setFilterScreener({
                                     ...filterScreener,
                                     [groupKey]: {
                                         ...filterScreener[groupKey],
                                         "components": {
                                             ...filterScreener[groupKey]['components'],
                                             [componentKey]: {
                                                 ...filterScreener[groupKey]['components'][componentKey],
                                                 value: filterScreener[groupKey]['components'][componentKey]['value'].filter(_item => _item !== item)
                                             }
                                         }
                                     }
                                 })
                             }}
                        >
                            <p className={'filter-component-region-tab-text'}>{item}</p>
                            <AiOutlineClose
                                className={'filter-component-region-tab-close'}/>
                        </div>
                    )
                })}

                <div className={'filter-component-region-tab'}
                     onClick={() => handleOpenSectorModel(type)}>
                    <p className={'filter-component-region-tab-text filter-component-region-tab-text-add'}>Add&nbsp;
                        {type === 'sector' ? "Sector" : "Industry"}</p>
                    <AiOutlinePlus
                        className={'filter-component-region-tab-add'}/>
                </div>
            </div>

            {/*<div className={'row my-1'}>*/}
            {/*    {filterScreener[groupKey]['components'][componentKey].value.map((item, index) => {*/}
            {/*        return (*/}
            {/*            <div key={index} className={'col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12'}>*/}
            {/*                <div className={'filter-component-region-tab'}*/}
            {/*                     onClick={() => {*/}
            {/*                         setFilterScreener({*/}
            {/*                             ...filterScreener,*/}
            {/*                             [groupKey]: {*/}
            {/*                                 ...filterScreener[groupKey],*/}
            {/*                                 "components": {*/}
            {/*                                     ...filterScreener[groupKey]['components'],*/}
            {/*                                     [componentKey]: {*/}
            {/*                                         ...filterScreener[groupKey]['components'][componentKey],*/}
            {/*                                         value: filterScreener[groupKey]['components'][componentKey]['value'].filter(_item => _item !== item)*/}
            {/*                                     }*/}
            {/*                                 }*/}
            {/*                             }*/}
            {/*                         })*/}
            {/*                     }}*/}
            {/*                >*/}
            {/*                    <p className={'filter-component-region-tab-text'}>{item}</p>*/}
            {/*                    <AiOutlineClose*/}
            {/*                        className={'filter-component-region-tab-close'}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })}*/}

            {/*    <div className={'col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12'}>*/}
            {/*        <div className={'filter-component-region-tab'}*/}
            {/*             onClick={() => handleOpenSectorModel(type)}>*/}
            {/*            <p className={'filter-component-region-tab-text filter-component-region-tab-text-add'}>Add&nbsp;*/}
            {/*                {type === 'sector' ? "Sector" : "Industry"}</p>*/}
            {/*            <AiOutlinePlus*/}
            {/*                className={'filter-component-region-tab-add'}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Modal
                open={openSectorModal}
                onClose={handleCloseSectorModel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <FilterComponentSectorModal groupKey={groupKey} componentKey={componentKey}
                                            filterScreener={filterScreener} setFilterScreener={setFilterScreener}
                                            filterType={filterType}
                                            handleCloseSectorModel={handleCloseSectorModel}/>
            </Modal>

        </div>
    );
}

export default FilterComponentSector;
