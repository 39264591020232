import React, {useEffect, useState} from 'react';
import {AiOutlineArrowRight} from "react-icons/ai";
import Config from "../../../Config";
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import {FaCheckCircle} from "react-icons/fa";
import {MdCancel} from "react-icons/md";
import building from "../../../assets/default-building.png";
import clsx from "clsx";

const company_overview_fmp_news = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-fmp-news/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const company_overview_key_facts = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-key-facts/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function CompanyOverviewLatestNews({logoURL, code, exchange, isqore = false}) {
    const [tableState, setTableState] = useState(true)

    const [news, setNews] = useState([])

    const [keyFacts, setKeyFacts] = useState([])

    const {data: companyOverviewFMPNewReturn, isLoading: companyOverviewFMPNewIsLoading} = useQuery({
        queryKey: [`company-overview-fmp-news/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_fmp_news(code, exchange),
        staleTime: 5 * 60000
    })

    const {data: companyOverviewKeyFactsReturn, isLoading: companyOverviewKeyFactsIsLoading} = useQuery({
        queryKey: [`company-overview-key-facts/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_key_facts(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!companyOverviewFMPNewIsLoading && companyOverviewFMPNewReturn && !isqore) {
            setNews(companyOverviewFMPNewReturn)
        }
    }, [companyOverviewFMPNewReturn, companyOverviewFMPNewIsLoading, isqore])

    useEffect(() => {
        if (!companyOverviewKeyFactsIsLoading && companyOverviewKeyFactsReturn) {
            setKeyFacts(companyOverviewKeyFactsReturn)
        }
    }, [companyOverviewKeyFactsReturn, companyOverviewKeyFactsIsLoading]);

    return (
        <div className={'company-overview-table-container'}>
            <div className={'market-overview-summary-table-title key-facts-table-title'}>
                <div className={tableState ? 'selected' : 'unselected'} style={{display: 'flex'}}
                     onClick={() => {
                         setTableState(true)
                     }}>
                    <img className={'company-overview-logo key-facts-logo'}
                         src={logoURL ?? building}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = building;
                         }}
                    />
                    <p>{code}'s Key Facts</p>
                </div>
                {!isqore &&
                    <>
                        <p className={'unselected'} style={{margin: 'auto 10px'}}>|</p>
                        <p className={!tableState ? 'selected' : 'unselected'} onClick={() => {
                            setTableState(false)
                        }}>
                            Latest News
                        </p>
                    </>
                }
            </div>

            {tableState ?
                <div className={clsx('company-overview-table-body-container', isqore ? 'isqore-keyfacts-body-container' : 'company-overview-middle-body-container')}>
                    <div className={clsx('company-keyfacts-container', isqore ? 'isqore-keyfacts-inner-container' : 'company-news-container')}>
                        {keyFacts.length ?
                            keyFacts.map((item, index) => {
                                return (
                                    <div style={{fontWeight: !item.color ? 700 : 450}}
                                         className={'company-overview-dividend-header-body-point company-overview-key-facts-point'}>
                                        {item.color &&
                                            (item.color === 'success' ?
                                                <FaCheckCircle className={'check'}
                                                               style={{color: "#28c07f"}}/>
                                                : item.color === 'warning' ?
                                                    <FaCheckCircle className={'check'}
                                                                   style={{color: "#d8bd28"}}/>
                                                    :
                                                    <MdCancel style={{color: "#ea165d"}}/>)
                                        }
                                        <div style={{display: 'flex'}}>
                                            <p style={{marginRight: item.meta ? 10 : 0}}>{item.text}</p>
                                            {item.meta ?
                                                <p>{item.meta.text} {item.meta.type === 'currency' &&
                                                    <span className={'text-secondary'} style={{fontSize: 10}}>
                                                        {item.meta.currency}</span>}</p>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <p style={{fontSize: 12, fontWeight: 600}}>No Data Available</p>
                        }
                    </div>
                </div>
                :
                <div className={clsx('company-overview-table-body-container', isqore ? 'isqore-keyfacts-body-container' : 'company-overview-middle-body-container')}>
                    <div className={'company-news-container'}>
                        {news.length ?
                            news.map((item, index) => {
                                return (
                                    <a key={index} className={'news-inner-container'} target={"_blank"}
                                       href={item.url}>
                                        <img src={item.image} alt={'news'}/>
                                        <div>
                                            <p className={'title'}>{item.title.length > 80 ? item.title.substring(0, 80) + "..." : item.title}</p>
                                            <p className={'publishedDate'}>{item.site} | {item.publishedDate}</p>
                                            <p className={'read-more'}>Read More <AiOutlineArrowRight/></p>
                                        </div>
                                    </a>
                                )
                            })
                            :
                            <p style={{fontSize: 12, fontWeight: 600}}>No News available for this company</p>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default CompanyOverviewLatestNews;