import React, {useState} from 'react';
import {numberWithCommas, return_strong_num, return_value_in_million} from "../Utils";
import clsx from "clsx";

function EtfProfilerTable({data}) {
    const [state, setState] = useState(1)

    return (
        <div className={'etf-profiler-stat-table-container'}>

            <div className={'index-stat-table-title'}>
                <p className={state === 1 ? 'selected' : 'unselected'} onClick={() => {
                    setState(1)
                }}>Key Facts</p>
                <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                <p className={state === 2 ? 'selected' : 'unselected'} onClick={() => {
                    setState(2)
                }}>Ratio</p>
            </div>

            {state === 1 ?
                <div className={'index-stat-table-body-container'}>
                    <table className={'table generic-table-body etf-sm-table-body'}>
                        <tbody>
                        <tr>
                            <td>
                                Code
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.code}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ISIN
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.ISIN}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Exchange
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.exchange}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Currency
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.general.CurrencyName}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Category
                            </td>
                            <td className={clsx(data.general.Category.length > 12 && "hovertext etf-category-hover", "item-right-align-td")}
                                data-hover={data.general.Category}
                            >{data.general.Category.length > 12 ? data.general.Category.substring(0, 12) + "..." : data.general.Category}</td>
                        </tr>
                        <tr>
                            <td>
                                ETF Issuer
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.etfData.Company_Name}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                AUM {data.general.CurrencySymbol} (mln.)
                            </td>
                            <td className={'item-right-align-td'}>
                                {numberWithCommas(return_value_in_million(data.etfData.TotalAssets))}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {data.etfData.Holdings_Count === 10 ? "Only Top 10 Holdings" : "Holdings"}
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.etfData.Holdings_Count}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Div. Yield
                            </td>
                            <td className={'item-right-align-td'}>
                                {data.etfData.Yield} %
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                :
                <div className={'index-stat-table-body-container'}>
                    <table className={'table generic-table-body etf-sm-table-body'}>
                        <tbody>
                        <tr>
                            <td>
                                1 year Volatility
                            </td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data.performance.Volatility_1y)} %
                            </td>
                        </tr>

                        <tr>
                            <td>
                                3 year Volatility
                            </td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data.performance.Volatility_3y)} %
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3 year sharp Ratio
                            </td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data.performance.SharpRatio_3y)} %
                            </td>
                        </tr>


                        <tr>
                            <td>
                                Total expense ratio
                            </td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data.etfData.NetExpenseRatio * 100)} %
                            </td>
                        </tr>

                        <tr>
                            <td>Price/Earnings</td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data['valuations']['price_prospective_earnings'])}
                            </td>
                        </tr>
                        <tr>
                            <td>Price/Book</td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data['valuations']['price_book'])}
                            </td>
                        </tr>

                        <tr>
                            <td>Price/Sales</td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data['valuations']['price_sales'])}
                            </td>
                        </tr>
                        <tr>
                            <td>Price/Free Cash Flow</td>
                            <td className={'item-right-align-td'}>
                                {return_strong_num(data['valuations']['price_cash_flow'])}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }

        </div>
    );
}

export default EtfProfilerTable;
