import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {hydrate, render} from "react-dom";

import './css/index.css';
import './css/valuation.css';
import './css/financials.css';
import './css/ruleof40.css';
import './css/accounts.css';
import './css/payment.css';
import './css/indices.css';
import './css/profile.css';
import './css/navbar.css';
import './css/market-overview.css';
import './css/sidebar.css';
import './css/top-bar-indices.css';
import './css/watchlist.css';
import './css/blocker.css';
import './css/company-overview.css';
import './css/i-sqore.css';
import './css/etf.css';
import './css/landing-page.css';
import './css/screener.css';
import './css/analystforecast.css';
import './css/guru-investor.css';
import {HelmetProvider} from "react-helmet-async";


const Root = (
    <HelmetProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </HelmetProvider>
)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(Root, rootElement);
} else {
    render(Root, rootElement);
}

reportWebVitals();
