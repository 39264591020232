import React, {useState} from 'react';
import {formatNumericValue, return_bg_grade_color, return_grade_color} from "../Utils";

function ISqoreTable({name, data}) {

    const [selectedValues, setSelectedValues] = useState("current")

    const handleSortChange = (event) => {
        setSelectedValues(event.target.value);
    };

    const iSqore = formatNumericValue({
        value: data.iSqore.value,
        multiply: 100
    })

    return (
        <div className={'table-responsive'}>
            <div className={'company-overview-table-container'}>
                <div className={'company-overview-table-body-container company-overview-bottom-table-body-container'}>
                    <table className={'table generic-table-body company-overview-sm-table-body isqore-table'}>
                        <thead>
                        <tr>
                            <th className={'isqore-table-title'}>{name} {iSqore > 100 ? 100 : iSqore} | <span
                                style={{color: return_grade_color(data.iSqore.grade)}}>{data.iSqore.grade}</span></th>
                            <th className={'isqore-table-dropdown'}>
                                <select className="form-select"
                                        value={selectedValues}
                                        onChange={handleSortChange}
                                >
                                    <option selected value="current">Current Values</option>
                                    <option value="yearly">Yearly Values</option>
                                    <option value="all">All Values</option>
                                </select>
                            </th>
                            <th>Ratio</th>
                            <th>Grade</th>
                            <th>3Y Avg.</th>
                            <th>5y Avg.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data['tableData'].map((item, index) => {
                            if (selectedValues !== 'all') {
                                if (selectedValues === 'current') {
                                    if (!item.name.toLowerCase().includes("(cur)") && !item.name.toLowerCase().includes("(fwd)") && !item.name.toLowerCase().includes('(ttm)') && !item.name.toLowerCase().includes('(yoy)') && !item.name.toLowerCase().includes('(quarterly)') && !item.name.toLowerCase().includes('(quarter)')) {
                                        return
                                    }
                                } else if (selectedValues === 'yearly') {
                                    if (!item.name.toLowerCase().includes("(year)")) {
                                        return
                                    }
                                }
                            }
                            return (
                                <tr key={index}>
                                    <td>
                                        {/*{get_name(item.name)}*/}
                                        {item.name}
                                    </td>
                                    <td></td>
                                    <td>
                                        {formatNumericValue({
                                            value: item.ratio,
                                            multiply: item.per ? item.name === 'EPS Growth (fwd)' ? 1 : 100 : 1
                                        }) ?? '-'} {item.per && "%"}
                                    </td>
                                    <td style={{display: 'flex', textAlign: "center"}}>
                                        <p className={'grade_p grade_p_isqore_table'}
                                           style={{
                                               border: `1px solid ${return_grade_color(item.grade)}`,
                                               color: return_grade_color(item.grade),
                                               backgroundColor: return_bg_grade_color(item.grade)
                                           }}>
                                            {item.grade}
                                        </p>
                                    </td>
                                    <td>
                                        {formatNumericValue({
                                            value: item.avg_3,
                                            multiply: item.per ? 100 : 1
                                        }) ?? '-'} {item.per && "%"}
                                    </td>
                                    <td>
                                        {formatNumericValue({
                                            value: item.avg_5,
                                            multiply: item.per ? 100 : 1
                                        }) ?? '-'} {item.per && "%"}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ISqoreTable;