import React, {useContext, useState} from 'react';
import {numberWithCommas, return_strong_num, return_td_color, return_value_in_million} from "../Utils";
import {Modal, TablePagination} from "@material-ui/core";
import {ThemeContext} from "../../Context/ThemeContext";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import building from "../../assets/default-building.png";

function EarningsTable({tableData, handleOpenModal}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const navigate = useNavigate()
    const {webTheme} = useContext(ThemeContext);

    const [columns, setColumns] = useState(
        [
            {name: 'Watchlist', className: ''},
            {name: 'Ticker', className: ''},
            {name: 'Company', className: ''},
            {name: 'Exchange', className: ''},
            {name: 'Market Cap.', className: ''},
            {name: 'Date', className: ''},
            {name: 'Quarter Ending', className: ''},
            {name: 'Revenue Estimate', className: ''},
            {name: 'EPS Estimate', className: ''}
        ]
    )

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <div className={'table-responsive'}>
                <table className="table generic-lg-table">
                    <thead>
                    <tr>
                        {columns.map((item, index) => {
                            return (
                                <th key={index} className={item.class_name} onClick={() => {
                                }}>
                                    {item.name}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {
                        tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td
                                            className={'watchlist'}
                                            onClick={() => {
                                                if (!isAuthenticated) {
                                                    toast.error("Please login to add stocks into watchlist")
                                                    navigate('/login/')
                                                    return
                                                }
                                                handleOpenModal(item)
                                            }}>
                                            {item.watchlist ? <AiFillStar
                                                style={{
                                                    color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                                }}/> : <AiOutlineStar
                                                style={{
                                                    color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                                }}/>}
                                        </td>

                                        <td>
                                            <img className={'price-target-company-logo'}
                                                 src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}
                                                 onError={({currentTarget}) => {
                                                     currentTarget.onerror = null;
                                                     currentTarget.src = building;
                                                 }}/>
                                            {item.ticker}
                                        </td>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.exchange}
                                        </td>
                                        <td>
                                            {numberWithCommas(return_value_in_million(item.mcap))}
                                        </td>
                                        <td>
                                            {item.date}
                                        </td>
                                        <td>
                                            {item['quarter-ending']}
                                        </td>
                                        <td>
                                            {item['revenue-estimate'] ?
                                                numberWithCommas(return_value_in_million(item['revenue-estimate']))
                                                :
                                                ""
                                            }
                                        </td>
                                        <td>
                                            {item['eps-estimate'] ?
                                                item['eps-estimate']
                                                :
                                                ""
                                            }
                                        </td>
                                    </tr>
                                    <div key={index} style={{marginTop: 3, width: "100%"}}/>
                                </>
                            )
                        })
                    }
                    </tbody>

                </table>
                <TablePagination
                    style={{color: webTheme === 'dark' ? 'white' : 'black'}}
                    rowsPerPageOptions={false}
                    component="div"
                    count={tableData ? tableData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

        </>
    );
}

export default EarningsTable;