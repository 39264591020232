import DashboardLayout from "../Layout/DashboardLayout";
import MajorIndices from "../Container/MajorIndices/MajorIndices";
import DashboardTabs from "../Container/Tabs/LandingPageTabs/DashboardTabs";
import {Helmet} from "react-helmet-async";
import React from "react";

function MarketOverview() {
    return (
        <>
            <Helmet>
                <title>Market Overview: Global market Analysis, News, Trends and Performance</title>
                <meta name={"description"}
                      content={"Market Overview: Stay up-to-date with our comprehensive market overview, news, and price targets."}/>
            </Helmet>
            <DashboardLayout>
                <MajorIndices/>
                <DashboardTabs/>
            </DashboardLayout>
        </>
    );
}

export default MarketOverview;
