import React, {useRef, useState} from 'react';
import Config from "../Config";
import {toast} from "react-hot-toast";
import axios from "axios";
import {getModalStyle, raise_error} from "../Container/Utils";
import {AiOutlineClose} from "react-icons/ai";

function ChangePassword({handleCloseForgotPasswordModal}) {

    const formRef = useRef()
    const [modalStyle] = useState(getModalStyle);
    const [email, setEmail] = useState('')

    const forgetPassword = () => {
        const config = Config()

        toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/change-password-by-email/`, {email: email}, config), {
            loading: 'Please wait...',
            success: (res) => res.data.message,
            error: (err) => raise_error(err)
        })
    }

    return (
        <div style={modalStyle}
             className={'generic-modal-body change-password-modal'}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <AiOutlineClose onClick={handleCloseForgotPasswordModal}
                                className={'rule-of-forty-close-modal-button'}/>
            </div>

            <p style={{color: 'white', fontSize: 14, fontWeight: 600, marginBottom: 10}}>Enter your email address</p>
            <form ref={formRef}>
                <input
                    value={email}
                    name={'email'}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    required={true}
                    type={'email'}
                    className={'form-control input-field'}
                    placeholder={'Email Address'}/>

                <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                    <button className={"btn reg-control-btn reg-next-btn"}
                            onClick={(e) => {
                                e.preventDefault()
                                const form = formRef.current;
                                if (!form.checkValidity()) {
                                    form.reportValidity()

                                } else {
                                    forgetPassword()
                                }
                            }}>Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChangePassword;