import {useEffect, useState} from 'react';
import PaymentColumns from "../Container/Payment/PaymentColumns";
import countries from "countries-list";
import {lookup} from 'country-data-list';
import LandingPageNavbar from "../Container/LandingPage/landing-page-navbar";
import {useDispatch, useSelector} from "react-redux";
import {load_user, RefreshToken, VerifyToken} from "../actions/actions";
import {useNavigate} from "react-router-dom";

function Payment() {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.actions.user)

    const country_list = countries.countries;

    const [euro, setEuro] = useState(false);

    useEffect(() => {
        if (user.country) {

            const country = lookup.countries({name: user.country})[0];

            const countryDetail = country_list[country.alpha2]

            if (countryDetail) {
                if (countryDetail.continent === 'EU') {
                    setEuro(true)
                }
            }
        }
    }, [user])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(VerifyToken(navigate))
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setInterval(() => {
                dispatch(RefreshToken())
            }, 5 * 60000);
        }
    }, [isAuthenticated]);

    return (
        <div className={'landing-page-container'}>
            <div className={'ornament-2'}/>
            <div className={'landing-page'}>
                <div className="container">

                    <LandingPageNavbar/>

                    <PaymentColumns euro={euro}/>

                </div>
            </div>
        </div>
    );
}

export default Payment;
