import React, {useCallback, useEffect, useState} from 'react';
import _debounce from "lodash/debounce";
import Config from "../../Config";
import axios from "axios";

function ExchangeSearchBody({performFilter, setTempCheckedExchange, tempCheckedExchange, setCheckedExchange, setCompanyLoading}) {
    const [search, setSearch] = useState("")

    const [exchanges, setExchanges] = useState([]);

    const [loading, setLoading] = useState(true)

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    function handleDebounceFn(search) {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/exchange-list/`,
            {search: search},
            config)
            .then(response => {
                setExchanges(response.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        handleDebounceFn(search)
    }, [])

    const handleExchangeClick = (exchange) => {
        setTempCheckedExchange(tempCheckedExchange.includes(exchange.code) ? tempCheckedExchange.filter(item => item !== exchange.code) : [...tempCheckedExchange, exchange.code])
    }

    const reset_filter = () => {
        setTempCheckedExchange([])
        setCheckedExchange([])
    }

    useEffect(() => {
        console.log("tempCheckedExchange =>", tempCheckedExchange)
    }, [tempCheckedExchange])

    return (
        <div>
            <input
                value={search}
                onChange={(e) => {
                    setLoading(true)
                    setSearch(e.target.value)
                    debounceFn(e.target.value)
                }}
                className={'form-control exchange-search-filter'}
                placeholder={'Search Exchanges i.e Xetra'}/>

            <div style={{display: 'flex', margin: '15px 0'}}>
                <p className={'search-modal-text-heading'} style={{margin: "auto 0"}}>Select to filter</p>
                <button className={'btn checked-filter-btn'}
                        onClick={reset_filter}>Reset Filter
                </button>
            </div>


            <div className={'exchange-list'}>
                {
                    loading ?
                        <>
                            <div className={'navbar-modal-company-loader-container'}>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}
                                     style={{marginLeft: 'auto'}}
                                ></div>
                            </div>
                            <div className={'navbar-modal-company-loader-container'}>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}
                                     style={{marginLeft: 'auto'}}
                                ></div>
                            </div>
                        </>
                        :
                        exchanges.length !== 0 ?
                            <>
                                {exchanges.map((exchange, index) => {
                                    return (
                                        <div className={'exchange-searched-container'}
                                             onClick={() => handleExchangeClick(exchange)}>
                                            <input className="form-check-input filter-exchange-checkbox"
                                                   type="checkbox" value="" id="flexCheckChecked"
                                                   checked={tempCheckedExchange.includes(exchange.code)}/>
                                            <p className={'exchange-searched-name'}>
                                                {exchange.name}
                                            </p>
                                            <p className={'exchange-searched-code'}>
                                                {exchange.code}
                                            </p>
                                            <div style={{margin: "auto 0 auto auto"}}>
                                                <p className={'exchange-searched-country'}>
                                                    {exchange.currency}
                                                </p>
                                                <p className={'exchange-searched-country'}>
                                                    {exchange.country} - {exchange.region}
                                                </p>
                                            </div>
                                        </div>)
                                })}
                            </>
                            :
                            <p className={'no-companies-found-text'}>No Exchange Found</p>
                }
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn checked-filter-btn'} onClick={() => {
                    if(setCompanyLoading) {
                        setCompanyLoading(true)
                    }
                    performFilter()
                }}>Apply
                </button>
            </div>

        </div>
    );
}

export default ExchangeSearchBody;