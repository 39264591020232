import React, {useContext, useEffect, useState} from 'react';
import clsx from "clsx";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import Index from "./Index";
import {ThemeContext} from "../../../Context/ThemeContext";
import PropTypes from "prop-types";
import axios from "axios";
import Config from "../../../Config";
import {a11yProps, TabPanel} from "../../Utils";

function IndexProfile({
                          currentTab,
                          setCurrentTab,
                          selectedIndex,
                          selectedIndexData,
                          loadingSelectedIndex,
                          setLoadingSelectedIndex,
                          setSelectedIndex,
                          tabs
                      }) {

    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    const handleChangeIndex = (index) => {
        setCurrentTab(index);
    };

    return (
        <div className={"generic-tabs-container"}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'secondary'}
                    scrollButtons="auto"
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Tab
                                onClick={() => {
                                    setSelectedIndex(tab)
                                    setLoadingSelectedIndex(true)
                                }}
                                label={tab.name} {...a11yProps({index})} />
                        )
                    })}
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={currentTab}
                onChangeIndex={handleChangeIndex}
                disabled={true}
            >
                {tabs.map((tab, index) => {
                    return (
                        <TabPanel className={'generic-tab-panel'} value={currentTab} index={index}
                                  dir={theme.direction}>
                            <Index index={selectedIndex} selectedIndexData={selectedIndexData}
                                   loadingSelectedIndex={loadingSelectedIndex}/>
                        </TabPanel>
                    )
                })}
            </SwipeableViews>
        </div>
    );
}

export default IndexProfile;
