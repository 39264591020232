import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../Context/ThemeContext";
import {return_value_in_million} from "../Utils";

function CompanyOverColumnCharts({dates, series, stacked=false, title, color, yaxis={}}) {
    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })
    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const options = {
        chart: {
            type: 'bar',
            stacked: stacked,
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            position: 'top'
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            type: 'datetime',
            categories: dates,
        },
        colors: color,
        yaxis: {
            labels: {
                formatter: function (val) {
                    return return_value_in_million(val)
                }
            },
            ...yaxis
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            x: {
                format: 'MMM/yy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return return_value_in_million(value)
                }
            },
        },
    }

    return (
        <div className={'rule-of-forty-chart-container'}>
            <div className={'rule-of-forty-inner-container'} style={{height: 300, padding: 10}}>
                <p className={'market-overview-chart-name'}>{title}</p>
                <ReactApexChart options={options} series={series} type={'bar'}
                                width={'100%'}
                                height={'95%'}/>
            </div>
        </div>
    );
}

export default CompanyOverColumnCharts;