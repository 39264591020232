import React, {useState} from 'react';
import {a11yProps, getModalStyle, TabPanel} from "../Utils";
import {AiOutlineClose} from "react-icons/ai";
import {Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import NavbarSearchTabBody from "./NavbarSearchTabBody";
import AppBar from "@material-ui/core/AppBar";
import {load_common_etf, load_common_stock} from "../../actions/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

function SearchModalBody({handleCloseSearchModal}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const stock_onclick = (stock_type, code, exchange) => {
        if (stock_type === 'Common Stock') {
            dispatch(load_common_stock(code, exchange))
            navigate('/company-overview/' + code + '/' + exchange + '/')
            handleCloseSearchModal()
        } else if (stock_type === 'ETF') {
            dispatch(load_common_etf(code, exchange))
            navigate('/etfs/' + code + '/' + exchange)
            handleCloseSearchModal()
        }
    }

    const [modalStyle] = useState(getModalStyle);
    return (
        <div style={modalStyle} className={'generic-modal-body search-modal-body'}>
            <div style={{display: 'flex'}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <AiOutlineClose onClick={handleCloseSearchModal}
                                className={'search-close-modal-button'}/>
            </div>

            <div className={'generic-tabs-container navbar-tab-container'}>

                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor={'secondary'}
                    >
                        <Tab label="Common Stocks" {...a11yProps(0)} />
                        <Tab label="ETF"
                             style={{}} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <div className="tabsPanelContainer">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        disabled={true}
                    >
                        <TabPanel className={'navbar-tab-panel'} value={value} index={0}
                                  dir={theme.direction}>
                            <NavbarSearchTabBody stock_type={'Common Stock'}
                                                 stock_onclick={stock_onclick}/>
                        </TabPanel>

                        <TabPanel className={'navbar-tab-panel'} value={value} index={1}
                                  dir={theme.direction}>
                            <NavbarSearchTabBody stock_type={'ETF'}
                                                 stock_onclick={stock_onclick}/>
                        </TabPanel>
                    </SwipeableViews>
                </div>

            </div>

        </div>
    );
}

export default SearchModalBody;
