import React, {useEffect, useState} from 'react';
import {formatNumericValue, return_bg_grade_color, return_grade_color} from "../../../Utils";
import axios from "axios";
import Config from "../../../../Config";
import {useQuery} from "@tanstack/react-query";
import clsx from "clsx";

const company_overview_financials_averages = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-averages/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function FinancialOverviewAvgTabs({code, exchange, fairValueTab=false}) {

    const [financialAverages, setFinancialAverages] = useState({
        ...(!fairValueTab && {
        pe_forward: {
            "name": "P/E Fwd",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        }}),
        revenue_growth: {
            "name": "Rev. Growth",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": false,
            "fiveYear": true
        },
        fcf_growth: {
            "name": "FCF Growth",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": false,
            "fiveYear": true
        },
        roic: {
            "name": "ROIC",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        },
        ...(!fairValueTab && {
        p_fcf: {
            "name": "P/FCF",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        }}),
        profit_margin: {
            "name": "Profit Margin",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        },
        fcf_margin: {
            "name": "FCF Margin",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        },
        fcf_debt: {
            "name": "FCF Debt",
            "value": '-',
            "grade": '',
            "avgValue": '-',
            "avg": true,
            "fiveYear": true
        }
    })

    const {data: financialAveragesValuesReturn, isLoading: isLoadingFinancialAveragesValues} = useQuery({
        queryKey: [`company-overview-financials-averages/${code}/${exchange}`, code, exchange],
        queryFn: () => company_overview_financials_averages(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!isLoadingFinancialAveragesValues && financialAveragesValuesReturn) {
            let financialAveragesValuesReturnTemp = {...financialAveragesValuesReturn}

            if (fairValueTab) {
                delete financialAveragesValuesReturnTemp['pe_forward']
                delete financialAveragesValuesReturnTemp['p_fcf']
            }

            setFinancialAverages(financialAveragesValuesReturnTemp)
        }
    }, [isLoadingFinancialAveragesValues , financialAveragesValuesReturn]);

    return (
        <div className={'row g-0 mb-2'}>
            {Object.values(financialAverages).map((item, index) => {
                return (
                    <div className={clsx('col-md-6 col-sm-12 col-xs-12 col-12 p-1',
                    !fairValueTab ? "col-xl-3 col-lg-3" : "col-xl-4 col-lg-6"
                    )}>
                        <div key={index}
                             className={'fg-color color-text'}
                             style={{padding: 12, borderRadius: 10, display: "flex", fontSize: 12}}>
                            <div style={{marginRight: 'auto'}}>
                                <p style={{fontWeight: 600, fontSize: 14}}>{item.name}</p>
                                <p style={{fontSize: 10}}>{item.fiveYear ? "5" : "3"}Y {item.avg ? "Avg" : "CAGR"}&nbsp;
                                    {formatNumericValue({
                                        value:item.avgValue, multiply: item?.per ? 100 : 1
                                    })}{item?.per && '%'}
                                </p>
                            </div>
                            <p style={{marginRight: 5, fontSize: 14}}>{formatNumericValue({
                                value:item.value, multiply: item?.per ? 100 : 1
                            })}{item?.per && '%'}</p>
                            <div className={'grade_p'}
                                 style={{
                                     border: `1px solid ${return_grade_color(item.grade)}`,
                                     color: return_grade_color(item.grade),
                                     backgroundColor: return_bg_grade_color(item.grade),
                                     height: 20,
                                     width: 25,
                                     fontSize: 12,
                                     display: 'flex'
                                 }}>
                                <p style={{margin: 'auto'}}>
                                    {item.grade}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default FinancialOverviewAvgTabs;
