import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import Config from "../../Config";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import {getModalStyle} from "../Utils";
import building from "../../assets/default-building.png";
import _debounce from "lodash/debounce";

function TickerModal({setData, handleCloseModal, watchlist, get_ticker_watchlist}) {
    const [modalStyle] = useState(getModalStyle);

    const [search, setSearch] = useState("")

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    const [tickers, setTickers] = useState([])

    const [loader, setLoader] = useState(true)

    function handleDebounceFn(search) {
        const config = Config()

        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/get-tickers/`, {
            search: search
        }, config)
            .then(response => {
                setLoader(false)
                setTickers(response.data)
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        handleDebounceFn(search)
    }, [])


    const saveTicker = (id) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/add-ticker/`, {
            watchlist_id: watchlist.id,
            company_id: id
        }, config)
            .then(response => {
                // setData(response.data)
                get_ticker_watchlist(watchlist)
            })
            .catch(err => console.log(err.message))
        handleCloseModal()
    }

    return (
        <div style={modalStyle}
             className={clsx('ticker-modal-body')}>

            <div style={{display: 'flex'}}>
                <p className={'ticker-modal-title'}>Add Ticker</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <input className={'form-control ticker-modal-search-modal'} placeholder={'Search Common Stocks'}
                   value={search}
                   onChange={(e) => {
                       setLoader(true)
                       setSearch(e.target.value)
                       debounceFn(e.target.value)
                   }}/>

            <div className={'companies-list'}>
                {loader ?
                    <>
                        <div className={'navbar-modal-company-loader-container'}>
                            <div className={'loader navbar-searched-circle-loader'}></div>
                            <div className={'loader navbar-searched-pill-loader'}></div>
                            <div className={'loader navbar-searched-pill-loader'}></div>
                        </div>
                        <div className={'navbar-modal-company-loader-container'}>
                            <div className={'loader navbar-searched-circle-loader'}></div>
                            <div className={'loader navbar-searched-pill-loader'}></div>
                            <div className={'loader navbar-searched-pill-loader'}></div>
                        </div>
                    </>
                    :
                    tickers.length !== 0
                        ?
                        tickers.map((ticker, index) => {
                            return (
                                <div key={index} className={'navbar-search-company-container'} onClick={() => {
                                    saveTicker(ticker.id)
                                }}>
                                    <img className={'navbar-search-company-logo'}
                                         src={ticker.LogoURL && ticker.LogoURL !== '' ? ticker.LogoURL : building}
                                         onError={({currentTarget}) => {
                                             currentTarget.onerror = null; // prevents looping
                                             currentTarget.src = building;
                                         }}/>
                                    <p className={'navbar-searched-company-name'}>
                                        {ticker.name}
                                    </p>
                                    <p className={'navbar-searched-company-code'}>
                                        {ticker.code}
                                    </p>
                                    <p className={'navbar-searched-company-exchange'}>
                                        {ticker.stock_exchange.name}
                                    </p>
                                </div>
                            )
                        })
                        :
                        <p className={'no-companies-found-text'}>No Tickers Found</p>
                }
            </div>
        </div>
    );
}

export default TickerModal;
