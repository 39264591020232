import React, {useState} from 'react';
import {AiOutlineClose, AiOutlinePlus} from "react-icons/ai";
import {Modal} from "@material-ui/core";
import FilterComponentExchangeModal from "./filterComponentExchangeModal";

function FilterComponentExchange({componentKey, groupKey, filterScreener, setFilterScreener, type}) {

    const [openRegionModal, setOpenRegionModal] = useState(false)

    const handleCloseRegionModel = () => setOpenRegionModal(false)

    const handleOpenRegionModel = () => setOpenRegionModal(true)

    return (
        <div className={'filter-component'}>
            <p className={'filter-component-title'}>{filterScreener[groupKey]['components'][componentKey]['name']}</p>

            <div style={{display: 'flex', flexWrap: "wrap"}}>
                {filterScreener[groupKey]['components'][componentKey].value.map((exchange, index) => {
                    return (
                        <div className={'filter-component-region-tab'}
                             onClick={() => {
                                 setFilterScreener({
                                     ...filterScreener,
                                     "General": {
                                         ...filterScreener['General'],
                                         components: {
                                             ...filterScreener['General']['components'],
                                             [type]: {
                                                 ...filterScreener['General']['components'][type],
                                                 value: filterScreener['General']['components'][type]['value'].filter(item => item.name !== exchange.name)
                                             }
                                         }
                                     }
                                 })
                             }}
                        >
                            <img src={exchange.flag} style={{width: 20, height: 15, margin: "auto 5px auto 0"}}/>
                            <p className={'filter-component-region-tab-text'}>{exchange.name}</p>
                            <AiOutlineClose
                                className={'filter-component-region-tab-close'}/>
                        </div>
                    )
                })}
                <div className={'filter-component-region-tab'}
                     onClick={handleOpenRegionModel}>
                    <p className={'filter-component-region-tab-text filter-component-region-tab-text-add'}>{type === "exchanges" ? "Add Region" : "Add Index"}</p>
                    <AiOutlinePlus
                        className={'filter-component-region-tab-add'}/>
                </div>
            </div>

            <Modal
                open={openRegionModal}
                onClose={handleCloseRegionModel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <FilterComponentExchangeModal
                    filterScreener={filterScreener} setFilterScreener={setFilterScreener}
                    handleCloseRegionModel={handleCloseRegionModel} type={type}/>
            </Modal>

        </div>
    );
}

export default FilterComponentExchange;