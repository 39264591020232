import {useState} from 'react';
import {FaCcPaypal} from "react-icons/fa";
import {BsCreditCard2Back} from "react-icons/bs";
import clsx from "clsx";
import PaymentForm from "../../Pages/PaymentForm";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {useSelector} from "react-redux";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

function SelectPaymentTypeModal({handleCloseModal, selectedPackage, euro}) {

    const get_stripe_price_id = (type) => {

        if (euro) {
            if (type === 'Monthly') {
                return process.env.REACT_APP_STRIPE_EURO_MONTHLY
            } else {
                return process.env.REACT_APP_STRIPE_EURO_YEARLY
            }
        } else {
            if (type === 'Monthly') {
                return process.env.REACT_APP_STRIPE_DOLLAR_MONTHLY
            } else {
                return process.env.REACT_APP_STRIPE_DOLLAR_YEARLY
            }
        }
    }

    const user = useSelector(state => state.actions.user);

    const [modalStyle] = useState(getModalStyle);

    const [selectedPaymentType, setSelectedPaymentType] = useState('paypal');

    return (
        user &&
        <div style={modalStyle}
             className={clsx('generic-modal-body payment-modal-card')}>
            <div style={{display: 'flex', margin: "10px 0"}}>
                <AiOutlineArrowLeft
                    onClick={handleCloseModal}
                    style={{margin: 'auto 5px auto 0', color: '#5E5E5E', cursor: 'pointer'}}/>
                <p className={'payment-info-heading'}>Pay
                    with {selectedPaymentType === 'card' ? 'Card' : 'PayPal'}</p>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-4 col-5">
                    <div
                        className={clsx('payment-method-container', selectedPaymentType === 'paypal' && "payment-type-selected")}
                        onClick={() => {
                            setSelectedPaymentType('paypal')
                        }}>
                        <p className={'payment-method-helper-text'}>Paypal</p>
                        <FaCcPaypal className={'payment-method-cards'}/>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-4 col-5">
                    <form
                        action={`${process.env.REACT_APP_API_URL}/finqube-payment/card-payment/`}
                        method='POST'
                    >
                        <input type="hidden" name="email" value={user['email']}/>
                        <input type="hidden" name="price_id" value={get_stripe_price_id(selectedPackage.type)}/>
                        <button className={'btn form-control payment-method-container'} type='submit'>
                            Card
                            <BsCreditCard2Back className={'payment-method-cards'}/>
                        </button>
                    </form>
                </div>
            </div>

            <PaymentForm euro={euro}
                         selectedPackage={selectedPackage}/>

        </div>
    );
}

export default SelectPaymentTypeModal;
