import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {return_per_div} from "../../Utils";
import Watchlist from "../../Tabs/LandingPageTabs/SummaryTab/Watchlist";

import MarketOverviewIndicesTable from "../MarketOverviewIndicesTable";

function SummaryIndicesWatchlistTable({setChartData, handleSetChartData, data, watchlists, setWatchList}) {

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)

    const [tableState, setTableState] = useState(true)

    const get_body = (body) => {
        return body.map((item) => {
            return {
                name: item[0],
                symbol: item[1],
                price: item[2]['price'],
                change_p: item[2]['changesPercentage'],
                code: item[3].split('.')[0],
                exchange : item[3].split('.')[1],
                type: 'index',
                flag: item[5]
            }
        })
    }


    const [body, setBody] = useState(get_body([
        ['S&P 500', 'GSPC', data['^GSPC'], "GSPC.INDX", "index", "https://flagcdn.com/108x81/us.png"],
        ['Dow Jones', 'DJI', data['^DJI'], "DJI.INDX", "index", "https://flagcdn.com/108x81/us.png"],
        ['Nasdaq 100', 'NDX', data['^NDX'], "NDX.INDX", "index", "https://flagcdn.com/108x81/us.png"],
        ['Russell 2000', 'RUT', data['^RUT'], "RUT.INDX", "index", "https://flagcdn.com/108x81/us.png"],

        ['TSX 60', 'GSPTSE', data['TX60.TS'], "GSPTSE.INDX", "index", "https://flagcdn.com/108x81/ca.png"],

        ['Bovespa', 'BOVESPA', data['^BVSP'], "IBX50.INDX", "index", "https://flagcdn.com/108x81/br.png"],

        ['Stoxx Europe 600', 'STOXX', data['^STOXX'], "STOXX.INDX", "index", "https://flagcdn.com/108x81/eu.png"],
        ['Euro Stoxx 50', 'STOXX50E', data['^STOXX50E'], "STOXX50E.INDX", "index", "https://flagcdn.com/108x81/eu.png"],

        ['DAX 40', 'DAX40', data['^GDAXI'], "GDAXI.INDX", "index", "https://flagcdn.com/108x81/de.png"],
        ['DAX Midcap', 'MDAXI', data['^MDAXI'], "MDAXI.INDX", "index", "https://flagcdn.com/108x81/de.png"],

        ['FTSE 100', 'FTSE', data['^FTSE'], "FTSE.INDX", "index", "https://flagcdn.com/108x81/gb.png"],

        ['CAC 40', 'FCHI', data['^FCHI'], "FCHI.INDX", "index", "https://flagcdn.com/108x81/fr.png"],

        ['IBEX 35', 'IBEX', data['^IBEX'], "IBEX.INDX", "index", "https://flagcdn.com/108x81/es.png"],

        ['AEX 25', 'AEX', data['^AEX'], "AEX.INDX", "index", "https://flagcdn.com/108x81/nl.png"],

        ['ATX 20', 'ATX', data['^ATX'], "ATX.INDX", "index", "https://flagcdn.com/108x81/at.png"],

        ['Swiss Market Index', 'SSMI', data['^SSMI'], "SSMI.INDX", "index", "https://flagcdn.com/108x81/ch.png"],

        ['ASX 200', 'AXJO', data['^AXJO'], "AXAF.INDX", "index", "https://flagcdn.com/108x81/au.png"],
        ['NIKKEI 225', 'N225', data['^N225'], "N225.INDX", "index", "https://flagcdn.com/108x81/jp.png"],
        ['Taiwan Weighted Index', 'TWII', data['^TWII'], "TWII.INDX", "index", "https://flagcdn.com/108x81/tw.png"],
        ['Hang Seng', 'HSI', data['^HSI'], "HSI.INDX", "index", "https://flagcdn.com/108x81/hk.png"],
        ['FBM KLCI', 'KLSE', data['^KLSE'], "KLSE.INDX", "index", "https://flagcdn.com/108x81/my.png"],
        ['NIFTY', 'NSEI', data['^NSEI'], "NSEI.INDX", "index", "https://flagcdn.com/108x81/in.png"]
    ]))

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                {/*<p>Equity Market</p> | <p>Watchlist</p>*/}
                <p className={tableState ? 'selected' : 'unselected'} onClick={() => {
                    setTableState(true)
                }}>Global Indices</p>
                {isAuthenticated &&
                    <>
                        <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                        <p className={!tableState ? 'selected' : 'unselected'} onClick={() => {
                            setTableState(false)
                        }}>Watchlist</p>
                    </>
                }
            </div>
            {tableState ? <MarketOverviewIndicesTable body={body} setBody={setBody} handleSetChartData={handleSetChartData} /> :
                <Watchlist watchlists={watchlists} setWatchList={setWatchList} handleSetChartData={handleSetChartData}/>}
        </div>
    );
}

export default SummaryIndicesWatchlistTable;