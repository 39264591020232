import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {TablePagination} from "@material-ui/core";
import {ThemeContext} from "../../Context/ThemeContext";
import {is_number, return_quarter_earnings_surprise_div, return_strong_num} from "../Utils";
import EarningsBarGraph from "./CalenderTabs/EarningsBarGraph";
import EarningsQuarterEarnings from "./EarningsQuarterEarnings";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";

const company_overview_earnings = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-earnings/${code}/${exchange}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

const EarningTabs = ({code, exchange}) => {
    const [data, setData] = useState([])

    const {data: earnings, isLoading: earningsIsLoading} = useQuery({
        queryKey: [`company-overview-earnings/${code}/${exchange}/`, code, exchange],
        queryFn: () => company_overview_earnings(code, exchange),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!earningsIsLoading) {
            const name = "date"

            const sortedData = [...earnings].sort((a, b) => {
                if (is_number(a)) {
                    a = parseFloat(a)
                }
                if (is_number(b)) {
                    b = parseFloat(b)
                }
                if (a[name] > b[name]) {
                    return -1;
                }
                if (a[name] < b[name]) {
                    return 1;
                }
                return 0;
            })

            setData(
                sortedData
            );
        }
    }, [earnings, earningsIsLoading]);

    return (
        <>
            <div className="row g-0 mt-2">
                <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12 col-12">
                    <EarningsBarGraph code={code} data={data}/>
                </div>
                <div className="col-xl-7 col-lg-5 col-md-12 col-sm-12 col-12">
                    <EarningsQuarterEarnings code={code} exchange={exchange}/>
                </div>
            </div>
        </>
    );
}

export default EarningTabs;
