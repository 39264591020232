import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../Context/ThemeContext";
import {return_strong_num} from "../../Utils";

function EarningsBarGraph({code, data}) {
    const {webTheme} = useContext(ThemeContext);
    const [graphVarChange, setGraphVarChange] = useState(false)

    const [loading, setLoading] = useState(true)

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [series, setSeries] = useState(
        [{
            name: 'Actual',
            data: []
        }]
    )

    const options = {
        chart: {
            width: '100%',
            height: 350,
            type: 'bar',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        plotOptions: {
            bar: {
                columnWidth: '60%'
            }
        },
        colors: ['#00E396'],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        yaxis: {
            opposite: true,
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Actual', 'Expected'],
            markers: {
                fillColors: ['#00E396', '#775DD0']
            }
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
        }
    }

    useEffect(() => {
        let _data = {
            name: 'Actual',
            data: []
        }

        data.map(item => {
            _data.data.push(
                {
                    x: item.date,
                    y: item.eps ? return_strong_num(item.eps) : 0,
                    goals: [
                        {
                            name: 'Expected',
                            value: item.epsEstimated ? return_strong_num(item.epsEstimated) : 0,
                            strokeHeight: 5,
                            strokeColor: '#775DD0'
                        }
                    ]
                }
            )
        })

        setLoading(false)
        setSeries([_data])
    }, [data])

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'company-overview-chart-container earnings-bar-graph-chart-container'}>
            <div className={'price-target-chart-inner-container-2'}>
                <div id="chart">
                    <div className="toolbar" style={{marginBottom: 0}}>
                        <p className={'market-overview-chart-name'}>{code} Quarterly Earnings</p>
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type="bar" width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default EarningsBarGraph;