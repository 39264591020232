import React, {useEffect, useState} from 'react';
import {getModalStyle} from "../Utils";
import {AiOutlineClose} from "react-icons/ai";

function FilterComponentSectorModal({
                                        groupKey,
                                        componentKey,
                                        filterScreener,
                                        setFilterScreener,
                                        handleCloseSectorModel,
                                        filterType
                                    }) {
    const [modalStyle] = useState(getModalStyle);

    const handleClick = (item) => {
        // setFilterScreener({
        //     ...filterScreener,
        //     [filterType.type]: filter[filterType.type].includes(item) ? filter[filterType.type].filter(item => item !== item) : [...filter[filterType.type], item]
        // })
        setFilterScreener({
            ...filterScreener,
            [groupKey]: {
                ...filterScreener[groupKey],
                "components": {
                    ...filterScreener[groupKey]['components'],
                    [componentKey]: {
                        ...filterScreener[groupKey]['components'][componentKey],
                        value: filterScreener[groupKey]['components'][componentKey]['value'].includes(item)
                            ? filterScreener[groupKey]['components'][componentKey]['value'].filter(_item => _item !== item) : [...filterScreener[groupKey]['components'][componentKey]['value'], item]
                    }
                }
            }
        })
    }

    const [search, setSearch] = useState("")

    const [items, setItems] = useState(filterType.list)

    useEffect(() => {
        if (search === "") {
            setItems(filterType.list)
        } else {
            setItems(filterType.list.filter(item => {
                const words = item.split(" ")
                let included = false
                words.map(i => {
                    if (i.toLowerCase().includes(search.toLowerCase())) {
                        included = true
                    }
                })
                return included
            }))
        }
    }, [search])

    const handleSelectAllBtn = () => {
        setFilterScreener({
            ...filterScreener,
            [groupKey]: {
                ...filterScreener[groupKey],
                "components": {
                    ...filterScreener[groupKey]['components'],
                    [componentKey]: {
                        ...filterScreener[groupKey]['components'][componentKey],
                        value: items
                    }
                }
            }
        })
    }

    const handleClearAllBtn = () => {
        setFilterScreener({
            ...filterScreener,
            [groupKey]: {
                ...filterScreener[groupKey],
                "components": {
                    ...filterScreener[groupKey]['components'],
                    [componentKey]: {
                        ...filterScreener[groupKey]['components'][componentKey],
                        value: []
                    }
                }
            }
        })
    }

    return (
        <div style={modalStyle} className={'generic-modal-body search-modal-body'}>
            <div style={{display: 'flex', margin: "10px 0"}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <AiOutlineClose onClick={handleCloseSectorModel}
                                className={'search-close-modal-button'}/>
            </div>

            <input
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                className={'form-control exchange-search-filter'}
                placeholder={`Search ${filterType.type}`}/>

            <div className={'exchange-list'}>
                {items.map((item, index) => {
                    return (
                        <div key={index} className={'exchange-searched-container'}
                             onClick={() => handleClick(item)}>
                            <input className="form-check-input filter-exchange-checkbox"
                                   type="checkbox" value="" id="flexCheckChecked"
                                   checked={filterScreener[groupKey]['components'][componentKey]['value'].includes(item)}
                            />
                            <p className={'exchange-searched-name'}>
                                {item}
                            </p>
                        </div>
                    )
                })}
            </div>

            <div style={{display: 'flex', marginTop: 15}}>
                <button className={'btn btn-danger clear-all-btn'} style={{marginRight: 10}}
                        onClick={handleClearAllBtn}>Clear All
                </button>
                <button className={'btn checked-filter-btn'} style={{marginRight: 10, marginLeft: 0}}
                        onClick={handleSelectAllBtn}>Select All
                </button>
                <button className={'btn checked-filter-btn'} style={{margin: 0}}
                        onClick={handleCloseSectorModel}>Done
                </button>
            </div>
        </div>
    );
}

export default FilterComponentSectorModal;
