import React, {useState} from 'react';
import {return_strong_num} from "../Utils";

function IndexStatExchangeTable({index, exchange, holidays}) {

    const [state, setState] = useState(1)

    return (
        <div className={'index-stat-table-container'}>

            <div className={'index-stat-table-title'}>
                <p className={state === 1 ? 'selected' : 'unselected'} onClick={() => {setState(1)}}>Key Facts</p>
                <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                <p className={state === 2 ? 'selected' : 'unselected'} onClick={() => {setState(2)}}>Exchange Holidays</p>
            </div>


            {state === 1 ?
                <div className={'index-stat-table-body-container'}>
                    <table className={'table generic-table-body indices-sm-table-body'}>
                        <tbody>
                        <tr>
                            <td>
                                Index Code
                            </td>
                            <td className={'item-right-align-td'}>
                                {index.symbol}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Country
                            </td>
                            <td className={'item-right-align-td'}>
                                {exchange['country']}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Exchange Code
                            </td>
                            <td className={'item-right-align-td'}>
                                {exchange['code']}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Currency
                            </td>
                            <td className={'item-right-align-td'}>
                                {exchange['currency']}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Timezone
                            </td>
                            <td className={'item-right-align-td'}>
                                {exchange['exchange_details']['time_zone']}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Trading Days
                            </td>
                            <td className={'item-right-align-td'}>
                                {exchange['exchange_details']['working_days']}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Trading Hours
                            </td>
                            <td className={'item-right-align-td'}>
                                {`${exchange['exchange_details']['open']} - ${exchange['exchange_details']['close']}`}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                :
                <div className={'index-stat-table-body-container'}>
                    <table className={'table generic-table-body indices-sm-table-body'}>
                        <tbody>
                        {holidays.map((holiday, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {holiday['name']}
                                    </td>
                                    <td className={'item-right-align-td'}>
                                        {holiday['date']}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default IndexStatExchangeTable;