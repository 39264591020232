import React, {useState} from 'react';
import {Helmet} from "react-helmet-async";
import DashboardLayout from "../Layout/DashboardLayout";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import {a11yProps, TabPanel} from "../Container/Utils";
import SwipeableViews from "react-swipeable-views";
import CalenderSubTabs from "../Container/Calender/CalenderSubTabs";

function Calender() {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <Helmet>
                <title>Calender</title>
                <meta name={"description"}
                      content={"Calender"}/>
            </Helmet>
            <DashboardLayout>
                <div className={"generic-tabs-container"}>
                    <Box display="flex" justifyContent="left" width="100%">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            indicatorColor={'primary'}
                            scrollButtons="auto"
                        >
                            <Tab label="Earnings" {...a11yProps(0)} />
                            <Tab label="Dividends"
                                 style={{}} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <div className="tabsPanelContainer company-overview-panel">
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            disabled={true}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel className={'generic-tab-panel'} value={value} index={0}
                                      dir={theme.direction}>
                                <CalenderSubTabs type={'earnings'}/>
                            </TabPanel>

                            <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                                <CalenderSubTabs type={'dividends'}/>
                            </TabPanel>

                        </SwipeableViews>
                    </div>
                </div>
            </DashboardLayout>
        </>
    );
}

export default Calender;