import React, {useEffect, useState} from 'react';
import {CgClose} from "react-icons/cg";
import {getModalStyle} from "../Utils";
import ExchangeSearchBody from "../ISqoreTabs/ExchangeSearchBody";

function CalenderExchangeModal({handleCloseModal, checkedExchange, setCheckedExchange}) {
    const [modalStyle] = useState(getModalStyle);

    const [tempCheckedExchange, setTempCheckedExchange] = useState(checkedExchange);

    const performFilter = () => {
        setCheckedExchange(tempCheckedExchange)
    }

    useEffect(() => {
        console.log("checkedExchange =>", checkedExchange)
    }, [checkedExchange])

    return (
        <div style={modalStyle}
             className={'best-iSqore-filter-modal-body'}>

            <div style={{display: 'flex', marginBottom: 10}}>
                <p className={'search-modal-text-heading'}>Finqube Advance Company Search Engine</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <ExchangeSearchBody
                performFilter={performFilter}
                setTempCheckedExchange={setTempCheckedExchange}
                tempCheckedExchange={tempCheckedExchange}
                setCheckedExchange={setCheckedExchange}
            />

        </div>
    );
}

export default CalenderExchangeModal;