import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import clsx from "clsx";
import axios from "axios";
import Config from "../../../Config";
import {getModalStyle} from "../../Utils";
import {CgClose} from "react-icons/cg";
import {toast} from "react-hot-toast";
import {useSelector} from "react-redux";

function SelectWatchListModal({
                                  selectedTicker,
                                  save_ticker,
                                  handleCloseModal,
                                  topBar = false
                              }) {
    const user = useSelector(state => state.actions.user);

    const [modalStyle] = useState(getModalStyle);

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(true)

    const [watchLists, setWatchLists] = useState([])
    const [checkedWatchListItems, setCheckWatchListItems] = useState([])

    const [slide, setSlide] = useState(1)

    const handleChangeCheck = (item) => {
        setWatchLists(watchLists.map(watchList => {
            if (watchList.id === item.id) {
                watchList.checked = !watchList.checked
            }
            return watchList
        }))
        // if (!topBar) {
        if (checkedWatchListItems.includes(item.id)) {
            setCheckWatchListItems(checkedWatchListItems.filter(checkedItem => checkedItem !== item.id))
        } else {
            setCheckWatchListItems([...checkedWatchListItems, item.id])
        }
        // }
    }

    const get_watchlist = () => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-index-watchlists/${selectedTicker.company_id}`, config)
            .then(response => {
                setWatchLists(response.data)
                setLoading(false)
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        get_watchlist()
    }, [])

    const save_watchlist = () => {
        if (user.subscription.payment_method === 'Free' && watchLists.length >= 1) {
            toast.error("Free package allows only one watchlist, please subscribe for our Basic Plan to get up to 10 watchlists")
            return
        }
        if (user.subscription.payment_method !== 'Free' && watchLists.length >= 10) {
            toast.error("You have reached the maximum number of watchlists")
            return
        }
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/add-watchlist/`, {name: name}, config)
            .then(response => {
                setWatchLists(response.data)
            })
            .catch(err => console.log(err.message))
    }

    return (
        <div style={modalStyle}
             className={clsx('add-component-to-watchlist-modal')}>
            <div style={{display: 'flex'}}>
                <p className={'add-component-to-watchlist-heading'}>Add Component to Watchlist</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            {slide === 1 &&
                <>
                    <p className={'add-component-to-watchlist-name'}>{selectedTicker['Company Name']}</p>

                    <div style={{display: 'flex', margin: "20px 0"}}>
                        <p className={'add-component-to-watchlist-name'}>Your Watchlist</p>

                        <button className={'btn btn-danger'}
                                onClick={() => {
                                    setSlide(2)
                                }}
                                style={{fontSize: 12, fontWeight: 600, marginLeft: 'auto'}}>Add New
                            Watchlist +
                        </button>
                    </div>

                    {
                        loading ?
                            <>
                            </>
                            :
                            watchLists.length === 0 ?
                                <p className={'add-component-to-watchlist-alert'}>No Watchlist available. Please add new
                                    Watchlist</p>
                                :
                                <div className={'add-component-to-watchlist-watchlist'}>
                                    {watchLists.map((watchlist, index) => {
                                        return (
                                            <div key={index} className={'add-component-to-watchlist-watchlist-container'}>
                                                <p className={'add-component-to-watchlist-watchlist-name'}>{index + 1}. {watchlist.name}</p>
                                                <div className="add-component-to-watchlist-watchlist-check form-check">
                                                    <input className="form-check-input"
                                                           type="checkbox" value="" id="flexCheckChecked"
                                                           onChange={() => {
                                                               handleChangeCheck(watchlist)
                                                           }}
                                                           checked={watchlist.checked}/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                    }

                    {checkedWatchListItems.length !== 0 &&
                        <div style={{display: 'flex'}}>
                            <button className={'btn btn-success'}
                                    onClick={() => {
                                        save_ticker(watchLists)
                                    }}
                                    style={{fontSize: 12, fontWeight: 600, marginLeft: "auto"}}>
                                Update Watchlist
                            </button>
                        </div>
                    }

                </>
            }

            {slide === 2 &&
                <>

                    <p className={'add-component-to-watchlist-new-watchlist-name'}>Save new WatchList</p>

                    <input placeholder={'Enter Watchlist Name'}
                           onChange={(e) => {
                               setName(e.target.value)
                           }}
                           className={'form-control my-3 add-component-to-watchlist-input'}
                    />

                    <div style={{display: 'flex'}}>
                        <button className={'btn btn-warning'}
                                onClick={() => {
                                    get_watchlist()
                                    setSlide(1)
                                }}
                                style={{fontSize: 12, fontWeight: 600}}>Back
                        </button>
                        <button className={'btn btn-danger'}
                                onClick={() => {
                                    setLoading(true)
                                    save_watchlist()
                                    setTimeout(() =>
                                        get_watchlist(), 500);
                                    setSlide(1)
                                }}
                                style={{fontSize: 12, fontWeight: 600, marginLeft: 'auto'}}>Save
                            Watchlist
                        </button>
                    </div>
                </>
            }

        </div>
    );
}

export default SelectWatchListModal;