import React, {useState} from 'react';
import Config from "../../Config";
import axios from "axios";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import {getModalStyle} from "../Utils";

function DeleteScreenerModal({
                                 screener_id,
                                 setValue,
                                 handleCloseModal,
                                 setUserScreeners
                             }) {
    const [modalStyle] = useState(getModalStyle);

    const DeleteScreener = (id) => {
        const config = Config()
        const body = {id: id}
        axios.delete(`${process.env.REACT_APP_API_URL}/finqube-api/screener-settings/${screener_id}/`, body, config)
            .then(response => {
                setUserScreeners((prev) => prev.filter(item => item.id !== screener_id))
                setValue(0)
            })
            .catch(err => console.log(err.message))
    }

    return (
        <div style={modalStyle}
             className={clsx('watchlist-modal-body')}>
            <div style={{display: 'flex'}}>
                <p className={'watchlist-modal-title'}>Are you sure you want to delete this Screener</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn settings-ticker-btn'} onClick={handleCloseModal}
                        style={{marginRight: "auto"}}>No
                </button>
                <button className={'btn delete-ticker-btn'} onClick={() => {
                    DeleteScreener(screener_id)
                    handleCloseModal()
                }} style={{marginLeft: "auto"}}>Delete
                </button>
            </div>

        </div>
    );
}

export default DeleteScreenerModal;