import React, {useEffect, useState} from 'react';
import MarketMoverGaugeChart from "./MarketMoverGaugeChart";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Tooltip} from "react-tooltip";

function VixGauge({data}) {

    const [price, setPrice] = useState(0)

    useEffect(() => {
        if (price !== data['^VIX'].price) {
            setPrice(data['^VIX'].price)
        }
    }, [data]);

    let text = ''

    if (price < 10) {
        text = 'Calm'
    } else if (price >= 10 && price <= 15) {
        text = 'Low'
    } else if (price > 15 && price <= 20) {
        text = 'Moderate'
    } else if (price > 20 && price <= 25) {
        text = 'Increased'
    } else if (price > 25 && price <= 30) {
        text = 'High'
    } else if (price > 30 && price <= 40) {
        text = 'Extreme'
    } else if (price > 40) {
        text = 'Panic'
    }

    return (
        <div className={'fear-and-greed-container'}>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
                <h4 style={{margin: '0 auto', textAlign: 'center', flexGrow: 1}}>Volatility Index</h4>
                <AiOutlineInfoCircle
                    data-tooltip-id="volatility-index-tooltip"
                    style={{fontSize: 17}}
                    data-tooltip-html={
                        `<div style="text-align: left; font-size: 10px; width: 300px;">
                            <p>The Volatility Index (VIX), is a market measure that 
                            shows how much volatility investors expect in the next 30 days.
                            Developed by the Chicago Board Options Exchange (CBOE), 
                            it is often called the "fear gauge" or "fear index" because it 
                            usually goes up when investors are concerned about major drops
                            in the stock market.</p>
                            <ul style="margin: 0 0 2px 5px; padding: 5px;">
                                 <li>< 10 Calm, extremely low volatility, indicating a very calm and stable market.</li>
                                 <li>10 - 15 Low volatility, suggesting a stable market with moderate price fluctuations.</li>
                                 <li>15 - 20 Moderate volatility, considered normal market conditions with expected fluctuations.</li>
                                 <li>20 - 25 Increased volatility, indicating rising uncertainty and potential for larger price swings.</li>
                                 <li>25 - 30 High volatility, signaling significant market stress and uncertainty.</li>
                                 <li>30 - 40 Extreme volatility, often associated with major market corrections or economic events.</li>
                                 <li>> 40 Panic, extremely high volatility, indicating severe market turmoil and widespread fear among investors.</li>                       
                            </ul>
                        </div>`
                    }
                />
                <Tooltip id="volatility-index-tooltip" opacity={1}/>
            </div>

            <MarketMoverGaugeChart price={price} text={text}/>
        </div>
    );
}

export default VixGauge;
