import React, {useState} from 'react';
import MarketOverviewIndicesTable from "../MarketOverviewIndicesTable";
import {get_index_body} from "../../Utils";

function MarketMoversUs({data, handleSetChartData, handleSetIndexCompanies}) {

    const [body, setBody] = useState(get_index_body([
        ['S&P 500', 'GSPC', data['^GSPC'], "GSPC.INDX", "index", "https://flagcdn.com/108x81/us.png", "^GSPC"],
        ['S&P 100', 'SP100', data['^OEX'], "SP100.INDX", "index", "https://flagcdn.com/108x81/us.png", "^OEX"],
        ['Dow Jones', 'DJI', data['^DJI'], "DJI.INDX", "index", "https://flagcdn.com/108x81/us.png", "^DJI"],

        ['Nasdaq 100', 'NDX', data['^NDX'], "NDX.INDX", "index", "https://flagcdn.com/108x81/us.png", "^NDX"],
        ['Russell 2000', 'RUT', data['^RUT'], "RUT.INDX", "index", "https://flagcdn.com/108x81/us.png", "^RUT"],

        ['TSX 60', 'GSPTSE', data['^NDX'], "GSPTSE.INDX", "index", "https://flagcdn.com/108x81/ca.png", "TX60.TS"],
        ['Bovespa', 'BVSP', data['^BVSP'], "BVSP.INDX", "index", "https://flagcdn.com/108x81/br.png", "^BVSP"]
    ]))

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                <p>US</p>
            </div>
            <MarketOverviewIndicesTable
                body={body} setBody={setBody}
                handleSetChartData={handleSetChartData}
                handleSetIndexCompanies={handleSetIndexCompanies}
                height={190}
                applySort={true}
            />
        </div>
    );
}

export default MarketMoversUs;