import React, {useContext, useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../Context/ThemeContext";

function InvestorsPieChart({investors}) {

    const {webTheme} = useContext(ThemeContext);
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        let weights = []
        let labels = []
        investors.sort((a, b) => b.ownership - a.ownership).map((investor, index) => {
            if (labels.length <= 9) {
                labels.push(investor.investorName.length > 25 ? investor.investorName.substring(0, 25) + "..." : investor.investorName)
                weights.push(parseFloat(investor.ownership.toFixed(2)))
            }
        })

        const sum = weights.reduce((partialSum, a) => partialSum + a, 0);

        labels.push("Others")
        weights.push(100 - sum.toFixed(2))

        setData(weights)
        setLabels(labels)

    }, [investors])

    const state = {
        series: data,
        options: {
            chart: {
                type: 'donut',
                foreColor: graphVar.color,
                width: 300,
            },
            dataLabels: {
                enabled: true,
            },
            colors: ['#ee1981', '#dc1294', '#c50da7', '#a410c2', '#8929cb', '#6d46d4', '#5a5ddb', '#506bdf', '#467be3', '#4090e9', '#3e9fed'],
            legend: {
                show: true,
                position: 'left',
                formatter: function (seriesName, opts) {
                    return [opts.w.globals.series[opts.seriesIndex].toFixed(2) + " %", ' - ', seriesName.length > 40 ? seriesName.slice(0, 40) + '...' : seriesName]
                }
            },
            labels: labels,
            responsive: [{
                breakpoint: 490,
                options: {
                    chart: {
                        width: 310
                    },
                    legend: {
                        position: 'top',
                        show: false
                    }
                }
            }]
        }
    };

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        data.length > 0 &&
        <div className={'company-overview-pie-chart-container'}>
            <div className={'company-overview-pie-chart-inner-container'}>
                <p className={'company-overview-pie-chart-title'}>Top Institutional Ownership</p>
                <ReactApexChart options={state.options} series={data} type="donut" width={'100%'}
                                height={'100%'}
                />
            </div>
        </div>
    );
}

export default InvestorsPieChart;