import React, {useEffect, useState} from 'react';
import DashboardLayout from "../Layout/DashboardLayout";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {a11yProps, TabPanel} from "../Container/Utils";
import PersonalInformationTab from "../Container/Profile/PersonalInformationTab";
import ProfileSubscriptionTab from "../Container/Profile/ProfileSubscriptionTab";
import ProfileApplicationSettings from "../Container/Profile/ProfileApplicationSettings";
import NotesPriceAlertsTab from "../Container/Profile/NotesPriceAlertsTab";
import CommunityForumTab from "../Container/Profile/CommunityForumTab";
import ConnectedPortfoliosTab from "../Container/Profile/ConnectedPortfoliosTab";

function Profile() {
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login')
        }
    }, [])

    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const user = useSelector(state => state.actions.user);

    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        birthday: "",
        country: "",
        zip_code: "",
        city: "",
        address: "",
        img: "",
        email: ""
    })

    useEffect(() => {
        setUserData(user)
    }, [])

    return (
        <DashboardLayout>
            <div className={"generic-tabs-container"}>
                <Box display="flex" justifyContent="left" width="100%">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor={'primary'}
                        scrollButtons="auto"
                    >
                        <Tab label="Personal Information" {...a11yProps(0)} />
                        <Tab label="Subscription" {...a11yProps(1)} />
                        <Tab label="Application Settings" {...a11yProps(2)} />
                        <Tab label="Notes/Price Alerts" {...a11yProps(3)} />
                        <Tab label="Community Forum" {...a11yProps(4)} />
                        <Tab label="Connected Portfolios" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <div className="tabsPanelContainer company-overview-panel">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        disabled={true}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={0}
                                  dir={theme.direction}>
                            <PersonalInformationTab user={user}
                                                    userData={userData}
                                                    setUserData={setUserData}/>
                        </TabPanel>
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={1}
                                  dir={theme.direction}>
                            <ProfileSubscriptionTab />
                        </TabPanel>
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={2}
                                  dir={theme.direction}>
                            <ProfileApplicationSettings
                                user={user}
                                userData={userData} />
                        </TabPanel>
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={3}
                                  dir={theme.direction}>
                            <NotesPriceAlertsTab />
                        </TabPanel>
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={4}
                                  dir={theme.direction}>
                            <CommunityForumTab />
                        </TabPanel>
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={5}
                                  dir={theme.direction}>
                            <ConnectedPortfoliosTab />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default Profile;