import React, {useState} from 'react';
import PerformanceTable from "./PerformanceTable";
import SummaryTabGraphContainer from "../Summary/SummaryTabGraphContainer";

function PerformanceVixFearGreedTable({tableState, setTableState}) {

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                {/*<p>Equity Market</p> | <p>Watchlist</p>*/}
                <p className={tableState === 'performance' ? 'selected' : 'unselected'} onClick={() => {
                    setTableState('performance')
                }}>Performance</p>
                <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                <p className={tableState === 'vix' ? 'selected' : 'unselected'} onClick={() => {
                    setTableState('vix')
                }}>VIX</p>
                <p className={'unselected'} style={{margin: '0 10px'}}>|</p>
                <p className={tableState === 'fear' ? 'selected' : 'unselected'} onClick={() => {
                    setTableState('fear')
                }}>Fear & Greed</p>
            </div>

            <div className={'market-overview-summary-table-body-container'} style={{
                padding: "0 10px 0 0",
                borderRadius: '0 0 10px 10px'
            }}>
                {tableState === 'performance' ?
                    <PerformanceTable/>
                    : tableState === 'vix' ?
                        <SummaryTabGraphContainer
                            chartData={{
                                name: "",
                                code: "VIX",
                                exchange: "INDX",
                                type: "index"
                            }} container={'market-overview-chart-container market-overview-vix-outer-container'}
                            innerContainer={'market-overview-inner-container market-mover-vix-container'}/>
                        :
                        <SummaryTabGraphContainer
                            chartData={{
                                name: "",
                                code: "fearNGreed",
                                exchange: "fearNGreed",
                                type: "fearNGreed"
                            }} container={'market-overview-chart-container'}
                            innerContainer={'market-overview-inner-container market-mover-vix-container'}/>
                }
            </div>

        </div>
    );
}

export default PerformanceVixFearGreedTable;