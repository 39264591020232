import React, {useEffect, useState} from 'react';
import axios from "axios";
import Config from "../../Config";

function EtfSecondSearch({
                             tempCheckedExchange,
                             setTempCheckedExchange,
                             setCheckedExchange,
                             setCheckedSecond,
                             tempCheckedSecond,
                             setTempCheckedSecond,
                             setLoading,
                             url,
                             handleCloseModal,
                             setETFData,
                             setFilterETFData,
                             setETFLoadingStat,
                             return_instrument,
                             etf_type
                         }) {

    const [filterCategoryList, setFilterCategoryList] = useState([])
    const [categoryList, setCategoryList] = useState([])

    const [filterLoader, setFilterLoader] = useState(true)

    useEffect(() => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/get-etf-category2-list/`, {
            etf_type: etf_type
        }, config).then((response) => {
            setCategoryList(response.data)
            setFilterCategoryList(response.data)
            setFilterLoader(false)
        })
            .catch(e => console.log(e.message))
    }, [])

    const reset_filter = () => {
        setTempCheckedSecond([])
        setCheckedSecond([])
    }

    const handleSecondClick = (cat) => {
        setTempCheckedSecond(tempCheckedSecond.includes(cat) ? tempCheckedSecond.filter(item => item !== cat) : [...tempCheckedSecond, cat])
    }

    const performFilter = () => {
        const config = Config()
        let body = {
            etf_type: etf_type,
            exchanges: tempCheckedExchange,
            category2: tempCheckedSecond
        }

        axios.post(url, body, config)
            .then(response => {
                handleCloseModal()
                setETFLoadingStat((prev) => ({
                    ...prev,
                    url: response.data.next,
                    totalETF: response.data.count,
                    filter_type: "filter",
                    see_more: response.data.next !== null
                }))

                const etf = return_instrument(response.data.results)
                setETFData(etf)
                setFilterETFData(etf)

                setLoading(false)
                setCheckedSecond(tempCheckedSecond)
                setCheckedExchange(tempCheckedExchange)
            })
            .catch(response => {
                setETFLoadingStat((prev) => ({
                        ...prev,
                        see_more: false
                    })
                )
            })
    }

    const [search, setSearch] = useState("")
    useEffect(() => {
        if (search === '') {
            setFilterCategoryList(categoryList)
        } else {
            setFilterCategoryList(categoryList.filter(category => category.toLowerCase().includes(search.toLowerCase())))
        }
    }, [search])


    return (<div>

        <input
            value={search}
            onChange={(e) => {
                setSearch(e.target.value)
            }}
            className={'form-control exchange-search-filter'}
            placeholder={etf_type === 'Thematic' ? 'Search i.e Fintech' : 'Search Region i.e Germany'}/>

        <div style={{display: 'flex', margin: '15px 0'}}>
            <p className={'search-modal-text-heading'} style={{margin: "auto 0"}}>Select to filter</p>
            <button className={'btn checked-filter-btn'}
                    onClick={reset_filter}>Reset Filter
            </button>
        </div>

        <div className={'exchange-list'}>
            {filterLoader ? <>
                <div className={'navbar-modal-company-loader-container'}>
                    <div className={'loader navbar-searched-pill-loader'}></div>
                    <div className={'loader navbar-searched-pill-loader'}></div>
                    <div className={'loader navbar-searched-pill-loader'}
                         style={{marginLeft: 'auto'}}
                    ></div>
                </div>
                <div className={'navbar-modal-company-loader-container'}>
                    <div className={'loader navbar-searched-pill-loader'}></div>
                    <div className={'loader navbar-searched-pill-loader'}></div>
                    <div className={'loader navbar-searched-pill-loader'}
                         style={{marginLeft: 'auto'}}
                    ></div>
                </div>
            </> : filterCategoryList.length !== 0 ? <>
                {filterCategoryList.map((cat, index) => {
                    return (
                        <div key={index}
                            className={'exchange-searched-container'}
                             onClick={() => handleSecondClick(cat)}>
                            <input className="form-check-input filter-exchange-checkbox"
                                   type="checkbox" value="" id="flexCheckChecked"
                                   checked={tempCheckedSecond.includes(cat)}/>
                            <p className={'exchange-searched-name'}>
                                {cat}
                            </p>
                        </div>
                    )
                })}
            </> : <p className={'no-companies-found-text'}>Nothing Found</p>}

        </div>

        <div style={{display: 'flex', marginTop: 10}}>
            <button className={'btn checked-filter-btn'} onClick={() => {
                setLoading(true)
                performFilter()
            }}>Apply
            </button>
        </div>

    </div>);
}

export default EtfSecondSearch;