import IndexStatPerformanceTable from "../../Indices/IndexStatPerformanceTable";
import IndexStatExchangeTable from "../../Indices/IndexStatExchangeTable";
import SummaryTabGraphContainer from "../../MarketOverview/Summary/SummaryTabGraphContainer";

const IndexStats = ({index, selectedIndexData}) => {

    return (
        <div className={'row g-0 mt-3'}>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <SummaryTabGraphContainer chartData={{name: index.name, code: index.symbol, exchange: "INDX", type: "index"}} container={'index-chart-container'}
                                                  innerContainer={'index-chart-inner-container'}/>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12">
                <IndexStatPerformanceTable
                    title={"Performance"}
                    performance={selectedIndexData['indices']['real-time']['performance']}
                />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <IndexStatExchangeTable
                    index={index}
                    exchange={selectedIndexData['exchange']}
                    holidays={selectedIndexData['exchange']['exchange_holidays']}
                />
            </div>
        </div>
    );
}

export default IndexStats;