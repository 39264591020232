import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import Select from "react-select";
import {Modal, TablePagination} from "@material-ui/core";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import axios from "axios";
import Config from "../../../Config";
import SelectWatchListModal from "./SelectWatchListModal";
import {useDispatch, useSelector} from "react-redux";
import * as Utils from "../../Utils";
import {
    is_number, return_grade_background_color,
    return_grade_color,
    return_per_div,
    return_strong_num,
    return_td_color,
    selected_table_styles, tableDataRow
} from "../../Utils";
import _debounce from "lodash/debounce";
import clsx from "clsx";
import LoginPaymentBlocker from "../../LoginPaymentBlocker";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {load_common_stock} from "../../../actions/actions";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import indexComponentBlockerDark from '../../../assets/blockers/index-components-blocker-dark.png';
import indexComponentBlockerLight from '../../../assets/blockers/index-components-blocker-light.png';
import building from "../../../assets/default-building.png";
import LgSelectableTable from "../../LgSelectableTable";
import {useQuery} from "@tanstack/react-query";
import IndexComponentsTable from "./IndexComponentsTable";


function IndicesSelectableTable({selectedIndexData}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const options = [
        {index: 0, value: 'watchlist', label: '', isFixed: true, state: 0, sort: false, className: "indices-table-star"},
        {index: 1, value: 'code', label: 'Ticker', isFixed: true, state: 0, sort: true, className: "indices-table-code"},
        {index: 2, value: 'name', label: 'Company Name', isFixed: true, state: 0, sort: true, className: "indices-table-name"},
        {index: 3, value: 'ISIN', label: 'ISIN', isFixed: true, state: 0, sort: true, className: ""},
        {index: 4, value: "industry", label: "Industry", state: 0, sort: true, className: "indices-table-name"},
        {index: 5, value: 'adjusted_close', label: 'Last Price', state: 0, sort: true, className: "item-right-align-th"},
        {index: 6, value: 'change_p', label: 'Change %', state: 0, sort: true, className: "item-right-align-th"},
        {
            index: 7,
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            state: 0, sort: true,
            className: "item-right-align-th"
        },
        {index: 8, value: 'p_e', label: 'P/E', state: 0, sort: true, className: "item-right-align-th"},
        {index: 9, value: 'p_b', label: 'P/B', state: 0, sort: true, className: "item-right-align-th"},
        {index: 10, value: 'ev_ebit', label: 'EV/EBIT', state: 0, sort: true, className: "item-right-align-th"},
        {index: 11, value: 'price_target', label: 'Price Target', state: 0, sort: true, className: "item-right-align-th"},
        {index: 12, value: 'iSqore', label: 'iSqore', state: 0, sort: true, className: "item-right-align-th"},
    ]
    const defaultColumns = [
        {index: 0, value: 'watchlist', label: '', isFixed: true, state: 0, sort: false, className: "indices-table-star"},
        {index: 1, value: 'code', label: 'Ticker', isFixed: true, state: 0, sort: true, className: "indices-table-code"},
        {index: 2, value: 'name', label: 'Company Name', isFixed: true, state: 0, sort: true, className: "indices-table-name"},
        {index: 3, value: 'ISIN', label: 'ISIN', isFixed: true, state: 0, sort: true, className: ""},
        {index: 4, value: "industry", label: "Industry", state: 0, sort: true, className: "indices-table-name"},
        {index: 5, value: 'adjusted_close', label: 'Last Price', state: 0, sort: true, className: "item-right-align-th"},
        {index: 6, value: 'change_p', label: 'Change %', state: 0, sort: true, className: "item-right-align-th"},
        {
            index: 7,
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            state: 0, sort: true,
            className: "item-right-align-th"
        },
        {index: 12, value: 'iSqore', label: 'iSqore', state: 0, sort: true, className: "item-right-align-th"},
    ]
    const [columns, setColumns] = useState([
        {index: 0, value: 'watchlist', label: '', isFixed: true, state: 0, sort: false, className: "indices-table-star"},
        {index: 1, value: 'code', label: 'Ticker', isFixed: true, state: 0, sort: true, className: "indices-table-code"},
        {index: 2, value: 'name', label: 'Company Name', isFixed: true, state: 0, sort: true, className: "indices-table-name"},
        {index: 3, value: 'ISIN', label: 'ISIN', isFixed: true, state: 0, sort: true, className: ""},
        {index: 4, value: "industry", label: "Industry", state: 0, sort: true, className: "indices-table-name"},
        {index: 5, value: 'adjusted_close', label: 'Last Price', state: 0, sort: true, className: "item-right-align-th"},
        {index: 6, value: 'change_p', label: 'Change %', state: 0, sort: true, className: "item-right-align-th"},
        {
            index: 7,
            value: 'm_cap_trading',
            label: 'Market Cap (mln)',
            state: 0, sort: true,
            className: "item-right-align-th"
        },
        {index: 12, value: 'iSqore', label: 'iSqore', state: 0, sort: true, className: "item-right-align-th"},
    ])

    const return_row = (data) => {
        let indexComponents = []

        data.map((item) => {
            indexComponents.push({
                name: item.company_id,
                rowItems: [
                    tableDataRow({
                        content: item.watchlistticker_exists ? <AiFillStar className={'watchlist-star'}/> :
                            <AiOutlineStar className={'watchlist-star'}/>,
                        onClick: () => {
                            handleOpenModal(item)
                        },
                        additional_attr: {label: ""}
                    }),
                    tableDataRow({
                        content: <>
                            <td className={""}>
                                <img className={'price-target-company-logo'}
                                     src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}
                                     onError={({currentTarget}) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src = building;
                                     }}/>
                                {item.symbol}
                            </td>
                            {/*<img alt={'img'} style={{width: 23, borderRadius: 5}} src={item.LogoURL}/> {item.symbol}*/}
                        </>,
                        onClick: () => {
                            dispatch(load_common_stock(item.symbol, item.exchange_code))
                            navigate('/company-overview/' + item.symbol + '/' + item.exchange_code + '/')
                        },
                        additional_attr: {label: "Ticker"}
                    }),
                    tableDataRow({
                        content: item.name,
                        additional_attr: {label: "Company Name"},
                        hoverText:true,
                        hoverCount: 25
                    }),
                    tableDataRow({
                        label: "ISIN",
                        content: item.ISIN,
                        additional_attr: {label: "ISIN"}
                    }),
                    tableDataRow({
                        content: item.Industry,
                        additional_attr: {label: "Industry"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.last_price),
                        className: 'item-right-align-th',
                        additional_attr: {label: "Last Price"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.per_change),
                        className: 'item-right-align-th td-per-change',
                        per: true,
                        additional_attr: {changePer: true, label: "Change %"}
                    }),
                    tableDataRow({
                        content: item.m_cap_trading,
                        className: 'item-right-align-th',
                        valueInMillion: true,
                        valueInComma: true,
                        additional_attr: {label: "Market Cap (mln)"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.p_e),
                        className: 'item-right-align-th',
                        additional_attr: {label: "P/E"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.p_b),
                        className: 'item-right-align-th',
                        additional_attr: {label: "P/B"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.ev_ebit),
                        className: 'item-right-align-th',
                        additional_attr: {label: "EV/EBIT"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.price_target),
                        className: 'item-right-align-th',
                        additional_attr: {label: "Price Target"}
                    }),
                    tableDataRow({
                        content: Utils.return_strong_num(item.iSqore * 100),
                        className: 'item-right-align-th isqore-lg-table-td',
                        additional_attr: {iSqore: true, iSqoreRating: item.Rating, label: "iSqore"}
                    }),
                ]

            })
        })

        return indexComponents

    }

    const handleChange = (e) => {
        setColumns(e.sort((a, b) => {
            if (a.index < b.index) {
                return -1
            } else if (a.index > b.index) {
                return 1
            }
            return 0
        }))
    }

    const [selectedTicker, setSelectedTicker] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (item) => {
        setSelectedTicker(item)
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <IndexComponentsTable
            selectableTable={true}
            selectedIndexData={selectedIndexData}

            options={options}
            defaultColumns={defaultColumns}
            columns={columns}
            handleChange={handleChange}

            setColumns={setColumns}
            return_row={return_row}

            selectedTicker={selectedTicker}

            openModal={openModal}
            handleCloseModal={handleCloseModal}
        />
    );
}

export default IndicesSelectableTable;