import React, {useEffect, useState} from 'react';
import {is_number, return_float, return_per_div, return_strong_num} from "../Utils";
import SectorWeight from "./SectorWeight";

function return_num(value) {
    if (value && is_number(value)) {
        return parseFloat(value).toFixed(2);
    } else {
        return "-";
    }
}

function PerformanceWeightsTable({data}) {
    return (
        <div className={'etf-profiler-stat-table-container'}>

            <div className={'index-stat-table-title'}>
                <p className={'selected'}>Performance</p>
            </div>

            <div className={'index-stat-table-body-container'}>
                <table className={'table generic-table-body etf-sm-table-body performance-table'}>
                    <tbody>
                    <tr>
                        <td>
                            Ytd
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['ytd'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Week
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['week'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1 Month
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['one_month'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3 Month
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['three_month'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1 Year
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['one_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3 Years
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['three_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            5 Years
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['five_year'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            10 Years
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['ytd'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Ø p.a.(5y)
                        </td>
                        <td className={'item-right-align-td'}>
                            {return_per_div(data['performances']['ytd'] * 100, 11, 60, "0 0 0 auto")}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default PerformanceWeightsTable;