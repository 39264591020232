import React, {useContext} from 'react';
import {FormControlLabel, Switch} from "@material-ui/core";
import {ThemeContext} from "../../Context/ThemeContext";
import axios from "axios";
import Config from "../../Config";
import {toast} from "react-hot-toast";
import {raise_error} from "../Utils";
import {change_theme} from "../../actions/actions";
import {useDispatch} from "react-redux";
import {MdLightMode, MdNightlightRound} from "react-icons/md";
import {BsPencil} from "react-icons/bs";

function ProfileApplicationSettings({userData}) {
    const {webTheme, changeTheme} = useContext(ThemeContext);
    const dispatch = useDispatch()

    const request_change_password = () => {
        const config = Config()

        toast.promise(axios.get(`${process.env.REACT_APP_API_URL}/finqube-accounts/change-password/`, config), {
            loading: '',
            success: (res) => {
                return res.data.message
            },
            error: (err) => {
                return raise_error(err)
            }
        })

    }

    const request_change_email = () => {
        const config = Config()
        toast.promise(axios.get(`${process.env.REACT_APP_API_URL}/finqube-accounts/change-email/`, config), {
            loading: '',
            success: (res) => {
                return res.data.message
            },
            error: (err) => {
                return raise_error(err)
            }
        })
    }

    return (
        <div className="profile-container">
            <div style={{display: 'flex', marginBottom: 15}}>
                <p className={'profile-header'}>App Settings</p>
            </div>
            <div className={'app-settings-fields-container'}>
                <p className={'text-input-field-app-settings'}>Change Theme</p>
                <MdLightMode style={{color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", margin: "auto 0"}}/>
                <FormControlLabel
                     style={{margin: "auto 0"}}
                    control={
                        <Switch
                            checked={webTheme === 'dark'}
                            onChange={() => {
                                changeTheme(webTheme === 'dark' ? 'light' : 'dark')
                                dispatch(change_theme(webTheme === 'dark' ? 'light' : 'dark'))
                            }}
                            color="primary"
                        />
                    }
                />
                <MdNightlightRound style={{color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", margin: "auto 0"}}/>
            </div>

            <div className={'app-settings-fields-container'}>
                <p className={'text-input-field-app-settings'}>Change Email</p>
                <p className={'text-input-field-value'}>{userData.email}</p>
                <button onClick={request_change_email}
                        className={'btn btn-primary btn-request-change-password btn-request-change-password-lg'}>
                    Change Email
                </button>
            </div>
            <button onClick={request_change_email}
                    className={'btn btn-primary btn-request-change-password btn-request-change-password-sm'}>
                Change Email
            </button>

            <div className={'app-settings-fields-container'}>
                <p className={'text-input-field-app-settings'}>Change Password</p>
                <p className={'text-input-field-value'}>***************</p>
                <button onClick={request_change_password}
                        className={'btn btn-success btn-request-change-password btn-request-change-password-lg'}>Change Password
                </button>
            </div>
            <button onClick={request_change_password}
                    className={'btn btn-success btn-request-change-password btn-request-change-password-sm'}>Change Password
            </button>

        </div>
    );
}

export default ProfileApplicationSettings;