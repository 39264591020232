import {useContext, useEffect} from 'react';
import {ThemeContext} from "../Context/ThemeContext";
import {useSelector} from "react-redux";

function WebsiteContainer({children}) {
    const {changeTheme} = useContext(ThemeContext);
    const theme = useSelector(state => state.actions.theme);

    useEffect(() => {
        changeTheme(theme)
    }, [])

    return (
        <div>
            {children}
        </div>
    );
}

export default WebsiteContainer;