import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import ReactApexChart from "react-apexcharts";

function DividendDonutGraph({dividends}) {
    const {webTheme} = useContext(ThemeContext);

    const [loading, setLoading] = useState(true)

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        if (dividends) {
            setSeries([dividends['split_dividends']['PayoutRatio'] * 100, 100 - dividends['split_dividends']['PayoutRatio'] * 100])
        }
    }, [dividends])

    const [series, setSeries] = useState([])
    const options = {
        chart: {
            width: '100%',
            type: 'donut',
            foreColor: graphVar.color
        },
        grid: {
            show: false
        },
        labels: ['Payout Ratio', 'Retention Ratio'],
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(2)
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'left',
            formatter: function (seriesName, opts) {
                return [opts.w.globals.series[opts.seriesIndex].toFixed(2) + " %", ' - ' + seriesName]
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
        }]
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'dividend-chart-container'}>
            <div className={'donut-chart-inner-container'}>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type="donut" width={'100%'}
                                    height={'100%'}/>
                }
            </div>
        </div>
    );
}

export default DividendDonutGraph;