import React, {useEffect} from 'react';
import {MdOutlineAddCircleOutline, MdOutlineCancel} from "react-icons/md";
import clsx from "clsx";

function CompareStockFieldsSidebar({
                                       handleOpenModal,

                                       selectedSidebarFields,

                                       sidebarFields,
                                       setSidebarFields
                                   }) {

    const remove_fields = (groupKey, fieldKey) => {
        setSidebarFields({
            ...sidebarFields,
            [groupKey]: {
                ...sidebarFields[groupKey],
                components: {
                    ...sidebarFields[groupKey]['components'],
                    [fieldKey]: {
                        ...sidebarFields[groupKey]['components'][fieldKey],
                        display: false
                    }
                }
            }
        })
    }

    return (
        <div className={'iSqore-fields-container iSqore-fields-container-margin'} style={{marginTop: 113}}>
            {Object.entries(selectedSidebarFields).map(([fieldKey, field]) => {
                return (
                    <div key={fieldKey} className={'isqore-sidebar-text'}>
                        <p>{field.name}</p>
                        <MdOutlineCancel
                            onClick={() => {
                                remove_fields(field.groupKey, field.fieldKey)
                            }}
                            style={{margin: "auto 0 auto auto", fontSize: 14, cursor: "pointer"}}/>
                    </div>
                )
            })}
            <MdOutlineAddCircleOutline onClick={handleOpenModal} style={{margin: "auto", cursor: "pointer"}}/>
        </div>
    );
}

export default CompareStockFieldsSidebar;
