import {useState} from 'react';
import clsx from "clsx";
import axios from "axios";
import Config from "../../Config";
import {getModalStyle} from "../Utils";
import {CgClose} from "react-icons/cg";

function WatchlistSettingModal({setUserWatchList, handleCloseModal, watchListName, watchListID}) {
    const [modalStyle] = useState(getModalStyle);

    const [name, setName] = useState(watchListName)

    const handleChange = (e) => {
        setName(e.target.value)
    }

    const updateWatchListName = () => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/update-watchlist/`, {
            watchlist_id: watchListID,
            name: name
        }, config)
            .then(response => setUserWatchList(response.data))
            .catch(err => console.log(err.message))
        handleCloseModal()
    }

    return (
        <div style={modalStyle}
             className={clsx('watchlist-settings-modal-body')}>

            <div style={{display: 'flex'}}>
                <p className={'watchlist-settings-modal-heading'}>Update Watchlist Name</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <input value={name} onChange={handleChange} placeholder={'Update Name'}
                   className={'form-control watchlist-input-field my-3'}/>
            <div style={{display: 'flex'}}>
                <button
                    onClick={handleCloseModal}
                    className={'btn btn-warning ticker-modal-back-btn'}>Cancel
                </button>
                <button className={'btn ticker-modal-save-btn'}
                        onClick={updateWatchListName}
                >Save
                </button>
            </div>
        </div>
    );
}

export default WatchlistSettingModal;