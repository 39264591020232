import React, {useState} from 'react';
import {company_overview_sort, numberWithCommas, return_per_div, return_value_in_million} from "../../Utils";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {commit} from "lodash/seq";

function InvestorsTable({investors, loadingInvestors}) {

    const [columns, setColumns] = useState(
        [
            {name: 'Investor', className: 'investor-name'},
            {name: 'Put/Call/Share', className: ''},
            {name: '# Share', className: ''},
            {name: '% Change', className: ''},
            {name: 'Market Value', className: ''},
            {name: '% Owned', className: ''},
            {name: '% Portfolio', className: ''},
            {name: 'First Added', className: ''},
        ]
    )
    return (
        <>
            {loadingInvestors ?
                <div style={{width: '100%', height: '100%', display: ' flex'}}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                <div className={'table-responsive generic-lg-table-container'}>
                    <table className="table generic-lg-table company-overview-rating-table">
                        <thead>
                        <tr>
                            {columns.map((item) => {
                                return (
                                    <th className={item.className}>
                                        {item.name}
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {investors.sort((a, b) => b.ownership - a.ownership).map(item =>
                            <>
                                <tr>
                                    <td>
                                        {item.investorName}
                                    </td>
                                    <td>
                                        {item.putCallShare}
                                    </td>
                                    <td>
                                        {numberWithCommas(item.sharesNumber)}
                                    </td>
                                    {item.isNew ?
                                        <td>
                                            New
                                        </td>
                                        :
                                        item.isSoldOut ?
                                            <td>Sold</td>
                                            :
                                            <td style={{
                                                display: 'flex',
                                                paddingRight: 2
                                            }}>
                                                {return_per_div(item.changeInSharesNumberPercentage, 11, 60, "auto 0")}
                                            </td>
                                    }
                                    <td>
                                        {numberWithCommas(return_value_in_million(item.marketValue))}
                                    </td>
                                    <td>
                                        {item.ownership.toFixed(2)} %
                                    </td>
                                    <td>
                                        {item.weight.toFixed(2)} %
                                    </td>
                                    <td>
                                        {item.firstAdded}
                                    </td>
                                </tr>
                                <div style={{marginTop: 3, width: "100%"}}/>
                            </>
                        )}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}

export default InvestorsTable;