import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Logout} from "../actions/actions";
import Config from "../Config";
import {toast} from "react-hot-toast";
import axios from "axios";
import {raise_error} from "../Container/Utils";

function ConfirmChangeEmail() {
    const formRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const [data, setData] = useState({
        password: "",
        email: "",
        new_email: "",
    })

    const [showPassword, setShowPassword] = useState(false)

    const changeEmail = () => {
        if (data.email === data.new_email) {
            toast.error("New email has to be different from the previous email", {duration: 4000})
        } else {
            const config = Config()
            toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/finqube-accounts/confirm-change-email/`, data, config), {
                loading: 'Changing email...',
                success: (res) => {
                    navigate('/login')
                    return res.data.message
                },
                error: (err) => {
                    return raise_error(err)
                }
            })
        }
    }

    const change_text_handler = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        const code = location.pathname.split('/')[2]
        setData({...data, email: atob(code)})
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(Logout())
        }
    }, [])

    return (
        <div style={{position: "relative", overflow: 'hidden'}}>
            <div className={'ornament-2'}/>
            <form ref={formRef}>
                <div
                    style={{
                        backgroundColor: "#0C1B29",
                        height: "100vh",
                        width: '100%',
                        display: 'flex',
                        overflow: 'auto'
                    }}>
                    <div className="container" style={{margin: 'auto'}}>
                        <div className="change-password-container">
                            <p className={'input-field-helper-text'}>Email</p>
                            <input value={data.email}
                                   disabled={true}
                                   name={"email"}
                                   required={true}
                                   type={'email'}
                                   onChange={change_text_handler}
                                   className={'form-control input-field change-password-email-field mb-2'}/>

                            <p className={'input-field-helper-text'}>Your Password</p>
                            <input value={data.password}
                                   name={"password"}
                                   autoComplete={false}
                                   type={showPassword ? 'text' : 'password'}
                                   required={true}
                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                   title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                   onChange={change_text_handler}
                                   className={'form-control input-field mb-2'}/>

                            <div style={{display: 'flex', margin: "5px 0"}}>
                                <p className={'show-password'}
                                   onClick={() => {
                                       setShowPassword(!showPassword)
                                   }}
                                >Show Password</p>
                            </div>

                            <p className={'input-field-helper-text'}>Enter new email</p>
                            <input value={data.new_email}
                                   autoComplete="off"
                                   name={"new_email"}
                                   type={'email'}
                                   required={true}
                                   onChange={change_text_handler}
                                   className={'form-control input-field mb-2'}/>

                            <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                                <button className={"btn reg-control-btn reg-next-btn"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const form = formRef.current;
                                            if (!form.checkValidity()) {
                                                form.reportValidity()

                                            } else {
                                                changeEmail()
                                            }
                                        }}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ConfirmChangeEmail;