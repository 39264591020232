import React, {useCallback, useEffect, useState} from 'react';
import _debounce from "lodash/debounce";
import Config from "../../Config";
import axios from "axios";

function EtfExchangeSearch({
                               tempCheckedExchange,
                               setTempCheckedExchange,
                               setCheckedExchange,
                               setCheckedSecond,
                               tempCheckedSecond,
                               setTempCheckedSecond,
    url,
                               setLoading,
                               handleCloseModal,
                               setETFData, setFilterETFData,
                               setETFLoadingStat,
                               return_instrument,
                               etf_type,
                           }) {

    const [exchanges, setExchanges] = useState([]);

    const [filterLoader, setFilterLoader] = useState(true)

    const [search, setSearch] = useState("")

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    function handleDebounceFn(search) {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/exchange-list/`,
            {search: search},
            config)
            .then(response => {
                setExchanges(response.data)
                setFilterLoader(false)
            })
    }

    useEffect(() => {
        handleDebounceFn(search)
    }, [])

    const handleExchangeClick = (exchange) => {
        setTempCheckedExchange(tempCheckedExchange.includes(exchange.code) ? tempCheckedExchange.filter(item => item !== exchange.code) : [...tempCheckedExchange, exchange.code])
    }

    const reset_filter = () => {
        setTempCheckedExchange([])
        setCheckedExchange([])
    }

    const performFilter = () => {
        const config = Config()
        let body = {
            etf_type: etf_type,
            exchanges: tempCheckedExchange,
            category2: tempCheckedSecond
        }

        axios.post(url, body, config)
            .then(response => {
                handleCloseModal()
                setETFLoadingStat((prev) => ({
                    ...prev,
                    url: response.data.next,
                    totalETF: response.data.count,
                    filter_type: "filter",
                    see_more: response.data.next !== null
                }))

                const etf = return_instrument(response.data.results)
                setETFData(etf)
                setFilterETFData(etf)

                setLoading(false)
                setCheckedSecond(tempCheckedSecond)
                setCheckedExchange(tempCheckedExchange)
            })
            .catch(response => {
                setETFLoadingStat((prev) => ({
                        ...prev,
                        see_more: false
                    })
                )
            })
    }

    return (
        <div>
            <input
                value={search}
                onChange={(e) => {
                    setLoading(true)
                    setSearch(e.target.value)
                    debounceFn(e.target.value)
                }}
                className={'form-control exchange-search-filter'}
                placeholder={'Search Exchanges i.e Xetra'}/>

            <div style={{display: 'flex', margin: '15px 0'}}>
                <p className={'search-modal-text-heading'} style={{margin: "auto 0"}}>Select to filter</p>
                <button className={'btn checked-filter-btn'}
                        onClick={reset_filter}>Reset Filter
                </button>
            </div>

            <div className={'exchange-list'}>
                {
                    filterLoader ?
                        <>
                            <div className={'navbar-modal-company-loader-container'}>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}
                                     style={{marginLeft: 'auto'}}
                                ></div>
                            </div>
                            <div className={'navbar-modal-company-loader-container'}>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}></div>
                                <div className={'loader navbar-searched-pill-loader'}
                                     style={{marginLeft: 'auto'}}
                                ></div>
                            </div>
                        </>
                        :
                        exchanges.length !== 0 ?
                            <>
                                {exchanges.map((exchange, index) => {
                                    return (
                                        <div key={index} className={'exchange-searched-container'}
                                             onClick={() => handleExchangeClick(exchange)}>
                                            <input className="form-check-input filter-exchange-checkbox"
                                                   type="checkbox" value="" id="flexCheckChecked"
                                                   checked={tempCheckedExchange.includes(exchange.code)}/>
                                            <p className={'exchange-searched-name'}>
                                                {exchange.name}
                                            </p>
                                            <p className={'exchange-searched-code'}>
                                                {exchange.code}
                                            </p>
                                            <div style={{margin: "auto 0 auto auto"}}>
                                                <p className={'exchange-searched-country'}>
                                                    {exchange.currency}
                                                </p>
                                                <p className={'exchange-searched-country'}>
                                                    {exchange.country} - {exchange.region}
                                                </p>
                                            </div>
                                        </div>)
                                })}
                            </>
                            :
                            <p className={'no-companies-found-text'}>No Exchange Found</p>
                }
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn checked-filter-btn'} onClick={() => {
                    setLoading(true)
                    performFilter()
                }}>Apply
                </button>
            </div>

        </div>
    );
}

export default EtfExchangeSearch;