import React from 'react';
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";
import {divide, multiple, return_num, return_strong_num, return_td_color} from "../../Utils";

function PriceTargetInfoOuterContainer({code, highlights, analystRating}) {
    return (
        <div className={'price-target-info-outer-container'}>
            <div className={'price-target-info-container'}>
                <div className="price-target-info-title-container">
                    <p className="price-target-info-title">
                        {code} Stock 12 Months Forecast
                    </p>
                </div>

                <div className="price-target-info-description-container">
                    <div className={'price-target-highlights-outer-container'}>
                        <div className={'price-target-highlights-container'}>
                            <p className={'price-target-highlights-price'}>
                                <span style={{fontSize: 18}}>{highlights.currency}</span>&nbsp;
                                {return_strong_num(highlights.WallStreetTargetPrice)}
                            </p>
                            <p className={'price-target-highlights-upside'}>
                                {highlights.upside > 0 ?
                                    <>
                                        <MdArrowDropUp/>
                                        ({return_strong_num(highlights.upside * 100)}% Upside)
                                    </>
                                    :
                                    <>
                                        <MdArrowDropDown/>
                                        ({return_strong_num(highlights.upside * 100)}% Downside)
                                    </>
                                }
                            </p>
                        </div>

                        <div className={'price-target-analyst-rating'}>
                            <p className={'analyst-ratings'}>
                                {analystRating.total} Analyst
                                Ratings</p>

                            <div className={'analyst-progress-container'}>
                                <div
                                    className={"progress analyst-progress-bar price-target-analyst-progress-bar"}>
                                    <div className="progress-bar" role="progressbar"
                                         style={{
                                             width: `${Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'buy'), return_num(analystRating, 'total')), 100)))}%`,
                                             backgroundColor: '#63b752'
                                         }}
                                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                                <p className={'analyst-progress-text price-target-analyst-progress-text'}>
                                    {Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'buy'), return_num(analystRating, 'total')), 100)))}%
                                    Buy</p>
                            </div>
                            <div className={'analyst-progress-container'} style={{margin: '2px 0'}}>
                                <div
                                    className={"progress analyst-progress-bar  price-target-analyst-progress-bar"}>
                                    <div className="progress-bar" role="progressbar"
                                         style={{
                                             width: `${Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'hold'), return_num(analystRating, 'total')), 100)))}%`,
                                             backgroundColor: '#fbda00'
                                         }}
                                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                                <p className={'analyst-progress-text price-target-analyst-progress-text'}>{Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'hold'), return_num(analystRating, 'total')), 100)))}%
                                    Hold</p>
                            </div>
                            <div className={'analyst-progress-container'}>
                                <div
                                    className={"progress analyst-progress-bar  price-target-analyst-progress-bar"}>
                                    <div className="progress-bar" role="progressbar"
                                         style={{
                                             width: `${Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'sell'), return_num(analystRating, 'total')), 100)))}%`,
                                             backgroundColor: '#f66f4e'
                                         }}
                                         aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                                <p className={'analyst-progress-text price-target-analyst-progress-text'}>{Math.round(return_strong_num(multiple(divide(return_num(analystRating, 'sell'), return_num(analystRating, 'total')), 100)))}%
                                    Sell</p>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <p className="price-target-info-description">
                        According to <span
                        style={{fontWeight: 600}}>{analystRating.total > 0 ? analystRating.total : ""}</span> financial
                        analysts, <span style={{fontWeight: 600}}>{highlights.name}</span> is
                        expected to reach an average
                        one-year price target of <span
                        style={{fontWeight: 600}}>{highlights.currency} {return_strong_num(highlights.WallStreetTargetPrice)}</span>,
                        indicating a potential&nbsp;
                        <span
                        >{highlights.upside > 0 ? "Upside" : "Downside"} of&nbsp;
                            <span style={{
                                color: return_td_color(return_strong_num(highlights.upside)),
                                fontWeight: 600,
                                fontSize: 12,
                                backgroundColor: return_strong_num(highlights.upside) > 0 ? '#4ebe9629' : '#d84f6829',
                                padding: "2px 4px",
                                borderRadius: 5,
                                textAlign: "center",
                                marginRight: 4
                            }}>
                                                    {return_strong_num(highlights.upside * 100)}%
                                                    </span>
                                            </span>
                        from the last closing price of <span
                        style={{fontWeight: 600}}>{highlights.currency} {highlights.price}</span>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PriceTargetInfoOuterContainer;