import React, {useContext} from 'react';
import {ThemeContext} from "../../../Context/ThemeContext";
import clsx from "clsx";

function FinancialsControls({handleActive, active, options = [], balance_sheet = false, meta, fairValue = false}) {

    return (
        <>
            <div className={'financial-control-container'}>
                <div className={'financial-control-inner-container'}>
                    {options.map((option, index) => {
                        return (
                            <>
                                <div key={index}
                                     className={clsx('financial-control-buttons', active === option.key && 'financial-control-buttons-active')}
                                     onClick={() => handleActive(option.key)}>
                                    <p>{option.label}</p>
                                </div>
                                {options.length - 1 !== index &&
                                    <div className={'financial-control-buttons-separator'}/>
                                }
                            </>
                        )
                    })}
                    {/*<div*/}
                    {/*    className={clsx('financial-control-buttons', active === 'annual' && 'financial-control-buttons-active')}*/}
                    {/*    onClick={() => handleActive('annual')}>*/}
                    {/*    <p>Annual</p>*/}
                    {/*</div>*/}

                    {/*<div className={'financial-control-buttons-separator'}/>*/}

                    {/*<div*/}
                    {/*    className={clsx('financial-control-buttons', active === 'quarter' && 'financial-control-buttons-active')}*/}
                    {/*    onClick={() => handleActive('quarter')}>*/}
                    {/*    <p>Quarterly</p>*/}
                    {/*</div>*/}

                    {/*{!balance_sheet &&*/}
                    {/*    <>*/}
                    {/*        <div className={'financial-control-buttons-separator'}/>*/}

                    {/*        <div*/}
                    {/*            className={clsx('financial-control-buttons', active === 'ttm' && 'financial-control-buttons-active')}*/}
                    {/*            onClick={() => handleActive('ttm')}>*/}
                    {/*            <p>TTM</p>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*}*/}
                </div>

                {!fairValue &&
                    <select className="form-select currency-selector-financials">
                        <option selected value="USD">{meta.currency}</option>
                    </select>
                }
            </div>
            <div style={{margin: '10px 0'}}>
                <p className={'currency-info-text'}>Currency is in {meta.currency} / values in mil.</p>
            </div>
        </>
    );
}

export default FinancialsControls;