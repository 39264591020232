import React, {useContext, useEffect, useState} from 'react';
import DashboardLayout from "../../Layout/DashboardLayout";
import {Helmet} from "react-helmet-async";
import clsx from "clsx";
import {Box, Modal, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {useSelector} from "react-redux";
import Config from "../../Config";
import axios from "axios";
import {a11yProps, TabPanel} from "../Utils";
import {toast} from "react-hot-toast";
import Screener from "../../Pages/Screener";
import AddNewScreenerModal from "./AddNewScreenerModal";
import UserScreener from "./UserScreener";

function ScreenerTab() {
    const theme = useTheme();

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const [userScreeners, setUserScreeners] = useState([])
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (userScreeners.length > 0) {
            setValue(userScreeners.length - 1)
        }
    }, [userScreeners]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [openScreenerModel, setOpenScreenerModel] = useState(false)

    const handleOpenScreenerModal = () => {
        if (user.subscription.payment_method === 'Free' && userScreeners.length >= 1) {
            toast.error(<p><b>Free Plan</b> allows only one Screener, please subscribe for our <b>Basic Plan</b> to save up to 10 Screeners</p>)
            return
        }
        if (user.subscription.payment_method !== 'Free' && userScreeners.length >= 10) {
            toast.error("You have reached the maximum number of Screeners")
            return
        }
        setOpenScreenerModel(true)
    }

    const handleCloseScreenerModal = () => {
        setOpenScreenerModel(false)
    }

    useEffect(() => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/user-screeners/`, config)
            .then(response => setUserScreeners(response.data))
            .catch(err => console.log(err.message))
    }, [])

    return (
        <>
            <Helmet>
                <title>Stock Screener: Search, filter and scan stocks</title>
                <meta name={"description"}
                      content={"Screener: Screen through different stocks and filter by criteria such as MCap, P/E ratio, dividend yield, and more."}/>
            </Helmet>
            <DashboardLayout>
                {isAuthenticated ?
                    <>
                        <div className={'entire-body-lg'}>
                            <div
                                style={{position: 'relative', width: '100%', height: '100%'}}>
                                <div style={{display: 'flex'}}>
                                    <p className={'watch-headings'}>Customize your Screeners</p>

                                    <button className={'btn add-watchlist-btn add-watchlist-btn-lg'}
                                            style={{margin: 'auto 0 auto auto'}}
                                            onClick={handleOpenScreenerModal}
                                    >
                                        Add Screener +
                                    </button>
                                </div>

                                <div className={clsx("generic-tabs-container")}>
                                    <Box display="flex" justifyContent="left" width="100%">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            indicatorColor={'primary'}
                                            scrollButtons="auto"
                                        >
                                            {userScreeners.map((item, index) => {
                                                return (
                                                    <Tab label={item.name} {...a11yProps(index)} />
                                                )
                                            })}
                                        </Tabs>
                                    </Box>

                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={value}
                                        disabled={true}
                                        onChangeIndex={handleChangeIndex}
                                    >
                                        {userScreeners.map((item, index) => {
                                            return (
                                                <TabPanel className={'generic-tab-panel'} value={value}
                                                          index={index} dir={theme.direction}>
                                                    <UserScreener screener_id={item.id}
                                                                  ScreenerName={item.name}
                                                                  setValue={setValue}
                                                                  setUserScreeners={setUserScreeners}
                                                    />
                                                </TabPanel>
                                            )
                                        })}
                                    </SwipeableViews>
                                </div>
                            </div>
                        </div>

                        <Modal
                            open={openScreenerModel}
                            onClose={handleOpenScreenerModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <AddNewScreenerModal setUserScreeners={setUserScreeners}
                                            handleCloseModal={handleCloseScreenerModal}/>
                        </Modal>

                    </>
                    :
                    <UserScreener />
                }
            </DashboardLayout>
        </>
    );
}

export default ScreenerTab;
