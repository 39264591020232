import React, {useContext, useEffect, useState} from 'react';
import LoginPaymentBlocker from "../LoginPaymentBlocker";
import compareISqoreDark from "../../assets/blockers/compare-isqore-dark.png";
import compareISqoreLight from "../../assets/blockers/compare-isqore-light.png";
import CompareStockFieldsSidebar from "./CompareStockFieldsSidebar";
import EmptyISqoreTab from "./EmptyISqoreTab";
import {useSelector} from "react-redux";
import {ThemeContext} from "../../Context/ThemeContext";
import ScreenerFilterModal from "../Screener/ScreenerFilterModal";
import {Modal} from "@material-ui/core";
import ScreenerFilterJson from "../ScreenerFilterJson";
import CompareStocksSearch from "./CompareStocksSearch";
import CompareStockProTab from "./CompareStockProTab";
import clsx from "clsx";
import ScreenerModalFilterJson from "../ScreenerModalFilterJson";

function CompareStocksPro({}) {
    const user = useSelector(state => state.actions.user);
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const {webTheme} = useContext(ThemeContext);

    const [compareData, setCompareData] = useState([])


    let compareStockModelFilterJSON = JSON.parse(JSON.stringify(ScreenerModalFilterJson));
    const compare_stocks_models_filter_new_entries = {
        name: {
            tree: false,
            key: "name",
            name: "Company",
            selected: false,
            display_name: "Company"
        },
        code: {
            tree: false,
            key: "code",
            name: "Code",
            selected: false,
            display_name: "Code"
        },
        country: {
            tree: false,
            key: "country",
            name: "Country",
            selected: false,
            display_name: "Country"
        },
        exchange: {
            tree: false,
            key: "exchange",
            name: "Exchange",
            selected: false,
            display_name: "Exchange"
        },
        sector: {
            tree: false,
            key: "sector",
            name: "Sector",
            selected: false,
            display_name: "Sector"
        },
        industry: {
            tree: false,
            key: "industry",
            name: "Industry",
            selected: false,
            display_name: "Industry"
        }
    }
    compareStockModelFilterJSON["Company Information"] = {
        ...compare_stocks_models_filter_new_entries,
        ...compareStockModelFilterJSON["Company Information"]
    }

    const new_entries = {
        name: {key: 'name', name: "Company", display: true},
        code: {key: 'code', name: "Code", display: true},
        country: {key: 'country', name: "Country", display: true},
        exchange: {key: 'exchange', name: "Exchange", display: true},
        sector: {key: 'sector', name: "Sector", display: true},
        industry: {key: 'industry', name: "Industry", display: true},
    }

    let CompareFieldsJSON = JSON.parse(JSON.stringify(ScreenerFilterJson));
    CompareFieldsJSON['Company Information']['components'] = {
        ...new_entries,
        ...CompareFieldsJSON['Company Information']['components']
    }
    delete CompareFieldsJSON['General']

    CompareFieldsJSON['Company Information']['components']['m_cap_trading'].name = "M Cap (mln)"

    const [sidebarFields, setSidebarFields] = useState(CompareFieldsJSON)

    const [selectedSidebarFields, setSelectedSidebarFields] = useState({})

    useEffect(() => {
        let selectedSidebarFieldsTemp = {...selectedSidebarFields}

        Object.entries(sidebarFields).map(([groupKey, group]) =>
            Object.entries(group.components).map(([fieldKey, field]) => {
                if (field.display && !(fieldKey in selectedSidebarFieldsTemp)) {
                    selectedSidebarFieldsTemp[fieldKey] = {
                        groupKey: groupKey,
                        fieldKey: fieldKey,
                        name: field.name,
                        percentage: field.value?.type,
                        ops: field.value?.ops,
                        currency: field.value?.currency
                    }
                } else if (!field.display && fieldKey in selectedSidebarFieldsTemp){
                    delete selectedSidebarFieldsTemp[fieldKey]
                }
            })
        )

        setSelectedSidebarFields(selectedSidebarFieldsTemp)
    }, [sidebarFields]);

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }


    const [openSearchModal, setOpenSearchModal] = useState(false)

    const handleOpenSearchModal = () => {
        setOpenSearchModal(true)
    }

    const handleCloseSearchModal = () => {
        setOpenSearchModal(false)
    }

    return (
        <div className={'entire-body-lg'}>
            {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                <LoginPaymentBlocker condition={'You must be paid subscriber to use  this feature'}
                                     lg={true} login={true} subscription={true}/>
            }

            {((!user.subscription || user.subscription.package_name === 'Free') || !isAuthenticated) &&
                <img src={webTheme === 'dark' ? compareISqoreDark : compareISqoreLight}
                     className={'blocker-img'}/>
            }

            {(isAuthenticated && user.subscription.package_name !== 'Free') &&
                <div className={clsx('compare-isqore-container')}>
                    <CompareStockFieldsSidebar
                        handleOpenModal={handleOpenModal}

                        selectedSidebarFields={selectedSidebarFields}

                        sidebarFields={sidebarFields}
                        setSidebarFields={setSidebarFields}
                    />
                    {compareData.map((item, index) => {
                        return (
                            <CompareStockProTab selectedSidebarFields={selectedSidebarFields}
                                                setCompareData={setCompareData}
                                                item={item}
                                                index={index}
                            />
                        )
                    })}
                    <EmptyISqoreTab handleOpenModal={handleOpenSearchModal}/>
                </div>
            }

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ScreenerFilterModal filterScreener={sidebarFields}
                                     setFilterScreener={setSidebarFields}

                                     handleFilterCloseModal={handleCloseModal}
                                     compareStockModelFilterJSON={compareStockModelFilterJSON}
                />
            </Modal>

            <Modal
                open={openSearchModal}
                onClose={handleCloseSearchModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompareStocksSearch
                    compareData={compareData}
                    setCompareData={setCompareData}
                    handleCloseSearchModal={handleCloseSearchModal}
                    search_type={'compare_stocks'}
                />
            </Modal>

        </div>
    );
}

export default CompareStocksPro;
