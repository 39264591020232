import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";
import {return_strong_num} from "../Utils";
import RestrictedContent from "../RestrictedContent";
import fairValueDark from "../../assets/blockers/fair-value-dark.png";
import fairValueLight from "../../assets/blockers/fair-value-light.png";
import FairValueInputTable from "./FairValueInputTable";
import FairValueInfoTable from "./FairValueInfoTable";
import FinancialOverviewAvgTabs from "../CompanyTabs/FinancialTabs/FinancialOverview/FinancialOverviewAvgTabs";
import {toast} from "react-hot-toast";
import {Modal} from "@material-ui/core";
import FairValueModal from "./FairValueModal";

function FairValueTabTemp({code, exchange}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);
    const currPrice = useSelector(state => state.actions.price)

    const [userSettings, setUserSettings] = useState(null)
    const [userSettingsLoaded, setUserSettingsLoaded] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-setting/${code}/${exchange}/`, Config())
            .then(response => {
                const settings = response.data.settings

                setUserSettings(response.data.created_at)

                setEditableFields(settings.editableFields)

                setYearsData(settings.yearsData)

                setMarginsLoaded(true)
            }).catch(err => {
            setUserSettings(null)
            setLoadedCAGR(false)
            setMarginsLoaded(false)
            setUserSettingsLoaded(false)
        })
    }, [code, exchange]);

    const initialEditableFields = {
        code: {
            code: code,
            exchange: exchange
        },
        exp_growth: {
            text: "Revenue Growth %",
            info: "Anticipated sales growth over the next 10 years, taking into account historical performance and market trends.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        growth_decline_rate: {
            text: "Growth Decline Rate %",
            info: "This variable considers the expected decrease in the growth rate over time, providing a more conservative calculation of future growth.",
            year1: null,
            year3: null,
            year5: null,
            fields: {
                low: {editable: true, value: 2},
                mid: {editable: true, value: 2},
                high: {editable: true, value: 2}
            }
        },
        profit_margin_exp: {
            text: "Profit Margin %",
            info: "Expected net profit relative to sales in the next 10 years, considering the company's historical performance and industry dynamics.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        fcf_margin_exp: {
            text: "FCF Margin %",
            info: "Expected free cash flow margin over the next 10 years, based on the company's operational efficiency and potential market changes.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: '-'},
                mid: {editable: true, value: '-'},
                high: {editable: true, value: '-'}
            }
        },
        desired_pe: {
            text: "Desired P/E",
            info: "Desired valuation of the company in 10 years is determined based on the strength of its business model. We apply a base P/E of 15. The higher the moat the higher the P/E can be.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: 15},
                mid: {editable: true, value: 18},
                high: {editable: true, value: 21}
            }
        },
        desired_pfcf: {
            text: "Desired P/FCF",
            info: "Similar to the P/E ratio, this metric determines the fair valuation of the company in 10 years, considering its competitive advantages (moat) and profitability. We apply a base P/FCF of 15. The higher the moat the higher the P/FCF can be.",
            year1: 0,
            year3: 0,
            year5: 0,
            fields: {
                low: {editable: true, value: 15},
                mid: {editable: true, value: 18},
                high: {editable: true, value: 21}
            }
        },
        discount_rate: {
            text: "Desired Return %",
            info: "This rate represents the opportunity cost of investing in a specific stock, reflecting the expected rate of return that an investor would require, and it is determined based on market conditions and the company's risk profile.",
            year1: null,
            year3: null,
            year5: null,
            fields: {
                low: {editable: true, value: 10},
                mid: {editable: true, value: 12},
                high: {editable: true, value: 14}
            }
        },
    }

    const [editableFields, setEditableFields] = useState(initialEditableFields)

    const [marginsLoaded, setMarginsLoaded] = useState(false)

    const initialFairValueCalculation = {
        earnings_valuation: {
            current_price: 0,
            intrinsic_value: 0,
            fair_value: 0,
            fair_value_evaluation_per: 0,
            fair_value_evaluation: "",
        },
        dcf_valuation: {
            current_price: 0,
            intrinsic_value: 0,
            fair_value: 0,
            fair_value_evaluation_per: 0,
            fair_value_evaluation: "",
        },
        potential_return: 0
    }

    const [lowFairValueCalculation, setLowFairValueCalculation] = useState(initialFairValueCalculation)

    const [midFairValueCalculation, setMidFairValueCalculation] = useState(initialFairValueCalculation)

    const [highFairValueCalculation, setHighFairValueCalculation] = useState(initialFairValueCalculation)

    const [loadedCAGR, setLoadedCAGR] = useState(false)

    useEffect(() => {
        async function getExpectedCAGR() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-expected-cagr/${code}/${exchange}/`, Config());
                const expectedCARG = response.data

                let high = Math.floor(parseFloat(return_strong_num(expectedCARG * 100)))

                if (high === null || isNaN(high) || high < 8) {
                    high = 8;
                } else if (high > 30) {
                    high = 30
                }

                const mid = high - 3
                const low = high - 6

                setEditableFields((prev) => {
                    return {
                        ...prev,
                        exp_growth: {
                            ...prev.exp_growth,
                            fields: {
                                low: {editable: true, value: low},
                                mid: {editable: true, value: mid},
                                high: {editable: true, value: high}
                            }
                        }
                    }
                })

                setLoadedCAGR(true)
            } catch (error) {

            }
        }


        console.log(loadedCAGR, userSettingsLoaded)
        if (!loadedCAGR && !userSettingsLoaded) {
            void getExpectedCAGR()
        }
    }, [code, exchange, loadedCAGR, userSettingsLoaded]);


    useEffect(() => {
        async function getMargins() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-margins/${code}/${exchange}/`, Config());
                const data = response.data

                let profit_margin_exp = data.profit_margin_exp
                let fcf_margin_exp = data.fcf_margin_exp

                if (profit_margin_exp === null || profit_margin_exp < 8) {
                    profit_margin_exp = 8
                } else if (profit_margin_exp > 30) {
                    profit_margin_exp = 30
                }

                if (fcf_margin_exp === null || fcf_margin_exp < 8) {
                    fcf_margin_exp = 8
                } else if (fcf_margin_exp > 30) {
                    fcf_margin_exp = 30
                }

                setEditableFields({
                    ...editableFields,
                    profit_margin_exp: {
                        ...editableFields.profit_margin_exp,
                        fields: {
                            low: {editable: true, value: profit_margin_exp - 6},
                            mid: {editable: true, value: profit_margin_exp - 3},
                            high: {editable: true, value: profit_margin_exp}
                        }
                    },
                    fcf_margin_exp: {
                        ...editableFields.fcf_margin_exp,
                        fields: {
                            low: {editable: true, value: fcf_margin_exp - 6},
                            mid: {editable: true, value: fcf_margin_exp - 3},
                            high: {editable: true, value: fcf_margin_exp}
                        }
                    },
                })

                setMarginsLoaded(true)
                setLoadedCAGR(false)
            } catch (error) {

            }
        }

        if (!marginsLoaded && loadedCAGR) {
            void getMargins()
        }
    }, [code, exchange, marginsLoaded, loadedCAGR])

    const fairValuation = async (code, exchange, exp_growth, growth_decline_rate, desired_pe, desired_pfcf, profit_margin_exp, fcf_margin_exp, discount_rate, type, setFairValueCalculation) => {

        const earning_response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/pe-valuation/`,
            {
                code,
                exchange,
                exp_growth,
                growth_decline_rate,
                desired_pe,
                desired_pfcf,
                profit_margin_exp,
                fcf_margin_exp,
                discount_rate
            }, Config());

        const earning = earning_response.data;

        const dcf_response = await axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/dcf-valuation/`,
            {
                code,
                exchange,
                exp_growth,
                growth_decline_rate,
                desired_pe,
                desired_pfcf,
                profit_margin_exp,
                fcf_margin_exp,
                discount_rate
            }, Config());

        const dcf = dcf_response.data

        setFairValueCalculation({
            earnings_valuation: {
                ...earning
            },
            dcf_valuation: {
                ...dcf
            },
            potential_return: dcf.potential_return
        })
    }

    useEffect(() => {
            if (marginsLoaded) {
                void fairValuation(code, exchange,
                    editableFields.exp_growth.fields.low.value,
                    editableFields.growth_decline_rate.fields.low.value,
                    editableFields.desired_pe.fields.low.value,
                    editableFields.desired_pfcf.fields.low.value,
                    editableFields.profit_margin_exp.fields.low.value,
                    editableFields.fcf_margin_exp.fields.low.value,
                    editableFields.discount_rate.fields.low.value,
                    'low',
                    setLowFairValueCalculation
                )
            }
        },
        [
            editableFields.code,
            editableFields.exp_growth.fields.low.value,
            editableFields.growth_decline_rate.fields.low.value,
            editableFields.desired_pe.fields.low.value,
            editableFields.desired_pfcf.fields.low.value,
            editableFields.profit_margin_exp.fields.low.value,
            editableFields.fcf_margin_exp.fields.low.value,
            editableFields.discount_rate.fields.low.value,
            marginsLoaded
        ])

    useEffect(() => {
            if (marginsLoaded) {
                void fairValuation(code, exchange,
                    editableFields.exp_growth.fields.mid.value,
                    editableFields.growth_decline_rate.fields.mid.value,
                    editableFields.desired_pe.fields.mid.value,
                    editableFields.desired_pfcf.fields.mid.value,
                    editableFields.profit_margin_exp.fields.mid.value,
                    editableFields.fcf_margin_exp.fields.mid.value,
                    editableFields.discount_rate.fields.mid.value,
                    'mid',
                    setMidFairValueCalculation
                )
            }
        },
        [
            editableFields.code,
            editableFields.exp_growth.fields.mid.value,
            editableFields.growth_decline_rate.fields.mid.value,
            editableFields.desired_pe.fields.mid.value,
            editableFields.desired_pfcf.fields.mid.value,
            editableFields.profit_margin_exp.fields.mid.value,
            editableFields.fcf_margin_exp.fields.mid.value,
            editableFields.discount_rate.fields.mid.value,
            marginsLoaded
        ])

    useEffect(() => {
            if (marginsLoaded) {
                void fairValuation(code, exchange,
                    editableFields.exp_growth.fields.high.value,
                    editableFields.growth_decline_rate.fields.high.value,
                    editableFields.desired_pe.fields.high.value,
                    editableFields.desired_pfcf.fields.high.value,
                    editableFields.profit_margin_exp.fields.high.value,
                    editableFields.fcf_margin_exp.fields.high.value,
                    editableFields.discount_rate.fields.high.value,
                    'high',
                    setHighFairValueCalculation
                )
            }
        },
        [
            editableFields.code,
            editableFields.exp_growth.fields.high.value,
            editableFields.growth_decline_rate.fields.high.value,
            editableFields.desired_pe.fields.high.value,
            editableFields.desired_pfcf.fields.high.value,
            editableFields.profit_margin_exp.fields.high.value,
            editableFields.fcf_margin_exp.fields.high.value,
            editableFields.discount_rate.fields.high.value,
            marginsLoaded
        ])

    const initialYearsData = {
        code: {
            code: code,
            exchange: exchange
        },
        exp_growth: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        growth_decline_rate: {
            year1: null,
            year3: null,
            year5: null,
        },
        profit_margin_exp: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        fcf_margin_exp: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        desired_pe: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        desired_pfcf: {
            year1: 0,
            year3: 0,
            year5: 0,
        },
        discount_rate: {
            year1: null,
            year3: null,
            year5: null,
        },
    }

    const [yearsData, setYearsData] = useState(initialYearsData)

    useEffect(() => {
        const fetch_years_data = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-years-data/${code}/${exchange}/`, Config());
                const data = response.data

                setYearsData({
                    ...initialYearsData,
                    ...data
                })
            } catch (e) {

            }
        }

        void fetch_years_data()
    }, [code, exchange])


    const reset = () => {
        setEditableFields(initialEditableFields)
        setMarginsLoaded(false)
        setLoadedCAGR(false)

        setLowFairValueCalculation(initialFairValueCalculation)
        setMidFairValueCalculation(initialFairValueCalculation)
        setHighFairValueCalculation(initialFairValueCalculation)
    }

    const manual_reset = () => {
        reset()
        setUserSettingsLoaded(false)
    }

    const clear = () => {
        setEditableFields({
            code: {
                code: code,
                exchange: exchange
            },
            exp_growth: {
                text: "Revenue Growth %",
                info: "Anticipated sales growth over the next 10 years, taking into account historical performance and market trends.",
                year1: 0,
                year3: 0,
                year5: 0,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            growth_decline_rate: {
                text: "Growth Decline Rate %",
                info: "This variable considers the expected decrease in the growth rate over time, providing a more conservative calculation of future growth.",
                year1: null,
                year3: null,
                year5: null,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            profit_margin_exp: {
                text: "Profit Margin %",
                info: "Expected net profit relative to sales in the next 10 years, considering the company's historical performance and industry dynamics.",
                year1: 0,
                year3: 0,
                year5: 0,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            fcf_margin_exp: {
                text: "FCF Margin %",
                info: "Expected free cash flow margin over the next 10 years, based on the company's operational efficiency and potential market changes.",
                year1: 0,
                year3: 0,
                year5: 0,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            desired_pe: {
                text: "Desired P/E",
                info: "Desired valuation of the company in 10 years is determined based on the strength of its business model. We apply a base P/E of 15. The higher the moat the higher the P/E can be.",
                year1: 0,
                year3: 0,
                year5: 0,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            desired_pfcf: {
                text: "Desired P/FCF",
                info: "Similar to the P/E ratio, this metric determines the fair valuation of the company in 10 years, considering its competitive advantages (moat) and profitability. We apply a base P/FCF of 15. The higher the moat the higher the P/FCF can be.",
                year1: 0,
                year3: 0,
                year5: 0,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
            discount_rate: {
                text: "Desired Return %",
                info: "This rate represents the opportunity cost of investing in a specific stock, reflecting the expected rate of return that an investor would require, and it is determined based on market conditions and the company's risk profile.",
                year1: null,
                year3: null,
                year5: null,
                fields: {
                    low: {editable: true, value: 0},
                    mid: {editable: true, value: 0},
                    high: {editable: true, value: 0}
                }
            },
        })
    }

    const [fairValueModal, setFairValueModal] = useState(false)
    const handleFairValueModal = () => {
        if (!isAuthenticated || (!user.subscription || user.subscription.package_name === 'Free')) {
            toast.error('You need to be a subscriber to load settings')
            return
        }
        setFairValueModal(!fairValueModal)
    }

    useEffect(() => {
        reset()
        setUserSettingsLoaded(true)
    }, [code, exchange])

    const save_settings = () => {
        if (!isAuthenticated || (!user.subscription || user.subscription.package_name === 'Free')) {
            toast.error('You need to be a subscriber to Save Settings')
            return
        }
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-setting/${code}/${exchange}/`, {
            price: currPrice,
            settings: {
                editableFields,
                yearsData
            }
        }, Config())
            .then(response => {
                setUserSettings(response.data.created_at)

                toast.success("Saved Assumptions")
            }).catch(err => {
            console.log(err.response.data)
            toast.error("Failed to save. Please try again")
        })
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={fairValueDark}
            lightBlockerImg={fairValueLight}
            code={code}
            exchange={exchange}

            content={
                <>
                    <div style={{position: 'relative', width: '100%', height: '100%'}}>
                        <div className={'row g-0'}>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <FairValueInputTable
                                    editableFields={editableFields}
                                    lowFairValueCalculation={lowFairValueCalculation}
                                    midFairValueCalculation={midFairValueCalculation}
                                    highFairValueCalculation={highFairValueCalculation}
                                    setEditableFields={setEditableFields}
                                    yearsData={yearsData}
                                />
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style={{padding: "0 5px"}}>
                                <div style={{margin: "5px 0"}} className={'d-flex'}>
                                    {userSettings &&
                                        <div style={{fontSize: 11, fontWeight: 600}}>
                                            <p>Current Assumptions from:</p>
                                            <p>{userSettings}</p>
                                        </div>
                                    }

                                    <button
                                        onClick={save_settings}
                                        className={'btn btn-outline-success'}
                                        style={{
                                            margin: "auto 0 auto auto",
                                            width: 100,
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>
                                        Save
                                    </button>

                                    <button
                                        onClick={handleFairValueModal}
                                        className={'btn btn-outline-primary'}
                                        style={{
                                            margin: "auto 0 auto 10px",
                                            width: 100,
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>
                                        Load
                                    </button>

                                    <button
                                        onClick={manual_reset}
                                        className={'btn btn-warning'}
                                        style={{
                                            margin: "auto 0 auto 10px",
                                            width: 100,
                                            fontSize: 13,
                                            fontWeight: 600
                                        }}>
                                        Reset
                                    </button>

                                    <button
                                        onClick={clear}
                                        className={'btn btn-danger'}
                                        style={{
                                            margin: "auto 0 auto 10px",
                                            width: 100,
                                            fontSize: 13,
                                            fontWeight: 600
                                        }}>
                                        Clear
                                    </button>
                                </div>

                                <FairValueInfoTable
                                    code={code}
                                    exchange={exchange}
                                />

                                <FinancialOverviewAvgTabs code={code} exchange={exchange} fairValueTab={true}/>
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={fairValueModal}
                        onClose={handleFairValueModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <FairValueModal
                            code={code} exchange={exchange}
                            handleFairValueModal={handleFairValueModal}
                            setEditableFields={setEditableFields}
                            setYearsData={setYearsData}
                            setUserSettings={setUserSettings}
                        />
                    </Modal>
                </>
            }
        />
    );
}

export default FairValueTabTemp;
