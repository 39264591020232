import React, {useState} from 'react';
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import {a11yProps, TabPanel} from "../Utils";
import SwipeableViews from "react-swipeable-views";
import CalenderSubTabPeriod from "./CalenderSubTabPeriod";

function CalenderSubTabs({type}) {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const get_date = (period) => {
        let today = new Date();
        today.setDate(today.getDate());

        let tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        let dayAfterTomorrow = new Date(today);
        dayAfterTomorrow.setDate(today.getDate() + 2);

        if (today.getDay() === 4) {
            dayAfterTomorrow.setDate(today.getDate() + 2)
        } else if (today.getDay() === 5) {
            tomorrow.setDate(today.getDate() + 3)
            dayAfterTomorrow.setDate(today.getDate() + 4)
        } else if (today.getDay() === 6) {
            const _today = today.getDate()
            today.setDate(_today + 2)
            tomorrow.setDate(_today + 3)
            dayAfterTomorrow.setDate(_today + 4)
        } else if (today.getDay() === 0) {
            const _today = today.getDate()
            today.setDate(_today + 1)
            tomorrow.setDate(_today + 2)
            dayAfterTomorrow.setDate(_today + 3)
        }

        function getDaySuffix(day) {
            if (day >= 11 && day <= 13) {
                return "th";
            }
            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        }

        let options = {day: "numeric", month: "short"};

        if (period === 'today') {
            return today.toLocaleDateString("en-US", options) + getDaySuffix(today.getDate());
        } else if (period === 'tomorrow') {
            return tomorrow.toLocaleDateString("en-US", options) + getDaySuffix(tomorrow.getDate());
        } else if (period === 'day-after') {
            return dayAfterTomorrow.toLocaleDateString("en-US", options) + getDaySuffix(dayAfterTomorrow.getDate());
        }
    }

    return (
        <div className={"generic-tabs-container"}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'secondary'}
                    scrollButtons="auto"
                >
                    <Tab label={get_date('today')} {...a11yProps(0)} />
                    <Tab label={get_date('tomorrow')} {...a11yProps(1)} />
                    <Tab label={get_date('day-after')} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <div className="tabsPanelContainer company-overview-panel">
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    disabled={true}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel className={'generic-tab-panel'} value={value} index={0}
                              dir={theme.direction}>
                        <CalenderSubTabPeriod type={type} period={'today'}/>
                    </TabPanel>

                    <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                        <CalenderSubTabPeriod type={type} period={'tomorrow'}/>
                    </TabPanel>

                    <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                        <CalenderSubTabPeriod type={type} period={'day-after'}/>
                    </TabPanel>

                </SwipeableViews>
            </div>
        </div>
    );
}

export default CalenderSubTabs;