import {BsArrowDown, BsArrowUp, BsPencilSquare} from "react-icons/bs";
import {
    is_number,
    numberWithCommas,
    return_bg_grade_color,
    return_grade_color,
    return_per_div,
    return_strong_num,
    return_td_color,
    return_value_in_million
} from "../Utils";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {Modal} from "@material-ui/core";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import building from "../../assets/default-building.png";
import clsx from "clsx";
import CompanyNotesModal from "../CompanyDetailTab/CompanyNotesModal";

function ScreenerSelectableTable({
                                     columns,
                                     setColumns,
                                     options,
                                     data,
                                     setData,
                                     loadingCompanies,
                                     setLoadingCompanies,
                                     seeMore,
                                     componentLoader,
                                     setComponentLoader,
                                     dataStats, currency
                                 }) {
    const columns_list = ['Watchlist', ...options.map(option => option.label)]

    const navigate = useNavigate();
    const sort = (key, order) => {
        setColumns({
            ...columns,
            [key]: {...columns[key], state: !columns[key].state}
        })
        if (order) {
            setData(data.sort((a, b) => {
                    let _a = a[columns[key]['value']]
                    let _b = b[columns[key]['value']]
                    if (is_number(_a)) {
                        _a = parseFloat(_a)
                    }
                    if (is_number(_b)) {
                        _b = parseFloat(_b)
                    }

                    if (_a < _b) {
                        return -1;
                    }
                    if (_a > _b) {
                        return 1;
                    }
                    return 0;
                })
            );
        } else {
            setData(data.sort((a, b) => {
                    let _a = a[columns[key]['value']]
                    let _b = b[columns[key]['value']]
                    if (is_number(_a)) {
                        _a = parseFloat(_a)
                    }
                    if (is_number(_b)) {
                        _b = parseFloat(_b)
                    }

                    if (_a > _b) {
                        return -1;
                    }
                    if (_a < _b) {
                        return 1;
                    }
                    return 0;
                })
            );
        }
    }
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const [selectedTicker, setSelectedTicker] = useState({})

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                const updateData = data.map(item => {
                    if (item.company_id === selectedTicker.company_id) {
                        return {
                            ...item,
                            watchlist: response.data['ticker_exists']
                        }
                    }
                    return item
                })
                setData(updateData)

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseWatchlistModal()
            })
            .catch(err => console.log(err.message))
    }

    const [openWatchlistModal, setOpenWatchlistModal] = useState(false)
    const handleOpenWatchlistModal = (item) => {
        setSelectedTicker(item)
        setOpenWatchlistModal(true)
    }
    const handleCloseWatchlistModal = () => {
        setOpenWatchlistModal(false)
    }

    const [openNotesModal, setOpenNotesModal] = useState(false);

    const [notesCompany, setNotesCompany] = useState({
        code: "",
        exchange: "",
        name: "",
        currency: "",
        priceData: {
            price: 0,
            change: 0,
            changesPercentage: 0,
        }
    })

    const handleOpenNotesModal = (code, exchange, name, currency, price, perChange) => {

        setNotesCompany({
            code: code,
            exchange: exchange,
            name: name,
            currency: currency,
            priceData: {
                price: price,
                change: return_strong_num((return_strong_num(price * perChange) / 100) * 100),
                changesPercentage: return_strong_num(perChange * 100)
            }
        })

        setOpenNotesModal(true)
    }

    const handleCloseNotesModal = () => {
        setOpenNotesModal(false)
    }

    return (
        <div>
            <div className="table-responsive generic-lg-table-container">
                <table className="table generic-lg-table watchlist-lg-table screener-lg-table">
                    <thead>
                    <tr>
                        <th className={"screener-watchlist"}></th>
                        <th className={"screener-watchlist"}></th>
                        {columns_list.map((column, index) => {
                            if (column in columns) {
                                return (
                                    <th key={index}
                                        className={columns[column]['class_name']}
                                        onClick={() => {
                                            sort(column, columns[column]['state'])
                                        }}>
                                        {column}
                                        {
                                            !columns[column]['state'] ?
                                                <BsArrowUp/> :
                                                <BsArrowDown/>
                                        }
                                    </th>
                                )
                            }
                        })}
                    </tr>

                    </thead>

                    <tbody>
                    {data.map((item, index) => {
                        return (
                            <>
                                <tr key={index}>
                                    <td key={index}
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                            if (isAuthenticated) {
                                                handleOpenWatchlistModal(item)
                                            } else {
                                                navigate('/login/')
                                            }
                                        }}>
                                        {item.watchlist ? <AiFillStar
                                            style={{
                                                color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                            }}/> : <AiOutlineStar
                                            style={{
                                                color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                            }}/>}
                                    </td>
                                    <td key={index}
                                        className={'watchlist'}>
                                        <BsPencilSquare className={'delete-watchlist-ticker'}
                                                        onClick={() => {
                                                            if (isAuthenticated) {
                                                                handleOpenNotesModal(
                                                                    item.code,
                                                                    item.exchange_code,
                                                                    item.name,
                                                                    item.currency,
                                                                    item.adjusted_close,
                                                                    item.yesterday_change
                                                                )
                                                            } else {
                                                                navigate('/login/')
                                                            }
                                                        }}/>
                                    </td>
                                    {columns_list.map((column, index) => {
                                        if (column in columns) {
                                            const key = columns[column].value
                                            const per = columns[column].per
                                            const ops = columns[column].ops

                                            if (column === 'Ticker') {
                                                return (
                                                    <td className={""}>
                                                        <img className={'price-target-company-logo'}
                                                             src={item.logoURL && item.logoURL !== '' ? item.logoURL : building}
                                                             onError={({currentTarget}) => {
                                                                 currentTarget.onerror = null;
                                                                 currentTarget.src = building;
                                                             }}/>
                                                        {item.code}
                                                    </td>
                                                )
                                            } else if (column === 'Company Name') {
                                                return (
                                                    <td className={clsx((item[key] && item[key].length > 26) && "hovertext", 'screener-company-name')}
                                                        data-hover={item[key]}>
                                                        <a href={`/company-overview/${item.code}/${item.exchange_code}/`}
                                                           target={'_blank'}>
                                                            {(item[key] && item[key].length > 26) ? item[key].substring(0, 26) + "..." : item[key]}
                                                        </a>
                                                    </td>
                                                )
                                            } else if (column === 'Sector' || column === 'Industry') {
                                                return (
                                                    <td className={(item[key] && item[key].length > 26) && "hovertext"}
                                                        data-hover={item[key]}>
                                                        {(item[key] && item[key].length > 26) ? item[key].substring(0, 26) + "..." : item[key]}
                                                    </td>
                                                )
                                            } else if (column === "MCap") {
                                                return (
                                                    <td className={"item-right-align-td"}>
                                                        {currency === 'usd' &&
                                                            <>
                                                                {numberWithCommas(return_value_in_million(item.m_cap_usd))} $
                                                            </>
                                                        }
                                                        {currency === 'euro' &&
                                                            <>
                                                                {numberWithCommas(return_value_in_million(item.m_cap_euro))} €
                                                            </>
                                                        }
                                                        {currency === 'local' &&
                                                            <>
                                                                {numberWithCommas(return_value_in_million(item.m_cap_trading))} {item.currencySymbol}
                                                            </>
                                                        }
                                                    </td>
                                                )
                                            } else if (column === 'Analyst Potential') {
                                                return (
                                                    <td className={'item-right-align-td'} style={{
                                                        display: 'flex',
                                                        padding: 3
                                                    }}>
                                                        <div style={{
                                                            color: return_td_color(item.upside_potential),
                                                            fontWeight: 600,
                                                            margin: "auto 0 auto auto",
                                                            fontSize: 11,
                                                            backgroundColor: return_strong_num(item.upside_potential) > 0 ? '#4ebe9629' : '#d84f6829',
                                                            padding: "3px 5px",
                                                            borderRadius: 5,
                                                            textAlign: "center",
                                                        }}>
                                                            {return_strong_num(item.upside_potential)}% {item.upside_potential > 0 ? "Upside" : "Downside"}
                                                        </div>
                                                    </td>
                                                )
                                            } else if (column === "Yesterday Close Price" || column === "Close Price" || column === "Analyst Price Target") {
                                                return (
                                                    <td className={"item-right-align-td"}>
                                                        {item[key]} {item.currency}
                                                    </td>
                                                )
                                            } else if (column === "iSqore" || column === 'Value' || column === 'Profitability' || column === 'Growth' || column === 'Health' || column === 'Quality') {
                                                return (
                                                    <td className={'item-right-align-td'}>
                                                        <div className={'isqore-lg-table-td'}>
                                                            <div className={'isqore-lg-table-td-div'}>
                                                                <p>{Math.min(return_strong_num(item[key] * 100), 100)}</p>
                                                                <p className={'isqore-td-separator'}>|</p>
                                                                <p className={'grade_p'}
                                                                   style={{
                                                                       border: `1px solid ${return_grade_color(item[key + 'Grade'])}`,
                                                                       color: return_grade_color(item[key + 'Grade']),
                                                                       backgroundColor: return_bg_grade_color(item[key + 'Grade'])
                                                                   }}>
                                                                    {item[key + 'Grade']}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                )
                                            } else if (column === 'Exchange' || column === 'Exchange Code' || column === 'ISIN') {
                                                return (
                                                    <td>
                                                        {item[key]}
                                                    </td>
                                                )
                                            } else if (column === "IPO Date" || column === 'TSI Rank') {
                                                return (
                                                    <td className={'item-right-align-td'}>
                                                        {item[key]}
                                                    </td>
                                                )
                                            } else if (column === "Insiders %" || column === 'Institutions %') {
                                                return (
                                                    <td className={'item-right-align-td'}>
                                                        {return_strong_num(item[key])}%
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td className={'item-right-align-td'}>
                                                        {ops === "multiply" ?
                                                            per ?
                                                                return_per_div(return_strong_num(item[key] * 100))
                                                                :
                                                                <>
                                                                    {return_strong_num(item[key] * 100)}
                                                                </>
                                                            : ops === "million-comma" ?
                                                                numberWithCommas(return_value_in_million(item[key]))
                                                                : ops === 'comma' ?
                                                                    numberWithCommas(item[key])
                                                                    : return_strong_num(item[key])
                                                        }
                                                    </td>
                                                )
                                            }
                                        }
                                    })}
                                </tr>
                                <div style={{marginTop: 3, width: "100%"}}/>
                            </>
                        )
                    })}
                    </tbody>

                </table>
            </div>

            <div style={{display: 'flex', width: "100%"}}>
                <p className={'show-results-number'}>Showing results {data.length} of {dataStats.count}</p>
                {!componentLoader && !loadingCompanies && dataStats.see_more &&
                    <p className={'see-more-option'}
                       onClick={() => {
                           setLoadingCompanies(true)
                           seeMore()
                       }}
                    >See More
                    </p>
                }
            </div>
            {loadingCompanies &&
                <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <div className="spinner-border text-warning" role="status" style={{margin: "auto"}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }

            <Modal
                open={openWatchlistModal}
                onClose={handleCloseWatchlistModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseWatchlistModal}
                />
            </Modal>

            <Modal
                open={openNotesModal}
                onClose={handleCloseNotesModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompanyNotesModal
                    name={notesCompany.name}
                    currency={notesCompany.currency}
                    priceData={notesCompany.priceData}
                    code={notesCompany.code}
                    exchange={notesCompany.exchange}
                    handleCloseModal={handleCloseNotesModal}
                />
            </Modal>
        </div>
    );
}

export default ScreenerSelectableTable;