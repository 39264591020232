import React, {useState} from 'react';
import {getModalStyle} from "../Utils";
import clsx from "clsx";
import {CgClose} from "react-icons/cg";
import Config from "../../Config";
import axios from "axios";

function ScreenerSettingModal({
                                  screener_id,
                                  ScreenerName,
                                  setValue,
                                  handleCloseModal,
                                  setUserScreeners
                              }) {

    const [modalStyle] = useState(getModalStyle);

    const [name, setName] = useState(ScreenerName)

    const handleChange = (e) => {
        setName(e.target.value)
    }

    const updateScreenerName = () => {

        const config = Config()
        const body = {name}
        axios.put(`${process.env.REACT_APP_API_URL}/finqube-api/screener-settings/${screener_id}/`, body, config)
            .then(response => {
                setUserScreeners((prev) => {
                    return prev.map((item => {
                        if (item.id === screener_id) {
                            item.name = name
                        }
                        return item
                    }))
                })
                setValue(0)
            })
            .catch(err => console.log(err.message))

        handleCloseModal()
    }

    return (
        <div style={modalStyle}
             className={clsx('watchlist-settings-modal-body')}>

            <div style={{display: 'flex'}}>
                <p className={'watchlist-settings-modal-heading'}>Update Screener Name</p>
                <CgClose className={'close-modal'} onClick={handleCloseModal}/>
            </div>

            <input value={name} onChange={handleChange} placeholder={'Update Name'}
                   className={'form-control watchlist-input-field my-3'}/>
            <div style={{display: 'flex'}}>
                <button
                    onClick={handleCloseModal}
                    className={'btn btn-warning ticker-modal-back-btn'}>Cancel
                </button>
                <button className={'btn ticker-modal-save-btn'}
                        onClick={updateScreenerName}
                >Save
                </button>
            </div>
        </div>
    );
}

export default ScreenerSettingModal;
