import React, {useCallback, useEffect, useState} from 'react';
import * as Utils from "../Utils";
import Config from "../../Config";
import axios from "axios";
import _debounce from "lodash/debounce";
import {BsArrowDown, BsArrowUp, BsFilterLeft} from "react-icons/bs";
import {return_strong_num, return_td_color, sort} from "../Utils";
import {Modal} from "@material-ui/core";
import EtfFilter from "./ETFFilter";
import {load_common_etf} from "../../actions/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

function EtfTypeTab({etf_type, setValue}) {
    const dispatch = useDispatch()

    const [checkedExchange, setCheckedExchange] = useState([])

    const [checkedSecond, setCheckedSecond] = useState([])

    const navigate = useNavigate()

    const [columns, setColumns] = useState([
        {name: "Name", state: 0, class_name: "etf-name"},
        {name: "Code", state: 0, class_name: "etf-code"},
        {name: "ISIN", state: 0, class_name: ""},
        {name: "Exchange", state: 0, class_name: "etf-exchange"},
        {name: "TER%", state: 0, class_name: "item-right-align-th"},
        {name: "AUM (mln)", state: 1, class_name: "item-right-align-th"},
        // {name: "Currency", state: 0, class_name: ""},
        {name: "Last Price", state: 0, class_name: "etf-price item-right-align-th"},
        {name: "% Change", state: 0, class_name: "etf-change item-right-align-th"},
        {name: "YTD", state: 0, class_name: "etf-ytd item-right-align-th"},
        {name: "Week", state: 0, class_name: "etf-week item-right-align-th"},
        {name: "1 Month", state: 0, class_name: "etf-week item-right-align-th"},
        {name: "1 Year", state: 0, class_name: "etf-week item-right-align-th"},
        {name: "5 Year", state: 0, class_name: "etf-week item-right-align-th"},
        // {name: "Ø P.A.(5years)", state: 0, class_name: "item-right-align-th"}
    ])

    const [ETFData, setETFData] = useState([])
    const [filteredETFData, setFilterETFData] = useState([])

    const [ETFLoadingStat, setETFLoadingStat] = useState({
        url: `${process.env.REACT_APP_API_URL}/finqube-api/load-all-etf/?page=1`,
        totalETF: 0, see_more: false
    })

    const [loading, setLoading] = useState(true)

    const return_instrument = (data) => {
        let instruments = []
        data.map((item) => {
            instruments.push(
                {
                    "company_id": item.id,
                    'Code': item.code,
                    'Name': item.name,
                    "exchange_code": item.stock_exchange_code,
                    'Exchange': item.exchange,
                    'ISIN': item.ISIN,
                    'TER%': Utils.return_strong_num(item['NetExpenseRatio'] * 100),
                    'currency': item.currency,
                    'AUM (mln)': Utils.return_value_in_million(item['TotalAssets']),
                    '% Change': Utils.return_strong_num(item['% Change']),
                    'Last Price': Utils.return_strong_num(item.last_price),
                    "YTD": Utils.return_strong_num(item.ytd * 100),
                    "Week": Utils.return_strong_num(item.week * 100),
                    "1 Month": Utils.return_strong_num(item.one_month * 100),
                    "1 Year": Utils.return_strong_num(item.one_year * 100),
                    "5 Year": Utils.return_strong_num(item.five_year * 100)
                    // "Ø p.a.(5y)": Utils.return_strong_num(item.performance.five_average * 100)
                }
            )
        })
        instruments.sort((a, b) => {
            if (parseFloat(a['AUM (mln)']) > parseFloat(b['AUM (mln)'])) {
                return -1;
            }
            if (parseFloat(a['AUM (mln)']) < parseFloat(b['AUM (mln)'])) {
                return 1;
            }
            return 0;
        });

        return instruments
    }

    const load_etf = () => {
        const config = Config()
        axios.post(ETFLoadingStat.url,
            {
                "etf_type": etf_type,
                "exchanges": checkedExchange,
                "category2": checkedSecond
            }, config)
            .then(response => {
                const etf = return_instrument(response.data.results)
                setETFData([...ETFData, ...etf])
                setFilterETFData([...ETFData, ...etf])

                setETFLoadingStat({
                    ...ETFLoadingStat,
                    url: response.data.next,
                    totalETF: response.data.count,
                    see_more: response.data.next !== null
                })

                setLoading(false)
            })
            .catch(e => console.log(e.message))
    }

    const seeMore = () => {
        load_etf()
    }

    useEffect(() => {
        load_etf()
    }, [])


    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [search, setSearch] = useState('')

    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

    const onChange = (e) => {
        if (e.target.value === '') {
            setTimeout(() => {
                setSearch('')
                setFilterETFData(ETFData)
            }, 500);
        } else {
            setSearch(e.target.value)
            debounceFn(e.target.value)
            setLoading(true)
        }
    }

    function handleDebounceFn(_search) {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/search-etf-companies/`, {
            search: _search,
            etf_type: etf_type
        }, config)
            .then(response => {
                setLoading(false)
                const etf = return_instrument(response.data)

                setFilterETFData(etf)
            })
    }

    return (
        <>
            <div className={'isqore-filter-btn'} onClick={handleOpenModal}>
                <p>
                    Filter
                    <span>
                            <BsFilterLeft style={{fontSize: 20}}/>
                        </span>
                </p>
            </div>
            <input
                placeholder={'Search for Components'}
                className={'form-control indices-search'}
                onChange={onChange}
            />
            <div className={"table-responsive generic-lg-table-container"}>
                <table className="table generic-lg-table etf-lg-table">
                    <thead>
                    <tr>
                        {columns.map((item, index) => {
                            return (
                                <th key={index} className={item.class_name} onClick={() => {
                                    sort(item.name, item.state, setColumns, columns, filteredETFData, setFilterETFData)
                                }}>
                                    {item.name} {item.state ? <BsArrowUp/> : <BsArrowDown/>}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {filteredETFData
                        .map((item, index) => {
                                return (
                                    <>
                                        <tr key={index} onClick={() => {
                                            dispatch(load_common_etf(item['Code'], item['exchange_code']))
                                            navigate('/etfs/' + item['Code'] + '/' + item['exchange_code'])
                                        }}>
                                            <td className={item['Name'].length > 35 && "hovertext"}
                                                data-hover={item['Name']}
                                            >{item['Name'].length > 35 ? item['Name'].substring(0, 35) + "..." : item['Name']}</td>
                                            <td>{item['Code']}</td>
                                            <td>{item['ISIN']}</td>
                                            <td>{item['Exchange']}</td>
                                            <td className={'item-right-align-td'}>{item['TER%']} %</td>
                                            <td className={'item-right-align-td'}>{Utils.numberWithCommas(item['AUM (mln)'])} {item.currency}</td>
                                            <td className={'item-right-align-td'}>{item['Last Price']} {item.currency}</td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['% Change'])
                                                }}>{return_strong_num(item['% Change'])} %</p>
                                            </td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['YTD'])
                                                }}>{return_strong_num(item['YTD'])} %</p>
                                            </td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['Week'])
                                                }}>{return_strong_num(item['Week'])} %</p>
                                            </td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['1 Month'])
                                                }}>{return_strong_num(item['1 Month'])} %</p>
                                            </td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['1 Year'])
                                                }}>{return_strong_num(item['1 Year'])} %</p>
                                            </td>
                                            <td className={'item-right-align-td'}>
                                                <p style={{
                                                    fontWeight: 600,
                                                    color: return_td_color(item['5 Year'])
                                                }}>{return_strong_num(item['5 Year'])} %</p>
                                            </td>
                                            {/*<td className={'item-right-align-td'}*/}
                                            {/*    style={{color: return_td_color(item['Ø p.a.(5y)'])}}>{item['Ø p.a.(5y)']} %*/}
                                            {/*</td>*/}
                                        </tr>
                                        <div key={index} style={{marginTop: 3, width: "100%"}}/>
                                    </>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>
            <div style={{display: 'flex', width: "100%"}}>
                {search === '' && !loading && (
                    <p className={'show-results-number'}>Showing
                        results {filteredETFData.length} of {ETFLoadingStat.totalETF}</p>
                )}

                {search === '' && !loading && ETFLoadingStat.see_more
                    &&
                    <p className={'see-more-option'}
                       onClick={() => {
                           setLoading(true)
                           seeMore()
                       }}>
                        See More
                    </p>
                }
            </div>

            {search !== '' && !loading && filteredETFData.length === 0 &&
                <p className={'index-no-component-found'}>No Components Found</p>}

            {loading && <div style={{display: 'flex', width: '100%', height: '100%'}}>
                <div className="spinner-border text-warning" role="status" style={{margin: "auto"}}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <EtfFilter handleCloseModal={handleCloseModal}
                           etf_type={etf_type}

                           setETFData={setETFData}
                           setFilterETFData={setFilterETFData}
                           setETFLoadingStat={setETFLoadingStat}

                           return_instrument={return_instrument}
                           url={`${process.env.REACT_APP_API_URL}/finqube-api/load-all-etf/?page=1`}

                           checkedExchange={checkedExchange}
                           setCheckedExchange={setCheckedExchange}

                           checkedSecond={checkedSecond}
                           setCheckedSecond={setCheckedSecond}

                           setLoading={setLoading}
                />
            </Modal>
        </>
    )
}

export default EtfTypeTab;