import axios from "axios";
import Config from "../Config";
import {Modal} from "@material-ui/core";
import {AiOutlineSearch} from "react-icons/ai";
import React, {useEffect, useRef, useState} from "react";
import ScreenerTable from "../Container/Screener/ScreenerTable";
import ScreenerFilterModal from "../Container/Screener/ScreenerFilterModal";
import FilterComponentGeneralGroup from "../Container/Screener/FilterComponentGeneralGroup";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import DeleteScreenerModal from "../Container/Screener/DeleteScreenerModal";
import ScreenerSettingModal from "../Container/Screener/ScreenerSettingModal";

function Screener({
                      screener_id,
                      ScreenerName,
                      setValue,
                      filterScreener,
                      setFilterScreener,
                      setUserScreeners
                  }) {
    const formRef = useRef()

    const url = `${process.env.REACT_APP_API_URL}/finqube-api/filter-screener/?page=1`

    const [currency, setCurrency] = useState('usd')

    const [dataStats, setDataStats] = useState({
        url: url,
        see_more: false,
        count: 0
    })

    const [estimateCount, setEstimateCount] = useState(0)

    const [data, setData] = useState([])

    useEffect(() => {
        if (screener_id) {
            axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/screener-filter-settings/${screener_id}/`,
                filterScreener, Config())
                .then(response => {
                    // if(response.data){
                    //     setFilterScreener(response.data)
                    // }
                })
        }
    }, [filterScreener, screener_id]);

    const get_formatted_filter = () => {
        let _filterScreener = JSON.parse(JSON.stringify(filterScreener));
        Object.entries(_filterScreener).map(([key, value]) => {
                if (key === 'General') {
                    let industry_value = []
                    const industries = _filterScreener['General']['components']['industry']

                    industries.value.map((industry) => {
                        if (industry.includes('-')) {
                            industry_value.push(industry.replace('-', ' - '))
                        }
                        industry_value.push(industry)
                    })

                    _filterScreener['General']['components']['industry']['value'] = industry_value
                } else {
                    if (_filterScreener[key].display) {
                        Object.entries(_filterScreener[key].components).map(([ComponentKey, value]) => {
                            if (_filterScreener[key].components[ComponentKey].display) {
                                if (_filterScreener[key].components[ComponentKey].value.type === 'percentage' ||
                                    _filterScreener[key].components[ComponentKey].key === 'iSqore' ||
                                    _filterScreener[key].components[ComponentKey].key === 'value' ||
                                    _filterScreener[key].components[ComponentKey].key === 'profitability' ||
                                    _filterScreener[key].components[ComponentKey].key === 'growth' ||
                                    _filterScreener[key].components[ComponentKey].key === 'health' ||
                                    _filterScreener[key].components[ComponentKey].key === 'quality'
                                ) {
                                    if (_filterScreener[key].components[ComponentKey].value['value1'] !== null) {
                                        _filterScreener[key].components[ComponentKey].value['value1'] = parseFloat(_filterScreener[key].components[ComponentKey].value['value1']) / 100
                                    }
                                    if (_filterScreener[key].components[ComponentKey].value['value2'] !== null) {
                                        _filterScreener[key].components[ComponentKey].value['value2'] = parseFloat(_filterScreener[key].components[ComponentKey].value['value2']) / 100
                                    }
                                }
                            }
                        })
                    }
                }
            }
        )
        let newfilterScreener = {"General": _filterScreener["General"]}
        Object.entries(_filterScreener).map(([key, value]) => {
            if (_filterScreener[key].display) {
                newfilterScreener[key] = {title: key, display: true, components: {}}
                Object.entries(_filterScreener[key].components).map(([ComponentKey, value]) => {
                    if (_filterScreener[key].components[ComponentKey].display) {
                        newfilterScreener[key]['components'][ComponentKey] = _filterScreener[key].components[ComponentKey]
                    }
                })
            }
        })
        return newfilterScreener
    }

    useEffect(() => {
        const exchange_codes = filterScreener['General']["components"]['exchanges']["value"]
        const index_codes = filterScreener['General']["components"]['index']["value"]

        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/filter-length-screener/`, {
            exchange_codes,
            index_codes
        }, Config())
            .then(response => {
                setEstimateCount(response.data)
            })
        setComponentLoader(true)
    }, [filterScreener['General']['components']['exchanges'], filterScreener['General']['components']['index']])

    const [filterModal, setFilterModal] = useState(false)

    const [componentLoader, setComponentLoader] = useState(true)
    const [loadingCompanies, setLoadingCompanies] = useState(false)


    const handleFilterCloseModal = () => {
        setFilterModal(false)
    }

    const handleFilterOpenModal = (e) => {
        e.preventDefault()
        setFilterModal(true)
    }

    const handleSearch = (_dataStats) => {
        load_companies(false, _dataStats)
    }

    const seeMore = () => {
        load_companies(true, dataStats)
    }

    const load_companies = (see_more, _dataStats) => {
        const _filterScreener = get_formatted_filter()

        axios.post(_dataStats.url, {
            see_more: see_more,
            filterScreener: _filterScreener,
            stats: _dataStats
        }, Config())
            .then((response) => {
                if (see_more) {
                    setData([...data, ...response.data.results])
                } else {
                    setData(response.data.results)
                }
                // setData([...data, ...response.data.results])

                setDataStats({
                    ..._dataStats,
                    url: response.data.next !== null ? response.data.next : _dataStats.url,
                    see_more: response.data.next !== null,
                    count: response.data.count
                })
                setLoadingCompanies(false)
                setComponentLoader(false)
            })
            .catch(err => {
                console.log(err.message)
                setDataStats({
                    ..._dataStats,
                    see_more: false,
                    count: 0
                })
            })
    }

    useEffect(() => {
        setLoadingCompanies(true)
        setComponentLoader(true)
        load_companies(false, {
            ...dataStats,
            url: url
        })
    }, [])

    const [filtersClose, setFilterClose] = useState(false)

    const handleCloseFilter = (e) => {
        e.preventDefault()
        setFilterClose(!filtersClose)
    }

    const [openDeleteScreenerModal, setOpenDeleteScreenerModal] = useState(false)

    const handleOpenDeleteScreenerModal = () => {
        setOpenDeleteScreenerModal(true)
    }

    const handleCloseDeleteScreenerModal = () => {
        setOpenDeleteScreenerModal(false)
    }

    const [openSettingsModal, setOpenSettingsModal] = useState(false)

    const handleOpenSettingsModal = () => {
        setOpenSettingsModal(true)
    }

    const handleCloseSettingModal = () => {
        setOpenSettingsModal(false)
    }

    return (
        <>
            <form ref={formRef}>
                <div className={'screener-filter'}>
                    <div style={{display: 'flex'}}>
                        <p className={'screener-filter-meta-text'} style={{margin: 'auto 0'}}>Build your screener</p>
                        <p className={'estimated-results-text'} style={{margin: 'auto 0 auto auto'}}>Screened
                            Universe: {estimateCount} Stocks</p>

                        <button
                            onClick={handleCloseFilter}
                            className={'screener-add-filter-btn btn btn-warning'}
                            style={{margin: 'auto 0 auto 20px'}}>
                            {!filtersClose ? "Hide" : "Show"} Filters {!filtersClose ? <IoIosArrowDown/> :
                            <IoIosArrowUp/>}
                        </button>
                    </div>
                    {!filtersClose &&
                        <div className={'screener-filter-component'}>
                            <FilterComponentGeneralGroup filterScreener={filterScreener}
                                                         setFilterScreener={setFilterScreener}
                                                         currency={currency} setCurrency={setCurrency}/>
                        </div>
                    }
                </div>

                <button className={'screener-add-filter-btn btn btn-outline-primary'}
                        onClick={handleFilterOpenModal}
                >+ Select Filters
                </button>

                <button
                    onClick={(e) => {
                        e.preventDefault()
                        const form = formRef.current;
                        if (!form.checkValidity()) {
                            form.reportValidity()

                        } else {
                            const _dataStats = {
                                ...dataStats,
                                url: url
                            }
                            setDataStats(_dataStats)
                            setLoadingCompanies(true)
                            setData([])
                            handleSearch(_dataStats)
                        }
                    }}
                    className={'screener-add-search-btn btn btn-outline-success'}>
                    <AiOutlineSearch/> Search Companies
                </button>

                {screener_id &&
                    <>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                handleOpenSettingsModal()
                            }}
                            className={'screener-add-search-btn btn settings-ticker-btn btn'}>
                            Rename Screener
                        </button>

                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                handleOpenDeleteScreenerModal()
                            }}
                            className={'screener-add-search-btn btn btn-outline-danger'}>
                            Delete Screener
                        </button>
                    </>
                }
            </form>

            <ScreenerTable data={data} setData={setData} loadingCompanies={loadingCompanies}
                           setLoadingCompanies={setLoadingCompanies} seeMore={seeMore}
                           componentLoader={componentLoader} setComponentLoader={setComponentLoader}
                           filterScreener={filterScreener} dataStats={dataStats} currency={currency}
                           screener_id={screener_id}
            />

            <Modal
                open={filterModal}
                onClose={handleFilterCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ScreenerFilterModal filterScreener={filterScreener}
                                     setFilterScreener={setFilterScreener}

                                     handleFilterCloseModal={handleFilterCloseModal}/>
            </Modal>

            <Modal
                open={openDeleteScreenerModal}
                onClose={handleCloseDeleteScreenerModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DeleteScreenerModal
                    screener_id={screener_id}
                    setValue={setValue}
                    handleCloseModal={handleCloseDeleteScreenerModal}
                    setUserScreeners={setUserScreeners}/>
            </Modal>

            <Modal
                open={openSettingsModal}
                onClose={handleCloseSettingModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ScreenerSettingModal
                    screener_id={screener_id}
                    ScreenerName={ScreenerName}
                    setValue={setValue}
                    handleCloseModal={handleCloseSettingModal}
                    setUserScreeners={setUserScreeners}/>
            </Modal>
        </>
    );
}

export default Screener;
