import RegisterLayout from "../Layout/RegisterLayout";
import React, {useEffect, useRef, useState} from "react";
import {signup} from "../actions/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {gtag} from "ga-gtag";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-hot-toast";
import {Helmet} from "react-helmet-async";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Tooltip} from 'react-tooltip'

const Register = () => {
    gtag('event', 'conversion', {'send_to': 'AW-918911974/F2ClCKjJjosYEOb3lbYD'});
    const formRef = useRef()
    const captchaRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false);

    const [userData, setUserData] = useState({
        email: "",
        password1: "",
        password2: "",
    })

    const [showPasswordError, setShowPasswordError] = useState(false)
    const [showPasswordMatchError, setShowPasswordMatchError] = useState(false)

    const change_text_handler = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
        if (e.target.name === 'password1') {
            setShowPasswordError(false)
        }
        if (e.target.name === 'password2') {
            setShowPasswordMatchError(false)
        }
    }

    const submit = () => {
        const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
        if (!passwordPattern.test(userData.password1)) {
            setShowPasswordError(true)
            // toast.error("Your password must contain at least one number and one uppercase and one lowercase letter, and at least 8 or more characters.", {duration: 5000})
            return;
        }
        if (userData.password1 !== userData.password2) {
            // toast.error("Your passwords do not match", {duration: 5000})
            setShowPasswordMatchError(true)
            return;
        }
        if (captchaResult === null) {
            toast.error("Please check the captcha before submitting !!")
        } else {
            dispatch(signup({...userData, 'captcha_value': captchaResult, device: isMobile ? "M" : "W"}, navigate))
            setCaptchaResult(null)
            captchaRef.current.reset();
        }
    }

    const [captchaResult, setCaptchaResult] = useState(null)

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
    }

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            // Set a threshold value to distinguish between mobile and desktop devices
            const thresholdWidth = 768; // You can adjust this value based on your design and layout needs

            // Check if the window width is less than the threshold to determine if it's a mobile device
            setIsMobile(window.innerWidth < thresholdWidth);
        };

        // Attach the event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call the handler immediately to set the initial state based on the window width
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Register</title>
                <meta name={"description"}
                      content={"Register to Finqube.io"}/>
            </Helmet>
            <RegisterLayout>
                <div className={'register-container'}>
                    <p className={'accounts-message mb-2'}>Create an Account</p>
                    <form ref={formRef}>
                        <div className={'row'}>
                            <div className="my-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p className={'input-field-helper-text'}>Email Address</p>
                                <input value={userData.email}
                                       name={"email"}
                                       required={true}
                                       type={'email'}
                                       onChange={change_text_handler}
                                       className={'form-control input-field'}/>
                            </div>

                            <div className="my-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div style={{display: 'flex'}}>
                                    <p className={'input-field-helper-text'}>Enter Password</p>

                                    <AiOutlineInfoCircle
                                        data-tooltip-id="password-tooltip"
                                        data-tooltip-html={
                                            `<ul style="margin: 0 0 2px 5px; padding: 5px;">
                                                    <li>minimum 8 characters</li>
                                                    <li>at least 1 number</li>
                                                    <li>at least 1 uppercase letter</li>
                                                    <li>at least 1 lowercase letter</li>
                                             </ul>`
                                        }
                                        style={{
                                            color: 'white',
                                            fontSize: 17,
                                            margin: "auto 0 auto 10px"
                                        }}/>

                                    <Tooltip id="password-tooltip" opacity={1} />
                                </div>
                                <input value={userData.password1}
                                       name={"password1"}
                                       type={showPassword ? 'text' : 'password'}
                                       required={true}
                                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    //    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                       onChange={change_text_handler}
                                       className={'form-control input-field'}/>
                            </div>

                            <div style={{display: 'flex'}}>
                                <p className={'show-password'}
                                   onClick={() => {
                                       setShowPassword(!showPassword)
                                   }}
                                >{!showPassword ? "Show Password" : "Hide Password"}</p>
                            </div>

                            {showPasswordError &&
                            <p style={{color: 'red', fontWeight: 600, fontSize: 12, marginBottom: 10}}>
                                Your password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters
                            </p>
                            }

                            <div className="my-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p className={'input-field-helper-text'}>Confirm Password</p>
                                <input value={userData.password2}
                                       name={"password2"}
                                       type={showPassword ? 'text' : 'password'}
                                       required={true}
                                       onChange={change_text_handler}
                                       className={'form-control input-field'}/>
                            </div>
                            {showPasswordMatchError &&
                                <p style={{color: 'red', fontWeight: 600, fontSize: 12, marginBottom: 10}}>
                                    Your passwords do not match
                                </p>
                            }

                            <div className="my-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                           required={true}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I accept the&nbsp;
                                        <a target={'_blank'} className={'checkbox-link'}
                                           href={'https://web.finqube.io/privacy-policy/'}>Privacy
                                            Policy</a>,&nbsp;
                                        <a target={'_blank'} className={'checkbox-link'}
                                           href={'https://web.finqube.io/terms-of-use/'}>Terms
                                            of Use</a>&nbsp;
                                        and <a className={'checkbox-link'} target={'_blank'}
                                               href={'https://web.finqube.io/disclaimer/'}>Disclaimer</a>
                                    </label>
                                </div>
                            </div>

                            <div className="captcha" style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>
                                <ReCAPTCHA
                                    className={'g-recaptcha'}
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    // sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                    ref={captchaRef}
                                    theme={'dark'}
                                    onChange={handleRecaptcha}
                                />
                            </div>

                            <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                                <button className={"btn reg-control-btn reg-next-btn"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const form = formRef.current;
                                            if (!form.checkValidity()) {
                                                form.reportValidity()

                                            } else {
                                                submit()
                                            }
                                        }}>Register
                                </button>
                            </div>

                        </div>
                    </form>

                    <div style={{display: 'flex'}}>
                        <p className={'have-an-account-text'}
                           onClick={() => navigate('/login')}
                        >Already have an account? Login</p>
                    </div>
                </div>
            </RegisterLayout>
        </>
    );
}

export default Register;
