import axios from "axios";

function Config() {

    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Security-header-xyz': 'Security-header-xyz',
            'Strict-Transport-Security': 'max-age',
            'X-Content-Type-Options': 'nosniff',
            'X-Frame-Options': 'DENY'
        }
    }
}

export default Config;