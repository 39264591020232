import React from 'react';
import {formatNumericValue, numberWithCommas, return_strong_num, return_value_in_million} from "../Utils";
import building from "../../assets/default-building.png";

function InvestorCard({investor}) {
    return (
        <>
            <div>
                <p className={'investor-profile-company-name'}>{investor.company}</p>

                <div style={{display: 'flex'}}>
                    <div className={'profile-image-container holding-profile-image-container'}
                         style={{backgroundImage: investor.imageURL !== '' && `url(${investor.imageURL})`}}/>
                    <div className={'holding-profile-text-container'}>
                        <p className={'investor-profile-investor-name'}>{investor.name}</p>
                        <p className={'investor-profile-cik'}>CIK: {investor.CIK}</p>
                    </div>
                </div>
            </div>

            <div className={'holding-profile-highlights-container mt-2'}>
                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Most Recent Quarter</p>
                    <p className={'holding-profile-highlights-content'}>{investor.date}</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Market Value (mln)</p>
                    <p className={'holding-profile-highlights-content'}>${numberWithCommas(return_value_in_million(investor.marketValue))}</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Portfolio Size</p>
                    <p className={'holding-profile-highlights-content'}>{investor.portfolioSize}</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Avg. Holding Period</p>
                    <p className={'holding-profile-highlights-content'}>${investor.averageHoldingPeriod} Quarters</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Top 10</p>
                    <p className={'holding-profile-highlights-content'}>{formatNumericValue({value: investor.weight})} %</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Turnover</p>
                    <p className={'holding-profile-highlights-content'}>${return_strong_num(investor.turnover * 100)} %</p>
                </div>

                <div className={'holding-profile-highlights-div'}>
                    <p className={'holding-profile-highlights-title'}>Top 5</p>

                    <div style={{margin: "auto 0 auto auto"}}>
                        {investor.logoURLS.map((logoURL, index) => {
                            return (
                                <img className={'price-target-company-logo'}
                                     style={{marginRight: 0, marginLeft: 6}}
                                     src={logoURL && logoURL !== '' ? logoURL : building}
                                     onError={({currentTarget}) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src = building;
                                     }}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default InvestorCard;
