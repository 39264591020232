import React from 'react';
import {Link} from "react-router-dom";
import LandingPageNavbar from "../Container/LandingPage/landing-page-navbar";

function NotFound() {
    const links = [
        {
            link: "/",
            label: "Home"
        },
        {
            link: "/market-overview/",
            label: "Market Overview"
        },
        {
            link: "/company-overview/AAPL/US/",
            label: "Company Overview"
        }
    ]

    return (
        <div className={'landing-page-container'}>
            <div className={'ornament-2'}/>
            <div className={'landing-page'}>
                <div className="container">

                    <LandingPageNavbar/>

                    <div style={{marginTop: 40, textAlign: "center", color: "white"}}>
                        <h1>Oops! You seem to be lost.</h1>

                        <p style={{marginBottom: 10, fontSize: 20}}>
                            Here are some helpful links:
                        </p>

                        {links.map((link) => {
                            return (
                                <Link to={link.link}>
                                    <p style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        marginTop: 10
                                    }}>
                                        {link.label}
                                    </p>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;