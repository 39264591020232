import {useState} from 'react';
import axios from "axios";
import Config from "../../Config";
import {useDispatch} from "react-redux";
import {load_user} from "../../actions/actions";
import {Modal} from "@material-ui/core";
import clsx from "clsx";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import {raise_error} from "../Utils";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}


function ProfileSubscription({subscription}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cancel_subscription = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-payment/cancel-subscription/`, {}, Config())
            .then(response => {
                dispatch(load_user())
                handleCloseConfirmCancelPaymentModel()
                toast.success('Paid subscription cancelled successfully')
            })
            .catch((err) => {
                toast.error(raise_error(err), {duration: 4000})
            })
    }

    const [openConfirmCancelPayment, setOpenConfirmCancelPayment] = useState(false)

    const [modalStyle] = useState(getModalStyle);

    const handleCloseConfirmCancelPaymentModel = () => {
        setOpenConfirmCancelPayment(false)
    }

    const handleOpenConfirmCancelPaymentModel = () => {
        setOpenConfirmCancelPayment(true)
    }

    return (
        <>
            {subscription === null ?
                <div style={{display: 'flex'}}>
                    <p className={'subscription-label'}>Current Subscription </p>
                    <p className={'subscription-value'}>
                        None
                    </p>
                </div>
                :
                <div>
                    <>
                        <div className={'subscription-fields-container'}>
                            <p className={'subscription-label'}>Current Subscription </p>
                            <p className={'subscription-value'}>{subscription.package_name}</p>
                        </div>

                        {subscription.payment_method !== 'Free' &&
                            <div className={'subscription-fields-container'}>
                                <p className={'subscription-label'}>{subscription.active_billing ? "Next Billing Date" : "Active till"}</p>
                                <p className={'subscription-value'}>{subscription.next_billing_date}</p>
                            </div>
                        }

                        <div className={'subscription-fields-container'}>
                            <p className={'subscription-label'}>Payment Method </p>
                            <p className={'subscription-value'}>{subscription.payment_method}</p>
                        </div>
                    </>
                    {(subscription.payment_method !== 'Free' && subscription.active_billing !== false) &&
                        <button
                            className={'btn btn-danger cancel-subscription-btn'}
                            onClick={handleOpenConfirmCancelPaymentModel}>Cancel Subscription
                        </button>
                    }
                    {(subscription.payment_method === 'Free' || subscription.active_billing === false) &&
                        <button
                            className={'btn btn-success cancel-subscription-btn'}
                            onClick={() => navigate('/payment/')}
                        >Upgrade Subscription
                        </button>
                    }
                </div>
            }
            <Modal
                open={openConfirmCancelPayment}
                onClose={handleCloseConfirmCancelPaymentModel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle}
                     className={clsx('generic-modal-body cancel-subscription-modal')}>
                    <div className={'cancel-subscription-modal-heading-container'}>
                        <p className={'cancel-subscription-modal-heading'}>Payment method removal</p>
                        <AiOutlineCloseCircle onClick={handleCloseConfirmCancelPaymentModel}
                                              className={'cancel-subscription-modal-close'}
                        />
                    </div>
                    <hr className={'cancel-subscription-modal-hr'}/>
                    <p className={'cancel-subscription-modal-text'}>
                        Payment method tied to your account will be permanently deleted. To purchase something again you
                        will need to enter a new payment method. Are you sure you want to continue ?
                    </p>

                    <div className={'cancel-subscription-modal-footer-container'}>
                        <button className={'btn btn-outline-secondary btn-close-payment-modal'}
                                onClick={handleCloseConfirmCancelPaymentModel}>
                            No
                        </button>
                        <button onClick={cancel_subscription} className={'btn btn-continue-payment-modal'}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ProfileSubscription;