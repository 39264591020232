import React, {useState} from 'react';
import MarketOverviewIndicesTable from "../MarketOverviewIndicesTable";
import {get_index_body} from "../../Utils";

function MarketMoversEu({data, handleSetChartData, handleSetIndexCompanies}) {

    const [body, setBody] = useState(get_index_body([
        ['Stoxx Europe 600', 'STOXX', data['^STOXX'], "STOXX.INDX", "index", "https://flagcdn.com/108x81/eu.png", "^STOXX"],

        ['Euro Stoxx 50', 'STOXX50E', data['^STOXX50E'], "STOXX50E.INDX", "index", "https://flagcdn.com/108x81/eu.png", "^STOXX50E"],

        ['DAX 40', 'DAX40', data['^GDAXI'], "GDAXI.INDX", "index", "https://flagcdn.com/108x81/de.png", "^GDAXI"],

        ['DAX Midcap', 'MDAXI', data['^MDAXI'], "MDAXI.INDX", "index", "https://flagcdn.com/108x81/de.png", "^MDAXI"],

        ['DAX Smallcap', 'SDAXI', data['^SDAXI'], "SDAXI.INDX", "index", "https://flagcdn.com/108x81/de.png", "^SDAXI"],

        ['TecDAX', 'TECDAX', data['^TECDAX'], "TECDAX.INDX", "index", "https://flagcdn.com/108x81/de.png", "^TECDAX"],

        ['FTSE 100', 'FTSE', data['^FTSE'], "FTSE.INDX", "index", "https://flagcdn.com/108x81/gb.png", "^FTSE"],

        ['CAC 40', 'FCHI', data['^FCHI'], "FCHI.INDX", "index", "https://flagcdn.com/108x81/fr.png", "^FCHI"],

        ['FTSE MIB', 'SPMIB', data['FTSEMIB.MI'], "SPMIB.INDX", "index", "https://flagcdn.com/108x81/fr.png", "FTSEMIB.MI"],


        ['IBEX 35 Index', 'IBEX', data['^IBEX'], "IBEX.INDX", "index", "https://flagcdn.com/108x81/es.png", "^IBEX"],

        ['AEX Amsterdam Index', 'AEX', data['^AEX'], "AEX.INDX", "index", "https://flagcdn.com/108x81/nl.png", "^AEX"],

        ['Austrian Traded Index', 'ATX', data['^ATX'], "ATX.INDX", "index", "https://flagcdn.com/108x81/at.png", "^ATX"],

        ['Swiss Market Index', 'SSMI', data['^SSMI'], "SSMI.INDX", "index", "https://flagcdn.com/108x81/ch.png", "^SSMI"],

        ['BEL-20 Index', 'BEL20', data['^BFX'], "BEL20.INDX", "index", "https://flagcdn.com/108x81/be.png", "^BFX"],

        ['WIG All Shares', 'WIG', data['WIG.WA'], "WIG.INDX", "index", "https://flagcdn.com/108x81/pl.png", "WIG.WA"],

        ['OMX Copenhagen 20 Index', 'OMXC20', data['^OMXC20'], "OMXC20.INDX", "index", "https://flagcdn.com/108x81/dk.png", "^OMXC20"],

        ['OMX Helsinki 25', 'OMXH25', data['^OMXH25'], "OMXH25.INDX", "index", "https://flagcdn.com/108x81/fi.png", "^OMXH25"],

        ['OMX Stockholm 30', 'OMXS30GI', data['^OMXS30'], "OMXS30GI.INDX", "index", "https://flagcdn.com/108x81/se.png", "^OMXS30"],

        ['Tel Aviv 125 Index', 'TA125', data['^TA125.TA'], "TA125.INDX", "index", "https://flagcdn.com/108x81/il.png", "^TA125.TA"],

        ['BIST 100', 'XU100', data['XU100.IS'], "XU100.INDX", "index", "https://flagcdn.com/108x81/tr.png", "XU100.IS"],
    ]))

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                <p>Europe</p>
            </div>
            <MarketOverviewIndicesTable
                body={body} setBody={setBody}
                handleSetChartData={handleSetChartData}
                handleSetIndexCompanies={handleSetIndexCompanies}
                height={220}
                applySort={true}
            />
        </div>
    );
}

export default MarketMoversEu;
