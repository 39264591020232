import React, {useEffect, useState} from 'react';
import {is_number, numberWithCommas, return_per_div, return_per_div_string, return_strong_num} from "../Utils";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {load_common_stock} from "../../actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import building from "../../assets/default-building.png";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import {Modal} from "@material-ui/core";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {useQueryClient} from "@tanstack/react-query";

function HoldingsTable({holdingData, name, CIK, holdingDate}) {

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        if (!updated) {
            setData(holdingData)
            setFilteredData(holdingData)
        }
    }, [holdingData])

    const [columns, setColumns] = useState(
        [
            {name: '', sort: false, state: 0, key: '', className: 'watchlist'},
            {name: 'Ticker', sort: true, state: 0, key: 'symbol', className: 'ticker'},
            {name: 'Company Name', sort: true, state: 0, key: 'securityName', className: 'name'},
            {name: '% Portfolio', sort: true, state: 1, key: 'weight', className: 'portfolio'},
            {name: 'Activity', sort: true, state: 0, key: 'changeInSharesNumberPercentage', className: 'activity'},
            {name: '# Share', sort: true, state: 0, key: 'sharesNumber', className: 'sharesNumber'},
            {name: 'Reported Price', sort: true, state: 0, key: 'avgPricePaid', className: 'quarterEndPrice'},
            {name: 'Market Value', sort: true, state: 0, key: 'marketValue', className: 'marketValue'},
            {name: '% Owned', sort: true, state: 0, key: 'ownership', className: 'ownership'},
            {name: 'First Added', sort: true, state: 0, key: 'firstAdded', className: 'firstAdded'}
        ]
    )

    const redirect_to_company_overview = (code, exchange) => {
        dispatch(load_common_stock(code, exchange))
        navigate('/company-overview/' + code + '/' + exchange + '/')
    }

    const sort = (name, order, setColumns, columns, data, setData) => {
        setColumns(columns.map((item) => {
            if (item.key === name) {
                item.state = !item.state
                return item
            } else
                return item
        }))

        if (order) {
            setData(
                [...data].sort((a, b) => {
                    if (is_number(a[name])) {
                        a = parseFloat(a[name])
                    } else {
                        a = a[name]
                    }
                    if (is_number(b[name])) {
                        b = parseFloat(b[name])
                    } else {
                        b = b[name]
                    }

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                })
            );
        } else {
            setData(
                [...data].sort((a, b) => {
                    if (is_number(a[name])) {
                        a = parseFloat(a[name])
                    } else {
                        a = a[name]
                    }
                    if (is_number(b[name])) {
                        b = parseFloat(b[name])
                    } else {
                        b = b[name]
                    }

                    if (a > b) {
                        return -1;
                    }
                    if (a < b) {
                        return 1;
                    }
                    return 0;
                })
            );
        }
    };

    const [selectedTicker, setSelectedTicker] = useState({})
    const [openWatchListModal, setOpenWatchListModal] = useState(false)
    const handleCloseWatchListModal = () => {
        setOpenWatchListModal(false)
    }

    const handleOpenWatchListModal = (item) => {
        setSelectedTicker(item)
        setOpenWatchListModal(true)
    }

    const queryClient = useQueryClient();

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                const updatedData = data.map((item) => {
                    if (item.company_id === selectedTicker.company_id) {
                        return {
                            ...item,
                            watchlist: response.data['ticker_exists']
                        }
                    }
                    return item
                })

                setData(updatedData)

                setUpdated(true)

                const queryKey = ['get_investor', name]
                const previousData = queryClient.getQueryData(queryKey);

                // Update the cache with the new data
                queryClient.setQueryData(queryKey, {
                    ...previousData,
                    holdings: updatedData
                });

                // const queryKey = [`get_holding_data`, CIK, holdingDate];
                // const previousData = queryClient.getQueryData(queryKey);
                //
                // // Update the cache with the new data
                // queryClient.setQueryData(queryKey, {
                //     ...previousData,
                //     data: updatedData
                // });

                const updatedFilteredData = filteredData.map((item) => {
                    if (item.company_id === selectedTicker.company_id) {
                        return {
                            ...item,
                            watchlist: response.data['ticker_exists']
                        }
                    }
                    return item
                })

                setFilteredData(updatedFilteredData)

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseWatchListModal()
            })
            .catch(err => console.log(err.message))
    }

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const filter_activity = (selectedFilter) => {
        if (selectedFilter === '') {
            setFilteredData(data)
        } else if (selectedFilter === 'New Buy') {
            setFilteredData(data.filter(item => item.isNew))
        } else if (selectedFilter === 'Sold Out') {
            setFilteredData(data.filter(item => item.isSoldOut))
        } else if (selectedFilter === 'No Changes') {
            setFilteredData(data.filter(item => {
                if (item.isNew) {
                    return false
                } else if (item.isSoldOut) {
                    return false
                } else {
                    return return_strong_num(item.changeInSharesNumberPercentage) === null || return_strong_num(item.changeInSharesNumberPercentage) === '0.00'
                }
            }))
        } else if (selectedFilter === 'Added') {
            setFilteredData(data.filter(item => {
                if (item.isNew) {
                    return false
                } else if (item.isSoldOut) {
                    return false
                } else {
                    return return_strong_num(item.changeInSharesNumberPercentage) > 0
                }
            }))
        } else if (selectedFilter === 'Reduced') {
            setFilteredData(data.filter(item => {
                if (item.isNew) {
                    return false
                } else if (item.isSoldOut) {
                    return false
                } else {
                    return return_strong_num(item.changeInSharesNumberPercentage) < 0
                }
            }))
        }
    }

    return (
        <>
            <div style={{display: 'flex'}} className={'my-2 d-none d-sm-block'}>
                <button
                    onClick={() => {
                        filter_activity("Added")
                    }}
                    className={'btn holding-filter-btn btn-outline-success'}>Added
                </button>
                <button
                    onClick={() => {
                        filter_activity("Reduced")
                    }}
                    className={'btn holding-filter-btn btn-outline-danger'}>Reduced
                </button>
                <button
                    onClick={() => {
                        filter_activity("No Changes")
                    }}
                    className={'btn holding-filter-btn holding-filter-no-changes-btn btn-outline-secondary'}>No
                    Change
                </button>
                <button
                    onClick={() => {
                        filter_activity("New Buy")
                    }}
                    className={'btn holding-filter-btn holdings-btn-new-buy'}>New Buy
                </button>
                <button
                    onClick={() => {
                        filter_activity("Sold Out")
                    }}
                    className={'btn holding-filter-btn holdings-btn-sold-out'}>Sold Out
                </button>
                <button
                    onClick={() => {
                        filter_activity("")
                    }}
                    className={'btn holding-filter-btn btn-warning'}>Clear
                </button>
            </div>

            <div className={'row g-0 my-2 d-block d-sm-none'}>
                <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="row g-0">
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("Added")
                                }}
                                className={'btn holding-filter-btn btn-outline-success'}>Added
                            </button>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("Reduced")
                                }}
                                className={'btn holding-filter-btn btn-outline-danger'}>Reduced
                            </button>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("No Changes")
                                }}
                                className={'btn holding-filter-btn holding-filter-no-changes-btn btn-outline-secondary'}>No
                                Change
                            </button>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("New Buy")
                                }}
                                className={'btn holding-filter-btn holdings-btn-new-buy'}>New Buy
                            </button>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("Sold Out")
                                }}
                                className={'btn holding-filter-btn holdings-btn-sold-out'}>Sold Out
                            </button>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-xs-4 col-4">
                            <button
                                onClick={() => {
                                    filter_activity("")
                                }}
                                className={'btn holding-filter-btn btn-warning'}>Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'table-responsive generic-lg-table-container'}>
                <table className="table generic-lg-table holding-table">
                    <thead>
                    <tr>
                        {columns.map((item) => {
                            return (
                                <th className={item.className} onClick={() => {
                                    if (item.sort) {
                                        sort(item.key, item.state, setColumns, columns, filteredData, setFilteredData)
                                    }
                                }}>
                                    {item.name} {item.sort && (item.state ? <BsArrowUp/> : <BsArrowDown/>)}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {filteredData.map(item =>
                        <>
                            <tr>
                                <td
                                    className={'watchlist'}
                                    onClick={() => {
                                        if (!isAuthenticated) {
                                            toast.error("Please login to add stocks into watchlist")
                                            navigate('/login/')
                                            return
                                        }
                                        handleOpenWatchListModal(item)
                                    }}>
                                    {item.watchlist ? <AiFillStar
                                        style={{
                                            color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                        }}/> : <AiOutlineStar
                                        style={{
                                            color: "#1BA2DA", marginLeft: "10%", fontSize: 20
                                        }}/>}
                                </td>
                                <td onClick={() => {
                                    redirect_to_company_overview(item.code, item.exchange_code)
                                }}>
                                    <img className={'price-target-company-logo'}
                                         src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}
                                         onError={({currentTarget}) => {
                                             currentTarget.onerror = null;
                                             currentTarget.src = building;
                                         }}/>
                                    {item.code}
                                </td>
                                <td onClick={() => {
                                    redirect_to_company_overview(item.code, item.exchange_code)
                                }}>
                                    {item.name}
                                </td>
                                <td>
                                    {parseFloat(item.weight).toFixed(2)} %
                                </td>
                                {item.isNew ?
                                    <td style={{
                                        display: 'flex',
                                        paddingRight: 2
                                    }}>{return_per_div_string('New Buy', 11, 110, "auto 0")}</td>
                                    :
                                    item.isSoldOut ?
                                        <td style={{
                                            display: 'flex',
                                            paddingRight: 2
                                        }}>{return_per_div_string('Sold Out', 11, 110, "auto 0")}</td>
                                        :
                                        <td style={{
                                            display: 'flex',
                                            paddingRight: 2
                                        }}>
                                            {return_per_div(item.changeInSharesNumberPercentage, 11, 110, "auto 0", true, "Added", "Reduced")}
                                        </td>
                                }
                                <td>
                                    {numberWithCommas(item.sharesNumber)}
                                </td>
                                <td>
                                    {item.avgPricePaid}
                                </td>
                                <td>
                                    {numberWithCommas(item.marketValue)}
                                </td>
                                <td>
                                    {parseFloat(item.ownership).toFixed(4)} %
                                </td>
                                <td>
                                    {item.firstAdded}
                                </td>
                            </tr>
                            <div style={{marginTop: 3, width: "100%"}}/>
                        </>
                    )}
                    </tbody>
                </table>

                <Modal
                    open={openWatchListModal}
                    onClose={handleCloseWatchListModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <SelectWatchListModal
                        selectedTicker={selectedTicker}
                        save_ticker={save_ticker}
                        handleCloseModal={handleCloseWatchListModal}
                    />
                </Modal>

            </div>
        </>
    );
}

export default HoldingsTable;
