import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../Context/ThemeContext";
import ReactApexChart from "react-apexcharts";

const SectorWeight = ({data, labels}) => {

    console.log(data, labels)

    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    let state = {
        series: [{
            data: data
        }],
        options: {
            grid: {
                show: false
            },
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
                foreColor: graphVar.color,
                height: 300,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: 89,
                    dataLabels: {
                        position: 'bottom'
                    },
                }
            },
            xaxis: {
                categories: labels,
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                }
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: 12,
                    },
                    show: true
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: 10,
                style: {
                    colors: [graphVar.color],
                    fontSize: '10px',
                },
                formatter: function (val, opt) {
                    return parseFloat(val).toFixed(2) + " %"
                },
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex]
                        }
                    }
                }
            },
            responsive: [{
                breakpoint: 200,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]

        },
    };

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    return (
        <div className={'etf-profiler-chart-container'}>
            <div className={'etf-profiler-pie-chart-container'}>
                {data.length > 0 &&
                    <ReactApexChart options={state.options} series={state.series} type="bar" height={295}/>
                }
            </div>
        </div>
    );
}

export default SectorWeight;