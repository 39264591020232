import React, {useState} from 'react';
import MarketOverviewIndicesTable from "../MarketOverviewIndicesTable";
import {get_index_body} from "../../Utils";

function MarketMoversApac({data, handleSetChartData, handleSetIndexCompanies}) {

    const [body, setBody] = useState(get_index_body([
        ['ASX 50', 'AFLI', data['^AFLI'], "AFLI.INDX", "index", "https://flagcdn.com/108x81/au.png", "^AFLI"],

        ['ASX 200', 'AXJO', data['^AXJO'], "AXAF.INDX", "index", "https://flagcdn.com/108x81/au.png", "^AXJO"],

        ['NIKKEI 225', 'N225', data['^N225'], "N225.INDX", "index", "https://flagcdn.com/108x81/jp.png", "^N225"],

        ['SSE 180 Index', 'SSE180', data['000001.SS'], "SSE180.INDEX", "index", "https://flagcdn.com/108x81/cn.png", '000001.SS'],

        ['Hang Seng', 'HSI', data['^HSI'], "HSI.INDX", "index", "https://flagcdn.com/108x81/hk.png", "^HSI"],

        ['Hang Seng China Enterprise', "HSCE", data["^HSCE"], "HSCE.INDX", "index", "https://flagcdn.com/108x81/hk.png", "^HSCE"],

        ['Nifty 50', "NSEI", data["^NSEI"], "NSEI.INDX", "index", "https://flagcdn.com/108x81/in.png", "^NSEI"],

        ['FTSE Malaysia KLCI', "KLSE", data["^KLSE"], "KLSE.INDX", "index", "https://flagcdn.com/108x81/my.png", "^KLSE"],

        ['Taiwan Weighted Index', 'TWII', data['^TWII'], "TWII.INDX", "index", "https://flagcdn.com/108x81/tw.png", "^TWII"],

        ['Tadawul All Share', 'TASI', data['^TASI.SR'], "TASI.INDX", "index", "https://flagcdn.com/108x81/sa.png", "^TASI.SR"],

        ['Thailand SET Index', 'SET', data['^SET.BK'], "SET.INDX", "index", "https://flagcdn.com/108x81/th.png", "^SET.BK"],
    ]))

    return (
        <div className={'market-overview-summary-table-container'}>
            <div className={'market-overview-summary-table-title'}>
                <p>APAC</p>
            </div>

            <MarketOverviewIndicesTable
                body={body} setBody={setBody}
                handleSetChartData={handleSetChartData}
                handleSetIndexCompanies={handleSetIndexCompanies}
                height={194}
                applySort={true}
            />
        </div>
    );
}

export default MarketMoversApac;
