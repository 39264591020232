import {AiOutlineCloseCircle} from "react-icons/ai";
import building from "../../assets/default-building.png";
import {
    calculate_grade,
    CircularProgressWithLabel,
    formatNumericValue,
    numberWithCommas,
    return_bg_grade_color,
    return_grade_color, return_per_div, return_strong_num, return_td_color,
    return_value_in_million,
    screener_isqore_mapping
} from "../Utils";
import React from "react";

function CompareStockProTab({
                                selectedSidebarFields,
                                setCompareData,
                                item,
                                index
                            }) {

    let iSqoreValues = []
    let averageISqore = 0
    let averageISqoreGrade = '-'

    {
        Object.entries(selectedSidebarFields).map(([fieldKey, field]) => {
            if (fieldKey in screener_isqore_mapping) {
                const {valueKey} = screener_isqore_mapping[fieldKey]
                if (typeof item[valueKey] === 'number') {
                    iSqoreValues.push(item[valueKey])
                }

                averageISqore = iSqoreValues.reduce((partialSum, a) => partialSum + a, 0) / iSqoreValues.length;

                averageISqore = averageISqore > 1 ? 1 : averageISqore

                averageISqoreGrade = calculate_grade(averageISqore)
            }
        })
    }

    const return_value = (item, key, field) => {
        const value = item[key]
        const currency = item['localCurrency']
        let primary = item?.primary
        if (typeof primary !== 'boolean') {
            primary = true
        }
        const non_primary_currency = item?.non_primary_currency

        if (typeof value === 'string') {
            return <p>{value}</p>
        } else if (typeof value === 'number') {
            if (key === 'upside_potential') {
                return <div style={{
                    color: return_td_color(value),
                    fontWeight: 600,
                    margin: "0",
                    fontSize: 11,
                    backgroundColor: return_strong_num(value) > 0 ? '#4ebe9629' : '#d84f6829',
                    padding: "3px 5px",
                    borderRadius: 5,
                    textAlign: "center",
                }}>
                    {return_strong_num(value)}% {value > 0 ? "Upside" : "Downside"}
                </div>
            }
            else if (key === 'sharesOutstanding' || key === 'sharesFloat') {
                return <p>{numberWithCommas(value)}</p>
            }
            else if (key === 'percentInsiders' || key === 'percentInstitutions' || key === 'shortPercentFloat') {
                return <p>{formatNumericValue({value: value, multiply: key === 'shortPercentFloat' ? 100 : 1})} %</p>
            }
            else if (field.ops === 'million-comma') {
                if (key === 'm_cap_trading') {
                    return <p>{!primary ? non_primary_currency : field.currency ? currency : ""} {numberWithCommas(return_value_in_million(value))}</p>
                }
                return <p>{field.currency && currency} {numberWithCommas(return_value_in_million(value))}{field.percentage && "%"}</p>
            } else if (field.ops === 'multiply') {
                if (['ytd', 'week', 'one_month', 'three_month', 'one_year', 'three_year', 'five_year', 'five_year_cagr', 'ten_year'].includes(key)) {
                    return return_per_div(formatNumericValue({
                        value: value,
                        multiply: 100
                    }), 11, 55, '0 0 0 0')
                } else {
                    return <p>{formatNumericValue({
                        value: value,
                        multiply: 100
                    })} {field.percentage && "%"}</p>
                }
            } else {
                if (key === 'adjusted_close') {
                    return <p>{!primary ? non_primary_currency : field.currency && currency} {formatNumericValue({
                        value: value,
                        multiply: 1
                    })}</p>
                } else if (key === 'number_of_analyst') {
                    return <p>{value}</p>
                }
                return <p>{field.currency && currency} {formatNumericValue({
                    value: value,
                    multiply: 1
                })}</p>
            }
        } else {
            return <p>-</p>
        }
    }

    const check_grade = (item, key) => {
        if (key in screener_isqore_mapping) {
            const {gradeKey} = screener_isqore_mapping[key]

            return return_grade(item, gradeKey)
        }
    }

    const return_grade = (item, key) => {
        const grade = item[key]

        return <p className={'grade_p'}
                  style={{
                      textAlign: "center",
                      border: `1px solid ${return_grade_color(grade)}`,
                      color: return_grade_color(grade),
                      backgroundColor: return_bg_grade_color(grade),
                      marginLeft: 'auto'
                  }}>
            {grade}</p>
    }

    return (
        <div key={index} className={'company-isqore-container'}>
            <AiOutlineCloseCircle
                onClick={() => {
                    setCompareData(prev => prev.filter(company => company.index !== item.index))
                }}
                style={{color: '#676767', margin: '0 auto', cursor: 'pointer'}}/>

            <div style={{display: 'flex'}}>
                <img className={'company-company-logo'}
                     src={item.logoURL && item.logoURL !== "" ? item.logoURL : building}
                     onError={({currentTarget}) => {
                         currentTarget.onerror = null;
                         currentTarget.src = building;
                     }}
                />
                <CircularProgressWithLabel
                    value={averageISqore}
                    grade={averageISqoreGrade}
                    compare_stocks={true}
                />
            </div>

            {Object.entries(selectedSidebarFields).map(([fieldKey, field]) => {
                return (
                    <div className={'isqore-sidebar-text'}>
                        {return_value(item, fieldKey, field)}
                        {check_grade(item, fieldKey)}
                    </div>
                )
            })}

        </div>
    );
}

export default CompareStockProTab;
