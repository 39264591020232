import axios from "axios";
import Config from "../../Config";
import {BsSearch} from "react-icons/bs";
import _debounce from 'lodash/debounce';
import {useCallback, useEffect, useState} from 'react';
import building from '../../assets/default-building.png'
import {useQuery} from "@tanstack/react-query";

const search_companies = async (stock_type, search) => {
    try {
        let url = ""
        if (search === '') {
            url = `${process.env.REACT_APP_API_URL}/finqube-api/search-companies/${stock_type}/`
        } else {
            url = `${process.env.REACT_APP_API_URL}/finqube-api/search-companies/${stock_type}/${search}/`
        }
        const response = await axios.get(url, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function NavbarSearchTabBody({stock_type, stock_onclick}) {

    const [search, setSearch] = useState('')

    const [companies, setCompanies] = useState([])

    const [loader, setLoader] = useState(true)

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    const {data: searchCompaniesReturn, isLoading: companiesIsLoading, refetch} = useQuery({
        queryKey: [`search-companies/${stock_type}/${search}/`, stock_type, search],
        queryFn: () => search_companies(stock_type, search),
        staleTime: 5 * 60000,
        enabled: false
    })

    function handleDebounceFn(search) {
        void refetch()
    }

    useEffect(() => {
        void refetch()
    }, [])

    useEffect(() => {
        if (!companiesIsLoading && searchCompaniesReturn) {
            setCompanies(searchCompaniesReturn)
        } else {
            setCompanies([])
        }
        setLoader(false)
    }, [searchCompaniesReturn, companiesIsLoading]);

    return (
        <div>
            <div className={'navbar-modal-container'}>
                <input
                    autoFocus={true}
                    value={search}
                    onChange={(e) => {
                        setLoader(true)
                        setSearch(e.target.value)
                        debounceFn(e.target.value)
                    }}
                    className={'form-control navbar-modal-search-bar'}
                    placeholder={'search here'}/>
                <BsSearch className={'navbar-modal-search-bar-icon'}/>
            </div>

            <div className={'companies-list'}>
                {loader ? <>
                    <div className={'navbar-modal-company-loader-container'}>
                        <div className={'loader navbar-searched-circle-loader'}></div>
                        <div className={'loader navbar-searched-pill-loader'}></div>
                        <div className={'loader navbar-searched-pill-loader'}></div>
                    </div>
                    <div className={'navbar-modal-company-loader-container'}>
                        <div className={'loader navbar-searched-circle-loader'}></div>
                        <div className={'loader navbar-searched-pill-loader'}></div>
                        <div className={'loader navbar-searched-pill-loader'}></div>
                    </div>
                </> : companies.length !== 0 ? companies.map((company, index) => {
                    return (
                        <div key={index} className={'navbar-search-company-container'} onClick={() => {
                            stock_onclick(stock_type, company.code, company.stock_exchange_code)
                        }}>
                            <img className={'navbar-search-company-logo'}
                                 src={company.LogoURL && company.LogoURL !== '' ? company.LogoURL : building}
                                 onError={({currentTarget}) => {
                                     currentTarget.onerror = null; // prevents looping
                                     currentTarget.src = building;
                                 }}/>
                            <p className={'navbar-searched-company-name'}>
                                {company.name}
                            </p>
                            <p className={'navbar-searched-company-code'}>
                                {company.code}
                            </p>
                            <p className={'navbar-searched-company-exchange'}>
                                {company.stock_exchange_name}
                            </p>
                        </div>)
                }) : <p className={'no-companies-found-text'}>No Companies Found</p>}
            </div>
        </div>
    );
}

export default NavbarSearchTabBody;
