import clsx from "clsx";
import {useState} from "react";
import {return_strong_num} from "../Utils";

const Index = ({names, indexKey, data}) => {
    let index = data.filter(item => item['symbol'] === indexKey)[0]

    return (
        <div className={clsx('index')}>
            <p className={clsx('index-name')}>{names[indexKey]}</p>
            <div className={'index-text-container'}>
                <p className={clsx('index-cash')}>{return_strong_num(index.price)}</p>
                <p className={'index-first-num'} style={{color: index.change > 0 ? '#4ebe96' : 'red'}}
                >{return_strong_num(index.change)}</p>
                <p className={'index-second-num'} style={{color: index.changesPercentage > 0 ? '#4ebe96' : 'red'}}
                >{return_strong_num(index.changesPercentage)}%</p>
            </div>
        </div>
    );
}

export default Index;