import IndexTopBar from "./IndexTopBar";
import IndexStats from "./IndexStats";
import IndicesSelectableTable from "./IndicesSelectableTable";

const Index = ({
                   index,
                   selectedIndexData,
                   loadingSelectedIndex
               }) => {

    return (
        loadingSelectedIndex ?
            <div className={'generic-loading-container'}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <>
                <IndexTopBar index={index} selectedIndexData={selectedIndexData} />
                <IndexStats index={index} selectedIndexData={selectedIndexData} />
                <IndicesSelectableTable selectedIndexData={selectedIndexData} />
            </>
    );
}

export default Index;
