import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    return_obj,
    return_string,
} from "../Utils";
import GrossMarginOperatingNetChart
    from "./FinancialTabs/FinancialOverview/FinancialCharts/GrossMarginOperatingNetChart";
import iSqoreValueLight from "../../assets/blockers/iSqoreValueLight.png";
import iSqoreValueDark from "../../assets/blockers/iSqoreValueDark.png";
import RestrictedContent from "../RestrictedContent";
import ISqoreTable from "../ISqoreTabs/ISqoreTable";
import Config from "../../Config";

const ProbabilityGrowth = ({code, exchange}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-value/${code}/${exchange}/`, Config())
            .then(response => {
                format_data(response.data)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange])

    const format_data = (values) => {
        const data = {
            tableData: [

                return_obj(
                    "Return on Assets (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnAssets'],
                    values['iSqore']['returnOnAssetsTTMGrade'],
                    values['median_sector']['returnOnAssetsTTM'],

                    values['calculation-3-year-avg']['historical_data']['returnOnAssets'],
                    values['calculation-5-year-avg']['historical_data']['returnOnAssets'],
                    true
                ),
                return_obj(
                    "Return on Assets (Year)",
                    values['additional_cal']['returnOnAssets'],
                    values['iSqore']['returnOnAssetsYearGrade'],
                    values['median_sector']['returnOnAssetsYear'],

                    values['calculation-3-year-avg']['historical_data']['returnOnAssets'],
                    values['calculation-5-year-avg']['historical_data']['returnOnAssets'],
                    true
                ),

                return_obj(
                    "Return on Equity (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnEquity'],
                    values['iSqore']['returnOnEquityTTMGrade'],
                    values['median_sector']['returnOnEquityTTM'],

                    values['calculation-3-year-avg']['historical_data']['returnOnEquity'],
                    values['calculation-5-year-avg']['historical_data']['returnOnEquity'],
                    true
                ),
                return_obj(
                    "Return on Equity (Year)",
                    values['additional_cal']['returnOnEquity'],
                    values['iSqore']['returnOnEquityYearGrade'],
                    values['median_sector']['returnOnEquityYear'],

                    values['calculation-3-year-avg']['historical_data']['returnOnEquity'],
                    values['calculation-5-year-avg']['historical_data']['returnOnEquity'],
                    true
                ),

                return_obj(
                    "Return on Capital (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnCapital'],
                    values['iSqore']['returnOnCapitalTTMGrade'],
                    values['median_sector']['returnOnCapitalTTM'],

                    values['calculation-3-year-avg']['historical_data']['returnOnCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapital'],
                    true
                ),
                return_obj(
                    "Return on Capital (Year)",
                    values['additional_cal']['returnOnCapital'],
                    values['iSqore']['returnOnCapitalYearGrade'],
                    values['median_sector']['returnOnCapitalYear'],

                    values['calculation-3-year-avg']['historical_data']['returnOnCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapital'],
                    true
                ),

                return_obj(
                    "Return on Cap Empl. (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnCapitalEmployed'],
                    values['iSqore']['returnOnCapitalEmployedTTMGrade'],
                    values['median_sector']['returnOnCapitalEmployedTTM'],

                    values['calculation-3-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    true
                ),
                return_obj(
                    "Return on Cap Empl. (Year)",
                    values['additional_cal']['returnOnCapitalEmployed'],
                    values['iSqore']['returnOnCapitalEmployedYearGrade'],
                    values['median_sector']['returnOnCapitalEmployedYear'],

                    values['calculation-3-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    values['calculation-5-year-avg']['historical_data']['returnOnCapitalEmployed'],
                    true
                ),

                return_obj(
                    "Return on Invested Capital. (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['returnOnInvestedCapital'],
                    values['iSqore']['returnOnInvestedCapitalTTMGrade'],
                    values['median_sector']['returnOnInvestedCapitalTTM'],

                    values['calculation-3-year-avg']['historical_data']['returnOnInvestedCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnInvestedCapital'],
                    true
                ),
                return_obj(
                    "Return on Invested Capital. (Year)",
                    values['additional_cal']['returnOnInvestedCapital'],
                    values['iSqore']['returnOnInvestedCapitalYearGrade'],
                    values['median_sector']['returnOnInvestedCapitalYear'],

                    values['calculation-3-year-avg']['historical_data']['returnOnInvestedCapital'],
                    values['calculation-5-year-avg']['historical_data']['returnOnInvestedCapital'],
                    true
                ),

                return_obj(
                    "Gross Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['grossMargin'],
                    values['iSqore']['grossMarginTTMGrade'],
                    values['median_sector']['grossMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['grossMargin'],
                    values['calculation-5-year-avg']['historical_data']['grossMargin'],
                    true
                ),
                return_obj(
                    "Gross Margin (Year)",
                    values['additional_cal']['grossMargin'],
                    values['iSqore']['grossMarginYearGrade'],
                    values['median_sector']['grossMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['grossMargin'],
                    values['calculation-5-year-avg']['historical_data']['grossMargin'],
                    true
                ),

                return_obj(
                    "Operating Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['operatingMargin'],
                    values['iSqore']['operatingMarginTTMGrade'],
                    values['median_sector']['operatingMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['operatingMargin'],
                    values['calculation-5-year-avg']['historical_data']['operatingMargin'],
                    true
                ),
                return_obj(
                    "Operating Margin (Year)",
                    values['additional_cal']['operatingMargin'],
                    values['iSqore']['operatingMarginYearGrade'],
                    values['median_sector']['operatingMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['operatingMargin'],
                    values['calculation-5-year-avg']['historical_data']['operatingMargin'],
                    true
                ),

                return_obj(
                    "Net Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['netMargin'],
                    values['iSqore']['netMarginTTMGrade'],
                    values['median_sector']['netMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['netMargin'],
                    values['calculation-5-year-avg']['historical_data']['netMargin'],
                    true
                ),
                return_obj(
                    "Net Margin (Year)",
                    values['additional_cal']['netMargin'],
                    values['iSqore']['netMarginYearGrade'],
                    values['median_sector']['netMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['netMargin'],
                    values['calculation-5-year-avg']['historical_data']['netMargin'],
                    true
                ),

                return_obj(
                    "EBIT Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['ebitMargin'],
                    values['iSqore']['ebitMarginTTMGrade'],
                    values['median_sector']['ebitMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['ebitMargin'],
                    values['calculation-5-year-avg']['historical_data']['ebitMargin'],
                    true
                ),
                return_obj(
                    "EBIT Margin (Year)",
                    values['additional_cal']['ebitMargin'],
                    values['iSqore']['ebitMarginYearGrade'],
                    values['median_sector']['ebitMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['ebitMargin'],
                    values['calculation-5-year-avg']['historical_data']['ebitMargin'],
                    true
                ),

                return_obj(
                    "EBITDA Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['ebitdaMargin'],
                    values['iSqore']['ebitdaMarginTTMGrade'],
                    values['median_sector']['ebitdaMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['ebitdaMargin'],
                    values['calculation-5-year-avg']['historical_data']['ebitdaMargin'],
                    true
                ),
                return_obj(
                    "EBITDA Margin (Year)",
                    values['additional_cal']['ebitdaMargin'],
                    values['iSqore']['ebitdaMarginYearGrade'],
                    values['median_sector']['ebitdaMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['ebitdaMargin'],
                    values['calculation-5-year-avg']['historical_data']['ebitdaMargin'],
                    true
                ),

                return_obj(
                    "FCF Margin (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['fcfMargin'],
                    values['iSqore']['fcfMarginTTMGrade'],
                    values['median_sector']['fcfMarginTTM'],

                    values['calculation-3-year-avg']['historical_data']['fcfMargin'],
                    values['calculation-5-year-avg']['historical_data']['fcfMargin'],
                    true
                ),
                return_obj(
                    "FCF Margin (Year)",
                    values['additional_cal']['fcfMargin'],
                    values['iSqore']['fcfMarginYearGrade'],
                    values['median_sector']['fcfMarginYear'],

                    values['calculation-3-year-avg']['historical_data']['fcfMargin'],
                    values['calculation-5-year-avg']['historical_data']['fcfMargin'],
                    true
                ),

                return_obj(
                    "Asset Turnover (TTM)",
                    values['calculation-quarterly-ttm']['historical_data']['assetTurnOver'],
                    values['iSqore']['assetTurnOverTTMGrade'],
                    values['median_sector']['assetTurnOverTTM'],

                    values['calculation-3-year-avg']['historical_data']['assetTurnOver'],
                    values['calculation-5-year-avg']['historical_data']['assetTurnOver'],
                ),
                return_obj(
                    "Asset Turnover (Year)",
                    values['additional_cal']['assetTurnOver'],
                    values['iSqore']['assetTurnOverYearGrade'],
                    values['median_sector']['assetTurnOverYear'],

                    values['calculation-3-year-avg']['historical_data']['assetTurnOver'],
                    values['calculation-5-year-avg']['historical_data']['assetTurnOver'],
                )
            ],
            iSqore: {
                value: values['iSqore']['profitability'],
                grade: return_string(values['iSqore'], 'profitabilityGrade'),
            }
        }
        setData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={iSqoreValueDark}
            lightBlockerImg={iSqoreValueLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row g-0">
                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12">
                                <ISqoreTable
                                    name={'Profit'}
                                    data={data}/>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                                <GrossMarginOperatingNetChart code={code} exchange={exchange}
                                                              type={'annual'}
                                                              colors={["#3e9fed", "#29f3a7", "#ee1981"]}/>
                            </div>
                        </div>
                    </>
            }
        />
    );
}

export default ProbabilityGrowth;