import axios from "axios";
import {numberWithCommas, return_float, return_strong_num, return_value_in_million} from "../../Utils";
import FinancialsTable from "./FinancialsTable";
import React, {useContext, useEffect, useState} from 'react';
import FinancialsControls from "./FinancialsControls";
import RevenueBySegmentsPieChart from "./RevenueBySegmentsPieChart";
import {useSelector} from "react-redux";
import {ThemeContext} from "../../../Context/ThemeContext";
import revenueBySegmentDark from "../../../assets/blockers/revenueBySegmentDark.png";
import revenueBySegmentLight from "../../../assets/blockers/revenueBySegmentLight.png";
import Config from "../../../Config";
import {useQuery} from "@tanstack/react-query";
import RestrictedContent from "../../RestrictedContent";

const company_overview_financials_revenue_by_segments = (code, exchange, active) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-revenue-by-segments/${code}/${exchange}/${active}`, Config())
}

function RevenueBySegments({code, exchange}) {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    const {webTheme} = useContext(ThemeContext);

    const [active, setActive] = useState('annual')

    const [groupedData, setGroupedData] = useState(null)

    const [loading, setLoading] = useState(true)

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    const [highlightHeadings, setHighlightHeadings] = useState([])

    const handleActive = (type) => {
        setActive(type)
        setLoading(true)
    }

    const {data: revenueBySegmentData, isLoading} = useQuery({
        queryKey: [`company-overview-financials-revenue-by-segments/${code}/${exchange}/${active}`, code, exchange, active],
        queryFn: () => company_overview_financials_revenue_by_segments(code, exchange, active),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (revenueBySegmentData) {
            group_income(revenueBySegmentData.data, active)
        }
    }, [revenueBySegmentData])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`, Config())
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    const cal_growth_per = (x, y) => {
        if (!y) {
            return null
        }
        return return_strong_num(((x / y) - 1) * 100)
    }

    const cal_revenue_per = (x, y) => {
        if (!y) {
            return null
        }
        return return_strong_num((x / y) * 100)
    }

    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])

    const group_income = (_data, type) => {
        let series = []
        let labels = []

        let data = {
            dates: [],
            content: {
                'Revenue': {
                    name: 'Revenue',
                    data: {
                        'Revenue': [],
                        ...(active === 'annual' ? {'% Growth Annually': []} : {'% Growth Quarterly': []}),
                    }
                },
                'Revenue By Segment': {
                    name: 'Revenue By Segment',
                    data: {}
                }
            }
        }

        let highlightHeadings = ['Revenue']

        let innerKeys = {}

        let growth_spaces = {}

        _data['revenue-product'].map((item, index) => {
            const key = Object.keys(item)[0]

            item = item[key]
            data['dates'].push(key)

            Object.keys(item).map((service, i) => {
                const data_key = service.toLowerCase().replace(' ', '')

                if (!(data_key in innerKeys)) {
                    innerKeys[data_key] = service
                    highlightHeadings.push(service)
                }

                if (index === 0) {
                    growth_spaces[data_key] = i + 1

                    data['content']['Revenue By Segment'] = {
                        ...data['content']['Revenue By Segment'],
                        data: {
                            ...data['content']['Revenue By Segment']['data'],
                            [service]: [item[service]],
                            ...(active === 'annual' ? {['% Growth Annually' + ' '.repeat(i + 1)]: []} : {['% Growth Quarterly' + ' '.repeat(i + 1)]: []}),
                            ['% Total of Revenue' + ' '.repeat(i + 1)]: []
                        }
                    }
                } else {
                    if (Object.keys(data['content']['Revenue By Segment']['data']).filter(item => item.toLowerCase().replace(' ', '') === data_key).length !== 0) {
                        data['content']['Revenue By Segment']['data'][innerKeys[data_key]] = [...data['content']['Revenue By Segment']['data'][innerKeys[data_key]], item[service]]
                    }
                }
            })
        })

        const revenue = _data['revenue'].map((item) => {
            return return_value_in_million(item.revenue)
        })

        data['content']['Revenue']['data']['Revenue'] = revenue.slice(0, data['dates'].length - 1).map((item) => {
            return numberWithCommas(item)
        })

        const growth = revenue.slice(0, data['dates'].length - 1).map((item, index) => {
            return cal_growth_per(item, revenue[index + 1])
        })

        data['content']['Revenue']['data'][active === 'annual' ? '% Growth Annually' : '% Growth Quarterly'] = growth

        _data['revenue-product'].map((item, index) => {
            const key = Object.keys(item)[0]

            item = item[key]

            Object.keys(item).map(service => {
                const data_key = service.toLowerCase().replace(' ', '')
                if (Object.keys(data['content']['Revenue By Segment']['data']).filter(item => item.toLowerCase().replace(' ', '') === data_key).length !== 0) {
                    data['content']['Revenue By Segment']['data'][innerKeys[data_key]] = [...data['content']['Revenue By Segment']['data'][innerKeys[data_key]], ...Array(data['dates'].length - data['content']['Revenue By Segment']['data'][innerKeys[data_key]].length).fill(0)]
                }
            })

            console.log("service =>", item)

            Object.keys(item).map((service, i) => {
                const data_key = service.toLowerCase().replace(' ', '')
                if (Object.keys(data['content']['Revenue By Segment']['data']).filter(item => item.toLowerCase().replace(' ', '') === data_key).length !== 0) {

                    console.log("data_key =>", data_key)

                    const space = growth_spaces[data_key]

                    data['content']['Revenue By Segment']['data'][active === 'annual' ? '% Growth Annually' + ' '.repeat(space) : '% Growth Quarterly' + ' '.repeat(space)] = data['content']['Revenue By Segment']['data'][innerKeys[data_key]].slice(0, data['dates'].length).map((item, index) => {
                        return cal_growth_per(item, data['content']['Revenue By Segment']['data'][innerKeys[data_key]][index + 1])
                    })

                    data['content']['Revenue By Segment']['data']['% Total of Revenue' + ' '.repeat(space)] = data['content']['Revenue By Segment']['data'][innerKeys[data_key]].slice(0, data['dates'].length).map((item, index) => {
                        const revenue_per = cal_revenue_per(item, _data['revenue'].filter(item => item.date === data['dates'][index]).length ? _data['revenue'].filter(item => item.date === data['dates'][index])[0].revenue : 0)
                        if (!(labels.includes(innerKeys[data_key]))) {
                            labels.push(innerKeys[data_key])
                            series.push(return_float(revenue_per))
                        }
                        return revenue_per
                    })
                }
            })
        })

        let sum = 0
        series.map(item => sum = sum + item)
        if (sum < 100) {
            series.push(100 - sum)
            labels.push('Others')
        }

        const indices = Array.from(series.keys());

        indices.sort((i, j) => series[j] - series[i]);

        series = indices.map(i => series[i]);
        labels = indices.map(i => labels[i]);

        setLabels(labels)
        setSeries(series)

        let passed = []

        _data['revenue-product'].map((item, index) => {
            const key = Object.keys(item)[0]

            item = item[key]

            Object.keys(item).map(service => {
                const data_key = service.toLowerCase().replace(' ', '')
                if (Object.keys(data['content']['Revenue By Segment']['data']).filter(item => item.toLowerCase().replace(' ', '') === data_key).length !== 0 && !passed.includes(data_key)) {
                    passed.push(data_key)
                    data['content']['Revenue By Segment']['data'][innerKeys[data_key]] = data['content']['Revenue By Segment']['data'][innerKeys[data_key]].slice(0, data['dates'].length).map((item, index) => {
                        return numberWithCommas(return_value_in_million(item))
                    })
                }
            })
        })

        data['dates'] = data['dates'].slice(0, 5)

        Object.keys(data['content']['Revenue By Segment']['data']).map(service => {
            Object.keys(data['content']['Revenue By Segment']['data']).map(item => {
                data['content']['Revenue By Segment']['data'][item] = data['content']['Revenue By Segment']['data'][item].slice(0, data['dates'].length)
            })
        })
        setHighlightHeadings(highlightHeadings)
        setGroupedData(data)
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={revenueBySegmentDark}
            lightBlockerImg={revenueBySegmentLight}
            code={code}
            exchange={exchange}

            content={
                <>
                    <FinancialsControls
                        options={[{label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}]}
                        handleActive={handleActive} active={active} balance_sheet={true} meta={meta}/>
                    {
                        (loading && isLoading) ?
                            <div style={{width: '100%', height: '100%', display: ' flex'}}>
                                <div className="spinner-grow text-warning" role="status"
                                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                                </div>
                            </div>
                            :
                            groupedData && groupedData.dates.length > 0 &&
                            <div className={'row g-0 revenue-by-segments-container'}>
                                <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12">
                                    <FinancialsTable groupedData={groupedData} highlightHeadings={highlightHeadings}/>
                                </div>
                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12">
                                    <RevenueBySegmentsPieChart groupedData={groupedData} series={series}
                                                               labels={labels}/>
                                </div>
                            </div>
                    }
                </>
            }
        />
    );
}

export default RevenueBySegments;