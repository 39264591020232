import React from 'react';
import {return_bg_grade_color, return_grade_color} from "../../Utils";

function CompanyOverviewBottomTable({title, grade, data, per = false}) {
    return (
        <div className={'company-overview-table-container'}>
            <div className={'company-overview-table-body-container company-overview-bottom-table-body-container'}>
                <table className={'table generic-table-body company-overview-sm-table-body'}>
                    <thead>
                    <tr>
                        <th className={'title'}>{title}</th>
                        <th></th>
                        <th style={{color: return_grade_color(grade), textAlign: "center"}}
                            className={'grade'}>{grade}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {item.name}
                                </td>
                                <td style={{width: 100}} className={'item-right-align-td'}>
                                    {item.value ?? '-'} {item?.per && '%'}
                                </td>
                                <td className={'item-center-align-td'} style={{display: 'flex'}}>
                                    <p className={'grade_p grade_p_sm_table'}
                                       style={{
                                           border: `1px solid ${return_grade_color(item.grade)}`,
                                           color: return_grade_color(item.grade),
                                           backgroundColor: return_bg_grade_color(item.grade)
                                       }}>
                                        {item.grade}
                                    </p>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CompanyOverviewBottomTable;