import axios from "axios";
import Config from "../../Config";
import {useQuery} from "@tanstack/react-query";
import React, {useEffect, useState} from 'react';
import InvestorCard from "./InvestorCard";
import HoldingsTable from "./HoldingsTable";
import HoldingDonutChart from "./HoldingDonutChart";
import SectorWeight from "../ETF/SectorWeight";
import {return_strong_num} from "../Utils";

const get_holding_sectors_data = (sector) => {
    if (sector.length > 0) {
        return axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/holding-sector-data/`, sector, Config());
    }
}

const get_investor = async (name) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/investor/${name}/`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}

function Holdings({name}) {

    const [investor, setInvestor] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    const [holdingData, setHoldingData] = useState([])

    const {data: investorReturn, isInvestorLoadingReturn} = useQuery({
        queryKey: [`get_investor`, name],
        queryFn: () => get_investor(name),
        staleTime: 5 * 60000
    })

    const [sector, setSector] = useState([])

    const {data: sectorList} = useQuery({
        queryKey: [`investor`, sector],
        queryFn: () => get_holding_sectors_data(sector),
        staleTime: 5 * 60000
    })

    const [totalMarketValue, setTotalMarketValue] = useState(0)

    useEffect(() => {
        if (investorReturn && !isInvestorLoadingReturn) {
            setInvestor(investorReturn)

            setIsLoading(false)

            const holdings = investorReturn.holdings

            let sectors = []
            let totalMarketValue = 0

            holdings.sort((a, b) => b.weight - a.weight).map(item => {
                if (item.putCallShare === "Share") {
                    sectors.push(item.code)
                    totalMarketValue += parseInt(item.marketValue)
                }
            })

            setTotalMarketValue(totalMarketValue)

            setSector(sectors)
            setHoldingData(holdings)
        }
    }, [investorReturn, isInvestorLoadingReturn])

    const [sectorData, setSectorData] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        if (sectorList && totalMarketValue > 0) {

            const data = sectorList.data

            const sectorDict = {
                'Basic Materials': 0,
                'Energy': 0,
                'Real Estate': 0,
                'Communication Services': 0,
                'Financial Services': 0,
                'Utilities': 0,
                'Consumer Cyclicals': 0,
                'Healthcare': 0,
                'Technology': 0,
                'Consumer Defensive': 0,
                'Industrials': 0
            }

            data.map((item) => {
                const itemData = holdingData.find(holding => holding.code === item.company_code);
                if (itemData && sectorDict[item.sector] !== undefined) {
                    sectorDict[item.sector] += parseInt(itemData.marketValue)
                }
            })

            Object.keys(sectorDict).map((key) => {
                sectorDict[key] = return_strong_num((sectorDict[key] / totalMarketValue) * 100)
            })

            const sortedArray = Object.entries(sectorDict)
                .sort((a, b) => b[1] - a[1]);

            const sortedSectorDict = Object.fromEntries(sortedArray);

            let _labels = []
            let _series = []

            Object.keys(sortedSectorDict).forEach(function (key, index) {
                _labels.push(key)
                _series.push(sortedSectorDict[key])
            });

            setLabels(_labels)
            setSectorData(_series)
        }
    }, [sectorList, totalMarketValue])

    return (
        <>
            {isLoading ?
                <div style={{width: '100%', height: '100%', display: ' flex'}}>
                    <div className="spinner-grow text-warning" role="status"
                         style={{margin: "auto", width: "5rem", height: "5rem"}}>
                    </div>
                </div>
                :
                <>
                    <div className="row my-3">
                        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className={'investor-holding'}>
                                <InvestorCard investor={investor}/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 px-1">
                            <HoldingDonutChart holdingData={holdingData}/>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className={'etf-profiler-stat-table-container'} style={{padding: 0}}>
                                <div className={'index-stat-table-title'}>
                                    <p className={'selected'}>Sector Weights</p>
                                </div>
                                <SectorWeight data={sectorData} labels={labels}/>
                            </div>
                        </div>
                    </div>

                    {investor.CIK && investor.date &&
                        <HoldingsTable holdingData={investor.holdings} name={name} CIK={investor.CIK} holdingDate={investor.date}/>
                    }
                </>
            }
        </>
    );
}

export default Holdings;
