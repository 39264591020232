import DashboardLayout from "../Layout/DashboardLayout";
import EtfTab from "../Container/Tabs/LandingPageTabs/ETFTab/ETFTab";
import {Helmet} from "react-helmet-async";
import React from "react";


const Etf = () => {
    return (
        <>
            <Helmet>
                <title>ETF</title>
                <meta name={"description"}
                      content={"ETF: Discover a range of ETFs and find the one that fits your investment goals."}/>
            </Helmet>
            <DashboardLayout>
                <EtfTab/>
            </DashboardLayout>
        </>
    );
}

export default Etf;
