import React, {useContext} from 'react';
import Select from "react-select";
import {
    get_table_td,
    selected_table_styles, sortDataByIndex
} from "./Utils";
import {ThemeContext} from "../Context/ThemeContext";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {TablePagination} from "@material-ui/core";

function LgSelectableTable({
                               columns,
                               setColumns,
                               tableData,
                               setTableData,

                               selectableField = false,
                               defaultColumns,
                               options,
                               handleChange,

                               inputField = false,
                               inputOnChange,

                               pagination = false,
                               rowsPerPage,
                               page,
                               handleChangePage,
                               handleChangeRowsPerPage,
                               count,
                               tableContainerClass = ""
                           }) {

    const {darkTheme} = useContext(ThemeContext);

    const {webTheme} = useContext(ThemeContext);

    const selectedTableStyles = selected_table_styles(webTheme);

    return (
        <div className={tableContainerClass}
             style={{position: 'relative', width: '100%', height: '100%'}}>

            {selectableField &&
                <Select
                    onChange={handleChange}
                    isClearable={options.some((v) => !v.isFixed)}
                    closeMenuOnSelect={false}
                    defaultValue={defaultColumns}
                    styles={selectedTableStyles}
                    options={options}
                    isMulti={true}
                    theme={(theme) => ({
                        ...theme, color: {
                            ...theme.colors, primary: 'black'
                        }
                    })}
                />
            }

            {inputField &&
                <input
                    placeholder={'Search for Components'}
                    className={'form-control indices-search'}
                    onChange={inputOnChange}
                />
            }

            <div className="table-responsive generic-lg-table-container">
                <table className="table generic-lg-table indices-lg-table">
                    <thead>
                    <tr>
                        {columns.map((column, index) => {
                            return (
                                <th key={index} className={column.className} onClick={() => {
                                    if (column.sort) {
                                        sortDataByIndex(index, column.index, column.state, columns, setColumns, tableData, setTableData)
                                    }
                                }}>
                                    {column.label} {column.sort && (column.state ? <BsArrowUp/> : <BsArrowDown/>)}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {tableData.map((row, index) => {
                        return (
                            <>
                                <tr className={row['rowClass']}>
                                    {columns.map((column, index) => {
                                        return get_table_td(row, column)
                                    })}
                                </tr>
                                <div style={{marginTop: 3, width: "100%"}}/>
                            </>
                        )
                    })}
                    </tbody>
                </table>

                {pagination &&
                    <TablePagination
                        style={{color: darkTheme ? 'white' : 'black'}}
                        rowsPerPageOptions={false}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </div>

        </div>
    );
}

export default LgSelectableTable;