import React from 'react';
import {numberWithCommas, return_strong_num, return_td_color, return_value_in_million} from "../../../Utils";
import {IoMdArrowDropdownCircle, IoMdArrowDropupCircle} from "react-icons/io";
import clsx from "clsx";

function FinancialOverviewBarTab({
                                     number,
                                     text,
                                     year1,
                                     year3,
                                     showYear3 = true,
                                     year5,
                                     showYear5 = true,
                                     expectedCARG,
                                     fairValue = false,
                                     expected = false,
                                     avg = false,
                                     valuation_class=false,
                                     dividend_class=false,
                                 }) {
    return (
        <div className={clsx('financial-overview-bar-tab', fairValue && 'fair-overview-bar-tab', valuation_class && "valuation-tabs", dividend_class && "dividend-tabs")}>
            {!fairValue &&
                <p className={'financial-overview-bar-tab-number'}>{numberWithCommas(return_value_in_million(number))}</p>
            }
            <p className={'financial-overview-bar-tab-text'}>{text}</p>

            {expected ?
                <>
                    <div className="financial-overview-bar-container">
                            <div className={'financial-overview-bar-per-container'}
                                 style={{
                                     padding: "6px",
                                     fontSize: 12,
                                     margin: "12px auto 0 auto",
                                     color: return_td_color(expectedCARG * 100),
                                     backgroundColor: (expectedCARG === null || return_strong_num(expectedCARG * 100) === '0.00' || return_strong_num(expectedCARG * 100) === '-100.00') ? '#89898966' : return_strong_num(expectedCARG * 100) > 0 ? '#4ebe9629' : '#d84f6829',
                                 }}
                            >
                                {return_strong_num(expectedCARG * 100) > 0 ? <IoMdArrowDropupCircle/> :
                                    <IoMdArrowDropdownCircle/>}
                                &#160;{return_strong_num(expectedCARG * 100)} %
                            </div>
                    </div>
                </>
                :
                <>
                    {!fairValue &&
                        <div className="financial-overview-bar-container">
                            <p className={'financial-overview-bar-per-container-meta'}>1 Year</p>
                            {(return_strong_num(year1 * 100) && return_strong_num(year1 * 100) !== '0.00')
                                ?
                                <div className={'financial-overview-bar-per-container'}
                                     style={{
                                         color: return_td_color(return_strong_num(year1 * 100)),
                                         backgroundColor: return_strong_num(year1 * 100) > 0 ? '#4ebe9629' : '#d84f6829'
                                     }}
                                >
                                    {return_strong_num(year1) > 0 ? <IoMdArrowDropupCircle/> :
                                        <IoMdArrowDropdownCircle/>}
                                    &#160;{return_strong_num(year1 * 100)} %
                                </div>
                                :
                                <div className={'financial-overview-bar-per-container'}>
                                    <p className={'financial-overview-bar-per-container-none'}>-</p>
                                </div>
                            }
                        </div>
                    }

                    {showYear3 &&
                        <div className="financial-overview-bar-container">
                            <p className={'financial-overview-bar-per-container-meta'}>{avg ? "3Y Avg" : "3Y CARG"}</p>
                            {(return_strong_num(year3 * 100) && return_strong_num(year3 * 100) !== '0.00')
                                ?
                                <div className={'financial-overview-bar-per-container'}
                                     style={{
                                         color: return_td_color(return_strong_num(year3 * 100)),
                                         backgroundColor: return_strong_num(year3 * 100) > 0 ? '#4ebe9629' : '#d84f6829'
                                     }}
                                >
                                    {return_strong_num(year3) > 0 ? <IoMdArrowDropupCircle/> :
                                        <IoMdArrowDropdownCircle/>}
                                    &#160;{return_strong_num(year3 * 100)} %
                                </div>
                                :
                                <div className={'financial-overview-bar-per-container'}>
                                    <p className={'financial-overview-bar-per-container-none'}>-</p>
                                </div>
                            }
                        </div>
                    }

                    {showYear5 &&
                        <div className="financial-overview-bar-container">
                            <p className={'financial-overview-bar-per-container-meta'}>{avg ? "5Y Avg" : "5Y CARG"}</p>
                            {(return_strong_num(year5 * 100) && return_strong_num(year5 * 100) !== '0.00')
                                ?
                                <div className={'financial-overview-bar-per-container'}
                                     style={{
                                         color: return_td_color(return_strong_num(year5 * 100)),
                                         backgroundColor: return_strong_num(year5 * 100) > 0 ? '#4ebe9629' : '#d84f6829'
                                     }}
                                >
                                    {return_strong_num(year5) > 0 ? <IoMdArrowDropupCircle/> :
                                        <IoMdArrowDropdownCircle/>}
                                    &#160;{return_strong_num(year5 * 100)} %
                                </div>
                                :
                                <div className={'financial-overview-bar-per-container'}>
                                    <p className={'financial-overview-bar-per-container-none'}>-</p>
                                </div>
                            }
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default FinancialOverviewBarTab;