import FinancialsTable from "../CompanyTabs/FinancialTabs/FinancialsTable";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";
import {divide, numberWithCommas, return_strong_num, return_value_in_million} from "../Utils";

function FairValueInfoTable({code, exchange}) {

    const [groupedData, setGroupedData] = useState(null)

    useEffect(() => {
        async function fetchFairValueFinancialOutlook() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-financial-outlook/${code}/${exchange}/`, Config());
                const fairValueFinancialOutlook = response.data;

                let data = {
                    dates: [],
                    content: {
                        financials: {
                            name: `Financials (${fairValueFinancialOutlook['trading_curr']})`,
                            data: {
                                'Total Revenue': [],
                                '% Growth Yearly': [],

                                'Net Income': [],
                                '% Profit Margin': [],

                                'Free Cash Flow': [],
                                '% FCF Margin': [],

                                'Cash & Equivalents': [],

                                'Total Debt': []
                            }
                        }
                    }
                }

                fairValueFinancialOutlook.financial.map((item, index) => {
                    data['dates'].push(item.date)

                    data['content']['financials']['data'][`Total Revenue`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['totalRevenue']))))
                    data['content']['financials']['data'][`% Growth Yearly`].push(return_strong_num(item['growth']['totalRevenue'] * 100))

                    data['content']['financials']['data'][`Net Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['netIncome']))))
                    data['content']['financials']['data'][`% Profit Margin`].push(return_strong_num(item['growth']['netIncome'] * 100))

                    data['content']['financials']['data']['Free Cash Flow'].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['freeCashFlow']))))
                    data['content']['financials']['data']['% FCF Margin'].push(return_strong_num(item['growth']['freeCashFlow'] * 100))

                    data['content']['financials']['data'][`Cash & Equivalents`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['cashAndShortTermInvestments']))))

                    data['content']['financials']['data'][`Total Debt`].push(numberWithCommas(return_value_in_million(return_strong_num(item['value']['totalDebt']))))
                })

                setGroupedData(data)
            } catch (error) {
                console.error('Error fetching data:', error);
                setGroupedData(null)
            }
        }

        void fetchFairValueFinancialOutlook()
    }, [code, exchange])

    return (
        <>
            {groupedData &&
                <FinancialsTable
                    highlightHeadings={["Cash & Equivalents", "Total Debt", "ROIC", "P/E", "P/FCF", "P/S", "Debt/Equity", "Debt/Assets"]}
                    groupedData={groupedData}
                    tableClassName={'company-overview-fair-value-table'}
                    thClassName={"company-overview-financial-table-field-names-fair-value"}/>
            }

        </>
    );
}

export default FairValueInfoTable;
