import React from 'react';
import ProfileSubscription from "./ProfileSubscription";
import {BsPencil} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function ProfileSubscriptionTab({}) {

    const navigate = useNavigate()
    const user = useSelector(state => state.actions.user);

    return (
        <div className="profile-container">
            <div style={{display: 'flex', marginBottom: 15}}>
                <p className={'profile-header'}>Subscription</p>
                <BsPencil className={'profile-pencil'}
                          onClick={() => {
                              navigate('/payment')
                          }}/>
            </div>

            <ProfileSubscription subscription={user.subscription}/>
        </div>
    );
}

export default ProfileSubscriptionTab;