import axios from "axios";
import clsx from "clsx";
import ReactApexChart from "react-apexcharts";
import {ThemeContext} from "../../../Context/ThemeContext";
import React, {useContext, useEffect, useState} from 'react';
import Config from "../../../Config";
import {useQuery} from "@tanstack/react-query";

const get_chart_data = async (code, exchange, type, activeControl) => {
    if (code && exchange) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-chart-data/${code}/${exchange}/${type}/${activeControl}/`, Config());
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

function SummaryTabGraphContainer({chartData, container, innerContainer}) {
    const {webTheme} = useContext(ThemeContext);

    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29",
        grid: webTheme !== 'dark'
    })

    const [graphVarChange, setGraphVarChange] = useState(false)

    const [activeControl, setActiveControl] = useState('prices_5_years')
    const [loading, setLoading] = useState(true)

    const options = {
        chart: {
            width: '100%',
            type: 'area',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: dates
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val
                }
            }
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return value.toFixed(2)
                }
            },
            fillSeriesColor: false
        }
    }

    const updateData = (timeline) => {
        switch (timeline) {
            case 'prices_30_days':
                setActiveControl('prices_30_days')
                break
            case 'prices_1_year':
                setActiveControl('prices_1_year')
                break
            case 'prices_5_years':
                setActiveControl('prices_5_years')
                break
            case 'prices_10_years':
                setActiveControl('prices_10_years')
                break
            case 'all':
                setActiveControl('all')
                break
            default:
        }
        setLoading(true)
    }

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29", grid: webTheme !== 'dark'})
        setLoading(true)
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setLoading(false)
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const series = [{
        name: '',
        data: data,
        color: "#03f3a0"
    }]

    const {data: chartDataReturn, isLoading: chartDataIsLoading} = useQuery({
        queryKey: [`get-chart-data/${chartData.code}/${chartData.exchange}/${chartData.type}/${activeControl}/`, chartData.code, chartData.exchange, chartData.type, activeControl],
        queryFn: () => get_chart_data(chartData.code, chartData.exchange, chartData.type, activeControl),
        staleTime: 5 * 60000
    })

    useEffect(() => {
        if (!chartDataIsLoading) {
            setDates(chartDataReturn.dates)
            setData(chartDataReturn.prices)
            setLoading(false)
        }
    }, [chartDataReturn, chartDataIsLoading])

    return (
        <div className={container}>
            <div className={innerContainer}>
                <div id="chart">
                    <div className="toolbar">
                        <p className={'market-overview-chart-name'}>{chartData.name}</p>
                        <button id="one_month"
                                onClick={() => updateData('prices_30_days')}
                                className={clsx('btn graph-controls', activeControl === "prices_30_days" ? "btn-danger" : "btn-warning")}
                                style={{color: activeControl === "prices_30_days" && 'white'}}
                        >
                            1M
                        </button>
                        &nbsp;
                        <button id="one_year"
                                onClick={() => updateData('prices_1_year')}
                                className={clsx('btn graph-controls', activeControl === "prices_1_year" ? "btn-danger" : "btn-warning")}
                                style={{color: activeControl === "prices_1_year" && 'white'}}>
                            1Y
                        </button>
                        &nbsp;
                        <button id="5_year"
                                onClick={() => updateData('prices_5_years')}
                                className={clsx('btn graph-controls', activeControl === "prices_5_years" ? "btn-danger" : "btn-warning")}
                                style={{color: activeControl === "prices_5_years" && 'white'}}>
                            5Y
                        </button>
                        &nbsp;
                        <button id="10_year"
                                onClick={() => updateData('prices_10_years')}
                                className={clsx('btn graph-controls', activeControl === "prices_10_years" ? "btn-danger" : "btn-warning")}
                                style={{color: activeControl === "prices_10_years" && 'white'}}>
                            10Y
                        </button>
                        &nbsp;
                    </div>
                </div>
                {loading ?
                    <div style={{width: '100%', height: '85%', display: 'flex'}}>
                        <div className="spinner-grow text-warning m-auto" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <ReactApexChart options={options} series={series} type={'line'} width={'100%'}
                                    height={'85%'}/>
                }
            </div>
        </div>
    );
}

export default SummaryTabGraphContainer;