import axios from "axios";
import IndexProfile from "./IndexProfile";
import {useEffect, useState} from "react";
import {a11yProps, TabPanel} from "../../Utils";
import SwipeableViews from "react-swipeable-views";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import IndicesTab from "../LandingPageTabs/IndicesTab/IndicesTab";
import Config from "../../../Config";
import {useParams} from "react-router-dom";

const IndicesTabs = () => {
    const {indexcode} = useParams()

    useEffect(() => {
        if (indexcode) {
            change_index(indexcode)
        }
    }, [indexcode]);

    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setMainTab(newValue);
    };
    const handleChangeIndex = (index) => {
        setMainTab(index);
    };

    const allIndices = [
        {
            name: "S&P 500",
            symbol: "GSPC",
            displayUrl: "www.nyse.com",
            realUrl: 'https://www.nyse.com/',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 0
        },
        {
            name: "S&P 100",
            symbol: "SP100",
            displayUrl: "www.nyse.com",
            realUrl: 'https://www.nyse.com',
            country: "USA",
            country_code: "US",
            tab: false,
            index: 1
        },
        {
            name: "Dow Jones",
            symbol: "DJI",
            displayUrl: "www.nyse.com",
            realUrl: 'https://www.nyse.com',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 2
        },
        {
            name: "Nasdaq 100",
            symbol: "NDX",
            displayUrl: "www.nasdaq.com",
            realUrl: 'https://www.nasdaq.com',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 3
        },
        {
            name: "Russell 1000",
            symbol: "RUI",
            displayUrl: "www.ftserussell.com",
            realUrl: 'www.ftserussell.com/products/indices/russell-us',
            country: "USA",
            country_code: "US",
            tab: false,
            index: 4
        },
        {
            name: "Russell 2000",
            symbol: "RUT",
            displayUrl: "www.ftserussell.com",
            realUrl: 'www.ftserussell.com/products/indices/russell-us',
            country: "USA",
            country_code: "US",
            tab: false,
            index: 5
        },

        {
            name: "Europe Stoxx 600",
            symbol: "STOXX",
            displayUrl: "www.stoxx.com",
            realUrl: 'https://qontigo.com/',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 6
        },
        {
            name: "EuroStoxx 50 Index",
            symbol: "STOXX50E",
            displayUrl: "www.stoxx.com",
            realUrl: 'https://qontigo.com/',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 7
        },
        {
            name: "DAX40",
            symbol: "GDAXI",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: true,
            index: 8
        },
        {
            name: "DAX Midcap",
            symbol: "MDAXI",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 9
        },
        {
            name: "DAX Smallcap",
            symbol: "SDAXI",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 10
        },
        {
            name: "TecDAX",
            symbol: "TECDAX",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 11
        },
        {
            name: "HDAX",
            symbol: "GDAXHI",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: false,
            index: 12
        },

        {
            name: "FTSE 100",
            symbol: "FTSE",
            displayUrl: "www.londonstockexchange.com",
            realUrl: 'https://www.londonstockexchange.com',
            country: "United Kingdom",
            country_code: "LSE",
            tab: true,
            index: 13
        },
        {
            name: "FTSE 250",
            symbol: "FTMC",
            displayUrl: "www.londonstockexchange.com",
            realUrl: 'https://www.londonstockexchange.com',
            country: "United Kingdom",
            country_code: "LSE",
            tab: false,
            index: 14
        },
        {
            name: "FTSE 350",
            symbol: "FTLC",
            displayUrl: "www.londonstockexchange.com",
            realUrl: 'https://www.londonstockexchange.com',
            country: "United Kingdom",
            country_code: "LSE",
            tab: false,
            index: 15
        },
        {
            name: "FTSE All-Share",
            symbol: "FTAS",
            displayUrl: "www.londonstockexchange.com",
            realUrl: 'https://www.londonstockexchange.com',
            country: "United Kingdom",
            country_code: "LSE",
            tab: false,
            index: 16
        },

        {
            name: "CAC 40",
            symbol: "FCHI",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "France",
            country_code: "PA",
            tab: true,
            index: 17
        },
        {
            name: "CAC All-Tradable",
            symbol: "CACT",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "France",
            country_code: "PA",
            tab: false,
            index: 18
        },


        {
            name: "FTSE MIB",
            symbol: "SPMIB",
            displayUrl: "www.borsaitaliana.it",
            realUrl: 'https://www.borsaitaliana.it',
            country: "Italy",
            country_code: "MI",
            tab: true,
            index: 19
        },


        {
            name: "IBEX 35",
            symbol: "IBEX",
            displayUrl: "www.bolsamadrid.es",
            realUrl: 'https://www.bolsamadrid.es/ing/aspx/Portada/Portada.aspx',
            country: "Spain",
            country_code: "MC",
            tab: false,
            index: 20
        },

        {
            name: "AEX 25",
            symbol: "AEX",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "Netherlands",
            country_code: "AS",
            tab: true,
            index: 21
        },

        {
            name: "ATX 20",
            symbol: "ATX",
            displayUrl: "www.wienerborse.at",
            realUrl: 'https://www.wienerborse.at',
            country: "Austria",
            country_code: "VI",
            tab: false,
            index: 22
        },

        {
            name: "BEL 20",
            symbol: "BEL20",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "Belgium",
            country_code: "",
            tab: false,
            index: 23
        },

        {
            name: "OMX Copenhagen 20",
            symbol: "OMXC20",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Denmark",
            country_code: "CO",
            tab: false,
            index: 24
        },
        {
            name: "OMX Copenhagen All Shares",
            symbol: "OMXCGI",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Denmark",
            country_code: "CO",
            tab: false,
            index: 25
        },

        {
            name: "OMX Helsinki 25",
            symbol: "OMXH25",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Finland",
            country_code: "HE",
            tab: false,
            index: 26
        },
        {
            name: "OMX Helsinki All Shares",
            symbol: "OMXHPI",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Finland",
            country_code: "HE",
            tab: false,
            index: 27
        },

        {
            name: "OBX Oslo 25",
            symbol: "OBX",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Norway",
            country_code: "OL",
            tab: false,
            index: 28
        },
        {
            name: "OBX Oslo All Shares",
            symbol: "OSEAX",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Norway",
            country_code: "OL",
            tab: false,
            index: 29
        },


        {
            name: "OMX Stockholm 30",
            symbol: "OMXS30GI",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Sweden",
            country_code: "ST",
            tab: false,
            index: 30
        },
        {
            name: "OMX Stockholm All Shares",
            symbol: "OMXSPI",
            displayUrl: "www.nasdaqomxnordic.com",
            realUrl: 'http://www.nasdaqomxnordic.com/',
            country: "Sweden",
            country_code: "ST",
            tab: false,
            index: 31
        },

        {
            name: "ISEQ 20",
            symbol: "IETP",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "Ireland",
            country_code: "IR",
            tab: false,
            index: 32
        },

        {
            name: "Tel Aviv 35",
            symbol: "TA35",
            displayUrl: "www.tase.co.il",
            realUrl: 'www.tase.co.il/en',
            country: "Israel",
            country_code: "TA",
            tab: false,
            index: 33
        },
        {
            name: "Tel Aviv 100",
            symbol: "TA125",
            displayUrl: "www.tase.co.il",
            realUrl: 'www.tase.co.il/en',
            country: "Israel",
            country_code: "TA",
            tab: false,
            index: 34
        },

        {
            name: "WIG 30",
            symbol: "WIG30",
            displayUrl: "www.gpw.pl",
            realUrl: 'https://www.gpw.pl/en-home',
            country: "Poland",
            country_code: "WAR",
            tab: false,
            index: 35
        },
        {
            name: "WIG All Shares",
            symbol: "WIG",
            displayUrl: "www.gpw.pl",
            realUrl: 'https://www.gpw.pl/en-home',
            country: "Poland",
            country_code: "WAR",
            tab: false,
            index: 36
        },

        {
            name: "PSI 20",
            symbol: "PSI20",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "Portugal",
            country_code: "LS",
            tab: false,
            index: 37
        },

        {
            name: "Swiss Market Index",
            symbol: "SSMI",
            displayUrl: "www.six-group.com",
            realUrl: 'https://www.six-group.com/en/products-services/the-swiss-stock-exchange/market-data/indices/equity-indices.html',
            country: "Switzerland",
            country_code: "SW",
            tab: true,
            index: 38
        },
        {
            name: "Swiss Performance Index",
            symbol: "SSHI",
            displayUrl: "www.six-group.com",
            realUrl: 'https://www.six-group.com/en/products-services/the-swiss-stock-exchange/market-data/indices/equity-indices.html',
            country: "Switzerland",
            country_code: "SW",
            tab: false,
            index: 39
        },

        {
            name: "BIST 30",
            symbol: "XU030",
            displayUrl: "www.borsaistanbul.com",
            realUrl: 'https://www.borsaistanbul.com/en',
            country: "Turkey",
            country_code: "IS",
            tab: false,
            index: 40
        },
        {
            name: "BIST 100",
            symbol: "XU100",
            displayUrl: "www.borsaistanbul.com",
            realUrl: 'https://www.borsaistanbul.com/en',
            country: "Turkey",
            country_code: "IS",
            tab: false,
            index: 41
        },

        {
            name: "ASX 50",
            symbol: "AXAF",
            displayUrl: "www.asx.com.au",
            realUrl: 'www2.asx.com.au',
            country: "Australia",
            country_code: "AU",
            tab: true,
            index: 42
        },
        {
            name: "ASX 100",
            symbol: "ATOI",
            displayUrl: "www.asx.com.au",
            realUrl: 'https://www2.asx.com.au',
            country: "Australia",
            country_code: "AU",
            tab: false,
            index: 43
        },
        {
            name: "ASX 200",
            symbol: "AXAT",
            displayUrl: "www.asx.com.au",
            realUrl: 'https://www2.asx.com.au',
            country: "Australia",
            country_code: "AU",
            tab: false,
            index: 44
        },

        {
            name: "IBX 50",
            symbol: "IBX50",
            displayUrl: "www.b3.com.br",
            realUrl: 'https://www.b3.com.br/en_us',
            country: "Brazil",
            country_code: "SA",
            tab: true,
            index: 45
        },
        {
            name: "Bovespa",
            symbol: "BVSP",
            displayUrl: "www.b3.com.br",
            realUrl: 'https://www.b3.com.br/en_us',
            country: "Brazil",
            country_code: "SA",
            tab: false,
            index: 46
        },
        {
            name: "TSX 60",
            symbol: "GSPTSE",
            displayUrl: "www.tsx.com",
            realUrl: 'https://www.tsx.com',
            country: "Canada",
            country_code: "TO",
            tab: false,
            index: 47
        },
        {
            name: "CLX IPSA",
            symbol: "SPIPSA",
            displayUrl: "www.bolsadesantiago.com",
            realUrl: 'https://www.bolsadesantiago.com',
            country: "Chile",
            country_code: "SN",
            tab: false,
            index: 48
        },

        {
            name: "Nikkei 225",
            symbol: "N225",
            displayUrl: "www.jpx.co.jp",
            realUrl: 'https://www.jpx.co.jp/english/',
            country: "Japan",
            country_code: "TSE",
            tab: false,
            index: 49
        },

        {
            name: "SZSE Composite",
            symbol: "SZSC",
            displayUrl: "www.sse.com.cn",
            realUrl: 'https://english.sse.com.cn',
            country: "China",
            country_code: "SHE",
            tab: false,
            index: 50
        },
        {
            name: "SSE 180",
            symbol: "SSE180",
            displayUrl: "www.sse.com.cn",
            realUrl: 'https://english.sse.com.cn',
            country: "China",
            country_code: "SHE",
            tab: true,
            index: 51
        },

        {
            name: "Hang Seng",
            symbol: "HSI",
            displayUrl: "www.hkex.com.hk",
            realUrl: 'https://www.hkex.com.hk',
            country: "Hong Kong",
            country_code: "HK",
            tab: true,
            index: 52
        },
        {
            name: "Hang Seng China Enterprise",
            symbol: "HSCE",
            displayUrl: "www.hkex.com.hk",
            realUrl: 'https://www.hkex.com.hk',
            country: "Hong Kong",
            country_code: "HK",
            tab: true,
            index: 53
        },
        {
            name: "Hang Seng Composite",
            symbol: "HSCI",
            displayUrl: "www.hkex.com.hk",
            realUrl: 'https://www.hkex.com.hk',
            country: "Hong Kong",
            country_code: "HK",
            tab: true,
            index: 54
        },
        {
            name: "Hang Seng Tech",
            symbol: "HSTECH",
            displayUrl: "www.hkex.com.hk",
            realUrl: 'https://www.hkex.com.hk',
            country: "Hong Kong",
            country_code: "HK",
            tab: true,
            index: 55
        },


        {
            name: "Nifty 50",
            symbol: "NSEI",
            displayUrl: "www.nseindia.com",
            realUrl: 'www.nseindia.com',
            country: "India",
            country_code: "NSE",
            tab: true,
            index: 56
        },

        {
            name: "Kosdaq Composite",
            symbol: "KOSDAQ",
            displayUrl: "www.global.krx.co.kr",
            realUrl: 'global.krx.co.kr',
            country: "Korea",
            country_code: "KQ",
            tab: false,
            index: 57
        },

        {
            name: "FBM KLCI",
            symbol: "KLSE",
            displayUrl: "www.bursamalaysia.com",
            realUrl: 'www.bursamalaysia.com',
            country: "Malaysia",
            country_code: "KLSE",
            tab: false,
            index: 58
        },
        {
            name: "Karachi 100",
            symbol: "KSE100",
            displayUrl: "www.psx.com.pk",
            realUrl: 'www.psx.com.pk',
            country: "Pakistan",
            country_code: "KAR",
            tab: false,
            index: 59
        },

        {
            name: "PSEi",
            symbol: "PSEI",
            displayUrl: "www.pse.com.ph",
            realUrl: 'www.pse.com.ph',
            country: "Philippines",
            country_code: "PSE",
            tab: false,
            index: 60
        },

        {
            name: "Tadawul All Share",
            symbol: "TASI",
            displayUrl: "www.saudiexchange.sa",
            realUrl: 'https://www.saudiexchange.sa/wps/portal/tadawul/home/',
            country: "Saudi Arabia",
            country_code: "SR",
            tab: false,
            index: 61
        },

        {
            name: "Taiwan Weighted Index",
            symbol: "TWII",
            displayUrl: "www.twse.com",
            realUrl: 'https://www.twse.com.tw/en/',
            country: "Taiwan",
            country_code: "TW",
            tab: false,
            index: 62
        },

        {
            name: "Thailand SET Index",
            symbol: "SET",
            displayUrl: "www.set.or.th",
            realUrl: 'https://www.set.or.th/en/home',
            country: "Thailand",
            country_code: "BK",
            tab: false,
            index: 63
        },
    ];

    const [tabs, setTabs] = useState([
        {
            name: "DAX40",
            symbol: "GDAXI",
            displayUrl: "www.deutsche-boerse.com",
            realUrl: 'https://www.deutsche-boerse.com',
            country: "Germany",
            country_code: "XETRA",
            tab: true,
            index: 8
        },
        {
            name: "S&P 500",
            symbol: "GSPC",
            displayUrl: "www.nyse.com",
            realUrl: 'https://www.nyse.com/',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 0
        },
        {
            name: "Dow Jones",
            symbol: "DJI",
            displayUrl: "www.nyse.com",
            realUrl: 'https://www.nyse.com',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 2
        },
        {
            name: "Nasdaq 100",
            symbol: "NDX",
            displayUrl: "www.nasdaq.com",
            realUrl: 'https://www.nasdaq.com',
            country: "USA",
            country_code: "US",
            tab: true,
            index: 3
        },
        {
            name: "CAC 40",
            symbol: "FCHI",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "France",
            country_code: "PA",
            tab: true,
            index: 17
        },
        {
            name: "FTSE 100",
            symbol: "FTSE",
            displayUrl: "www.londonstockexchange.com",
            realUrl: 'https://www.londonstockexchange.com',
            country: "United Kingdom",
            country_code: "LSE",
            tab: true,
            index: 13
        },
        {
            name: "FTSE MIB",
            symbol: "SPMIB",
            displayUrl: "www.borsaitaliana.it",
            realUrl: 'https://www.borsaitaliana.it',
            country: "Italy",
            country_code: "MI",
            tab: true,
            index: 19
        },
        {
            name: "IBX 50",
            symbol: "IBX50",
            displayUrl: "www.b3.com.br",
            realUrl: 'https://www.b3.com.br/en_us',
            country: "Brazil",
            country_code: "SA",
            tab: true,
            index: 45
        },
        {
            name: "Swiss Market Index",
            symbol: "SSMI",
            displayUrl: "www.six-group.com",
            realUrl: 'https://www.six-group.com/en/products-services/the-swiss-stock-exchange/market-data/indices/equity-indices.html',
            country: "Switzerland",
            country_code: "SW",
            tab: true,
            index: 38
        },
        {
            name: "AEX 25",
            symbol: "AEX",
            displayUrl: "www.euronext.com",
            realUrl: 'https://live.euronext.com/en/products/indices/',
            country: "Netherlands",
            country_code: "AS",
            tab: true,
            index: 21
        },
        {
            name: "ASX 50",
            symbol: "AXAF",
            displayUrl: "www.asx.com.au",
            realUrl: 'www2.asx.com.au',
            country: "Australia",
            country_code: "AU",
            tab: true,
            index: 42
        },
        {
            name: "SSE 180",
            symbol: "SSE180",
            displayUrl: "www.sse.com.cn",
            realUrl: 'https://english.sse.com.cn',
            country: "China",
            country_code: "SHE",
            tab: true,
            index: 51
        },
        {
            name: "Nifty 50",
            symbol: "NSEI",
            displayUrl: "www.nseindia.com",
            realUrl: 'www.nseindia.com',
            country: "India",
            country_code: "NSE",
            tab: false,
            index: 52
        },
    ]);

    const [loadingIndexProfile, setLoadingIndexProfile] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(allIndices[5]);

    const [selectedIndexData, setSelectedIndexData] = useState({});

    const [loadingSelectedIndex, setLoadingSelectedIndex] = useState(true)
    const [mainTab, setMainTab] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);

    const change_index = (symbol) => {
        if (allIndices.filter(index => index.symbol === symbol).length > 0) {
            const _index = allIndices.filter(index => index.symbol === symbol)[0]
            setSelectedIndex(_index)
            setLoadingSelectedIndex(true)
            setLoadingIndexProfile(true)
            setMainTab(1)
        }
    }

    useEffect(() => {
        if (tabs.filter((tab) => tab.symbol === selectedIndex.symbol).length > 0) {
            for (let i = 0; i < tabs.length; i++) {
                if (tabs[i].symbol === selectedIndex.symbol) {
                    setCurrentTab(i)
                }
            }
        } else {
            setTabs([selectedIndex, ...tabs])
            setCurrentTab(0)
        }
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-index-profile/${selectedIndex.symbol}/${selectedIndex.country_code}/`, Config())
            .then(response => {
                setSelectedIndexData(response.data)
                setLoadingSelectedIndex(false)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [selectedIndex])

    return (
        <div className={"generic-tabs-container"}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={mainTab}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Global Indices" {...a11yProps(0)} />
                    <Tab label={"Index Profile"} {...a11yProps(1)} />
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={mainTab}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel className={'generic-tab-panel'} value={mainTab} index={0} dir={theme.direction}>
                    <IndicesTab change_index={change_index}/>
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={mainTab} index={1} dir={theme.direction}>
                    <IndexProfile allIndices={allIndices} currentTab={currentTab} setCurrentTab={setCurrentTab}
                                  selectedIndex={selectedIndex} selectedIndexData={selectedIndexData}
                                  loadingSelectedIndex={loadingSelectedIndex}
                                  setLoadingSelectedIndex={setLoadingSelectedIndex}
                                  setSelectedIndex={setSelectedIndex}
                                  change_index={change_index}
                                  loadingIndexProfile={loadingIndexProfile} tabs={tabs} setTabs={setTabs}
                                  />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default IndicesTabs;
