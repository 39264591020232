import {useState} from "react";
import ProfileTabs from "./ProfileTabs";
import FinancialTab from "./FinancialTab";
import {a11yProps, tab_locked, TabPanel} from "../Utils";
import Calender from "./CalenderTabs/Calender";
import SwipeableViews from "react-swipeable-views";
import CompanySummaryTab from "./CompanySummaryTab";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import AnalystForecast from "./AnalystForcastTab/AnalystForecast";
import RuleOfFortyAnalysis from "../RuleOfForty/RuleOfFortyAnalysis";
import ISqoreTab from "./iSqoreTab";
import Investors from "./InvestorTabs/Investors";
import FairValueTabs from "../FairValue/FairValueTabs";
import {FaLock} from "react-icons/fa";
import {useSelector} from "react-redux";

const CompanyTabs = ({logoURL, code, exchange}) => {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const user = useSelector(state => state.actions.user);

    let locked = tab_locked(isAuthenticated, user, code, exchange)

    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <div className={"generic-tabs-container"}>
                <Box display="flex" justifyContent="left" width="100%">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor={'primary'}
                        scrollButtons="auto"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Profile"
                             style={{}} {...a11yProps(1)} />
                        <Tab label="Financials"
                             {...a11yProps(2)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>iSqore</span>
                            </Box>
                        } {...a11yProps(3)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Fair Value</span>
                            </Box>
                        } {...a11yProps(4)} />
                        {/*<Tab label={*/}
                        {/*    <Box display="flex" alignItems="center">*/}
                        {/*        {locked && <FaLock style={{marginRight: 5}}/>}*/}
                        {/*        <span>Fair Value Temp</span>*/}
                        {/*    </Box>*/}
                        {/*} {...a11yProps(5)} />*/}
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Rule-of-40</span>
                            </Box>
                        } {...a11yProps(5)} />
                        <Tab label={
                            <Box display="flex" alignItems="center">
                                {locked && <FaLock style={{marginRight: 5}}/>}
                                <span>Analyst Forecast</span>
                            </Box>
                        } {...a11yProps(6)} />
                        <Tab label="Dividend"
                             {...a11yProps(7)} />
                        <Tab label="Ownership"
                             {...a11yProps(8)} />
                    </Tabs>
                </Box>
                <div className="tabsPanelContainer company-overview-panel">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        disabled={true}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel className={'generic-tab-panel company-overview-tab-panel'} value={value} index={0}
                                  dir={theme.direction}>
                            <CompanySummaryTab logoURL={logoURL} code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                            <ProfileTabs code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                            <FinancialTab code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={3} dir={theme.direction}>
                            <ISqoreTab logoURL={logoURL} code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={4} dir={theme.direction}>
                            <FairValueTabs code={code} exchange={exchange}/>
                        </TabPanel>

                        {/*<TabPanel className={'generic-tab-panel'} value={value} index={5} dir={theme.direction}>*/}
                        {/*    <FairValueTabTemp code={code} exchange={exchange}/>*/}
                        {/*</TabPanel>*/}

                        <TabPanel className={'generic-tab-panel'} value={value} index={5} dir={theme.direction}>
                            <RuleOfFortyAnalysis code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={6} dir={theme.direction}>
                            <AnalystForecast code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={7} dir={theme.direction}>
                            <Calender code={code} exchange={exchange}/>
                        </TabPanel>

                        <TabPanel className={'generic-tab-panel'} value={value} index={8} dir={theme.direction}>
                            <Investors code={code} exchange={exchange}/>
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </div>
        </>
    );
}

export default CompanyTabs;
