
function HighlightsTable({highlights, date}) {
    return (
        <div className={'company-overview-table-body-container company-overview-bottom-table-body-container'}>
            <table className={'table generic-table-body company-overview-sm-table-body'}>
                <thead>
                <tr>
                    <th>
                        Highlights
                    </th>
                    <th style={{textAlign: "right"}}>
                        {date}
                    </th>
                </tr>
                </thead>
                <tbody>
                {highlights.map(highlight => {
                    return (
                        <tr>
                            <td>
                                {highlight[0]}
                            </td>
                            <td style={{textAlign: "right"}}>
                                {highlight[1]}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

export default HighlightsTable;