import React, {useState} from 'react';
import {return_per_div, return_strong_num} from "../Utils";
import {BsPencilSquare} from "react-icons/bs";
import CompanyNotesModal from "../CompanyDetailTab/CompanyNotesModal";
import {Modal} from "@material-ui/core";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {useSelector} from "react-redux";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import SelectWatchListModal from "../Tabs/IndicesPageTabs/SelectWatchListModal";
import building from "../../assets/default-building.png";

function TopFlopsTableBody({companies, setCompanies, tableName, setChartData}) {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const [openNotesModal, setOpenNotesModal] = useState(false);

    const [notesCompany, setNotesCompany] = useState({
        code: "",
        exchange: "",
        name: "",
        currency: "",
        priceData: {
            price: 0,
            change: 0,
            changesPercentage: 0,
        }
    })

    const handleOpenNotesModal = (code, exchange, name, currency, price, perChange) => {
        setNotesCompany({
            code, exchange, name, currency,
            priceData: {
                price: price,
                change: return_strong_num((return_strong_num(price * perChange) / 100) * 100),
                changesPercentage: return_strong_num(perChange * 100)
            }
        })
        setOpenNotesModal(true)
    }

    const handleCloseNotesModal = () => {
        setOpenNotesModal(false)
    }

    const [selectedTicker, setSelectedTicker] = useState({})

    const save_ticker = (watchLists) => {
        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/finqube-api/save-ticker-bulk/`, {
            company_id: selectedTicker.company_id,
            watchLists: watchLists
        }, config)
            .then(response => {
                const updateData = companies.map(item => {
                    if (item.company_id === selectedTicker.company_id) {
                        return {
                            ...item,
                            watchlist: response.data['ticker_exists']
                        }
                    }
                    return item
                })
                setCompanies(updateData)

                toast.success('Watchlist Updated.', {duration: 1000})
                handleCloseWatchlistModal()
            })
            .catch(err => console.log(err.message))
    }

    const [openWatchlistModal, setOpenWatchlistModal] = useState(false)
    const handleOpenWatchlistModal = (item) => {
        setSelectedTicker(item)
        setOpenWatchlistModal(true)
    }
    const handleCloseWatchlistModal = () => {
        setOpenWatchlistModal(false)
    }

    return (
        <>
            <div className={'market-overview-summary-table-title'}
                 style={{padding: "5px 10px", borderRadius: 0}}>
                <p>{tableName}</p>
            </div>
            <div className={'market-overview-summary-table-body-container'}
                 style={{padding: "5px 10px", borderRadius: tableName === "Flops" ? "0 0 10px 10px" : "0"}}>
                <table className={'table generic-table-body'} style={{marginBottom: 5}}>
                    <tbody>
                    {companies.map((company, index) => {
                        const logoUrl = `${company.logoURL}?t=${new Date().getTime()}`;

                        return (
                            <tr key={index} style={{fontSize: 15, border: "none"}}>
                                <td style={{cursor: "pointer", width: 24}}
                                    onClick={() => {
                                        if (isAuthenticated) {
                                            handleOpenWatchlistModal(company)
                                        } else {
                                            navigate('/login/')
                                        }
                                    }}>
                                    {company.watchlist ? <AiFillStar
                                        style={{
                                            color: "#1BA2DA", fontSize: 16
                                        }}/> : <AiOutlineStar
                                        style={{
                                            color: "#1BA2DA", fontSize: 16
                                        }}/>}
                                </td>
                                <td style={{width: 24}}>
                                    <BsPencilSquare  style={{fontSize: 13}}
                                        className={'delete-watchlist-ticker'}
                                                    onClick={() => {
                                                        handleOpenNotesModal(
                                                            company.code,
                                                            company.exchange,
                                                            company.name,
                                                            company.currency,
                                                            company.price,
                                                            company.changesPercentage
                                                        )
                                                    }}/>
                                </td>
                                <td style={{cursor: "pointer", width: 95}}
                                    onClick={() => {
                                        setChartData({
                                            name: company.name,
                                            code: company.code,
                                            exchange: company.exchange,
                                            type: "Common Stock"
                                        })
                                    }}>
                                    <img src={logoUrl}
                                         onError={({currentTarget}) => {
                                             currentTarget.onerror = null;
                                             currentTarget.src = building;
                                         }}
                                         style={{width: 20, borderRadius: 5}}/> {company.code}

                                    {/*<img style={{width: 20, borderRadius: 5}}*/}
                                    {/*     src={item.LogoURL && item.LogoURL !== '' ? item.LogoURL : building}*/}
                                    {/*     onError={({currentTarget}) => {*/}
                                    {/*         currentTarget.onerror = null;*/}
                                    {/*         currentTarget.src = building;*/}
                                    {/*     }}/>*/}
                                </td>
                                <td
                                style={{
                                    maxWidth: 110,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                }}><a target={"_blank"} style={{color: "inherit", textDecoration: "none"}}
                                    href={`/company-overview/${company.code}/${company.exchange}/`}>
                                    {company.name}
                                </a></td>
                                <td style={{textAlign: "right"}}>{return_strong_num(company.price)}</td>
                                <td style={{
                                    display: 'flex',
                                    paddingRight: 2
                                }}>
                                    {return_per_div(company.changesPercentage)}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            <Modal
                open={openWatchlistModal}
                onClose={handleCloseWatchlistModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SelectWatchListModal
                    selectedTicker={selectedTicker}
                    save_ticker={save_ticker}
                    handleCloseModal={handleCloseWatchlistModal}
                />
            </Modal>

            <Modal
                open={openNotesModal}
                onClose={handleCloseNotesModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <CompanyNotesModal
                    name={notesCompany.name}
                    currency={notesCompany.currency}
                    priceData={notesCompany.priceData}
                    code={notesCompany.code}
                    exchange={notesCompany.exchange}
                    handleCloseModal={handleCloseNotesModal}
                />
            </Modal>
        </>
    );
}

export default TopFlopsTableBody;