import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk';
import rootReducer from './reducer'

import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewear = [thunk]

const configureStore = () => {
    return createStore(
        persistedReducer,
        {},
        composeWithDevTools(applyMiddleware(...middlewear))
    )
};

export const store = configureStore();
export const persistor = persistStore(store)
