import FilterComponentSector from "./FilterComponentSector";
import FilterComponentExchange from "./FilterComponentExchange";
import FilterComponentRange from "./FilterComponentRange";

function FilterComponentGeneralGroup({filterScreener, setFilterScreener, currency, setCurrency}) {

    return (
        <>
            {Object.entries(filterScreener).map(([groupKey, value]) =>
                filterScreener[groupKey].display && filterScreener[groupKey]['title'] === 'General' &&
                <div className={'filter-component-group'}>
                    <div className={'filter-component-group-container'}>
                        {Object.entries(filterScreener[groupKey].components).map(([key]) => {
                            if (filterScreener[groupKey].components[key].display) {
                                if (filterScreener[groupKey]['components'][key].type === 'exchange') {
                                    return (
                                        <FilterComponentExchange groupKey={groupKey} componentKey={key}
                                                                 filterScreener={filterScreener}
                                                                 setFilterScreener={setFilterScreener}
                                                                 type={'exchanges'}/>
                                    )
                                } else if (filterScreener[groupKey]['components'][key].type === 'index') {
                                    return (
                                        <FilterComponentExchange groupKey={groupKey} componentKey={key}
                                                                 filterScreener={filterScreener}
                                                                 setFilterScreener={setFilterScreener}
                                                                 type={'index'}/>
                                    )
                                } else if (filterScreener[groupKey]['components'][key].type === 'sector') {
                                    return (
                                        <FilterComponentSector
                                            groupKey={groupKey} componentKey={key}
                                            type={'sector'} filterScreener={filterScreener}
                                            setFilterScreener={setFilterScreener}/>
                                    )
                                } else if (filterScreener[groupKey]['components'][key].type === 'industry') {
                                    return (
                                        <FilterComponentSector
                                            groupKey={groupKey} componentKey={key}
                                            type={'industry'} filterScreener={filterScreener}
                                            setFilterScreener={setFilterScreener}/>
                                    )
                                }
                            }
                        })}
                    </div>
                </div>
            )}

            <div className={'filter-component-group'}>
                <div className={'filter-component-group-container'}>
                    <div style={{display: 'flex', flexWrap: "wrap"}}>
                        {Object.entries(filterScreener).map(([groupKey, value]) =>
                            filterScreener[groupKey].display && filterScreener[groupKey]['title'] !== 'General' &&
                            Object.entries(filterScreener[groupKey].components).map(([key]) => {
                                if (filterScreener[groupKey].components[key].display) {
                                    if (filterScreener[groupKey]['components'][key].type === 'range') {
                                        return (
                                            <FilterComponentRange
                                                groupKey={groupKey} componentKey={key}
                                                filterScreener={filterScreener}
                                                setFilterScreener={setFilterScreener}
                                                dropDown={true} currency={currency}
                                                setCurrency={setCurrency}/>
                                        )
                                    }
                                }
                            })
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterComponentGeneralGroup;
