import clsx from "clsx";
import {useEffect, useState} from "react";
import SideBar from "../Container/SideBar";
import {useNavigate} from "react-router-dom";
import Navbar from "../Container/Navbar/Navbar";
import {useDispatch, useSelector} from "react-redux";
import {load_user, RefreshToken, VerifyToken} from "../actions/actions";

const DashboardLayout = ({children}) => {
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openSideBar, setOpenSideBar] = useState(false)
    const handleOpenSidebar = () => {
        setOpenSideBar(!openSideBar)
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(VerifyToken(navigate))
        }
    }, [isAuthenticated])


    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setInterval(() => {
                dispatch(RefreshToken())
            }, 20 * 60000);
        }
    }, [isAuthenticated]);

    return (
        <>
            <Navbar handleOpenSidebar={handleOpenSidebar}/>
            <div style={{display: 'flex', position: 'relative'}}>
                <SideBar openSideBar={openSideBar}/>
                <div className={clsx('dashboard-body')}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default DashboardLayout;