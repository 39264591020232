import React, {useEffect, useState} from 'react';
import ScreenerFilterJson from "../ScreenerFilterJson";
import axios from "axios";
import Config from "../../Config";
import Screener from "../../Pages/Screener";

function UserScreener({screener_id, ScreenerName, setValue, setUserScreeners}) {

    let filterScreenerJSON = JSON.parse(JSON.stringify(ScreenerFilterJson));

    const [filterScreener, setFilterScreener] = useState(null)

    useEffect(() => {
        if (screener_id) {
            axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/screener-filter-settings/${screener_id}/`, Config())
                .then(response => {
                    if (response.data !== '{}') {
                        setFilterScreener(JSON.parse(response.data))
                    } else {
                        setFilterScreener(filterScreenerJSON)
                    }
                })
        } else {
            setFilterScreener(filterScreenerJSON)
        }
    }, [screener_id]);

    return (
        filterScreener &&
        <Screener screener_id={screener_id}
                  ScreenerName={ScreenerName}
                  setValue={setValue}
                  filterScreener={filterScreener}
                  setFilterScreener={setFilterScreener}
                  setUserScreeners={setUserScreeners}/>
    );
}

export default UserScreener;