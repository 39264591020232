import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import Config from "../../Config";
import ReactApexChart from "react-apexcharts";
import {return_strong_num, return_value_in_million} from "../Utils";
import clsx from "clsx";
import {ThemeContext} from "../../Context/ThemeContext";
import RuleOfFortyHighlights from "./RuleOfFortyHighlights";
import ruleOfFortyDark from "../../assets/blockers/rule-of-forty-dark.png";
import ruleOfFortyLight from "../../assets/blockers/rule-of-forty-light.png";
import RestrictedContent from "../RestrictedContent";

function RuleOfFortyAnalysis({code, exchange}) {
    const {webTheme} = useContext(ThemeContext);

    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })
    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    useEffect(() => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-rule-of-40-analysis-data/${code}/${exchange}/`, config)
            .then(response => {
                setAnalysisData(response.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [code, exchange])

    const [dates, setDates] = useState([])
    const [series, setSeries] = useState([{
        name: 'Total Revenue',
        type: "column",
        data: [],
        yaxis: 0
    }, {
        name: 'Net Income',
        type: "column",
        data: [],
        yaxis: 0
    }, {
        name: 'Free Cash Flow',
        type: "column",
        data: [],
        yaxis: 0
    }, {
        name: 'High Growth Ratio',
        type: 'line',
        data: [],
        yaxis: 1
    }
    ])

    const [activeControl, setActiveControl] = useState('Yearly')
    const [graphLoading, setGraphLoading] = useState(true)
    const updateData = (timeline) => {
        setActiveControl(timeline)
        setGraphLoading(true)
    }

    useEffect(() => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/get-rule-of-40-analysis-data-chart/${code}/${exchange}/${activeControl}`, config)
            .then(response => {
                let dates = []
                let total_revenue = []
                let net_income = []
                let free_cash_flow = []
                let high_growth_ratio = []
                if (activeControl === 'Yearly') {
                    for (let i = 0; i < response.data.yearly_income_statement.length; i++) {
                        dates.push(response.data.yearly_income_statement[i].date.substring(0, 7))
                        total_revenue.push(return_value_in_million(response.data.yearly_income_statement[i].totalRevenue))
                        net_income.push(return_value_in_million(response.data.yearly_income_statement[i].netIncome))
                        free_cash_flow.push(return_value_in_million(response.data.yearly_cash_flow[i].freeCashFlow))
                        high_growth_ratio.push(return_strong_num(response.data.yearly_high_growth_ratio[i].highGrowthRatio * 100))
                    }
                } else {
                    for (let i = 0; i < response.data.ttm.length; i++) {
                        dates.push(response.data.ttm[i].date.substring(0, 7))
                        total_revenue.push(return_value_in_million(response.data.ttm[i].historical_data.totalRevenue))
                        net_income.push(return_value_in_million(response.data.ttm[i].historical_data.netIncome_income_statement))
                        free_cash_flow.push(return_value_in_million(response.data.ttm[i].historical_data.freeCashFlow))
                        high_growth_ratio.push(return_strong_num(response.data.ttm[i].historical_data.highGrowthRatio * 100))
                    }
                }

                setDates(dates)

                setSeries([
                        {
                            name: 'Total Revenue',
                            type: "column",
                            data: total_revenue,
                            yaxis: 0
                        },
                        {
                            name: 'Net Income',
                            type: "column",
                            data: net_income,
                            yaxis: 0
                        },
                        {
                            name: 'Free Cash Flow',
                            type: "column",
                            data: free_cash_flow,
                            yaxis: 0
                        },
                        {
                            name: 'High Growth Ratio',
                            type: 'line',
                            data: high_growth_ratio,
                            yaxis: 1
                        },
                    ])

                setGraphLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [code, exchange, activeControl])

    const options = {
        chart: {
            width: '100%',
            type: 'line',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        colors: ["#3e9fed", "#29f3a7", "#ee1981", "#8929cd"],
        dataLabels: {
            enabled: true,
            enabledOnSeries: [3],
            formatter: function (val) {
                return `${val} %`
            }
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        yaxis: [
            {
                labels: {
                    formatter: function (val) {
                        return Math.floor(val)
                    }
                }
            },
            {
                show: false, // This hides the labels but still uses the axis for scaling
                min: 0,
                max: Math.max(...series[0].data), // Keep the range the same
                labels: {
                    show: false // Hide labels for this y-axis
                },
                axisBorder: {
                    show: false // Hide the axis border line
                },
                axisTicks: {
                    show: false // Hide the axis ticks
                }
            },
            {
                show: false, // Hide the labels but use axis for scaling
                min: 0,
                max: Math.max(...series[0].data), // Keep the range the same
                labels: {
                    show: false // Hide labels for this y-axis
                },
                axisBorder: {
                    show: false // Hide the axis border line
                },
                axisTicks: {
                    show: false // Hide the axis ticks
                }
            },
            {
                opposite: true,
                labels: {
                    formatter: function (val) {
                        return `${val} %`
                    }
                }
            },
        ],
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
        },
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
            categories: dates,
        },
    }

    return (
        <RestrictedContent
            conditionText={'You must be paid subscriber to use  this feature'}
            blockerLg={true}
            loginRequired={true}
            subscriptionRequired={true}

            darkBlockerImg={ruleOfFortyDark}
            lightBlockerImg={ruleOfFortyLight}
            code={code}
            exchange={exchange}

            content={
                loading ?
                    <div className={'generic-loading-container'}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    <div style={{position: 'relative', width: '100%', height: '100%'}}>
                        <div className="row g-0">

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">

                                <RuleOfFortyHighlights
                                    title={'Rule-of-40 Analysis'}
                                    data={[
                                        {key: 'Revenue Growth', value: analysisData['revenueGrowth'] * 100},
                                        {key: 'FCF/Margin', value: analysisData['fcfMargin'] * 100}
                                    ]}
                                    additional={{
                                        key: 'Rule-of-40',
                                        value: analysisData['highGrowthRatio'] * 100
                                    }}
                                />
                            </div>

                            <div className="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12">
                                <div className={'rule-of-forty-chart-container'}>
                                    <div className={'rule-of-forty-inner-container'}>
                                        <div style={{display: 'flex'}}>
                                            <p className={'rule-of-forty-chart-name'}>{code}</p>

                                            <div style={{display: 'flex', marginLeft: 'auto'}}>
                                                <button onClick={() => updateData('Yearly')}
                                                        className={clsx('btn graph-controls', activeControl === "Yearly" ? "btn-danger" : "btn-warning")}
                                                        style={{color: activeControl === "Yearly" && 'white'}}
                                                >
                                                    Yearly
                                                </button>
                                                &nbsp;
                                                <button onClick={() => updateData('TTM')}
                                                        className={clsx('btn graph-controls', activeControl === "TTM" ? "btn-danger" : "btn-warning")}
                                                        style={{color: activeControl === "TTM" && 'white'}}>
                                                    TTM
                                                </button>
                                                &nbsp;
                                            </div>
                                        </div>
                                        {graphLoading ?
                                            <div style={{width: '100%', height: '85%', display: 'flex'}}>
                                                <div className="spinner-grow text-warning m-auto" role="status"
                                                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                                                </div>
                                            </div>
                                            :
                                            <ReactApexChart options={options} series={series} type={'line'}
                                                            width={'100%'}
                                                            height={'93%'}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        />
    );
}

export default RuleOfFortyAnalysis;