import React, {useContext, useEffect, useState} from 'react';
import {return_strong_num, return_value_in_million} from "../../../Utils";
import {ThemeContext} from "../../../../Context/ThemeContext";
import ReactApexChart from "react-apexcharts";

function FinancialOverviewDebtEquityChart({quarterData}) {
    const [series, setSeries] = useState([
        {
            name: 'Debt',
            data: []
        },
        {
            name: 'Equity',
            data: []
        },
        {
            name: 'Cash & Equivalents',
            data: []
        },
    ])

    useEffect(() => {
        let totalDebt = []
        let totalStockHolderEquity = []
        let cashAndShortTermInvestments = []

        quarterData.dates.map((date, index) => {
            totalDebt.push({
                x: date,
                y: return_strong_num(quarterData['Total Debt'][index])
            })
            totalStockHolderEquity.push({
                x: date,
                y: return_strong_num(quarterData['Total Stock Holder Equity'][index])
            })
            cashAndShortTermInvestments.push({
                x: date,
                y: return_strong_num(quarterData['Cash and Short Term Investments'][index])
            })
        })

        setSeries([
            {
                name: 'Debt',
                data: totalDebt
            },
            {
                name: 'Equity',
                data: totalStockHolderEquity
            },
            {
                name: 'Cash & Equivalents',
                data: cashAndShortTermInvestments
            },
        ])
    }, [quarterData]);

    const {webTheme} = useContext(ThemeContext);

    const [graphVar, setGraphVar] = useState({
        color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"
    })
    const [graphVarChange, setGraphVarChange] = useState(false)

    useEffect(() => {
        setGraphVar({color: webTheme === 'dark' ? "#FFFFFF" : "#0C1B29"})
        setGraphVarChange(true)
    }, [webTheme])

    useEffect(() => {
        if (graphVarChange) {
            setGraphVarChange(false)
        }
    }, [graphVarChange])

    const lineOptions = {
        chart: {
            type: 'area',
            width: '100%',
            foreColor: graphVar.color,
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: ["#ee1981", "#3e9fed", "#29f3a7"],
        xaxis: {
            color: graphVar.color,
            type: 'datetime',
        },
        legend: {
            show: true,
            position: 'top',
            formatter: function (seriesName, opts) {
                return seriesName
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return return_value_in_million(val)
                }
            }
        },
        fill: {
            opacity: 0.5
        },
        tooltip: {
            x: {
                format: 'MMM dd yyyy'
            },
            y: {
                formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                    return return_value_in_million(value)
                }
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        }
    }

    return (
        <div className={'rule-of-forty-chart-container'}>
            <div className={'rule-of-forty-inner-container'} style={{height: 294, padding: "10px 10px 0 10px"}}>
                <p className={'market-overview-chart-name'}>Debt & Equity (mln.)</p>
                <ReactApexChart options={lineOptions} series={series} type={'area'}
                                width={'100%'}
                                height={'90%'}/>
            </div>
        </div>
    );
}

export default FinancialOverviewDebtEquityChart;